import { Tag } from 'bbcode-to-react'
import styles from './style.module.scss'
import React from 'react'
import CdnApi from '../../../../../../../api/CdnApi'
import { onErrorResponseShowMessage } from '../../../../../../../utils/onErrorReponseShowMessage/onErrorResponseShowMessage'
import { useSelector } from 'react-redux'
import { State } from '../../../../../../../redux/reducers/index'

type Props = {
  'cdn-img': string
}

export default class DepeszaCdnImgTag<T = { linkify: boolean }> extends Tag<T> {
  toReact() {
    const props = this.params as Props
    return (
      <>
        <LoadImageProps {...props} />
      </>
    )
  }
}

const LoadImageProps = (props: Props) => {
  const userUuid = useSelector((e: State) => e.user.uuid)

  const [image, setImage] = React.useState<any>(null)
  const [imageWidth, setImageWidth] = React.useState<number>(1280)
  const [imageHeight, setImageHeight] = React.useState<number>(720)

  React.useEffect(() => {
    CdnApi.getImageDetails(props['cdn-img']).then(res => {
      setImage(res)
      const width = ((res as any).width ?? 1280 as number) > 462 ? 462 : ((res as any).width as number)
      const widthPercentage =
          ((res as any).width ?? 1280 as number) > width ? width / ((res as any).width ?? 1280 as number) : 1
      const height = ((res as any).height ?? 720 as number) * widthPercentage
      setImageHeight(height)
      setImageWidth(width)
    }).catch(error => onErrorResponseShowMessage(error, true, { userUuid: userUuid }))
  },[])
  return (
    <>
      {
        image != null
        ? <div className={styles.cdnImg_imageContainer}>
            <div
              style={{
                width: imageWidth || 1280,
                height: imageHeight || 720
              }}
              className={styles.cdnImg_image}
            >
              <img
                width={imageWidth || 1280}
                height={imageHeight || 720}
                alt={image.alt}
                src={`${image.hostname}${image.path}${image.filename}`}
              />
              {
                image.signature
                ? <div className={styles.cdnImg_signature}>{image.signature}</div>
                : null
              }
            </div>
          </div>
        : null
      }
    </>
  )
}