import 'moment/locale/pl'
import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Switch, withRouter } from 'react-router-dom'
import { version } from '../package.json'
import './App.css'
import AccountActivation from './components/auth/AccountActivation/AccountActivation'
import AccountVerifyEmail from './components/auth/AccountVerifyEmail/AccountVerifyEmail'
import ResendActivationEmail from './components/auth/ResendActivationEmail/ResendActivationEmail'
import ResetPasswordFinish from './components/auth/ResetPasswordFinish/ResetPasswordFinish'
import ResetPasswordRequest from './components/auth/ResetPasswordRequest/ResetPasswordRequest'
import SignIn from './components/auth/SignIn/SignIn'
import PageCover from './components/common/PageCover/PageCover'
import CustomToastHook from './components/CustomToastProvider/CustomToastHook'
import CustomToastProvider from './components/CustomToastProvider/CustomToastProvider'
import { MessageEnum } from './components/CustomToastProvider/types'
import Dashboard from './components/Dashboard/Dashboard'
import { addCmdMessage } from './redux/messages/actions'
import { State } from './redux/reducers/index'
import store from './store'
import { Project } from './types/common'

export const ROUTER_BASE_NAME = '/'

function App({ location }: any) {
  const message = useSelector((e: State) => e.messages)

  const [showCover, setShowCover] = React.useState<boolean>(false)
  const [showDialogCover, setShowDialogCover] = React.useState<boolean>(false)

  React.useEffect(() => {
    store.dispatch(addCmdMessage(MessageEnum.DISABLE_PAGE_COVER))
  }, [location.pathname])

  React.useEffect(() => {
    switch (process.env.REACT_APP_PROJECT) {
      case Project.DEPESZA:
        console.log(`Depesza CMS v. ${version}`)
        break
      case Project.ZARADNA:
        console.log(`ZaradnaKobieta CMS v. ${version}`)
        break
    }
  }, [])

  React.useEffect(() => {
    switch (message.cmd) {
      case MessageEnum.ENABLE_PAGE_COVER:
        setShowCover(true)
        break
      case MessageEnum.DISABLE_PAGE_COVER:      
        setShowCover(false)
        setShowDialogCover(false)
        break
      case MessageEnum.ENABLE_DIALOG_COVER:
        setShowDialogCover(true)
        break
      case MessageEnum.DISABLE_DIALOG_COVER:
        setShowDialogCover(false)
        break
    }
  }, [message])

  return (
    <>
      <CustomToastProvider>
        <Switch>
          <Route
            path={'/login' }
            exact 
            component={SignIn} 
            key={document.location.href}
          />
          <Route
            path={'/password/reset/finish'}
            exact
            component={ResetPasswordFinish}
            key={document.location.href}
          />
          <Route
            path={'/password/reset/request'}
            exact
            component={ResetPasswordRequest}
            key={document.location.href}
          />
          <Route
            path={'/account/activation/resend'}
            exact
            component={ResendActivationEmail}
          />
          <Route
            path={'/account/activate'}
            exact
            component={AccountActivation}
          />
          <Route
            path={'/account/email/verify'}
            exact
            component={AccountVerifyEmail}
          />
          <Route 
            path={'/'}
            component={Dashboard} 
            key={document.location.href}
          />
        </Switch>
        <CustomToastHook />
        <PageCover show={showCover} zIndex={100} />
        <PageCover show={showDialogCover} zIndex={200} />
      </CustomToastProvider>
    </>
  )
}

export default withRouter(App);
