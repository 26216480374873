import { Tag } from 'bbcode-to-react'
import React from 'react'
import styles from './style.module.scss'
import {decode} from 'html-entities'

export default class ZaradnaInstagramTag<T = { linkify: boolean }> extends Tag<T> {
  toReact() {
    return (
      <div
        className={styles.instagram_container}
        dangerouslySetInnerHTML={{
          __html: decode(this.getContent().replace(/<br>|<br\/>|<br \/>/g, ''))
        }}
      >
      </div>
    )
  }
}