import { MdVisibility, MdVisibilityOff } from 'react-icons/md'
import styles from './styles.module.scss'
import { FormikProps } from 'formik'
import React from 'react'
import { preventEnterKey } from '../../../utils/form'

type Props = {
  name: string
  id?: string
  className?: string
  errorClassName?: string
  errorLabelClassName?: string
  type?: string
  error?: boolean
  errorLabel?: JSX.Element
  showPassword?: boolean
  formikProps?: FormikProps<any>
  value?: string
  setValue?: React.Dispatch<React.SetStateAction<string>>
  showPasswordOnClick?: () => void
}

const CustomPasswordField = (props: Props) => {
  const [value, setValue] = React.useState<string>("")
  
  return (
    <div>
      <div style={{
        width: '100%',
        position: 'relative'
      }}>
        <input
          {...props}
          className={`${(props.className || `${styles.input} ${props.error ? styles.inputError : styles.inputNormal }`)}`}
          onKeyDown={preventEnterKey}
          style={{
            paddingRight: '2.5rem'
          }}
          onChange={(ev) => {
            props.setValue ? props.setValue(ev.target.value) : setValue(ev.target.value)
            props.formikProps?.setFieldValue(
              props.name,
              ev.target.value
            )
          }}
        />
        <span 
          onClick={props.showPasswordOnClick}
          className={styles.showPasswordButton}
        >
          {props.showPassword ? <MdVisibility size={'1.25rem'}/> : <MdVisibilityOff size={'1.25rem'}/>}
        </span>
      </div>
      {
        props.error && props.errorLabel ? <div className={props.errorLabelClassName || styles.errorLabel}>
          {props.errorLabel}
        </div> : null
      }
    </div>
  )
}

export default CustomPasswordField
