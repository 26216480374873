import { Form, Formik } from 'formik'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import AuthRefreshApi from '../../../api/AuthRefreshApi'
import { addSuccessMessage } from '../../../redux/messages/actions'
import store from '../../../store'
import { onErrorResponseShowMessage } from '../../../utils/onErrorReponseShowMessage/onErrorResponseShowMessage'
import { changePasswordValidation } from '../../../utils/validations/changePasswordValidation'
import LoadingButton from '../../common/LoadingButton/LoadingButton'
import PasswordInput from '../../common/PasswordInput/PasswordInput'
import { MessageEnum } from '../../CustomToastProvider/types'
import styles from './styles.module.scss'

type InitialFormValues = {
  currentPassword: string
  newPassword: string
  reNewPassword: string
}

const initialValues: InitialFormValues = {
  currentPassword: '',
  newPassword: '',
  reNewPassword: ''
}

export type Props = {
  onFinish: () => void
}

const ChangePassword = (props: Props) => {
  const texts = useTranslation().t

  const [initialFormValues, setInitialFormValues] = React.useState<InitialFormValues>(initialValues)
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false)

  const formRef = useRef() as any

  const handleSubmit = async (data: any) => {
    try {
      setIsSubmitting(true)
      await AuthRefreshApi.changePassword(data.currentPassword, data.newPassword)
      store.dispatch(addSuccessMessage(MessageEnum.PWD_HAS_BEEN_CHANGED))
      props.onFinish()
    } catch (error) {
      onErrorResponseShowMessage(error)
    } finally {
      setIsSubmitting(false)
    }
  }
  
  return (
    <>
      <Formik
        initialValues={initialFormValues}
        validationSchema={changePasswordValidation(texts)}
        onSubmit={handleSubmit}
        validateOnBlur={true}
        validateOnChange={true}
        enableReinitialize
        innerRef={formRef}
      >
        {(formikProps) => (
          <Form>
            <div className={styles.passwordsContainer}>
              <PasswordInput
                name={'currentPassword'}
                label={texts('auth:current_password')}
                classes={{
                  label: styles.inputLabel
                }}
                formikProps={formikProps}
                value={formikProps.values.currentPassword}
                error={
                  formikProps.touched.currentPassword && Boolean(formikProps.errors.currentPassword)
                }
              />
              <PasswordInput
                name={'newPassword'}
                label={texts('auth:new_password')}
                classes={{
                  label: styles.inputLabel
                }}
                formikProps={formikProps}
                value={formikProps.values.newPassword}
                error={
                  formikProps.touched.newPassword && Boolean(formikProps.errors.newPassword)
                }
              />
              <PasswordInput
                name={'reNewPassword'}
                label={texts('auth:re_new_password')}
                classes={{
                  label: styles.inputLabel
                }}
                formikProps={formikProps}
                value={formikProps.values.reNewPassword}
                error={
                  formikProps.touched.reNewPassword && Boolean(formikProps.errors.reNewPassword)
                }
              />
            </div>
            <LoadingButton
              type={'button'}
              className={styles.formButton}
              loading={isSubmitting}
              onClick={() => {
                formikProps.submitForm()
              }}
            >
              <div className={styles.formButtonContent}>{texts('auth:change_password')}</div>
            </LoadingButton>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default ChangePassword