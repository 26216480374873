import { Tag } from 'bbcode-to-react'
import styles from './style.module.scss'

export default class ZaradnaListTag<T = { linkify: boolean }> extends Tag<T> {
  toReact() {
    return (
      <ul className={styles.ul}>
        {this.getComponents()}
      </ul>
    )
  }
}