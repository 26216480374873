import React from 'react'
import styles from './style.module.scss'
import { useTranslation } from 'react-i18next'
import { Project } from '../../../types/common'

const HorizontalAdBlock = () => {
  const texts = useTranslation().t

  let placeholderClass: string = ''
  switch (process.env.REACT_APP_PROJECT) {
    case Project.ZARADNA:
      placeholderClass = styles.adContainerZaradnaPlaceholder
      break
    case Project.DEPESZA:
    default:
      placeholderClass = styles.adContainerDepeszaPlaceholder
      break
  }

  return (
    <div className={styles.adBlock}>
      {texts('common:advert_block_label')}
      <div 
        className={[
          styles.adContainer,
          placeholderClass
        ].join(' ')}
      >
        <div className={styles.adFlexSpacer}></div>
        <div 
          className={[
            styles.ad,
          ].join(' ')}
        >
          <div>
          </div>
        </div>
        <div className={styles.adFlexSpacer}></div>
      </div>
    </div>
  )
}

export default HorizontalAdBlock
