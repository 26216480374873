import { Tag } from 'bbcode-to-react'
import React from 'react'
import styles from './style.module.scss'
import { decode } from 'html-entities'

type Props = {
  pinterest?: string
}

export default class ZaradnaPintrestTag<T = { linkify: boolean }> extends Tag<T> {
  toReact() {
    const props = this.params as Props
    return (
      <div
        className={styles.pintrest_container}
        {...(props.pinterest && props.pinterest !== '' ? {} : {
          dangerouslySetInnerHTML: {
            __html: decode(this.getContent().replace(/<br>|<br\/>|<br \/>/g, ''))
          }
        })}
      >
        {
          props.pinterest && props.pinterest !== ''
          ? <iframe 
              src={`https://assets.pinterest.com/ext/embed.html?id=${props.pinterest}`}
              height="900" 
              width="450" 
              frameBorder="0" 
              scrolling="no"
            ></iframe>
          : null
        }
        
      </div>
    )
  }
}