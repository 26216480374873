import { ErrorMessage, FormikProps } from 'formik'
import React from 'react'
import Scrollbars from 'react-custom-scrollbars'
import { useTranslation } from 'react-i18next'
import Select, { components } from 'react-select'
import Checkbox from '../../../lib/react-custom-checkbox'
import { AiOutlineCheck } from 'react-icons/ai'
import styles from './styles.module.scss'

type Props = {
  id?: string
  instanceId: string
  name: string
  disabled?: boolean
  isMulti?: boolean
  className?: string
  errorClassName?: string
  errorLabelClassName?: string
  warningLabelClassName?: string
  warningMessage?: string
  warning?: boolean
  error?: boolean
  value?: {value: any, label: string} | {value: any, label: string}[] | null
  setValue?: React.Dispatch<React.SetStateAction<{value: any, label: string} | null>>
  options: any[]
  placeholder?: string
  formikProps?: FormikProps<any>
  customSelectStyles?: object
  noErrorLabel?: boolean
  height?: string
  portalZIndex?: number
}

const renderSelectMenuListScrollbar = (props: any) => {
  const len = props.children.length
  return (
    <div style={{ 
      height: len ? (len < 10 ? `${len * 33}px` : '300px') : '33px', 
      backgroundColor: '#fff',
      color: '#383838'
    }}>
      <Scrollbars 
        universal={true} 
        renderThumbVertical={
          ({ style, ...props }: { style: React.CSSProperties, [x: string]: any}) => <div {...props} style={{ ...style, backgroundColor: '#38383866', width: '4px', opacity: '0.5'}}/>
        }
      >
        {props.children}
      </Scrollbars>
    </div>
  )
}

const MultiOption = (props: any) => {
  return (
    <div
      style={{
        height: '33px'
      }}
    >
      <components.Option {...props}>
        <Checkbox 
          checked={props.isSelected}
          borderRadius={3}
          size={15}
          borderColor={'#383838'}
          containerStyle={{
            display: 'flex',
            alignItems: 'flex-start',
          }}
          style={{
            marginTop: '2px'
          }}
          icon={
            <div style={{
              fontSize: '0.938rem',
              borderRadius: 3,
              height: '0.938rem',
              color: '#FFF',
              backgroundColor: '#383838',
              margin: 0
            }}>
              <AiOutlineCheck size={15} color={'#fff'}/>
            </div>
          }
          labelStyle={{
            fontFamily: 'Roboto',
            color: '#383838',
            fontSize: '0.938rem',
            paddingLeft: '5px',
            whiteSpace: 'break-spaces'
          }}
          label={<label>{props.label}</label>}
        />
      </components.Option>
    </div>
  )
}

const ValueContainer = ({ children, ...props }: { children: any, [x: string]: any}) => {
  const { getValue, hasValue } = props;
  const nbValues = getValue().length;
  const label = (getValue() as any[]).map(e => e.label).join(', ')
  return (
    <components.ValueContainer {...props as any}>
      <div style={{
        maxWidth: '100%',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
      }}>
      {`${nbValues > 1 ? `(${nbValues}) ` : ''}${label}`}
      </div>
      {nbValues === 0 ? children.slice(-2, -1) : null}
      {children.slice(-1)}
    </components.ValueContainer>
  )
}

// TODO change to rem

const CustomSelectField = (props: Props) => {
  const [value, setValue] = React.useState<{value: any, label: string} | null>(null)

  const t = useTranslation("forms").t

  const customSelectStyles = {
    container: (provided: any, state: any) => ({
      ...provided,
      width: '100%',
      flex: 1,
      "@media (max-width: 600px)": {
        width: '100%'
      }
    }),
    menuPortal: (provided: any, state: any) => ({
      ...provided,
      margin: 0,
      borderRadius: 100,
      zIndex: props.portalZIndex
    }),
    menuList: (provided: any, state: any) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      borderRadius: 100
    }),
    indicatorSeparator: (provided: any, state: any) => ({
      ...provided,
      width: 0
    }),
    indicatorsContainer: (provided: any, state: any) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      ...( props.height ? { height: props.height } : {})
    }),
    valueContainer: (provided: any, state: any) => {
      return ({
      ...provided,
      fontSize: '0.938rem',
      paddingLeft: 14,
      fontFamily: 'Roboto',
      ...(props.isMulti ? { maxWidth: 'calc(100% - 40px)', flexWrap: 'nowrap' } : {})
    })},
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: '#fff',
      color: state.data.color || '#383838',
      padding: 5,
      paddingLeft: state.data.level ? state.data.level * 20 + 5 : 5,
      fontFamily: 'Roboto',
      textAlign: 'left',
      '&:hover': {
        backgroundColor: '#38383866'
      }
    }),
    control: (provided: any, state: any) => ({
      ...provided,
      height: props.height ?? '38px',
      minHeight: props.height ?? '38px',
      opacity: state.isDisabled ? 0.4 : 1,
      backgroundColor: '#fff',
      borderRadius: '5px',
      padding: state.isFocused ? 0 : 1,
      border: state.isFocused ? `2px solid ${props.error ? 'red' : '#383838'}` : `1px solid ${props.error ? 'red' : '#383838'}`, 
      boxShadow: 'none',
      '&:hover': {
        border: state.isFocused ? `2px solid ${props.error ? 'red' : '#383838'}` : `1px solid ${props.error ? 'red' : '#383838'}`
      }
    }),
    singleValue: (provided: any, state: any) => ({
      ...provided,
      color: state.data.placeholder ? 'rgba(255,255,255,0.35)' : '#383838',
    }),
    input: (provided: any, state: any) => ({
      ...provided,
      margin: 0,
      fontSize: '0.938rem',
      fontFamily: 'Roboto',
    })
  }

  const renderNoOptionMessage = (props: any) => {
    return (
      <div style={{
        fontFamily: 'Roboto',
        textAlign: 'center',
        color: '#38383866',
        paddingTop: '6px'
      }}>
        {t("messages:no_option_message")}
      </div>
    )
  }

  return (
    <div style={{ 
      minHeight: props.noErrorLabel ? '43px' : '62px',
      marginTop: 0
    }}>
      <Select 
        onBlur={()=> {
          props.formikProps?.setFieldTouched(props.name)
        }}
        menuPortalTarget={document.body}
        instanceId={props.instanceId}
        backspaceRemovesValue={false}
        name={props.name}
        options={props.options}
        isDisabled={props.disabled}
        isMulti={props.isMulti}
        hideSelectedOptions={false}
        isClearable={false}
        closeMenuOnSelect={props.isMulti != null ? !props.isMulti : true}
        value={props.value}
        onChange={(sel) => {
          props.formikProps?.setFieldTouched(props.name)
          props.formikProps?.setFieldValue(
            props.name,
            sel
          )
          if (props.setValue) {
            props.setValue(sel as any)
          } else {
            setValue(sel as any)
          }
        }}
        placeholder={props.placeholder}
        styles={props.customSelectStyles || customSelectStyles}
        components={{
          MenuList: renderSelectMenuListScrollbar,
          NoOptionsMessage: renderNoOptionMessage,
          ...(props.isMulti ? { 
            Option: MultiOption,
            // ValueContainer: ValueContainer
          } : {})
        }}
    />
      {
        !props.error && props.warning && props.warningMessage ? <div className={props.warningLabelClassName || styles.warningLabel} style={{
          float: 'left'
        }}>
          {props.warningMessage}
        </div> : null
      }
      {
        props.error && props.name ? <div className={props.errorLabelClassName || styles.errorLabel} style={{
          float: 'left'
        }}>
          <ErrorMessage name={props.name} />
        </div> : null
      }
    </div>
  )
}

export default CustomSelectField
