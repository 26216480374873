import { Tag } from 'bbcode-to-react'
import styles from './style.module.scss'

type Props = {
  'telegram-post': string
}

export default class DepeszaTelegramPostTag<T = { linkify: boolean }> extends Tag<T> {
  toReact() {
    const props = this.params as Props

    return (
      <div className={styles.telegramPost_container}>
        <iframe
          id={`telegram-post-embed-${props['telegram-post']}`}
          width={'500px'}
          height={'250px'}
          frameBorder={0}
          srcDoc={`
            <html>
              <body>
                <script>
                  this.container = this.frameElement.contentWindow.document.body;
                  this.watch = () => {
                    cancelAnimationFrame(this.watcher);
                    if (this.lastScrollHeight !== container.scrollHeight) {
                      parent.resizeIframeHeight(this.frameElement);  
                    }
                    this.lastScrollHeight = container.scrollHeight;
                    this.watcher = requestAnimationFrame(this.watch);
                  };
                  this.watcher = window.requestAnimationFrame(this.watch);
                </script>
                <div>
                  <script async src="https://telegram.org/js/telegram-widget.js?15" data-telegram-post="${props['telegram-post']}" data-width="100%"></script>
                </div>
              </body>
            </html>
          `}
        >
        </iframe>
      </div>
    )
  }
}