import React from 'react'
import { useTranslation } from 'react-i18next'
import AssetLoader from '../../../assets/loader'
import BBCodeArticle from '../BBCodeArticle/BBCodeArticle'
import Timestamp from '../Timestamp/Timestamp'
import styles from './styles.module.scss'

type Props = {
  contentFieldName: string
  formikProps: any
  image?: {
    src: string
    signature?: string
  }
  galleryData: any
  pageNumber?: number
  pageCount: number
  leadText: string
}

const BBCodeZaradnaGalleryPagePreview = (props: Props) => {
  const texts = useTranslation().t

  return (
    <>
      <h1 className={styles.galleryTitle}>
        { props.galleryData.title }
      </h1>
      <div className={styles.galleryPage}>
        <div className={styles.pageImageContainer}>
          <div className={styles.pageCounter}>
            {`${props.pageNumber ?? '-'}/${props.pageCount}`}
          </div>
          <div className={styles.pageImageNoLink}>
            <div className={styles.pageImage}>
              {
                props.image?.src
                ? <img
                    src={props.image.src}
                    className={styles.image}
                    alt={''}
                  />
                : null
              }
            </div>
          </div>
          {
            props.image?.signature
            ? <div className={styles.pageImageSignature}>
                {props.image.signature}
              </div>
            : null
          }
        </div>
      </div>
      <div className={styles.goToBottomButtonContainer}>
        <div
          className={styles.goToBottomButton}
          onClick={() => {
            document.getElementById('next-page')?.scrollIntoView({behavior: 'smooth'})
          }}
        >
          <svg width="26" height="26" viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
            <g stroke="#FFF" strokeWidth="2" fill="none" fillRule="evenodd" strokeDasharray="0,0" strokeLinecap="round" strokeLinejoin="round">
              <path d="M13 1.333v23.333M24.667 13 13 24.667 1.333 13"/>
            </g>
          </svg>
        </div>
      </div>
      <div className={styles.pageArticle}>
        <div className={styles.content}>
          <BBCodeArticle bbcode={props.formikProps.values[props.contentFieldName]} />
        </div>
        <p className={styles.articleLeadText}>
          {props.leadText}
        </p>
      </div>
      <div id={'next-page'} className={styles.nextPageNameSection}>
        <span className={styles.nextPageNameLabel}>{texts('gallery:see_next_page_label')} </span> {props.galleryData.title} - galeria zdjęć
      </div>
      <div className={styles.galleryNextPageTileContainer}>
        <div className={styles.nextPageSection}>
          <div className={styles.nextPageTile}>
            <div 
              className={
                [
                  styles.imageDarkGradientBase,
                  styles.gallerySectionTileGradientNormal,
                  styles.gallerySectionTileGradientMoz,
                  styles.gallerySectionTileGradientWebkit,
                  styles.gallerySectionTileGradientWebkitLinear
                ].join(' ')
              }
            ></div>
            <div className={styles.nextPageImageContainer}>
              <div className={styles.nextPageTileButtonContainer}>
                <div className={styles.nextPageTileButton}>
                  <div className={styles.nextPageTileButtonLabel}>
                    <div className={styles.nextPageTileButtonLabelText}>{texts('gallery:next_image_nbsp')}</div>
                    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                      <defs>
                        <path id="a" d="M0 0h24v24H0z"/>
                      </defs>
                      <g fill="none" fill-rule="evenodd">
                        <mask id="b" fill="#fff">
                          <use xlinkHref="#a"/>
                        </mask>
                        <path stroke="#FFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0,0" mask="url(#b)" d="M5 12h14M12 5l7 7-7 7"/>
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
              <div className={styles.nextPageTileImage}>
                <img
                  src={AssetLoader.getPlaceholderImage()}
                  width={700}
                  height={500}
                  alt={''}
                  className={styles.nextPageImage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.galleryInfo}>
        <span className={styles.galleryInfoAuthor}>
          {`${texts('gallery:author_preview_label')}${props.galleryData?.author ?? ''}, `}
        </span>
        <span className={styles.galleryInfoDate}>
          <Timestamp 
            date={props.galleryData.publishedAt}
            hideTime
          />
        </span>
      </div>
    </>
  )
}

export default BBCodeZaradnaGalleryPagePreview
