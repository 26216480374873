import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { MdCameraAlt, MdKeyboardArrowDown } from 'react-icons/md'
import BBCodeArticle from '../BBCodeArticle/BBCodeArticle'
import { SinglePhoto } from '../MediaLibrary/types'
import styles from './styles.module.scss'
import AssetLoader from '../../../assets/loader'
import Timestamp from '../Timestamp/Timestamp'
import { evalStringTemplate } from '../../../utils/text';

type Props = {
  contentFieldName: string
  formikProps: any
  mainImageData?: {
    img: SinglePhoto
    croppedImage?: Blob
  } | null
  gallery?: any
  publishedAt?: string | Date
  modifiedAt?: string | Date
}

const BBCodeDepeszaArticlePreview = (props: Props) => {
  const texts = useTranslation().t

  const getMainImgUrl = () => {
    if (props.mainImageData == null) {
      return ''
    } else if (props.mainImageData.croppedImage != null) {
      return URL.createObjectURL(props.mainImageData.croppedImage)
    } else {
      return `${props.mainImageData.img.hostname}${props.mainImageData.img.path}${props.mainImageData.img.filename}`
    }
  }

  return (
    <>
      <div className={styles.previewTitle}>
        {
          props.formikProps.values.title
        }
      </div>
      {
        props.mainImageData
        ? <div className={styles.mainImageContainer}>
            <img
              className={styles.mainImage}
              src={getMainImgUrl()}
              alt={''}
            />
            {
              props.mainImageData.img.signature != null
              ? <div className={styles.mainImageSource}>{props.mainImageData.img.signature}</div>
              : null
            }
          </div>
        : null
      }
      {
        props.gallery
        ? <div
            className={styles.goToGalleryButton}
            onClick={() => {
              document.getElementById('gallery')?.scrollIntoView({behavior: 'smooth'})
            }}
          >
            <MdCameraAlt size={32}/>
            <div className={styles.goToGalleryButtonLabel}>{texts('editor:go_to_gallery_button')}</div>
            <MdKeyboardArrowDown size={24}/>
          </div>
        : null
      }
      <div className={styles.articleInfo}>
        {
          props.formikProps.values.hideAuthor
          ? null
          : <div className={styles.articleInfoAuthor}>
              {`${props.formikProps.values.author ?? '-'}, `}
            </div>
        }
        {
          props.publishedAt
          ? <Timestamp 
              date={props.publishedAt}
              showDateTime
            />
          : null
        }
      </div>
      <div className={styles.articleLeadText}>
        {
          props.formikProps.values.leadText
        }
      </div>
      <div className={styles.content}>
        <BBCodeArticle bbcode={props.formikProps.values[props.contentFieldName]} />
      </div>
      {
        props.gallery
        ? <>
            <div className={styles.gallerySectionNameSection}>
              <span className={styles.gallerySectionNameLabel}>{texts('editor:see_gallery_label')} </span> {props.gallery.title}
            </div>
            <div id={'gallery'} className={styles.gallerySection}>
              <div className={styles.gallerySectionSpacer}></div>
              <div className={styles.gallerySectionTile}>
                <div 
                  className={
                    [
                      styles.gallerySectionTileGradientBase,
                      styles.gallerySectionTileGradientNormal,
                      styles.gallerySectionTileGradientMoz,
                      styles.gallerySectionTileGradientWebkit,
                      styles.gallerySectionTileGradientWebkitLinear
                    ].join(' ')
                  }
                ></div>
                <div className={styles.gallerySectionTileButton}>
                  <div className={styles.gallerySectionTileButtonLabel}>
                    <MdCameraAlt size={32}/>
                    <div className={styles.gallerySectionTileButtonLabelText}>{texts('editor:see_gallery_label_nbsp')}</div>
                  </div>
                  {
                    props.gallery.pageCount !== null
                    ? <div className={styles.gallerySectionTileButtonCount}>
                        {evalStringTemplate(texts('gallery:galleryCountLabel'), {count: props.gallery.pageCount, plural: (props.gallery.pageCount === 1 ? 'zdjęcie' : props.gallery.pageCount %10 >= 2 && props.gallery.pageCount %10 <=4 && (props.gallery.pageCount %100 < 10 || props.gallery.pageCount %100 >= 20) ? 'zdjęcia' : 'zdjęć')})}
                      </div>
                    : null
                  }
                </div>
                <img
                  src={
                    props.gallery.firstPage?.photo
                    ? `${props.gallery.firstPage.photo.hostname}${props.gallery.firstPage.photo.path}${props.gallery.firstPage.photo.filename}`
                    : AssetLoader.getPlaceholderImage()
                  }
                  width={660}
                  height={470}
                  className={[
                    styles.galleryImage,
                    props.gallery.showAdultWarning || props.gallery.firstPage?.showAdultWarning ? styles.galleryImageContainerBlur : ''
                  ].join(' ')}
                />
              </div>
            </div>
          </>
        : null
      }
      {
        props.formikProps.values.tags && props.formikProps.values.tags.length > 0 && !props.formikProps.values.isWikiArticle
        ? <div className={styles.previewTagsContainer}>
            {
              props.formikProps.values.tags.map((tag: any) => (
                <div className={styles.previewTag}>{`#${tag.label}`}</div>
              ))
            }
          </div>
        : null
      }
      <div className={styles.sponsoredSourceContainer}>
        {
          props.formikProps.values.source != null && props.formikProps.values.source !== ''
          ? <div className={styles.articleSource}>
              {`${texts('editor:source_label')}: ${props.formikProps.values.source}`}
            </div>
          : null
        }
        {
          props.formikProps.values.sponsoredContent 
          ? <div className={styles.sponsoredArticle}>
              {
                props.formikProps.values.sponsor != null && props.formikProps.values.sponsor !== ''
                ? props.formikProps.values.sponsor
                : texts('article:sponsoredLabel')
              }
            </div> 
          : null
        }
      </div>
    </>
  )
}

export default BBCodeDepeszaArticlePreview
