export const monthList: [
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string
] = [
  'Styczeń',
  'Luty',
  'Marzec',
  'Kwiecień',
  'Maj',
  'Czerwiec',
  'Lipiec',
  'Sierpień',
  'Wrzesień',
  'Październik',
  'Listopad',
  'Grudzień'
]

export const dayListShorthand: [
  string,
  string,
  string,
  string,
  string,
  string,
  string
] = [
  'Nd',
  'Pn',
  'Wt',
  'Śr',
  'Cz',
  'Pt',
  'Sb'
]

export const dayList: [
  string,
  string,
  string,
  string,
  string,
  string,
  string
] = [
  'Niedziela',
  'Poniedziałek',
  'Wtorek',
  'Środa',
  'Czwartek',
  'Piątek',
  'Sobota'
]