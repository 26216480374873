import { Tag } from 'bbcode-to-react'
import styles from './style.module.scss'

type Props = {
  signature?: string
}

export default class QuoteTag<T = { linkify: boolean }> extends Tag<T> {
  toReact() {
    const props = this.params as Props
    return (
      <blockquote className={styles.quote_block}>
        <em className={styles.quote_quote}>„{this.getComponents()}”</em>{props.signature != null ? ` - ${props.signature}` : ''}
      </blockquote>
    )
  }
}