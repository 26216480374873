import { Tag } from 'bbcode-to-react'

type Props = {
  'gpt-id': string
  'unit-name': string
  'defined-sizes': string
}

export default class AdBlockTag<T = { linkify: boolean }> extends Tag<T> {
  toReact() {
    return (
      <>
      </>
    )
  }
}