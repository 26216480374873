import { useTranslation } from 'react-i18next'
import { AiOutlineArrowDown, AiOutlineArrowUp } from 'react-icons/ai'
import ArticleTileContent from '../ArticleTileContent/ArticleTileContent'
import ArticleTileImage from '../ArticleTileImage/ArticleTileImage'
import styles from './styles.module.scss'
import moment from 'moment'
import { FRONT_BASE_URL } from '../../../utils/common'
import { SearchArticlePropsOptionsList } from '../../../types/common'

export type Tag = {
  name: string
  uuid?: string
}

export type Category = {
  name: string
  uuid?: string
}

export type Author = {
  name: string
  lastName: string
  nick?: string
  uuid?: string
}

export type MainImage = {
  filename: string,
  hostname: string,
  path: string
}

export type ArticleProp = {
  name: string
  value: number
  uuid: string
}

export type Article = {
  uuid: string
  draft: boolean
  active: boolean
  slug: string
  title?: string
  author?: Author
  lastEditor?: Author
  publishDate: Date
  modificationDate?: Date
  leadText?: string
  category?: Category
  tags?: Tag[],
  mainImage?: MainImage
  isWikiArticle?: boolean
  articleProps?: ArticleProp[]
}

type Props = { 
  article: Article
  linkToArticle?: boolean
  onButtonClick:(article:Article)=>void
  onUp?:(article:Article) => void 
  onDown?:(article:Article) => void
  index?: number
  maxIndex?: number
  buttonContent: string
  style?: React.CSSProperties
  classes?: {
    button?: string
  }
}
  
const AttachedArticle = (props: Props) => {
  const texts = useTranslation().t

  const onUp = () =>{
    if (props.onUp !== undefined) {
      props.onUp(props.article) 
    }
  }

  const onDown = () =>{
    if (props.onDown!== undefined) {
      props.onDown(props.article)
    }
  }

  const onButtonClick = () =>{
    props.onButtonClick(props.article)
  }

  return (
    <div
      className={styles.container}
      style={{ 
        position: 'relative',
      }}
    >
      <div
        className={styles.wrapper}
        style={{       
          ...(props.article.draft || !props.article.active ? { opacity: 0.6 } : {})
        }}
      >
        {
          props.article.active && props.article.draft && <div className={styles.draftBanner}>
            {texts("article:draft")}
          </div>
        }
        {
          !props.article.active && <div className={styles.inactiveBanner}>
            {texts("article:inactive")}
          </div>
        }
        <div className={styles.articleBadges}>
          {
            props.article.articleProps?.map(e => {
              return (
                <div className={styles.articlePropBanner}>
                  {SearchArticlePropsOptionsList.find(o => o.value === e.value)?.label ?? ''}
                </div>
              )
            })
          }
          {
            props.article.isWikiArticle && <div className={styles.wikiBanner}>
              {texts('common:wiki')}
            </div>
          }
        </div>
        <ArticleTileImage
          article={props.article}
        />
        <ArticleTileContent
          classes={{container:`${styles.contentContainer}`}}
          article={props.article} 
        />
      </div> 
      <div 
        className={
          [
            styles.contentButtonsContainerBase,
            props.index != null && props.maxIndex != null ? styles.contentButtonsContainerAllButtons : styles.contentButtonsContainerSingleButton
          ].join(' ')
        }
      >
        {
          props.index != null && props.maxIndex != null
          ? <div className={styles.contentButtonsRow}>
              {
                props.index > 0
                ? <button
                    type={"button"}
                    onClick={() => onUp()}
                    className={styles.contentButtonBase}
                  >
                    <div className={styles.buttonContent}>
                      <AiOutlineArrowUp size={20}/>
                        {texts("article:move_up")}                
                    </div>     
                  </button>
                : <div className={styles.contentButtonSpacer}/>
              }
              {
                props?.index < props.maxIndex - 1
                ? <button
                    type={"button"}
                    onClick={() => onDown()}
                    className={styles.contentButtonBase}
                  >
                    <div className={styles.buttonContent}>
                      <AiOutlineArrowDown size={20}/>                
                        {texts("article:move_down")}
                    </div>
                  </button>
                : <div className={styles.contentButtonSpacer}/>
              } 
              {
                props.index == null && props.linkToArticle && props.article.active && !props.article.draft && moment(props.article.publishDate).isSameOrBefore(moment())
                ? <button
                    className={styles.externalLinkButton}
                    onClick={() => {
                      window.open(`${FRONT_BASE_URL}/${props.article.slug}`, '_blank', 'noopener,noreferrer')
                    }}
                  >
                    {texts('article:show_article_label')}
                  </button>
                : null
              }
            </div>
          : (
              props.index == null && props.linkToArticle && props.article.active && !props.article.draft && moment(props.article.publishDate).isSameOrBefore(moment())
              ? <div className={styles.externalLinkButtonContainer}>
                  <button
                    className={styles.externalLinkButton}
                    onClick={() => {
                      window.open(`${FRONT_BASE_URL}/${props.article.slug}`, '_blank', 'noopener,noreferrer')
                    }}
                  >
                    {texts('article:show_article_label')}
                  </button>
                </div>
              : <div className={styles.flexSpacer}></div>
          )
        }  
        <div className={styles.contentButtonsRow}>
          <button
            type={"button"}
            onClick={() => onButtonClick()}  
            className={props.classes?.button} 
          >
            <div className={styles.buttonContent}>{props.buttonContent}</div>
          </button>
        </div>
      </div>
    </div>
  )
}

export default AttachedArticle