import React from 'react'
import { useTranslation } from 'react-i18next'

import CustomDialog from '../CustomDialog/CustomDialog'
import styles from './styles.module.scss'
import LoadingButton from '../LoadingButton/LoadingButton'

type Props = {
  id?: string
  hidden: boolean
  errorMessages: string[]
  onCloseClick: () => void
  onEditLabelClick?: () => void
}

const HtmlValidationErrorDialog = (props: Props) => {
  const texts = useTranslation().t

  return (
    <CustomDialog
      hidden={props.hidden}
      classes={{
        container: styles.container
      }}
      closeButton={true}
      onCloseButtonClick={() => {
        props.onCloseClick()
      }}
      title={texts('htmlValidation:title')}
    >
      <div className={styles.description}>
        {texts('htmlValidation:description')}<span className={props.onEditLabelClick ? styles.activeEditLabel : ''} onClick={props.onEditLabelClick}>{texts('htmlValidation:editLabel')}</span>
      </div>
      <div className={styles.errorMessagesLabel}>
        {texts('htmlValidation:errorMessagesLabel')}
      </div>
      <div className={styles.errorMessagesContainer}>
        <pre className={styles.errorMessages}>
          {
            props.errorMessages.join('\n')
          }
        </pre>
      </div>
      <div className={styles.buttonContainer}>
        <LoadingButton
          className={styles.button}
          type={'button'}
          onClick={() => { 
            props.onCloseClick()
          }}
        >
          <div className={styles.insertImageButtonContent}>
            {texts('htmlValidation:closeDialogButtonLabel')}
          </div>
        </LoadingButton>
      </div>
    </CustomDialog>
  )
}

export default HtmlValidationErrorDialog
