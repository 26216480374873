import { ErrorMessage, FormikProps } from 'formik'
import React from 'react'
import Scrollbars from 'react-custom-scrollbars'
import { useTranslation } from 'react-i18next'
import AsyncCreatableSelect from 'react-select/async-creatable'
import styles from './styles.module.scss'
import { evalStringTemplate } from '../../../utils/text';

type Props = {
  id?: string
  instanceId: string
  name: string
  disabled?: boolean
  isMulti?: boolean
  className?: string
  value?: string | string[] | {value: any, label: string} | {value: any, label: string}[] | number
  setValue?: React.Dispatch<React.SetStateAction<string[]>> | React.Dispatch<React.SetStateAction<string>> | React.Dispatch<React.SetStateAction<number>>
  options: any[]
  placeholder?: string
  formikProps?: FormikProps<any>
  customSelectStyles?: object
  height?: string
  hideSelectedOption?: boolean
  loadOptions: (inputValue: string) => Promise<{label: string, value: string}[]> 
  error?: boolean
  onChange?: (name: string, value: any) => void 
}

const renderSelectMenuListScrollbar = (props: any) => {
  const len = props.children.length
  return (
    <div style={{ 
      height: len ? (len < 10 ? `${len * 33}px` : '300px') : '33px', 
      backgroundColor: '#fff',
      color: '#383838'
    }}>
      <Scrollbars 
        universal={true} 
        renderThumbVertical={
          ({ style, ...props }: { style: React.CSSProperties, [x: string]: any}) => <div {...props} style={{ ...style, backgroundColor: '#38383866', width: '4px', opacity: '0.5'}}/>
        }
      >
        {props.children}
      </Scrollbars>
    </div>
  )
}

// TODO change to rem
// TODO style to classes if possible

const CustomAsyncCreatableSelectField = (props: Props) => {

  const texts = useTranslation("forms").t

  const customSelectStyles = {
    container: (provided: any, state: any) => ({
      ...provided,
      width: '100%',
      flex: 1,
      "@media (max-width: 600px)": {
        width: '100%'
      }
    }),
    menuPortal: (provided: any, state: any) => ({
      ...provided,
      margin: 0,
      borderRadius: 100
    }),
    menuList: (provided: any, state: any) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      borderRadius: 100
    }),
    indicatorSeparator: (provided: any, state: any) => ({
      ...provided,
      width: 0
    }),
    multiValue: (provided: any, state: any) => ({
      ...provided,
      flexShrink: 0
    }),
    multiValueLabel: (provided: any, state: any) => ({
      ...provided,
      whiteSpace: "normal"
    }),
    valueContainer: (provided: any, state: any) => {
      return ({
      ...provided,
      fontSize: '0.938rem',
      paddingLeft: 14,
      fontFamily: 'Roboto',
      ...(props.isMulti ? { maxWidth: 'calc(100% - 40px)', flexWrap: 'wrap' } : {})
    })},
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: '#fff',
      color: state.data.color || '#383838',
      padding: 5,
      paddingLeft: state.data.level ? state.data.level * 20 + 5 : 5,
      fontFamily: 'Roboto',
      textAlign: 'left',
      '&:hover': {
        backgroundColor: '#38383866'
      }
    }),
    control: (provided: any, state: any) => ({
      ...provided,
      minHeight: props.height ?? '30px',
      opacity: state.isDisabled ? 0.4 : 1,
      backgroundColor: '#fff',
      borderRadius: '5px',
      padding: state.isFocused ? 0 : 1,
      border: state.isFocused ? `2px solid ${props.error ? 'red' : '#383838'}` : `1px solid ${props.error ? 'red' : '#383838'}`, 
      boxShadow: 'none',
      '&:hover': {
        border: state.isFocused ? `2px solid ${props.error ? 'red' : '#383838'}` : `1px solid ${props.error ? 'red' : '#383838'}`
      }
    }),
    singleValue: (provided: any, state: any) => ({
      ...provided,
      color: state.data.placeholder ? 'rgba(255,255,255,0.35)' : '#383838',
    }),
    input: (provided: any, state: any) => ({
      ...provided,
      margin: 0,
      fontSize: '0.938rem',
      fontFamily: 'Roboto',
    })
  }

  const renderNoOptionMessage = (props: any) => {
    return (
      <div style={{
        fontFamily: 'Roboto',
        textAlign: 'center',
        color: '#38383866',
        paddingTop: '6px'
      }}>
        {texts('messages:no_option_message')}
      </div>
    )
  }

  return (
    <div style={{ 
      minHeight: '43px',
      marginBottom: '15px',
      marginTop: 0
    }}>
      <AsyncCreatableSelect 
        onBlur={() => {
          props.formikProps?.setFieldTouched(props.name)
        }}
        menuPortalTarget={document.body}
        instanceId={props.instanceId}
        backspaceRemovesValue={false}
        name={props.name}
        defaultOptions={props.options}
        isDisabled={props.disabled}
        isMulti={props.isMulti}
        hideSelectedOptions={props.hideSelectedOption ?? false}
        isClearable={false}
        closeMenuOnSelect={props.isMulti != null ? !props.isMulti : true}
        value={props.value as any}
        loadOptions={props.loadOptions}
        formatCreateLabel={(inputValue) => evalStringTemplate(texts('messages:new_position_message'), {value: inputValue})}
        onChange={(val) => {
          props.formikProps?.setFieldTouched(props.name)
          props.onChange?.(props.name, val)
        }}
        placeholder={props.placeholder}
        styles={props.customSelectStyles || customSelectStyles}
        components={{
          MenuList: renderSelectMenuListScrollbar,
          NoOptionsMessage: renderNoOptionMessage,
        }}
      />
      {
        props.error 
        ? <div className={styles.errorLabel}>
            <ErrorMessage name={props.name}/>
          </div>
        : null
      }
    </div>
  )
}

export default CustomAsyncCreatableSelectField
