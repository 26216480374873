import { Tag } from 'bbcode-to-react'
import styles from './style.module.scss'

type Props = {
  url: string
}

export default class UrlTag<T = { linkify: boolean }> extends Tag<T> {
  toReact() {
    return (
      <span className={styles.url_url}>{this.getComponents()}</span>
    )
  }
}