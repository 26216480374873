import { Tag } from 'bbcode-to-react'
import React from 'react'

type Props = {
  url: string
}

export default class InstagramTag<T = { linkify: boolean }> extends Tag<T> {
  toReact() {
    return (
      <></>
    )
  }
}