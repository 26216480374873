import { Tag } from 'bbcode-to-react'

export default class ZaradnaUListTag<T = { linkify: boolean }> extends Tag<T> {
  toReact() {
    return (
      <ul
        style={{
          listStyleType: 'disc'
        }}
      >
        {this.getComponents()}
      </ul>
    )
  }
}