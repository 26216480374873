import { Tag } from 'bbcode-to-react'
import { decode } from 'html-entities'
import styles from './style.module.scss'

type Props = {
  signature?: string
}

export default class ZaradnaQuoteTag<T = { linkify: boolean }> extends Tag<T> {
  toReact() {
    const props = this.params as Props
    return (
      <div className={styles.quote_container}>
        <div className={styles.quote_icon}>
        <svg width="29" height="21" viewBox="0 0 29 21" xmlns="http://www.w3.org/2000/svg">
          <g fill="#F35588" fillRule="nonzero">
            <path d="M5.292 13.747c-.624 1.569-1.607 3.119-2.92 4.612a1.394 1.394 0 0 0-.133 1.682c.265.415.7.642 1.172.642.132 0 .265-.01.397-.056 2.778-.813 9.27-3.695 9.45-12.937.066-3.562-2.542-6.624-5.935-6.973a6.702 6.702 0 0 0-5.14 1.672A6.65 6.65 0 0 0 0 7.312c0 3.119 2.211 5.85 5.292 6.435zM22.404.717a6.692 6.692 0 0 0-5.13 1.672 6.65 6.65 0 0 0-2.183 4.923c0 3.119 2.21 5.85 5.291 6.435-.623 1.569-1.606 3.119-2.92 4.612a1.394 1.394 0 0 0-.132 1.682c.265.415.7.642 1.172.642.132 0 .264-.01.397-.057 2.778-.812 9.27-3.694 9.449-12.936v-.132c0-3.506-2.58-6.492-5.944-6.841z"/>
          </g>
        </svg>
        </div>
        <blockquote className={styles.quote_block}>
          <em className={styles.quote_quote}>
            {this.getComponents()}  
          </em>
          <em className={styles.quote_signature}>
            {props.signature != null ? ` - ${decode(props.signature)}` : ''}
          </em>
        </blockquote>
      </div>
    )

  }
}