import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import GalleryLibraryTileContent from '../GalleryLibraryTileContent/GalleryLibraryTileContent'
import GalleryLibraryTileImage from '../GalleryLibraryTileImage/GalleryLibraryTileImage'
import styles from './styles.module.scss'

export type Author = {
  name: string
  lastName: string
  nick?: string
  uuid?: string
}

export type FirstPage = {
  uuid: string
  photo: Photo
  showAdultWarning: boolean
}

export type Photo = {
  filename: string,
  hostname: string,
  path: string
}

export type Gallery = {
  uuid: string
  draft: boolean
  active: boolean
  title?: string
  author?: Author
  lastEditor?: Author
  publishedAt?: Date | string
  createdAt?: Date | string
  modifiedAt?: Date | string
  firstPage?: FirstPage
  pageCount?: number
  adultContent: boolean
}

type Props = { 
  gallery: Gallery
  disabledControl?: boolean
  onDisconnect: () => void
  beforeEdit?: (() => { [key: string]: any }) | (() => Promise<{ [key: string]: any }>)
}

const ConnectedGalleryTile = (props: Props) => {
  const history = useHistory()
  const texts = useTranslation().t

  return (
    <div
      className={styles.container}
      style={{ 
        position: 'relative',
        //...(props.gallery.draft || !props.gallery.active ? { opacity: 0.6 } : {})
      }}
    >
      <div className={styles.wrapper} style={{...(props.gallery.draft || !props.gallery.active ? { opacity: 0.6 } : {})}}>
        {
          props.gallery.active && props.gallery.draft && <div className={styles.draftBanner}>
            {texts('common:draft')}
          </div>
        }
        {
          !props.gallery.active && <div className={styles.inactiveBanner}>
            {texts('common:inactive')}
          </div>
        }
        <GalleryLibraryTileImage
          gallery={props.gallery}
        />
        <GalleryLibraryTileContent
          gallery={props.gallery}  
        />
      </div>  
      <div
        className={styles.controlContainer}
        style={{
          opacity: props.disabledControl ? 0.5 : 1
        }}
      >
        <button
          type={'button'}
          disabled={props.disabledControl}
          className={styles.editButton}
          onClick={async () => {
            let state
            if (props.beforeEdit != null) {
              state = await props.beforeEdit()
            }
            
            if (state?.replace) {
              history.replace(
                state.replace.url,
                state.replace.state
              )
            }
            if (state?.push) {
              history.push({
                pathname: `/gallery/edit/${props.gallery.uuid}`,
                state: {
                  ...state?.push
                }
              })
            }
            // history.push({
            //   pathname: `/gallery/edit/${props.gallery.uuid}`,
            //   state: {
            //     ...state
            //   }
            // })
          }}
        >
          {texts('gallery:edit_gallery_button')}
        </button>
        <button
          disabled={props.disabledControl}
          type={'button'}
          className={styles.disconnectButton}
          onClick={props.onDisconnect}
        >
          {texts('gallery:unpin_gallery_button')}
        </button>
      </div>
    </div>
  )
}

export default ConnectedGalleryTile
