import { ADD_USER, REMOVE_USER } from './types'

export const addUserData = (data: any) => ({ 
  type: ADD_USER, 
  uuid: data.uuid,
  email: data.email,
  name: data.name,
  lastName: data.lastName,
  nick: data.nick
})

export const removeUserData = () => ({ type: REMOVE_USER })