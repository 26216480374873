import React from 'react'
import styles from './styles.module.scss'
import { useTranslation } from 'react-i18next'

type Props = {
  id?: string
  hidden?: boolean
  children?: React.ReactNode[] | React.ReactNode
  style?: React.CSSProperties
  zIndex?: number
  title?: string
  closeButton?: boolean
  onCloseButtonClick?: () => void
  classes?: {
    container?: string
    closeButton?: string
    title?: string
  }
}

const CustomDialog = (props: Props) => {

  const texts = useTranslation().t

  return (
    <div 
      key={props.id}
      className={props.classes?.container || styles.dialog}
      style={{
        ...(props.style ?? {}),
        zIndex: props.zIndex || 101,
        display: props.hidden ? 'none' : 'block'
      }}
    >
      {
        props.closeButton
        ? <div
            className={props.classes?.closeButton || styles.closeButton}
            onClick={() => { 
              if (props.onCloseButtonClick != null) {
                props.onCloseButtonClick()
              }
            }}
          >
            {texts('common:close_dialog_button')}
          </div>
        : null
      }
      {
        props.title
        ? <div className={props.classes?.title || styles.title}>{props.title}</div>
        : null
      }
      
      {props.children}
    </div>
  )
}

export default CustomDialog
