export class StorageService {
  private static instance: StorageService
    static ACCESS_TOKEN = "depesza_cms_access_token"
  private static REFRESH_TOKEN = "depesza_cms_refresh_token"
  private static USER_UUID = "user_uuid"
  private static USER_EMAIL = "user_email"
  private static USER_NICK = "user_nick"
  private static USER_NAME = "user_name"
  private static USER_LAST_NAME = "user_last_name"

  private constructor() {}

  public static getInstance(): StorageService {
    if (!StorageService.instance) {
      StorageService.instance = new StorageService()
    }

    return StorageService.instance
  }

  public setTokens(token: string, refreshToken: string) {
    localStorage.setItem(StorageService.ACCESS_TOKEN, token)
    localStorage.setItem(StorageService.REFRESH_TOKEN, refreshToken)
  }

  public clearToken() {
    localStorage.removeItem(StorageService.ACCESS_TOKEN)
    localStorage.removeItem(StorageService.REFRESH_TOKEN)
  }

  public setUserData(data: any) {
    localStorage.setItem(StorageService.USER_UUID, data.uuid)
    localStorage.setItem(StorageService.USER_EMAIL, data.email)
    localStorage.setItem(StorageService.USER_NICK, data.nick)
    localStorage.setItem(StorageService.USER_NAME, data.name)
    localStorage.setItem(StorageService.USER_LAST_NAME, data.lastName)
  }

  public clearUserData() {
    localStorage.removeItem(StorageService.USER_NICK)
    localStorage.removeItem(StorageService.USER_EMAIL)
    localStorage.removeItem(StorageService.USER_NAME)
    localStorage.removeItem(StorageService.USER_LAST_NAME)
  }

  public setUserEmail(email: string) {
    localStorage.setItem(StorageService.USER_EMAIL, email)
  }

  public clearUserEmail() {
    localStorage.removeItem(StorageService.USER_EMAIL)
  }

  public setAccessToken(token: string) {
    localStorage.setItem(StorageService.ACCESS_TOKEN, token)
  }

  public getAccessToken(): string | null {
    return localStorage.getItem(StorageService.ACCESS_TOKEN)
  }

  public getRefreshToken(): string | null {
    return localStorage.getItem(StorageService.REFRESH_TOKEN)
  }

  public clearUserCache() {
    this.clearToken()
    this.clearUserData()
  }
}