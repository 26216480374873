import { AxiosError, AxiosRequestConfig } from 'axios'
import { Api } from './Api'

export class BaseAuthApi extends Api {
  public constructor(config: AxiosRequestConfig) {
    super(config)
    this.setupBasicInterceptors()
  } 

  protected handleError = async (error: AxiosError): Promise<never> => {
    return Promise.reject(error)
  }
}
