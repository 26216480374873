import Modal from 'react-modal'
import styles from './styles.module.scss'
import { useTranslation } from 'react-i18next'

type Props = {
  children?: React.ReactNode[] | React.ReactNode
  title?: string
  closeButton?: boolean
  onCloseButtonClick?: () => void
  isOpen: boolean
  zIndex?: number 
  overlayStyle?: React.CSSProperties
  classes?: {
    container?: string
    title?: string
    modal?: string
  }
}

const CustomModal = (props: Props) => {

  const texts = useTranslation().t

  return (      
    <Modal
      isOpen={props.isOpen}
      className={props.classes?.container || styles.modalContainer}
      style={{
        overlay:{
          backgroundColor:'transparent',
          zIndex: props.zIndex ?? 101,
          ...(props.overlayStyle ?? {})
        }           
      }}
    >
      <div className = {props.classes?.modal || styles.modal}>
        <div className={styles.wrapper}>
          <div className={props.classes?.title || styles.title}>{props.title}</div>
          <button 
            className={styles.cancelModal}
            onClick={()=>{
              if (props.onCloseButtonClick != null) {
                props.onCloseButtonClick()
              }
            }}     
          >
            {texts("common:close_dialog_button")}
          </button>
        </div>  
          {props.children} 
      </div>        
    </Modal>
  )
}

export default CustomModal