import { Tag } from 'bbcode-to-react';
import React from 'react';

type Props = {
  title: string
  'article-deep-link-with-photo': string
}

export default class WorthSeeingTag<T = { linkify: boolean }> extends Tag<T> {
  toReact() {
    return (
      <></>
    )
  }
}