import React from "react"
import styles from './styles.module.scss'
import MoonLoader from 'react-spinners/MoonLoader'

type Props = {
  text?: string
  children?: React.ReactNode | React.ReactNode[]
  disabled?: boolean
  loading?: boolean
  variant?: "outlined" | "contained"
  className?: string
  circleSize?: number
  circleColor?: string
  type?: "button" | "submit" | "reset" | undefined
  style?: React.CSSProperties
  onClick?: any
}

//TODO zmiana styli komponentu
const LoadingButton = (props: Props) => {
  return (
    <button
      type={props.type ?? "button"}
      disabled={props.loading || props.disabled}
      style={{ 
        position: "relative",
        ...props.style 
      }}
      className={props.className ?? styles.button}
      onClick={props.onClick}
    >
      <div className={styles.content}>
        {
          props.loading 
            ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'left'}}>
                <MoonLoader loading={true} color={props.circleColor} size={props.circleSize ?? 25}/>
              </div>
            : <>{props.text ?? props.children}</>
        }
      </div>
    </button>
  )
}

export default LoadingButton
