import React from 'react'
import styles from './styles.module.scss'
import { SinglePhoto } from '../MediaLibrary/types'
import AssetLoader from '../../../assets/loader'

export type GalleryTileData = {
    uuid?: string
    content: string
    active: boolean
    order?: number
    author: {
      name: string
      lastName: string
      uuid?: string
    }
    lastEditor?: {
      name: string
      lastName: string
      uuid?: string
    }
    photoUuid?: string
    adultContent: boolean
    showAdultWarning: boolean
    photo?: SinglePhoto | null
    publishedAt?: Date | string
    modifiedAt?: Date | string
  } 

type Props = {
	data: GalleryTileData
}

const GalleryPageTileImage = (props: Props) => {
  return (
		<div className={styles.contentImageContainer} style={{ opacity: props.data.active ? 1 : 0.5}}>
			<img 
				className={styles.contentImage}
				src={props.data.photo ? `${props.data.photo.hostname}${props.data.photo.path}${props.data.photo.filename}` : AssetLoader.getPlaceholderImage()}
			/>
		</div>    
  )
}

export default GalleryPageTileImage