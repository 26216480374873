import { Tag } from 'bbcode-to-react'
import React from 'react'

export default class CdnImgTag<T = { linkify: boolean }> extends Tag<T> {
  toReact() {
    return (
      <>
      </>
    )
  }
}