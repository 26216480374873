import { Tag } from 'bbcode-to-react'
import { decode } from 'html-entities'
import React, { useRef } from 'react'
import styles from './style.module.scss'

export default class ZaradnaRedditTag<T = { linkify: boolean }> extends Tag<T> {
  toReact() {
    return (
      <RedditTagComponent
        content={this.getContent(true)}
      />
    )
  }
}

type ComponentProps = {
  content: string
}

const RedditTagComponent = (props: ComponentProps) => {
  const ref = useRef() as any

  React.useEffect(() => {
    const messageListener = (event: MessageEvent) => {
      if (event.origin === 'https://www.redditmedia.com') {
        if (event.source === ref?.current?.children[0]?.contentWindow) {
          ref?.current?.children[0]?.setAttribute('height', JSON.parse(event.data).data)
        }
      }
    }
    ref?.current?.children[0]?.setAttribute('style', 'border: none; width: 100%; max-width: 600px;')
    window.addEventListener('message', messageListener , false);
    return () => {
      window.removeEventListener('message', messageListener)
    }
  }, [])

  return (
    <div
      className={styles.reddit_container}
      ref={ref}
      dangerouslySetInnerHTML={{
        __html: decode(props.content.replace(/<br>|<br\/>|<br \/>/g, ''))
      }}
    >
    </div>
  )
}