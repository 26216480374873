
import React from 'react'
import { useToasts } from 'react-toast-notifications'
import { useSelector } from 'react-redux'
import { State } from '../../redux/reducers/index'
import { useTranslation } from 'react-i18next'

const CustomToastHook = () => {
  const { addToast } = useToasts()
  const messages = useSelector((state: State) => state.messages)
  const { t } = useTranslation('messages')

  React.useEffect(() => {
    if (messages.error || messages.success) {
      renderMessage()
    }
  }, [messages])

  const renderMessage = () => {
    if (messages.success !== '') {
      addToast(t(messages.success), { appearance: 'success'})
    } else if (messages.error !== '') {
      addToast(t(messages.error), { appearance: 'error'})
    }
  }

  return null
}

export default CustomToastHook
