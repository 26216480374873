import { Form, Formik } from 'formik'
import { encode } from 'html-entities'
import { parse as parseHtmlString } from 'node-html-parser'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { AiOutlineOrderedList, AiOutlineUnderline, AiOutlineUnorderedList } from 'react-icons/ai'
import { BiItalic } from 'react-icons/bi'
import { BsTypeBold } from 'react-icons/bs'
import { FaFacebook, FaInstagram, FaPinterest, FaTelegram, FaTwitter, FaYoutube } from 'react-icons/fa'
import { GrReddit } from 'react-icons/gr'
import { IoMdLink } from 'react-icons/io'
import { MdArtTrack, MdFormatClear, MdFormatQuote, MdFormatStrikethrough, MdImage, MdKeyboardReturn } from 'react-icons/md'
import { RiAdvertisementFill } from 'react-icons/ri'
import { SiTiktok } from 'react-icons/si'
import facebookCode from '../../../assets/images/facebook_kod.webp'
import facebookEmbed from '../../../assets/images/facebook_osadz.webp'
import instagramCode from '../../../assets/images/instagram_kod.webp'
import instagramEmbed from '../../../assets/images/instagram_osadz.webp'
import pinterestCode from '../../../assets/images/pinterest_kod.webp'
import pinterestEmbed from '../../../assets/images/pinterest_osadz.webp'
import redditCode from '../../../assets/images/reddit_kod.webp'
import redditEmbed from '../../../assets/images/reddit_osadz.webp'
import telegramEmbed from '../../../assets/images/telegram_post_osadz.webp'
import tiktokCode from '../../../assets/images/tiktok_kod.webp'
import tiktokEmbed from '../../../assets/images/tiktok_osadz.webp'
import twitterCode from '../../../assets/images/twitter_kod.webp'
import twitterEmbed from '../../../assets/images/twitter_osadz.webp'
import youtubeCode from '../../../assets/images/YT-kod.webp'
import youtubeEmbed from '../../../assets/images/YT-osadz.webp'
import { addCmdMessage } from '../../../redux/messages/actions'
import store from '../../../store'
import { addHtmlEmbedValidation } from '../../../utils/validations/addHtmlEmbedValidation'
import { addHtmlToBBCodeValidation } from '../../../utils/validations/addHtmlToBBCodeValidation'
import { addPinterestEmbedValidation } from '../../../utils/validations/addPinterestEmbedValidation'
import { addQuoteTagValidation } from '../../../utils/validations/addQuoteTagValidation'
import { addRedditEmbedValidation } from '../../../utils/validations/addRedditEmbedValidation'
import { addTelegramPostEmbedValidation } from '../../../utils/validations/addTelegramPostEmbedValidation'
import { addUrlTagValidation } from '../../../utils/validations/addUrlTagValidation'
import { addYoutubeEmbedValidation } from '../../../utils/validations/addYoutubeEmbedValidation'
import AddLinkToAnotherArticle, { DeepLinkTagType } from '../../AddLinkToAnotherArticle/AddLinkToAnotherArticle'
import { MessageEnum } from '../../CustomToastProvider/types'
import BaseInput from '../BaseInput/BaseInput'
import CustomDialog from '../CustomDialog/CustomDialog'
import CustomModal from '../CustomModal/CustomModal'
import LoadingButton from '../LoadingButton/LoadingButton'
import { SinglePhoto } from '../MediaLibrary/types'
import MediaLibraryDialog from '../MediaLibraryDialog/MediaLibraryDialog'
import styles from './styles.module.scss'
import { convertHtmlNodesToBBCode } from './utils'

type ExcludedActions = {
  photo?: boolean
  paragraph?: boolean
  h2?: boolean
  h3?: boolean
  h4?: boolean
  h5?: boolean
  h6?: boolean
  ulist?: boolean
  olist?: boolean
  quote?: boolean
  bold?: boolean
  italic?: boolean
  underline?: boolean
  strikethrough?: boolean
  url?: boolean
  facebook?: boolean
  instagram?: boolean
  twitter?: boolean
  tiktok?: boolean
  pinterest?: boolean
  youtube?: boolean
  converter?: boolean
  link?: boolean
  telegram?: boolean
  reddit?: boolean
  adBlock?: boolean
}

type Props = {
  name: string
  formikProps: any
  rows?: number
  mainImageData?: {
    img: SinglePhoto
    croppedImage?: Blob
  } | null
  gallery?: any
  disabled?: boolean
  excludedActions?: ExcludedActions
  title?: string
  contentTitle?: string
  previewTitle?: string
  excludedArticleUuid?: string
  previewComponent: React.ReactNode | React.ReactNode[]
  previewWidth?: string
}

type TagParam = {
  key: string
  value: string | number
}

const BBCodeEditor = (props: Props) => {
  const texts = useTranslation().t
  const [selectedText, setSelectedText] = React.useState<string>('')
  const [showLinkDialog, setShowLinkDialog] = React.useState<boolean>(false)
  const [showMediaLibrary, setShowMediaLibrary] = React.useState<boolean>(false)
  const [showUrlDialog, setShowUrlDialog] = React.useState<boolean>(false)
  const [showHtmlDialog, setShowHtmlDialog] = React.useState<boolean>(false)
  const [showPinterestDialog, setShowPinterestDialog] = React.useState<boolean>(false)
  const [showTwitterDialog, setShowTwitterDialog] = React.useState<boolean>(false)
  const [showYoutubeDialog, setShowYoutubeDialog] = React.useState<boolean>(false)
  const [showFacebookDialog, setShowFacebookDialog] = React.useState<boolean>(false)
  const [showInstagramDialog, setShowInstagramDialog] = React.useState<boolean>(false)
  const [showTikTokDialog, setShowTikTokDialog] = React.useState<boolean>(false)
  const [showQuoteDialog, setShowQuoteDialog] = React.useState<boolean>(false)
  const [showTelegramPostDialog, setShowTelegramPostDialog] = React.useState<boolean>(false)
  const [showRedditDialog, setShowRedditDialog] = React.useState<boolean>(false)
  const [isFloatControl, setIsFloatControl] = React.useState<boolean>(false)

  const editorRef = useRef() as any
  const urlDialogFormRef = useRef() as any
  const quoteDialogFormRef = useRef() as any
  const htmlDialogFormRef = useRef() as any
  const pinterestDialogFormRef = useRef() as any
  const youtubeDialogFormRef = useRef() as any
  const facebookDialogFormRef = useRef() as any
  const instagramDialogFormRef = useRef() as any
  const tikTokDialogFormRef = useRef() as any
  const twitterDialogFormRef = useRef() as any
  const telegramPostDialogFormRef = useRef() as any
  const redditDialogFormRef = useRef() as any

  const controlButtonTooltipClassName = isFloatControl ? styles.controlButtonTooltipFloat : styles.controlButtonTooltip;

  const scrollEventListener = () => {
    if ((document.getElementById('bbcode-editor-control-panel')?.getBoundingClientRect().top ?? 0) < 0 && (document.getElementById('bbcode-editor-control-panel')?.className !== styles.floatControl)) { 
      setIsFloatControl(true)
    } else if ((document.getElementById('bbcode-editor-control-panel')?.getBoundingClientRect().top ?? 0) > 0 && (document.getElementById('bbcode-editor-control-panel')?.className === styles.floatControl)){
      setIsFloatControl(false)
    }
  }

  React.useEffect(() => {
    window.addEventListener('scroll', scrollEventListener)
    return () => {
      window.removeEventListener('scroll', scrollEventListener)
    }
  }, [])

  const getMainImgUrl = () => {
    if (props.mainImageData == null) {
      return ''
    } else if (props.mainImageData.croppedImage != null) {
      return URL.createObjectURL(props.mainImageData.croppedImage)
    } else {
      return `${props.mainImageData.img.hostname}${props.mainImageData.img.path}${props.mainImageData.img.filename}`
    }
  }

  const reloadEmbedScripts = () => {
    const head = document.getElementsByTagName('head')[0]
    const oldScript = document.querySelector(`[src="https://www.tiktok.com/embed.js"]`)
    oldScript?.remove()
    const script= document.createElement('script')
    script.src= 'https://www.tiktok.com/embed.js'
    head.appendChild(script);
    ;(window as any).instgrm?.Embeds?.process()
    ;(window as any).twttr?.widgets?.load()
  }

  const clearStyleTagsFromSelection = (ref: any) => {
    if (ref.current) {
      const start = ref.current.value.substring(0, ref.current.selectionStart as number)
      let mid: string = ref.current.value.substring(
                    ref.current.selectionStart as number,
                    ref.current.selectionEnd as number
                  ) ?? ''
      const end = ref.current.value.substring(ref.current.selectionEnd as number, ref.current.value.length)
      mid = mid.replace(/(?!\[\/?twitter|\[\/?facebook|\[\/?youtube|\[\/?pinterest|\[\/?twitter|\[\/?instagram|\[\/?tiktok|\[\/?url|\[\/?cdn-img)(\[\\?.+?)\]/ig, '')
      ref.current.value = start + mid + end
      ref.current.focus({ preventScroll: true })
      ref.current.selectionStart = (start + mid).length
      ref.current.selectionEnd = (start + mid).length
      ref.current.blur()
      props.formikProps?.setFieldValue(
        props.name,
        start + mid + end
      )
    }
  }

  const addTag = (ref: any, tag: string, newLine?: boolean, mainParam?: string | number, params?: TagParam[]) => {
    if (ref.current) {
      const start = ref.current.value.substring(0, ref.current.selectionStart as number)
      const mid =
        `[${tag}${mainParam != null ? `=${mainParam}` : ''}${params != null && params.length > 0 ? ` ${params.map(e => `${e.key}=${e.value}`).join(' ')}` : ''}]` +
        ref.current.value.substring(
          ref.current.selectionStart as number,
          ref.current.selectionEnd as number
        ) +
        `[/${tag}]${newLine ? "\n" : ""}`
      const end = ref.current.value.substring(ref.current.selectionEnd as number, ref.current.value.length)
      ref.current.value = start + mid + end
      ref.current.focus({ preventScroll: true })
      ref.current.selectionStart = (start + mid).length
      ref.current.selectionEnd = (start + mid).length
      ref.current.blur()
      props.formikProps?.setFieldValue(
        props.name,
        start + mid + end
      )
    }
  }

  const addTagsBlock = (ref: any, tags: string, newLine?: boolean, replace?: boolean) => {
    if (ref.current) {
      const start = replace ? ref.current.value.substring(0, ref.current.selectionStart as number) : ref.current.value.substring(0, ref.current.selectionEnd as number)
      const mid = `${newLine ? "\n" : ""}${tags}${newLine ? "\n" : ""}`
      const end = ref.current.value.substring(ref.current.selectionEnd as number, ref.current.value.length)
      ref.current.value = start + mid + end
      ref.current.focus({ preventScroll: true })
      ref.current.selectionStart = (start + mid).length
      ref.current.selectionEnd = (start + mid).length
      ref.current.blur()
      props.formikProps?.setFieldValue(
        props.name,
        start + mid + end
      )
    }
  }

  const openMediaLibraryDialog = () => {
    store.dispatch(addCmdMessage(MessageEnum.ENABLE_PAGE_COVER))
    window.scrollTo(0, 0)
    setShowMediaLibrary(true)
  }

  const closeMediaLibraryDialog = () => {
    store.dispatch(addCmdMessage(MessageEnum.DISABLE_PAGE_COVER))
    setShowMediaLibrary(false)
  }

  const openQuoteDialog = () => {
    store.dispatch(addCmdMessage(MessageEnum.ENABLE_PAGE_COVER))
    setShowQuoteDialog(true)
  }

  const closeQuoteDialog = () => {
    store.dispatch(addCmdMessage(MessageEnum.DISABLE_PAGE_COVER))
    setShowQuoteDialog(false)
    quoteDialogFormRef?.current?.resetForm()
  }

  const openHtmlDialog = () => {
    store.dispatch(addCmdMessage(MessageEnum.ENABLE_PAGE_COVER))
    setShowHtmlDialog(true)
  }

  const closeHtmlDialog = () => {
    store.dispatch(addCmdMessage(MessageEnum.DISABLE_PAGE_COVER))
    setShowHtmlDialog(false)
    htmlDialogFormRef?.current?.resetForm()
  }

  const openUrlDialog = () => {
    store.dispatch(addCmdMessage(MessageEnum.ENABLE_PAGE_COVER))
    setShowUrlDialog(true)
  }

  const closeUrlDialog = () => {
    store.dispatch(addCmdMessage(MessageEnum.DISABLE_PAGE_COVER))
    setShowUrlDialog(false)
    urlDialogFormRef?.current?.resetForm()
  }

  const openRedditDialog = () => {
    store.dispatch(addCmdMessage(MessageEnum.ENABLE_PAGE_COVER))
    setShowRedditDialog(true)
  }

  const closeRedditDialog = () => {
    store.dispatch(addCmdMessage(MessageEnum.DISABLE_PAGE_COVER))
    setShowRedditDialog(false)
    redditDialogFormRef?.current?.resetForm()
  }

  const openPinterestDialog = () => {
    store.dispatch(addCmdMessage(MessageEnum.ENABLE_PAGE_COVER))
    setShowPinterestDialog(true)
  }

  const closePinterestDialog = () => {
    store.dispatch(addCmdMessage(MessageEnum.DISABLE_PAGE_COVER))
    setShowPinterestDialog(false)
    pinterestDialogFormRef?.current?.resetForm()
  }

  const openTelegramPostDialog = () => {
    store.dispatch(addCmdMessage(MessageEnum.ENABLE_PAGE_COVER))
    setShowTelegramPostDialog(true)
  }

  const closeTelegramPostDialog = () => {
    store.dispatch(addCmdMessage(MessageEnum.DISABLE_PAGE_COVER))
    setShowTelegramPostDialog(false)
    telegramPostDialogFormRef?.current?.resetForm()
  }

  const openYoutubeDialog = () => {
    store.dispatch(addCmdMessage(MessageEnum.ENABLE_PAGE_COVER))
    setShowYoutubeDialog(true)
  }

  const closeYoutubeDialog = () => {
    store.dispatch(addCmdMessage(MessageEnum.DISABLE_PAGE_COVER))
    setShowYoutubeDialog(false)
    youtubeDialogFormRef?.current?.resetForm()
  }

  const openFacebookDialog = () => {
    store.dispatch(addCmdMessage(MessageEnum.ENABLE_PAGE_COVER))
    setShowFacebookDialog(true)
  }

  const closeFacebookDialog = () => {
    store.dispatch(addCmdMessage(MessageEnum.DISABLE_PAGE_COVER))
    setShowFacebookDialog(false)
    facebookDialogFormRef?.current?.resetForm()
  }

  const openInstagramDialog = () => {
    store.dispatch(addCmdMessage(MessageEnum.ENABLE_PAGE_COVER))
    setShowInstagramDialog(true)
  }

  const closeInstagramDialog = () => {
    store.dispatch(addCmdMessage(MessageEnum.DISABLE_PAGE_COVER))
    setShowInstagramDialog(false)
    instagramDialogFormRef?.current?.resetForm()
  }

  const openTikTokDialog = () => {
    store.dispatch(addCmdMessage(MessageEnum.ENABLE_PAGE_COVER))
    setShowTikTokDialog(true)
  }

  const closeTikTokDialog = () => {
    store.dispatch(addCmdMessage(MessageEnum.DISABLE_PAGE_COVER))
    setShowTikTokDialog(false)
    tikTokDialogFormRef?.current?.resetForm()
  }

  const openTwitterDialog = () => {
    store.dispatch(addCmdMessage(MessageEnum.ENABLE_PAGE_COVER))
    setShowTwitterDialog(true)
  }

  const closeTwitterDialog = () => {
    store.dispatch(addCmdMessage(MessageEnum.DISABLE_PAGE_COVER))
    setShowTwitterDialog(false)
    twitterDialogFormRef?.current?.resetForm()
  }

  const openLinkDialog = () =>{
    setShowLinkDialog(true)
  }
  const closeLinkDialog = () =>{
    setShowLinkDialog(false)
  }

  const pinterestDialogContent = () => (
    <>
      <div className={styles.dialogFormContainer}>
        <Formik
          innerRef={pinterestDialogFormRef}
          initialValues={{
            embed: ''
          }}
          validationSchema={addPinterestEmbedValidation(texts)}
          onSubmit={(data) => { 
            addTagsBlock(editorRef, `[pinterest]${data.embed}[/pinterest]`)
            closePinterestDialog()
          }}
        >
          {(formikProps) => (
            <Form className={styles.dialogForm}>
              <div>
                <BaseInput
                  label={texts("editor:html_embed_label")}
                  name={'embed'}
                  rows={15}
                  formikProps={formikProps}
                  value={formikProps.values.embed}
                  classes={{
                    containerWithLabel: styles.inputContainerWithLabel,
                    containerWithoutLabel: styles.inputContainerWithoutLabel,
                    fieldBase: styles.textFieldBase
                  }}
                  error={
                    formikProps.touched.embed && Boolean(formikProps.errors.embed)
                  }
                />
              </div>
              <div className={styles.insertTagDialogButtonContainer}>
                <LoadingButton
                  type={'button'}
                  className={styles.insertTagDialogButton}
                  circleColor={'#fff'}
                  onClick={() => {
                    formikProps.submitForm()
                  }}
                >
                  <div className={styles.insertTagDialogButtonContent}>
                    <div>{texts('editor:add_label')}</div>
                  </div>
                </LoadingButton>
              </div>
              <div className={styles.embedInstructionImgContainer}>
                <img
                  className={styles.embedInstructionImg}
                  src={pinterestEmbed}
                />
              </div>
              <div className={styles.embedInstructionImgContainer}>
                <img
                  className={styles.embedInstructionImg}
                  src={pinterestCode}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  )
  
  const redditDialogContent = () => (
    <>
      <div className={styles.dialogFormContainer}>
        <Formik
          innerRef={redditDialogFormRef}
          initialValues={{
            embed: ''
          }}
          validationSchema={addRedditEmbedValidation(texts)}
          onSubmit={(data) => { 
            addTagsBlock(editorRef, `[reddit]${data.embed.replace(/\sid=".*?"/,'')}[/reddit]`)
            closeRedditDialog()
          }}
        >
          {(formikProps) => (
            <Form className={styles.dialogForm}>
              <div>
                <BaseInput
                  label={texts("editor:html_embed_label")}
                  name={'embed'}
                  rows={15}
                  formikProps={formikProps}
                  value={formikProps.values.embed}
                  classes={{
                    containerWithLabel: styles.inputContainerWithLabel,
                    containerWithoutLabel: styles.inputContainerWithoutLabel,
                    fieldBase: styles.textFieldBase
                  }}
                  error={
                    formikProps.touched.embed && Boolean(formikProps.errors.embed)
                  }
                />
              </div>
              <div className={styles.insertTagDialogButtonContainer}>
                <LoadingButton
                  type={'button'}
                  className={styles.insertTagDialogButton}
                  circleColor={'#fff'}
                  onClick={() => {
                    formikProps.submitForm()
                  }}
                >
                  <div className={styles.insertTagDialogButtonContent}>
                    <div>{texts('editor:add_label')}</div>
                  </div>
                </LoadingButton>
              </div>
              <div className={styles.embedInstructionImgContainer}>
                <img
                  className={styles.embedInstructionImg}
                  src={redditEmbed}
                />
              </div>
              <div className={styles.embedInstructionImgContainer}>
                <img
                  className={styles.embedInstructionImg}
                  src={redditCode}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  )

  const telegramPostDialogContent = () => (
    <>
      <div className={styles.dialogFormContainer}>
        <Formik
          innerRef={telegramPostDialogFormRef}
          initialValues={{
            postPath: ''
          }}
          validationSchema={addTelegramPostEmbedValidation(texts)}
          onSubmit={(data) => { 
            addTagsBlock(editorRef, `[telegram-post="${data.postPath}"][/telegram-post]`)
            closeTelegramPostDialog()
          }}
        >
          {(formikProps) => (
            <Form className={styles.dialogForm}>
              <div>
                <BaseInput
                  label={texts('editor:telegram_post_path')}
                  name={'postPath'}
                  formikProps={formikProps}
                  value={formikProps.values.postPath}
                  classes={{
                    containerWithLabel: styles.inputContainerWithLabel,
                    containerWithoutLabel: styles.inputContainerWithoutLabel,
                    fieldBase: styles.textFieldBase
                  }}
                  error={
                    formikProps.touched.postPath && Boolean(formikProps.errors.postPath)
                  }
                />
              </div>
              <div className={styles.insertTagDialogButtonContainer}>
                <LoadingButton
                  type={'button'}
                  className={styles.insertTagDialogButton}
                  circleColor={'#fff'}
                  onClick={() => {
                    formikProps.submitForm()
                  }}
                >
                  <div className={styles.insertTagDialogButtonContent}>
                    <div>{texts('editor:add_label')}</div>
                  </div>
                </LoadingButton>
              </div>
              <div className={styles.embedInstructionImgContainer}>
                <img
                  className={styles.telegramPostEmbedInstructionImg}
                  src={telegramEmbed}
                />
              </div>
              
            </Form>
          )}
        </Formik>
      </div>
    </>
  )

  const youtubeDialogContent = () => (
    <>
      <div className={styles.dialogFormContainer}>
        <Formik
          innerRef={youtubeDialogFormRef}
          initialValues={{
            videoId: ''
          }}
          validationSchema={addYoutubeEmbedValidation(texts)}
          onSubmit={(data) => { 
            addTagsBlock(editorRef, `[youtube]${data.videoId}[/youtube]`)
            closeYoutubeDialog()
          }}
        >
          {(formikProps) => (
            <Form className={styles.dialogForm}>
              <div>
                <BaseInput
                  label={texts('editor:video_id_label')}
                  name={'videoId'}
                  formikProps={formikProps}
                  value={formikProps.values.videoId}
                  classes={{
                    containerWithLabel: styles.inputContainerWithLabel,
                    containerWithoutLabel: styles.inputContainerWithoutLabel,
                    fieldBase: styles.textFieldBase
                  }}
                  error={
                    formikProps.touched.videoId && Boolean(formikProps.errors.videoId)
                  }
                />
              </div>
              <div className={styles.insertTagDialogButtonContainer}>
                <LoadingButton
                  type={'button'}
                  className={styles.insertTagDialogButton}
                  circleColor={'#fff'}
                  onClick={() => {
                    formikProps.submitForm()
                  }}
                >
                  <div className={styles.insertTagDialogButtonContent}>
                    <div>{texts('editor:add_label')}</div>
                  </div>
                </LoadingButton>
              </div>
              <div className={styles.embedInstructionImgContainer}>
                <img
                  className={styles.YoutubeEmbedInstructionImg}
                  src={youtubeEmbed}
                />
              </div>
              <div className={styles.embedInstructionImgContainer}>
                <img
                  className={styles.YoutubeEmbedInstructionImg}
                  src={youtubeCode}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  )

  const twitterDialogContent = () => (
    <>
      <div className={styles.dialogFormContainer}>
        <Formik
          innerRef={twitterDialogFormRef}
          initialValues={{
            embed: ''
          }}
          validationSchema={addHtmlEmbedValidation(texts)}
          onSubmit={(data) => { 
            addTagsBlock(editorRef, `[twitter]${data.embed}[/twitter]`)
            reloadEmbedScripts()
            closeTwitterDialog()
          }}
        >
          {(formikProps) => (
            <Form className={styles.dialogForm}>
              <div>
                <BaseInput
                  label={texts('editor:html_embed_label')}
                  name={'embed'}
                  rows={15}
                  formikProps={formikProps}
                  value={formikProps.values.embed}
                  classes={{
                    containerWithLabel: styles.inputContainerWithLabel,
                    containerWithoutLabel: styles.inputContainerWithoutLabel,
                    fieldBase: styles.textFieldBase
                  }}
                  error={
                    formikProps.touched.embed && Boolean(formikProps.errors.embed)
                  }
                />
              </div>
              <div className={styles.insertTagDialogButtonContainer}>
                <LoadingButton
                  type={'button'}
                  className={styles.insertTagDialogButton}
                  circleSize={15}
                  circleColor={'#fff'}
                  onClick={() => {
                    formikProps.submitForm()
                  }}
                >
                  <div className={styles.insertTagDialogButtonContent}>
                    <div>{texts('editor:add_label')}</div>
                  </div>
                </LoadingButton>
              </div>
              <div className={styles.embedInstructionImgContainer}>
                <img
                  className={styles.embedInstructionImg}
                  src={twitterEmbed}
                />
              </div>
              <div className={styles.embedInstructionImgContainer}>
                <img
                  className={styles.embedInstructionImg}
                  src={twitterCode}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  )

  const facebookDialogContent = () => (
    <>
      <div className={styles.dialogFormContainer}>
        <Formik
          innerRef={facebookDialogFormRef}
          initialValues={{
            embed: ''
          }}
          validationSchema={addHtmlEmbedValidation(texts)}
          onSubmit={(data) => { 
            addTagsBlock(editorRef, `[facebook]${data.embed}[/facebook]`)
            closeFacebookDialog()
          }}
        >
          {(formikProps) => (
            <Form className={styles.dialogForm}>
              <div>
                <BaseInput
                  label={texts('editor:html_embed_label')}
                  name={'embed'}
                  rows={15}
                  formikProps={formikProps}
                  value={formikProps.values.embed}
                  classes={{
                    containerWithLabel: styles.inputContainerWithLabel,
                    containerWithoutLabel: styles.inputContainerWithoutLabel,
                    fieldBase: styles.textFieldBase
                  }}
                  error={
                    formikProps.touched.embed && Boolean(formikProps.errors.embed)
                  }
                />
              </div>
              <div className={styles.insertTagDialogButtonContainer}>
                <LoadingButton
                  type={'button'}
                  className={styles.insertTagDialogButton}
                  circleSize={15}
                  circleColor={'#fff'}
                  onClick={() => {
                    formikProps.submitForm()
                  }}
                >
                  <div className={styles.insertTagDialogButtonContent}>
                    <div>{texts('editor:add_label')}</div>
                  </div>
                </LoadingButton>
              </div>
              <div className={styles.embedInstructionImgContainer}>
                <img
                  className={styles.embedInstructionImg}
                  src={facebookEmbed}
                />
              </div>
              <div className={styles.embedInstructionImgContainer}>
                <img
                  className={styles.embedInstructionImg}
                  src={facebookCode}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  )

  const instagramDialogContent = () => (
    <>
      <div className={styles.dialogFormContainer}>
        <Formik
          innerRef={instagramDialogFormRef}
          initialValues={{
            embed: ''
          }}
          validationSchema={addHtmlEmbedValidation(texts)}
          onSubmit={(data) => { 
            addTagsBlock(editorRef, `[instagram]${data.embed}[/instagram]`)
            reloadEmbedScripts()
            closeInstagramDialog()
          }}
        >
          {(formikProps) => (
            <Form className={styles.dialogForm}>
              <div>
                <BaseInput
                  label={texts('editor:html_embed_label')}
                  name={'embed'}
                  rows={15}
                  formikProps={formikProps}
                  value={formikProps.values.embed}
                  classes={{
                    containerWithLabel: styles.inputContainerWithLabel,
                    containerWithoutLabel: styles.inputContainerWithoutLabel,
                    fieldBase: styles.textFieldBase
                  }}
                  error={
                    formikProps.touched.embed && Boolean(formikProps.errors.embed)
                  }
                />
              </div>
              <div className={styles.insertTagDialogButtonContainer}>
                <LoadingButton
                  type={'button'}
                  className={styles.insertTagDialogButton}
                  circleSize={15}
                  circleColor={'#fff'}
                  onClick={() => {
                    formikProps.submitForm()
                  }}
                >
                  <div className={styles.insertTagDialogButtonContent}>
                    <div>{texts('editor:add_label')}</div>
                  </div>
                </LoadingButton>
              </div>
              <div className={styles.embedInstructionImgContainer}>
                <img
                  className={styles.embedInstructionImg}
                  src={instagramEmbed}
                />
              </div>
              <div className={styles.embedInstructionImgContainer}>
                <img
                  className={styles.embedInstructionImg}
                  src={instagramCode}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  )

  const tikTokDialogContent = () => (
    <>
      <div className={styles.dialogFormContainer}>
        <Formik
          innerRef={tikTokDialogFormRef}
          initialValues={{
            embed: ''
          }}
          validationSchema={addHtmlEmbedValidation(texts)}
          onSubmit={(data) => { 
            addTagsBlock(editorRef, `[tiktok]${data.embed}[/tiktok]`)
            reloadEmbedScripts()
            closeTikTokDialog()
          }}
        >
          {(formikProps) => (
            <Form className={styles.dialogForm}>
              <div>
                <BaseInput
                  label={texts('editor:html_embed_label')}
                  name={'embed'}
                  rows={15}
                  formikProps={formikProps}
                  value={formikProps.values.embed}
                  classes={{
                    containerWithLabel: styles.inputContainerWithLabel,
                    containerWithoutLabel: styles.inputContainerWithoutLabel,
                    fieldBase: styles.textFieldBase
                  }}
                  error={
                    formikProps.touched.embed && Boolean(formikProps.errors.embed)
                  }
                />
              </div>
              <div className={styles.insertTagDialogButtonContainer}>
                <LoadingButton
                  type={'button'}
                  className={styles.insertTagDialogButton}
                  circleSize={15}
                  circleColor={'#fff'}
                  onClick={() => {
                    formikProps.submitForm()
                  }}
                >
                  <div className={styles.insertTagDialogButtonContent}>
                    <div>{texts('editor:add_label')}</div>
                  </div>
                </LoadingButton>
              </div>
              <div className={styles.embedInstructionImgContainer}>
                <img
                  className={styles.embedInstructionImg}
                  src={tiktokEmbed}
                />
              </div>
              <div className={styles.embedInstructionImgContainer}>
                <img
                  className={styles.embedInstructionImg}
                  src={tiktokCode}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  )

  const urlDialogContent = () => (
    <>
      <div className={styles.dialogFormContainer}>
        <Formik
          innerRef={urlDialogFormRef}
          initialValues={{
            url: '',
            label: ''
          }}
          validationSchema={addUrlTagValidation(texts)}
          onSubmit={(data) => { 
            addTagsBlock(
              editorRef, 
              `[url=${data.url}]${data.label}[/url]`,
              false,
              editorRef?.current?.selectionStart !== editorRef?.current?.selectionEnd
            )
            closeUrlDialog()
          }}
        >
          {(formikProps) => (
            <Form className={styles.dialogForm}>
              <div>
                <BaseInput
                  label={texts('editor:url_label')}
                  name={'url'}
                  formikProps={formikProps}
                  value={formikProps.values.url}
                  classes={{
                    containerWithLabel: styles.inputContainerWithLabel,
                    containerWithoutLabel: styles.inputContainerWithoutLabel,
                    fieldBase: styles.textFieldBase
                  }}
                  error={
                    formikProps.touched.url && Boolean(formikProps.errors.url)
                  }
                />
              </div>
              <div>
                <BaseInput
                  label={texts('editor:label_label')}
                  name={'label'}
                  formikProps={formikProps}
                  value={formikProps.values.label}
                  classes={{
                    containerWithLabel: styles.inputContainerWithLabel,
                    containerWithoutLabel: styles.inputContainerWithoutLabel,
                    fieldBase: styles.textFieldBase
                  }}
                  error={
                    formikProps.touched.label && Boolean(formikProps.errors.label)
                  }
                />
              </div>
              <div className={styles.insertTagDialogButtonContainer}>
                <LoadingButton
                  type={'button'}
                  className={styles.insertTagDialogButton}
                  circleSize={15}
                  circleColor={'#fff'}
                  onClick={() => {
                    formikProps.submitForm()
                  }}
                >
                  <div className={styles.insertTagDialogButtonContent}>
                    <div>{texts('editor:add_label')}</div>
                  </div>
                </LoadingButton>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  )

  const htmlDialogContent = () => (
    <>
      <div className={styles.dialogFormContainer}>
      <Formik
        innerRef={htmlDialogFormRef}
        initialValues={{
          html: ''
        }}
        validationSchema={addHtmlToBBCodeValidation(texts)}
        onSubmit={(data) => { 
          const html = parseHtmlString(data.html)
          addTagsBlock(editorRef, convertHtmlNodesToBBCode(html.childNodes))
          closeHtmlDialog()
        }}
      >
        {(formikProps) => (
          <Form className={styles.dialogForm}>
            <div>
              <BaseInput
                label={texts('editor:html_label')}
                name={'html'}
                rows={30}
                formikProps={formikProps}
                value={formikProps.values.html}
                classes={{
                  containerWithLabel: styles.inputContainerWithLabel,
                  containerWithoutLabel: styles.inputContainerWithoutLabel,
                  fieldBase: styles.textFieldBase
                }}
                error={
                  formikProps.touched.html && Boolean(formikProps.errors.html)
                }
              />
            </div>
            <div className={styles.insertTagDialogButtonContainer}>
              <LoadingButton
                type={'button'}
                className={styles.insertTagDialogButton}
                circleSize={15}
                circleColor={'#fff'}
                onClick={() => {
                  formikProps.submitForm()
                }}
              >
                <div className={styles.insertTagDialogButtonContent}>
                  <div>{texts('editor:add_label')}</div>
                </div>
              </LoadingButton>
            </div>
          </Form>
        )}
      </Formik>
      </div>
    </>
  )

  const quoteDialogContent = () => (
    <>
      <div className={styles.dialogFormContainer}>
      <Formik
        innerRef={quoteDialogFormRef}
        initialValues={{
          quote: '',
          signature: ''
        }}
        validationSchema={addQuoteTagValidation(texts)}
        onSubmit={(data) => { 
          addTagsBlock(
            editorRef, 
            `[quote${data.signature != '' ? ` signature="${encode(data.signature)}"` : ''}]${data.quote}[/quote]`, 
            false,
            editorRef?.current?.selectionStart !== editorRef?.current?.selectionEnd)
          closeQuoteDialog()
        }}
      >
        {(formikProps) => (
          <Form className={styles.dialogForm}>
            <div>
              <BaseInput
                label={texts('editor:quote_label')}
                name={'quote'}
                rows={10}
                formikProps={formikProps}
                value={formikProps.values.quote}
                classes={{
                  containerWithLabel: styles.inputContainerWithLabel,
                  containerWithoutLabel: styles.inputContainerWithoutLabel,
                  fieldBase: styles.textFieldBase
                }}
                error={
                  formikProps.touched.quote && Boolean(formikProps.errors.quote)
                }
              />
            </div>
            <div style={{ marginTop: '1.5rem' }}>
              <BaseInput
                label={texts('editor:signature_label')}
                name={'signature'}
                maxLen={120}
                count={true}
                formikProps={formikProps}
                value={formikProps.values.signature}
                classes={{
                  containerWithLabel: styles.inputContainerWithLabel,
                  containerWithoutLabel: styles.inputContainerWithoutLabel,
                  fieldBase: styles.textFieldBase
                }}
                error={
                  formikProps.touched.signature && Boolean(formikProps.errors.signature)
                }
              />
            </div>
            <div className={styles.insertTagDialogButtonContainer}>
              <LoadingButton
                type={'button'}
                className={styles.insertTagDialogButton}
                circleSize={15}
                circleColor={'#fff'}
                onClick={() => {
                  formikProps.submitForm()
                }}
              >
                <div className={styles.insertTagDialogButtonContent}>
                  <div>{texts('editor:add_label')}</div>
                </div>
              </LoadingButton>
            </div>
          </Form>
        )}
      </Formik>
      </div>
    </>
  )

  return (
    <>
      {        
        props.excludedActions?.photo
          ? null
          : <MediaLibraryDialog
            id={'bbcode-editor-media-library'}
            name={'bbcode-editor-media-library'}
            hidden={!showMediaLibrary}
            onCloseClick={() => {
              closeMediaLibraryDialog()
            }}
            onFinish={(img) => { 
              addTag(editorRef, 'cdn-img', false, img.uuid)
            }}
          />
      }
      <CustomDialog
        hidden={!showUrlDialog}
        classes={{
          container: `${styles.dialog} ${styles.urlDialog}`
        }}
        title={texts('editor:add_url')}
        closeButton={true}
        onCloseButtonClick={() => closeUrlDialog()}
      >
        {urlDialogContent()}
      </CustomDialog>
      <CustomDialog
        hidden={!showQuoteDialog}
        title={texts('editor:quote_label')}
        classes={{
          container: `${styles.dialog} ${styles.quoteDialog}`
        }}
        closeButton={true}
        onCloseButtonClick={() => closeQuoteDialog()}
      >
        {quoteDialogContent()}
      </CustomDialog>
      <CustomDialog
        hidden={!showHtmlDialog}
        title={texts('editor:converter_html_to_bbcode')}
        classes={{
          container: `${styles.dialog} ${styles.htmlDialog}`
        }}
        closeButton={true}
        onCloseButtonClick={() => closeHtmlDialog()}
      >
        {htmlDialogContent()}
      </CustomDialog>
      <CustomDialog
        hidden={!showPinterestDialog}
        title={texts('editor:pinterest_embed')}
        classes={{
          container: `${styles.dialog} ${styles.pinterestDialog}`
        }}
        closeButton={true}
        onCloseButtonClick={() => closePinterestDialog()}
      >
        {pinterestDialogContent()}
      </CustomDialog>
      <CustomDialog
        hidden={!showTwitterDialog}
        title={texts('editor:twitter_embed')}
        classes={{
          container: `${styles.dialog} ${styles.twitterDialog}`
        }}
        closeButton={true}
        onCloseButtonClick={() => closeTwitterDialog()}
      >
        {twitterDialogContent()}
      </CustomDialog>
      <CustomDialog
        hidden={!showYoutubeDialog}
        title={texts('editor:youtube_embed')}
        classes={{
          container: `${styles.dialog} ${styles.youtubeDialog}`
        }}
        closeButton={true}
        onCloseButtonClick={() => closeYoutubeDialog()}
      >
        {youtubeDialogContent()}
      </CustomDialog>
      <CustomDialog
        hidden={!showFacebookDialog}
        title={texts('editor:facebook_embed')}
        classes={{
          container: `${styles.dialog} ${styles.facebookDialog}`
        }}
        closeButton={true}
        onCloseButtonClick={() => closeFacebookDialog()}
      >
        {facebookDialogContent()}
      </CustomDialog>
      <CustomDialog
        hidden={!showTelegramPostDialog}
        title={texts('editor:telegram_post_embed')}
        classes={{
          container: `${styles.dialog} ${styles.telegramDialog}`
        }}
        closeButton={true}
        onCloseButtonClick={() => closeTelegramPostDialog()}
      >
        {telegramPostDialogContent()}
      </CustomDialog>
      <CustomDialog
        hidden={!showRedditDialog}
        title={texts('editor:reddit_embed')}
        classes={{
          container: `${styles.dialog} ${styles.redditDialog}`
        }}
        closeButton={true}
        onCloseButtonClick={() => closeRedditDialog()}
      >
        {redditDialogContent()}
      </CustomDialog>
      <CustomDialog
        hidden={!showInstagramDialog}
        title={texts('editor:instagram_embed')}
        classes={{
          container: `${styles.dialog} ${styles.instagramDialog}`
        }}
        closeButton={true}
        onCloseButtonClick={() => closeInstagramDialog()}
      >
        {instagramDialogContent()}
      </CustomDialog>
      <CustomDialog
        hidden={!showTikTokDialog}
        title={texts('editor:tikTok_embed')}
        classes={{
          container: `${styles.dialog} ${styles.tikTokDialog}`
        }}
        closeButton={true}
        onCloseButtonClick={() => closeTikTokDialog()}
      >
        {tikTokDialogContent()}
      </CustomDialog>
      <CustomModal
        isOpen={showLinkDialog}
        onCloseButtonClick={() => closeLinkDialog()}
        title={texts('editor:deep_link_dialog_title')}
        classes={{
          modal:`${styles.linkModal}`,
          title:`${styles.linkTitle}`}}
      >
        <AddLinkToAnotherArticle
          baseZIndex={101}
          excludedArticleUuid={props.excludedArticleUuid}
          onFinish={(type: DeepLinkTagType, uuids: string, title?: string) => {
            let selectedTag
            switch (type) {
              case DeepLinkTagType.SINGLE:
                selectedTag = 'article-deep-link'
                break
              case DeepLinkTagType.MULTI:
                selectedTag = 'article-deep-link-with-photo'
                break
            }
            addTag(editorRef, selectedTag, true, uuids, title ? [{ key: 'title', value: title}] : undefined)
            closeLinkDialog()
          }}
        />
      </CustomModal>
      <div className={styles.mainContainer}>
        <div className={`${styles.editorColumn} ${styles.editorSectionTitle}`}>
          {props.title ?? texts('editor:editor_article_content')}
        </div>
        <div id={'bbcode-editor-control-panel'} className={isFloatControl ? styles.floatControl : ''}>
          <div className={styles.controlRow} style={{ opacity: props.disabled ? 0.5 : 1 }}>
            <div className={styles.buttonBlock}>
              <button
                type={'button'}
                disabled={props.disabled}
                className={`${styles.controlButton} ${styles.greenButton}`}
                onClick={() => addTag(editorRef, 'p')}
              >
                {texts('editor:paragraph_label')}
                <span className={controlButtonTooltipClassName}>
                  {texts('editor:paragraph_label_tooltip')}
                </span>
              </button>
            </div>
            <div className={styles.buttonBlock}>
              <button
                type={'button'}
                disabled={props.disabled}
                className={`${styles.controlButton} ${styles.blueButton}`}
                onClick={() => addTag(editorRef, 'h2', true)}
              >
                {texts('editor:h2')}
                <span className={controlButtonTooltipClassName}>
                  {texts('editor:h2_tooltip')}
                </span>
              </button>
              <button
                type={'button'}
                disabled={props.disabled}
                className={`${styles.controlButton} ${styles.blueButton}`}
                onClick={() => addTag(editorRef, 'h3', true)}
              >
                {texts('editor:h3')}
                <span className={controlButtonTooltipClassName}>
                  {texts('editor:h3_tooltip')}
                </span>
              </button>
              <button
                type={'button'}
                disabled={props.disabled}
                className={`${styles.controlButton} ${styles.blueButton}`}
                onClick={() => addTag(editorRef, 'h4', true)}
              >
                {texts('editor:h4')}
                <span className={controlButtonTooltipClassName}>
                  {texts('editor:h4_tooltip')}
                </span>
              </button>
              <button
                type={'button'}
                disabled={props.disabled}
                className={`${styles.controlButton} ${styles.blueButton}`}
                onClick={() => addTag(editorRef, 'h5', true)}
              >
                {texts('editor:h5')}
                <span className={controlButtonTooltipClassName}>{texts('editor:h5_tooltip')}</span>
              </button>
              <button
                type={'button'}
                disabled={props.disabled}
                className={`${styles.controlButton} ${styles.blueButton}`}
                onClick={() => addTag(editorRef, 'h6', true)}
              >
                {texts('editor:h6')}
                <span className={controlButtonTooltipClassName}>{texts('editor:h6_tooltip')}</span>
              </button>
            </div>
            <div className={styles.buttonBlock}>
              <button
                type={'button'}
                disabled={props.disabled}
                className={`${styles.controlButton} ${styles.redButton}`}
                onClick={() => {
                  if (editorRef?.current?.selectionStart as number !== editorRef?.current?.selectionEnd as number) {
                    let tag = '[list]'
                    const selectedText = editorRef?.current?.value.substring(
                      editorRef?.current?.selectionStart as number,
                      editorRef?.current?.selectionEnd as number
                    )
                    for (const row of selectedText.split('\n')) {
                      tag += `\n[li]${row}[/li]`
                    }
                    tag += '\n[/list]'
                    addTagsBlock(editorRef, tag, false, true)
                  } else {
                    addTagsBlock(editorRef, '[list]\n[li][/li]\n[li][/li]\n[li][/li]\n[/list]', true)
                  }
                }}
              >
                <AiOutlineUnorderedList size={20} />
                <span className={controlButtonTooltipClassName}>
                  {texts('editor:list_tooltip')}
                </span>
              </button>
              <button
                type={'button'}
                disabled={props.disabled}
                className={`${styles.controlButton} ${styles.redButton}`}
                onClick={() => {
                  if (editorRef?.current?.selectionStart as number !== editorRef?.current?.selectionEnd as number) {
                    let tag = '[olist]'
                    const selectedText = editorRef?.current?.value.substring(
                      editorRef?.current?.selectionStart as number,
                      editorRef?.current?.selectionEnd as number
                    )
                    for (const row of selectedText.split('\n')) {
                      tag += `\n[li]${row}[/li]`
                    }
                    tag += '\n[/olist]'
                    addTagsBlock(editorRef, tag, false, true)
                  } else {
                    addTagsBlock(editorRef, '[olist]\n[li][/li]\n[li][/li]\n[li][/li]\n[/olist]', true)
                  }
                }}
              >
                <AiOutlineOrderedList size={20} />
                <span className={controlButtonTooltipClassName}>
                  {texts('editor:olist_tooltip')}
                </span>
              </button>
              <button
                type={'button'}
                disabled={props.disabled}
                className={`${styles.controlButton} ${styles.redButton}`}
                onClick={() => {
                  quoteDialogFormRef?.current?.setFieldValue('quote', editorRef?.current?.value.substring(
                    editorRef?.current?.selectionStart as number,
                    editorRef?.current?.selectionEnd as number
                  ))
                  openQuoteDialog()
                }}
              >
                <MdFormatQuote size={20} />
                <span className={controlButtonTooltipClassName}>
                  {texts('editor:quote_tooltip')}
                </span>
              </button>
              <button
                type={'button'}
                disabled={props.disabled}
                className={`${styles.controlButton} ${styles.redButton}`}
                onClick={() => addTag(editorRef, 'b')}
              >
                <BsTypeBold size={20} />
                <span className={controlButtonTooltipClassName}>
                  {texts('editor:bold_tooltip')}
                </span>
              </button>
              <button
                type={'button'}
                disabled={props.disabled}
                className={`${styles.controlButton} ${styles.redButton}`}
                onClick={() => addTag(editorRef, 'i')}
              >
                <BiItalic size={20} />
                <span className={controlButtonTooltipClassName}>
                  {texts('editor:italic_tooltip')}
                </span>
              </button>
              <button
                type={'button'}
                disabled={props.disabled}
                className={`${styles.controlButton} ${styles.redButton}`}
                onClick={() => addTag(editorRef, 'u')}
              >
                <AiOutlineUnderline size={20} />
                <span className={controlButtonTooltipClassName}>
                  {texts('editor:underline_tooltip')}
                </span>
              </button>
              <button
                type={'button'}
                disabled={props.disabled}
                className={`${styles.controlButton} ${styles.redButton}`}
                onClick={() => addTag(editorRef, 's')}
              >
                <MdFormatStrikethrough size={20} />
                <span className={controlButtonTooltipClassName}>
                  {texts('editor:striketrough_tooltip')}
                </span>
              </button>
              <button
                type={'button'}
                disabled={props.disabled}
                className={`${styles.controlButton} ${styles.redButton}`}
                onClick={() => addTagsBlock(editorRef, '[br][/br]')}
              >
                <MdKeyboardReturn size={20} />
                <span className={controlButtonTooltipClassName}>
                  {texts('editor:newline_tooltip')}
                </span>
              </button>
            </div>
            <div className={styles.buttonBlock}>
              <button
                type={'button'}
                disabled={props.disabled}
                className={`${styles.controlButton} ${styles.blackButton}`}
                onClick={() => clearStyleTagsFromSelection(editorRef)}
              >
                <MdFormatClear size={20} />
                <span className={controlButtonTooltipClassName}>
                  {texts('editor:format_clear_tooltip')}
                </span>
              </button>
            </div>
            <div className={styles.buttonBlock}>
              {
                props.excludedActions?.adBlock
                ? null
                : <button
                    type={'button'}
                    disabled={props.disabled}
                    className={`${styles.controlButton} ${styles.orangeButton}`}
                    onClick={() => addTagsBlock(editorRef, '[ad-block][/ad-block]')}
                  >
                    <RiAdvertisementFill size={20} />
                    <span className={controlButtonTooltipClassName}>
                      {texts('editor:ad_block_tooltip_tooltip')}
                    </span>
                  </button>
              }
            </div>
            <div className={styles.buttonBlock}>
              <button
                type={'button'}
                disabled={props.disabled}
                className={`${styles.controlButton} ${styles.yellowButton}`}
                onClick={() => {
                  urlDialogFormRef?.current?.setFieldValue('label', editorRef?.current?.value.substring(
                    editorRef?.current?.selectionStart as number,
                    editorRef?.current?.selectionEnd as number
                  ))
                  openUrlDialog()
                }}
              >
                <IoMdLink size={20} />
                <span className={controlButtonTooltipClassName}>
                  {texts('editor:link_tooltip')}
                </span>
              </button>
              {
                props.excludedActions?.facebook
                ? null
                : <button
                    type={'button'}
                    disabled={props.disabled}
                    className={`${styles.controlButton} ${styles.yellowButton}`}
                    onClick={openFacebookDialog}
                  >
                    <FaFacebook size={20} />
                    <span className={controlButtonTooltipClassName}>
                      {texts('editor:facebook_tooltip')}
                    </span>
                  </button>
              }
              {
                props.excludedActions?.instagram
                ? null
                : <button
                    type={'button'}
                    disabled={props.disabled}
                    className={`${styles.controlButton} ${styles.yellowButton}`}
                    onClick={openInstagramDialog}
                  >
                    <FaInstagram size={20} />
                    <span className={controlButtonTooltipClassName}>
                      {texts('editor:instagram_tooltip')}
                    </span>
                  </button>
              } 
              {
                props.excludedActions?.twitter
                ? null
                : <button
                    type={'button'}
                    disabled={props.disabled}
                    className={`${styles.controlButton} ${styles.yellowButton}`}
                    onClick={openTwitterDialog}
                  >
                    <FaTwitter size={20} />
                    <span className={controlButtonTooltipClassName}>
                      {texts('editor:twitter_tooltip')}
                    </span>
                  </button>
              }
              {
                props.excludedActions?.tiktok
                ? null
                : <button
                    type={'button'}
                    disabled={props.disabled}
                    className={`${styles.controlButton} ${styles.yellowButton}`}
                    onClick={openTikTokDialog}
                  >
                    <SiTiktok size={20} />
                    <span className={controlButtonTooltipClassName}>
                      {texts('editor:tiktok_tooltip')}
                    </span>
                  </button>
              }
              {
                props.excludedActions?.pinterest
                ? null
                : <button
                    type={'button'}
                    disabled={props.disabled}
                    className={`${styles.controlButton} ${styles.yellowButton}`}
                    onClick={openPinterestDialog}
                  >
                    <FaPinterest size={20} />
                    <span className={controlButtonTooltipClassName}>
                      {texts('editor:pinterest_tooltip')}
                    </span>
                  </button>
              }
              {
                props.excludedActions?.youtube 
                ? null
                : <button
                    type={'button'}
                    disabled={props.disabled}
                    className={`${styles.controlButton} ${styles.yellowButton}`}
                    onClick={openYoutubeDialog}
                  >
                    <FaYoutube size={20} />
                    <span className={controlButtonTooltipClassName}>
                      {texts('editor:youtube_tooltip')}
                    </span>
                  </button>
              }
              {
                props.excludedActions?.telegram 
                ? null
                : <button
                    type={'button'}
                    disabled={props.disabled}
                    className={`${styles.controlButton} ${styles.yellowButton}`}
                    onClick={openTelegramPostDialog}
                  >
                    <FaTelegram size={20} />
                    <span className={controlButtonTooltipClassName}>
                      {texts('editor:telegram_tooltip')}
                    </span>
                  </button>
              }
              {
                props.excludedActions?.reddit 
                ? null
                : <button
                    type={'button'}
                    disabled={props.disabled}
                    className={`${styles.controlButton} ${styles.yellowButton}`}
                    onClick={openRedditDialog}
                  >
                    <GrReddit size={20} />
                    <span className={controlButtonTooltipClassName}>
                      {texts('editor:reddit_tooltip')}
                    </span>
                  </button>
              }
            </div>
            <div className={styles.buttonBlock}>
              {
                props.excludedActions?.converter
                ? null
                : <button
                    type={'button'}
                    disabled={props.disabled}
                    className={`${styles.controlButton} ${styles.transparentButton}`}
                    onClick={openHtmlDialog}
                  >
                  {'HTML -> BB CODE'}
                  <span className={controlButtonTooltipClassName}>
                    {texts('editor:html_to_bbcode_tooltip')}
                  </span>                  
                </button>
              }
            </div>
            {
              props.excludedActions?.photo 
              ? null
              : <button
                  type={'button'}
                  disabled={props.disabled}
                  className={`${styles.controlButton} ${styles.controlButtonMultiContent}`}
                  onClick={openMediaLibraryDialog}
                >
                  <MdImage size={20} style={{ marginRight: '0.375rem' }}/> WSTAW ZDJĘCIE
                  <span className={controlButtonTooltipClassName}>
                    {texts('editor:insert_image_tooltip')}
                  </span>
                </button>
            }
            {
              props.excludedActions?.link
              ? null
              : <button
                  type={'button'}
                  disabled={props.disabled}
                  onClick={openLinkDialog}
                  className={`${styles.controlButton} ${styles.controlButtonMultiContent}`}
                >                     
                  <MdArtTrack size={20}style={{ marginRight: '0.375rem' }}/>              
                  {texts('editor:deep_link_button_label')}
                  <span className={controlButtonTooltipClassName}>
                    {texts('editor:insert_deep_link_tooltip')}
                  </span>
                </button>
            }
          </div>
        </div>    
        <div className={styles.contentContainer}>
          <div
            className={`${styles.editorColumn} ${styles.editorSectionTitle}`}
            style={{
              ...(props.previewWidth ? { width: `calc(99% - ${props.previewWidth})` } : {})
            }}
          >
            {props.contentTitle ?? texts('editor:article_content_label')}
          </div>
          <div
            className={`${styles.previewColumn} ${styles.editorSectionTitle}`}
            style={{
              ...(props.previewWidth ? { width: props.previewWidth } : {})
            }}
          >
            {props.previewTitle ?? texts('editor:article_preview_label')}
          </div>
          <div
            className={styles.editorColumn}
            style={{
              ...(props.previewWidth ? { width: `calc(99% - ${props.previewWidth})` } : {})
            }}
          >
            <BaseInput  
              containerId={'bbcode-editor-content'}
              name={props.name}   
              classes={{
                fieldBase: styles.textField,
                fieldNormal: styles.textFieldNormal
              }}
              disabled={props.disabled}
              styles={{
                container: { height: '100%' },
                input: {
                  height: '100%',
                  opacity: props.disabled ? 0.5 : 1
                }
              }}
              formikProps={props.formikProps}
              value={props.formikProps.values[props.name]}
              rows={props.rows ?? 30}
              innerRef={editorRef}
              error={
                props.formikProps.touched[props.name] && Boolean(props.formikProps.errors[props.name])
              }
            /> 
          </div>
          <div
            className={`${styles.previewColumn} ${styles.preview}`}
            style={{
              ...(props.previewWidth ? { width: props.previewWidth } : {})
            }}
          >
            {props.previewComponent}
          </div>
        </div>
      </div>
    </>
  )
}

export default BBCodeEditor
