import { Tag } from 'bbcode-to-react'
import styles from './style.module.scss'

type Props = {
  url: string
}

export default class DepeszaUrlTag<T = { linkify: boolean }> extends Tag<T> {
  toReact() {
    const props = this.params as Props

    return (
      <a className={styles.url_url} href={props.url} target={'_blank'}>{this.getComponents()}</a>
    )
  }
}