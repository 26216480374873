import { Node } from 'node-html-parser'
import { decode } from 'html-entities'

export function convertHtmlNodesToBBCode (nodes: Node[]): string {
  let code = ''
  let tmp
  for (const elem of nodes) {
    if ((elem as any).rawTagName != null) {
      switch ((elem as any).rawTagName) {
        case 'p': 
          code += '[p]'
          code += convertHtmlNodesToBBCode(elem.childNodes)
          code += '[/p]\n'
          break
        case 'span': 
          code += '[span]'
          code += convertHtmlNodesToBBCode(elem.childNodes)
          code += '[/span]\n'
          break
        case 'a':
          tmp = (elem as any).rawAttrs.split(' ').find((e: string) => {
            return e.split('=')[0] === 'href'
          })
          code += tmp ? `[url=${tmp.substring(5, tmp.length-1).replace(/"/g, '')}]` : ''
          code += convertHtmlNodesToBBCode(elem.childNodes)
          code += tmp ? '[/url]' : ''
          break
        case 'strong':
        case 'b':
          code += '[b]'
          code += convertHtmlNodesToBBCode(elem.childNodes)
          code += '[/b]'
          break
        case 'h2':
          code += '[h2]'
          code += convertHtmlNodesToBBCode(elem.childNodes)
          code += '[/h2]\n'
          break
        case 'h3':
          code += '[h3]'
          code += convertHtmlNodesToBBCode(elem.childNodes)
          code += '[/h3]\n'
          break
        case 'h4':
          code += '[h4]'
          code += convertHtmlNodesToBBCode(elem.childNodes)
          code += '[/h4]\n'
          break
        case 'h5':
          code += '[h5]'
          code += convertHtmlNodesToBBCode(elem.childNodes)
          code += '[/h5]\n'
          break
        case 'h6':
          code += '[h6]'
          code += convertHtmlNodesToBBCode(elem.childNodes)
          code += '[/h6]\n'
          break
        case 'i':
        case 'em':
          code += '[i]'
          code += convertHtmlNodesToBBCode(elem.childNodes)
          code += '[/i]'
          break
        case 'u':
          code += '[u]'
          code += convertHtmlNodesToBBCode(elem.childNodes)
          code += '[/u]'
          break
        case 'ul':
          code += '[list]'
          code += convertHtmlNodesToBBCode(elem.childNodes)
          code += '[/list]\n'
          break
        case 'ol':
          code += '[olist]'
          code += convertHtmlNodesToBBCode(elem.childNodes)
          code += '[/olist]\n'
          break
        case 'li':
          code += '[li]'
          code += convertHtmlNodesToBBCode(elem.childNodes)
          code += '[/li]\n'
          break
        case 'blockquote':
          code += '[quote]'
          code += convertHtmlNodesToBBCode(elem.childNodes)
          code += '[/quote]'
          break
      }
    } else {
      code += decode(decodeURIComponent(elem.rawText.replace(/%/g, '%25')))
    }
  }
  return code
}