import { Tag } from 'bbcode-to-react'
import styles from './style.module.scss'

type Props = {
  type: "a" | "i" | "1" | "A" | "I" | undefined
}

export default class ZaradnaOListTag<T = { linkify: boolean }> extends Tag<T> {
  toReact() {
    const props = this.params as Props

    return (
      <ol
        {...(props.type ? { type: props.type } : {})}
        className={styles.ol}
      >
        {this.getComponents()}
      </ol>
    )
  }
}