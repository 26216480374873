import { auth, AuthState} from "../auth/auth"
import { messages, MessagesState } from "../messages/messages"
import { combineReducers } from "redux"
import { user, UserState } from '../user/user'

export type State = {
  auth: AuthState
  messages: MessagesState
  user: UserState
}

export const rootReducer = combineReducers<State, any>({
  auth: auth,
  messages: messages,
  user: user,
})