import React from 'react'
import styles from './styles.module.scss'
import { BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs'
import { useGetWindowWidth } from '../../../lib/react-window-width-hook'

type Props = {
  pages: number
  selectedPage: number
  onChange: (selectedPage: number) => void
}

const Pagination = (props: Props) => {
  const [pageToShow, setPageToShow] = React.useState<(string | number)[]>([])

  const screenWidth = useGetWindowWidth()

  React.useEffect(() => {
    let limit = 10

    if (screenWidth <= 1000) {
      limit = 5
    }

    const pageArray: (string | number)[] = []

    if (props.pages <= limit) {
      for (let i = 1; i <= props.pages; i++) {
        pageArray.push(i)
      }
    } else if (props.selectedPage + Math.floor(limit / 2) > props.pages) {
      for (let i = props.pages - limit + 3; i < props.pages; i++) {
        pageArray.push(i)
      }
    } else if (props.selectedPage - Math.floor(limit / 2) < 1) {
      for (let i = 1; i <= limit - 2; i++) {
        pageArray.push(i)
      }
    } else {
      const startPoint = props.selectedPage - Math.floor((limit - 2) / 2)
      for (let i = startPoint; i <= startPoint + limit - 3; i++) {
        pageArray.push(i)
      }
    }

    if (pageArray.length && pageArray[0] === 2) {
      pageArray.unshift(...[1])
    } else if (pageArray.length && pageArray[0] !== 1) {
      pageArray.unshift(...[1, '...'])
    }

    if (pageArray.length && pageArray[pageArray.length - 1] === props.pages - 1) {
      pageArray.push(...[props.pages])
    } else if (
      pageArray.length &&
      pageArray[pageArray.length - 1] !== props.pages
    ) {
      pageArray.push(...['...', props.pages])
    }
    
    setPageToShow(pageArray)
  }, [props.selectedPage, props.pages, screenWidth])

  return (
    <div className={styles.container}>
      <span
        className={styles.arrow}
        onClick={() => {
          if (props.selectedPage - 1 >= 1) {
            props.onChange(props.selectedPage - 1)
          }
        }}
      >
        <BsArrowLeftShort size={30}/>
      </span>

      <div className={styles.pagesContainer}>
        {pageToShow.map((e, idx) => {
          if (typeof e === 'number') {
            return (
              <button
                type={'button'}
                key={`pagination-element-${idx}`}
                className={`${styles.paginationElement} ${
                  e === props.selectedPage
                    ? styles.selectedOption
                    : ''
                }`}
                onClick={() => props.onChange(e)}
              >
                {e}
              </button>
            )
          }

          return <div key={`pagination-element-${idx}`} className={styles.paginationElement}>{e}</div>
        })}
      </div>
      <span
        className={styles.arrow}
        onClick={() => {
          if (props.selectedPage + 1 <= props.pages) {
            props.onChange(props.selectedPage + 1)
          }
        }}
      >
        <BsArrowRightShort size={30}/>
      </span>
    </div>
  )
}

export default Pagination
