import React from 'react'
import styles from './styles.module.scss'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import LoadingButton from '../LoadingButton/LoadingButton'
import { TagDto } from '../../../types/data'
import { connect, FormikProps } from 'formik'

type Props = {
  id?: string
  hidden?: boolean
  children?: React.ReactNode[] | React.ReactNode
  title?: string
  closeButton?: boolean
  onCloseButtonClick?: () => void
  onConfirmButtonClick?: () => void
  wikiTagSelected: TagDto[]
  formik?: FormikProps<any>
}

const ReassignWikiDialogContent = (props: Props) => {

  const texts = useTranslation().t

  return (
    <div 
      key={props.id}
      className={styles.dialog}
      style={{display: props.hidden ? 'none' : 'block'}}
    >
      {
        props.closeButton
        ? <div
            className={styles.closeButton}
            onClick={() => { 
              if (props.onCloseButtonClick) {
                props.onCloseButtonClick()
              }
            }}
          >
            {texts('common:close_dialog_button')}
          </div>
        : null
      }
      {
        props.title
        ? <div className={styles.title}>{props.title}</div>
        : null
      }
      <div className={styles.textContainer}>
        <p>
          <span>{texts('article:reassign_wiki_prompt_head')}</span>
          <span style={{color: 'red'}}>{texts('article:reassign_wiki_prompt_warning')}</span>
        </p>
        <p>
          <span>{texts('article:reassign_wiki_prompt_dialog')}</span>
          <Link to={`/article/edit/${props.wikiTagSelected[0]?.wikiArticleUuid}`} target="_blank" rel="noopener noreferrer" style={{color: 'red'}}>
            {texts('article:reassign_wiki_prompt_link')}
          </Link>
          <span>.</span>
        </p>
      </div>
        <div className={styles.buttonsSection}>
          <LoadingButton 
            text={texts('article:confirm_reassign_wiki_dialog_cancel_button_label')}
            className={styles.cancelButton}
            onClick={() => {
              if (props.onCloseButtonClick) {
                props.onCloseButtonClick()
              }
            }}
          />
          <LoadingButton 
            text={texts('article:confirm_reassign_wiki_dialog_accept_button_label')}
            className={styles.confirmButton}
            onClick={() => {
              props.formik?.setFieldValue('tags', props.wikiTagSelected)
              props.onConfirmButtonClick?.()
            }}
          /> 
        </div>
    </div>
  )
}

export default connect(ReassignWikiDialogContent) 
