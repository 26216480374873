import { BASE_CDN } from './baseRoutes'
import { BaseApiWithRefresh } from './BaseApiWithRefresh'

class CdnApi extends BaseApiWithRefresh {
  constructor() {
    super({
      baseURL: `${BASE_CDN}/api/v1/`,
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }

  public getImageList(search?: string, limit?: number, offset?: number) {
    const params: string[] = []
    if (limit != null) {
      params.push(`search=${search}`)
    }
    if (limit != null) {
      params.push(`limit=${limit}`)
    }
    if (offset != null) {
      params.push(`offset=${offset}`)
    }
    return this.api.get(`/library/media/image/list${params.length > 0 ? `?${params.join('&')}` : ''}`)
  }

  public getImageDetails(uuid: string) {
    return this.api.get(`/library/media/image/${uuid}/details`)
  }

  public editImage(uuid: string, title: string, alt: string, signature: string) {
    return this.api.patch(`/library/media/image/${uuid}/edit`, JSON.stringify({
      title: title,
      alt: alt,
      signature: signature
    }))
  }

  public uploadLibraryMediaImage(image: File) {
    const body = new FormData()
    body.set('image', image)
    return this.api.post('/library/media/image/upload', body, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  }
}

export default new CdnApi()