import { AnyAction } from "redux"
import { ADD_USER, REMOVE_USER } from './types'

export type UserState = {
  uuid: string
  nick: string
  name: string
  lastName: string
  email: string
}

const localStorageUserUuid = typeof localStorage.getItem('user_uuid') === "string" ? localStorage.getItem('user_uuid') as string : ''
const localStorageUserEmail = typeof localStorage.getItem('user_email') === "string" ? localStorage.getItem('user_email') as string : ''
const localStorageUserNick = typeof localStorage.getItem('user_nick') === "string" ? localStorage.getItem('user_nick') as string : ''
const localStorageUserName = typeof localStorage.getItem('user_name') === "string" ? localStorage.getItem('user_name') as string : ''
const localStorageUserLastName = typeof localStorage.getItem('user_last_name') === "string" ? localStorage.getItem('user_last_name') as string : ''

export const initialUserValue = {
  nick: localStorageUserNick,
  email: localStorageUserEmail,
  lastName: localStorageUserLastName,
  name: localStorageUserName,
  uuid: localStorageUserUuid
}

export const user = (state: UserState = initialUserValue, action: AnyAction) => {
  switch(action.type) {
    case ADD_USER:
      return { 
        uuid: action.uuid,
        email: action.email,
        name: action.name,
        lastName: action.lastName,
        nick: action.nick
      }
    case REMOVE_USER:
      return { 
        email: '',
        name: '',
        lastName: '',
        uuid: '',
        nick: ''
      }
    default:
      return state
  }
}