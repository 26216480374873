import React from 'react'
import styles from './styles.module.scss'

type Props = {
  show: boolean
  zIndex: number
  children?: React.ReactNode | React.ReactNode[]
  style?: React.CSSProperties
}

const PageCover = (props: Props) => {
  return (
    <div
      className={`${styles.cover} ${props.show ? styles.cover : styles.hideCover}`}
      style={{
        zIndex: props.zIndex,
        ...(props.style ?? {})
      }}
    >
      {props.children}
    </div>
  )
}

export default PageCover
