// Zaradna
import zaradnaLogo from './images/zaradna/logo.png'
import zaradnaPlaceholderAvatar from './images/zaradna/placeholderAvatar.webp'
import zaradnaPlaceholderImage from './images/zaradna/placeholderImage.webp'

// Depesza
import depeszaLogo from './images/depesza/logo.png'
import depeszaPlaceholderAvatar from './images/depesza/placeholderAvatar.webp'
import depeszaPlaceholderImage from './images/depesza/placeholderImage.webp'
import { Project } from '../types/common'


class AssetLoader {
  getLogo() {
    switch (process.env.REACT_APP_PROJECT) {
      case Project.DEPESZA:
        return depeszaLogo
      case Project.ZARADNA:
        return zaradnaLogo
    }
  }

  getPlaceholderImage() {
    switch (process.env.REACT_APP_PROJECT) {
      case Project.DEPESZA:
        return depeszaPlaceholderImage
      case Project.ZARADNA:
        return zaradnaPlaceholderImage
    }
  }

  getPlaceholderAvatar() {
    switch (process.env.REACT_APP_PROJECT) {
      case Project.DEPESZA:
        return depeszaPlaceholderAvatar
      case Project.ZARADNA:
        return zaradnaPlaceholderAvatar
    }
  }
}

export default new AssetLoader()