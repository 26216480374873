import * as Yup from 'yup'
import { email, forbiddenSpecialCharacters } from './validationRegexps'

export const changeEmailValidation = (t: (val: string) => string) => {
  return Yup.object({
    newEmail: Yup.string()
      .required(t('common:field_required'))
      .matches(forbiddenSpecialCharacters, t('common:special_characters_forbidden_validation'))
      .matches(email, t('auth:invalid_email_validation')),
    reNewEmail: Yup.string()
      .required(t('common:field_required'))
      .matches(forbiddenSpecialCharacters, t('common:special_characters_forbidden_validation'))
      .matches(email, t('auth:invalid_email_validation'))
      .oneOf([Yup.ref('newEmail'), null], t('auth:emails_must_match'))
  })
}