import { Tag } from 'bbcode-to-react'
import React from 'react'
import styles from './style.module.scss'
import {decode} from 'html-entities'

type Props = {
  url: string
}

export default class DepeszaTikTokTag<T = { linkify: boolean }> extends Tag<T> {
  toReact() {
    const props = this.params as Props
    
    return (
      <div
        className={styles.tiktok_container}
        dangerouslySetInnerHTML={{
          __html: decode(this.getContent().replace(/<br>|<br\/>|<br \/>/g, ''))
        }}
      >
      </div>
    )
  }
}