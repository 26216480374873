export type Role = {
  name: string
  value: number
}

export type CategoryDto = {
  label: string,
  value: string
}

export type TagDto = {
  label: string,
  value: string,
  wikiArticleUuid: string
}

export type ArticlePropsDto = {
  value: string, 
  label: string
}

export type Editor = {
  uuid?: string, 
  name: string, 
  lastName: string
}

export type SuggestedWikiDto = {
  uuid: string,
  name: string,
  matchedPhrase: string,
  wikiArticle: {
    uuid: string,
    slug: string,
    title: string
  }
}

export type Resources<T> = {
  data?: T | null,
  state: LoadingState,
  error?: any | null
}

export enum LoadingState {
  INIT, 
  LOADING,
  ERROR,
  LOADED
}