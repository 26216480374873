export enum MessageEnum  {
  SOMETHING_WENT_WRONG = 'something_went_wrong_message',
  TOKEN_INVALID = 'invalid_token_message',
  TOKEN_ALREADY_USED = 'token_already_used_message',
  EMAIL_TAKEN = 'email_taken_message',
  SAME_PASSWORD_AS_OLD_ONE = 'same_password_as_old_one_message',
  INACTIVE_USER = 'user_is_inactive_message',
  ACCOUNT_REMOVED = 'account_removed_message',
  INVALID_CREDENTIALS = 'invalid_credentials_message',
  INCORRECT_PASSWORD = 'incorrect_password_message',
  ACCOUNT_HAS_BEEN_ACTIVATED = 'account_has_been_activated_message',
  USER_DATA_HAS_BEEN_CHANGED = 'user_data_has_been_changed_message',
  EMAIL_VERIFICATION_MESSAGE_HAS_BEEN_SEND = 'email_verification_message_has_been_send_message',
  EMAIL_HAS_BEEN_VERIFIED = 'email_has_been_verified_message',
  PASSWORD_HAS_BEEN_CHANGED = 'password_has_been_changed_message',
  PASSWORD_RESET_HAS_BEEN_REQUESTED = 'user_reset_password_message',
  ACTIVATION_EMAIL_RESEND_HAS_BEEN_REQUESTED = 'user_resend_activation_email_message',
  ENABLE_PAGE_COVER = 'enable_page_cover_message',
  DISABLE_PAGE_COVER = 'disable_page_cover_message',
  ENABLE_DIALOG_COVER = 'enable_dialog_cover_message',
  DISABLE_DIALOG_COVER = 'disable_dialog_cover_message',
  UNAUTHORIZED = 'unauthorized_message',
  ARTICLE_NOT_FOUND = 'article_not_found_message',
  LOST_ACCESS = 'lost_access_message',
  GALLERY_NOT_VALID_TO_INSERT = 'only_active_galleries_message',
  ARTICLE_DEACTIVATED = 'article_deactivated_message',
  ARTICLE_ACTIVATED = 'article_activated_message',
  GALLERY_DEACTIVATED = 'gallery_deactivated_message',
  GALLERY_ACTIVATED = 'gallery_activated_message',
  ARTICLE_ADDED = 'article_created_message',
  ARTICLE_UPDATED = 'article_updated_message',
  GALLERY_ADDED = 'gallery_created_message',
  GALLERY_UPDATED = 'gallery_updated_message',
  ACCOUNT_UPDATED = 'account_updated_message',
  EMAIL_CHANGE_VERIFIED = 'email_change_verified_message',
  PWD_HAS_BEEN_CHANGED = 'pwd_has_been_changed_message',
  EMAIL_CHANGE_PENDING = 'email_change_pending_message',
  SAME_SLUG_ALREADY_EXISTS = 'same_slug_already_exists',
  TOO_MANY_AD_BLOCKS = 'too_many_ad_blocks_message'
}