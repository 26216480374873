import React from 'react'
import { ToastProvider } from 'react-toast-notifications'
import CustomToast from './CustomToast'

// import {
//   useLocation,
// } from "react-router-dom"

type Props = {
  children: React.ReactNode | React.ReactNode[]
}

const CustomToastProvider = (props: Props) => {

  // const [placement, setPlacement] = React.useState<Placement>('top-right')

  // const location = useLocation()

  // React.useEffect(() => {
  //   console.log('App render', location.pathname)
  //   if (location.pathname === '/left') {
  //     setPlacement('top-left')
  //   } else {
  //     setPlacement('top-right')
  //   }
  // }, [])

  return (
    <ToastProvider
      autoDismiss={true}
      autoDismissTimeout={3000}
      // placement={placement}
      components={{ 
        Toast: CustomToast
      }}
    >
      {props.children}
    </ToastProvider>
  )
}


export default CustomToastProvider
