import AssetLoader from '../../../assets/loader'
import styles from './styles.module.scss'

export type Tag = {
    name: string
    uuid?: string
}
  
export type Category = {
  name: string
  uuid?: string
}
   
export type Author = {
  name: string
  lastName: string
  nick?: string
  uuid?: string
}

export type MainImage = {
  filename: string,
  hostname: string,
  path: string
}
  
export type Article = {
  uuid: string
  draft: boolean
  active: boolean
  title?: string
  author?: Author
  publishDate: Date
  leadText?: string
  category?: Category
  tags?: Tag[],
  mainImage?: MainImage
}
  
type Props = { 
  article: Article
}

const ArticleTileImage = (props: Props) => {
  return(
    <div className={styles.imageContainer}>
      <img 
        src={
          props.article.mainImage
          ? `${props.article.mainImage.hostname}${props.article.mainImage.path}${props.article.mainImage.filename}`
          : AssetLoader.getPlaceholderImage()
        }
        width={191}
        height={100}
      />
    </div>
  )
}

export default ArticleTileImage