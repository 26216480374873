import { Location } from 'history'
import React from 'react'
import { Prompt, useHistory } from 'react-router-dom'
import CustomDialog from '../CustomDialog/CustomDialog'
import styles from './styles.module.scss'
import LoadingButton from '../LoadingButton/LoadingButton'
import { useTranslation } from 'react-i18next'

/**
 * Base code from: https://michaelchan-13570.medium.com/using-react-router-v4-prompt-with-custom-modal-component-ca839f5faf39
 */

interface Props {
  when?: boolean | undefined;
  shouldBlockNavigation: (location: Location) => boolean;
  customModal?: boolean
}
const RouteLeavingGuard = (props: Props) => {
  const history = useHistory()
  const text = useTranslation().t
  const [modalVisible, setModalVisible] = React.useState(false)
  const [lastLocation, setLastLocation] = React.useState<Location | null>(null)
  const [confirmedNavigation, setConfirmedNavigation] = React.useState(false)

  const closeModal = () => {
    setModalVisible(false)
  }

  const handleBlockedNavigation = (nextLocation: Location): string | boolean => {
    if (!confirmedNavigation && props.shouldBlockNavigation(nextLocation) && props.customModal) {
      setModalVisible(true)
      setLastLocation(nextLocation)
      return false
    } else if (!confirmedNavigation && props.shouldBlockNavigation(nextLocation)) {
      return text('common:leave_form_warning') as string
    }
    return true
  }

  const handleConfirmNavigationClick = () => {
    setModalVisible(false)
    setConfirmedNavigation(true)
  }

  React.useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      history.push(lastLocation.pathname, lastLocation.state)
    }
  }, [confirmedNavigation, lastLocation])

  return (
    <>
      <Prompt when={props.when} message={handleBlockedNavigation} />
      <div hidden={!modalVisible} className={styles.cover}>
        <CustomDialog
          hidden={!modalVisible}
          title={text('common:leave_page_warning_title')}
          closeButton
          zIndex={301}
          classes={{
            container: styles.dialog
          }}
          onCloseButtonClick={closeModal}
        >
          <div className={styles.dialogContent}>
            {text('common:leave_page_warning_description')}
          </div>
          <div className={styles.buttonsContainer}>
            <LoadingButton 
              text={text('common:leave_page_warning_confirm_button')}
              className={[
                styles.button,
                styles.confirmButton
              ].join(' ')}
              onClick={handleConfirmNavigationClick}
            />
            <LoadingButton 
              text={text('common:leave_page_warning_cancel_button')}
              className={[
                styles.button,
                styles.cancelButton
              ].join(' ')}
              onClick={closeModal}
            />
          </div>
        </CustomDialog>
      </div>
      
    </>
  );
};
export default RouteLeavingGuard;