import styles from './styles.module.scss'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { classNames } from 'react-select/src/utils'

export type Tag = {
  name: string
  uuid?: string
}
  
export type Category = {
  name: string
  uuid?: string
}
  
export type Author = {
  name: string
  lastName: string
  nick?: string
  uuid?: string
}
  
export type MainImage = {
  filename: string,
  hostname: string,
  path: string
}
  
export type Article = {
  uuid: string
  draft: boolean
  active: boolean
  title?: string
  author?: Author
  lastEditor?: Author
  publishDate: Date
  modificationDate?: Date
  leadText?: string
  category?: Category
  tags?: Tag[],
  mainImage?: MainImage
}
  
type Props = { 
  article: Article
  style?: React.CSSProperties
  classes?: {
  container?: string
  }
}

const ArticleTileContent = (props: Props) => {
	const texts = useTranslation().t

  const getAddedLabel = () => {
    if (props.article.draft) {
      return texts('article:draft_added_label')
    } else if (moment(props.article.publishDate).isAfter(moment())) {
      return texts('article:planned_label')
    } else {
      return texts('article:added_label')
    }
  }

  return (
    <div className={props.classes?.container? props.classes.container : styles.contentContainer}>
      {
        props.article.category 
        ? <div className={styles.category}>
            {props.article.category?.name}
          </div> 
        : null
      }
      <div className={styles.title}>
        {props.article.title}
      </div>
      <div className={styles.info}>
        <span className={styles.infoHeader}>{getAddedLabel()} </span> {`${props.article.author ? `${props.article.author.name} ${props.article.author.lastName}, ` : ''}${moment(props.article.publishDate).format('DD.MM.YYYY HH:mm')} `}
        {
          props.article.modificationDate && props.article.lastEditor
          ? <>
              (<span className={styles.infoHeader}>{texts('article:update_at_label')} </span>
              {
                `${props.article.lastEditor.name} ${props.article.lastEditor.lastName}, ${moment(props.article.modificationDate).format('DD.MM.YYYY HH:mm')}`
              })
            </>
          : null
        }
      </div>
      <div className={styles.description}>
        {props.article.leadText}
      </div>
      <div className={styles.tagsContainer}>
        {
          props.article.tags?.map((tag) =>  (
            <div className={styles.tag}>
              {`#${tag.name}`}
            </div>
          ))
        }
      </div>
    </div>
  )
}

export default ArticleTileContent