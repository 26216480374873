import * as Yup from 'yup'
import { forbiddenSomeSpecialCharactersPassword, passwordRules, englishCharactersAllowedPassword } from './validationRegexps'

export const changePasswordValidation = (t: (val: string) => string) => {
  return Yup.object({
    currentPassword: Yup.string()
      .required(t('common:field_required')),
    newPassword: Yup.string()
      .matches(
        forbiddenSomeSpecialCharactersPassword,
        t('auth:special_characters_password_validation')
      )
      .matches(englishCharactersAllowedPassword, t('auth:only_english_letters_allowed_validation'))
      .matches(passwordRules, t('auth:password_rules_validation'))
      .min(8, t('common:min_8_signs_validation'))
      .max(32, t('common:max_32_signs_validation'))
      .required(t('common:field_required')),
    reNewPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], t('auth:different_passwords_validation'))
      .required(t('common:field_required'))
  })
}