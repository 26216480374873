import { Form, Formik } from "formik"
import React from 'react'
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { Link, useHistory } from "react-router-dom"
import AuthApi from "../../../api/AuthApi"
import AssetLoader from '../../../assets/loader'
import { addSuccessMessage } from "../../../redux/messages/actions"
import { onErrorResponseShowMessage } from "../../../utils/onErrorReponseShowMessage/onErrorResponseShowMessage"
import { resendActivationEmailValidation } from '../../../utils/validations/resendActivationEmailValidation'
import BaseInput from '../../common/BaseInput/BaseInput'
import LoadingButton from '../../common/LoadingButton/LoadingButton'
import TopBar from '../../common/TopBar/TopBar'
import { MessageEnum } from '../../CustomToastProvider/types'
import styles from './styles.module.scss'

const ResendActivationEmail = () => {

  const texts = useTranslation().t
  const history = useHistory()
  const dispatch = useDispatch()
  const [loading, setLoading] = React.useState<boolean>(false)

  const onSubmitResendActivationEmail = async (values: { email: string }) => {
    setLoading(true)
    try {
      await AuthApi.resendActivationEmail(values.email)
      dispatch(addSuccessMessage(MessageEnum.ACTIVATION_EMAIL_RESEND_HAS_BEEN_REQUESTED))
      history.push("/login")
    } catch(error) {
      onErrorResponseShowMessage(error, false)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <TopBar 
        logoSrc={AssetLoader.getLogo()}
      />
      <div className={styles.main}>
        <div className={styles.title}>
          {texts('auth:resend_activation_email_title')}
        </div>
        <div>
          <Formik
            initialValues={{
              email: "",
            }}
            onSubmit={onSubmitResendActivationEmail}
            validationSchema={resendActivationEmailValidation(texts)}
          >
            {(formikProps) => (
              <Form className={styles.form}>
                <BaseInput
                  name="email"
                  label={texts("auth:email_address")}
                  classes={{
                    label: styles.inputLabel
                  }}
                  error={
                    Boolean(formikProps.errors.email) && formikProps.touched.email
                  }
                  formikProps={formikProps}
                />
                <LoadingButton
                  type="submit"
                  className={styles.submitButton}
                  loading={loading}
                  disabled={
                    !formikProps.isValid || !formikProps.dirty
                  }
                  style={{ marginTop: '0.625rem' }}
                >
                  <div className={styles.submitButtonContent}>
                    {texts("auth:send")}
                  </div>
                </LoadingButton>
                <Link
                  to="/login"
                  className={styles.link}
                >
                  <div className={styles.loginLink}>
                    <div className={styles.loginLinkContent}>
                      {texts("auth:back_to_login")}
                    </div>
                  </div>
                </Link>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  )
}

export default ResendActivationEmail
