export const MAX_ARTICLE_PER_PAGE = 10
export const MAX_TAG_SEARCH = 25
export const MAX_ATTACHED_ARTICLES_WITH_PHOTO = 3
export const MAX_ATTACHED_ARTICLES_WITHOUT_PHOTO = 1

export enum Project {
  DEPESZA = 'depesza',
  ZARADNA = 'zaradna'
}

export type Success = {
  success: true
}

export enum GalleryPageType {
  UNDEFINED,
  FROM_ADD_ARTICLE,
  FROM_EDIT_ARTICLE
}

export enum ArticleState {
  UNDEFINED,
  PUBLISHED,
  DRAFT,
  INACTIVE
}

export enum GalleryState {
  UNDEFINED,
  PUBLISHED,
  DRAFT,
  INACTIVE
}

export type SelectOption = {
  label: string
  value: string
}

export const ArticleStateOptionsList = [
  // {
  //   label: 'Wszystkie',
  //   value: ArticleState.UNDEFINED
  // },
  {
    label: 'Opublikowany',
    value: ArticleState.PUBLISHED
  },
  {
    label: 'Wersja robocza',
    value: ArticleState.DRAFT
  },
  {
    label: 'Nieaktywny',
    value: ArticleState.INACTIVE
  }
]

export const GalleryStateOptionsList = [
  // {
  //   label: 'Wszystkie',
  //   value: GalleryState.UNDEFINED
  // },
  {
    label: 'Opublikowany',
    value: GalleryState.PUBLISHED
  },
  {
    label: 'Wersja robocza',
    value: GalleryState.DRAFT
  },
  {
    label: 'Nieaktywny',
    value: GalleryState.INACTIVE
  }
]

export type DateRange = {
  from: Date
  to: Date
}

export const AdvertDefinedSizes = [
  {
    label: '[[360, 280], [336, 280], [300, 250], [728, 90]]',
    value: 'W1szNjAsIDI4MF0sIFszMzYsIDI4MF0sIFszMDAsIDI1MF0sIFs3MjgsIDkwXV0='
  },
  {
    label: '[[360, 280], [336, 280], [300, 250], [728, 90], [300, 600]]',
    value: 'W1szNjAsIDI4MF0sIFszMzYsIDI4MF0sIFszMDAsIDI1MF0sIFs3MjgsIDkwXSwgWzMwMCwgNjAwXV0='
  }
]

export enum SearchArticleProps {
  WIKI = -1,
  POPULAR = 0,
  HOT = 1,
  SUGGESTED_BY_REDACTION = 2,
  ON_TOP = 3
}

export const SearchArticlePropsOptionsList = [
  {
    label: 'Wiki',
    value: SearchArticleProps.WIKI
  },
  {
    label: 'Hot',
    value: SearchArticleProps.HOT
  },
  {
    label: 'Polecane',
    value: SearchArticleProps.SUGGESTED_BY_REDACTION
  },
  {
    label: 'Top',
    value: SearchArticleProps.ON_TOP
  }
]