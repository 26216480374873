import { Parser } from 'bbcode-to-react'
import ReactDOMServer from 'react-dom/server'
import { Project } from '../../../../types/common'
import DepeszaAdBlockTag from './tags/depesza/basic/adBlock'
import DepeszaBrTag from './tags/depesza/basic/br'
import DepeszaCdnImgTag from './tags/depesza/basic/cdnImg'
import DepeszaFacebookTag from './tags/depesza/basic/facebook'
import DepeszaInstagramTag from './tags/depesza/basic/instagram'
import DepeszaLiTag from './tags/depesza/basic/li'
import DepeszaListTag from './tags/depesza/basic/list'
import DepeszaOListTag from './tags/depesza/basic/oList'
import DepeszaParagraphTag from './tags/depesza/basic/p'
import DepeszaPinterestTag from './tags/depesza/basic/pinterest'
import DepeszaQuoteTag from './tags/depesza/basic/quote'
import DepeszaSeeAlsoTag from './tags/depesza/basic/seeAlso'
import DepeszaTelegramPostTag from './tags/depesza/basic/telegramPost'
import DepeszaTikTokTag from './tags/depesza/basic/tiktok'
import DepeszaTwitterTag from './tags/depesza/basic/twitter'
import DepeszaUListTag from './tags/depesza/basic/uList'
import DepeszaUrlTag from './tags/depesza/basic/url'
import DepeszaWorthSeeingTag from './tags/depesza/basic/worthSeeing'
import DepeszaYoutubeTag from './tags/depesza/basic/youtube'
import DepeszaSpanTag from './tags/depesza/basic/span'
import DepeszaRedditTag from './tags/depesza/basic/reddit'
import ZaradnaAdBlockTag from './tags/zaradna/basic/adBlock'
import ZaradnaArticleDeepLinkTag from './tags/zaradna/basic/articleDeepLink'
import ZaradnaArticleDeepLinksWithPhotosTag from './tags/zaradna/basic/articleDeepLinksWithPhotos'
import ZaradnaBrTag from './tags/zaradna/basic/br'
import ZaradnaCdnImgTag from './tags/zaradna/basic/cdnImg'
import ZaradnaFacebookTag from './tags/zaradna/basic/facebook'
import ZaradnaInstagramTag from './tags/zaradna/basic/instagram'
import ZaradnaLiTag from './tags/zaradna/basic/li'
import ZaradnaListTag from './tags/zaradna/basic/list'
import ZaradnaOListTag from './tags/zaradna/basic/oList'
import ZaradnaParagraphTag from './tags/zaradna/basic/p'
import ZaradnaPinterestTag from './tags/zaradna/basic/pinterest'
import ZaradnaQuoteTag from './tags/zaradna/basic/quote'
import ZaradnaTikTokTag from './tags/zaradna/basic/tiktok'
import ZaradnaTwitterTag from './tags/zaradna/basic/twitter'
import ZaradnaUListTag from './tags/zaradna/basic/uList'
import ZaradnaUrlTag from './tags/zaradna/basic/url'
import ZaradnaYoutubeTag from './tags/zaradna/basic/youtube'
import ZaradnaSpanTag from './tags/zaradna/basic/span'
import ZaradnaTelegramPostTag from './tags/zaradna/basic/telegramPost'
import ZaradnaRedditTag from './tags/zaradna/basic/reddit'


const basicTags = [
  'b', 'i', 'u', 's', 'h2', 'h3', 'h4', 'h5', 'h6', 'pre', 'table', 'thead', 'tbody',
  'tr', 'th', 'td', 'code', 'img', 'hr', 'size', 'center', 'right', 'color', 'list',
  '\'*\'', 'url', 'link', 'email']

class BBCodeParser {
  private basicInstance: Parser

  constructor() {
    this.basicInstance = new Parser(basicTags)
    switch (process.env.REACT_APP_PROJECT) {
      case Project.DEPESZA:
        this.basicInstance.registerTag('youtube', DepeszaYoutubeTag)
        this.basicInstance.registerTag('li', DepeszaLiTag)
        this.basicInstance.registerTag('p', DepeszaParagraphTag)
        this.basicInstance.registerTag('cdn-img', DepeszaCdnImgTag)
        this.basicInstance.registerTag('quote', DepeszaQuoteTag)
        this.basicInstance.registerTag('url', DepeszaUrlTag)
        this.basicInstance.registerTag('twitter', DepeszaTwitterTag)
        this.basicInstance.registerTag('facebook', DepeszaFacebookTag)
        this.basicInstance.registerTag('tiktok', DepeszaTikTokTag)
        this.basicInstance.registerTag('instagram', DepeszaInstagramTag)
        this.basicInstance.registerTag('pinterest', DepeszaPinterestTag)
        this.basicInstance.registerTag('olist', DepeszaOListTag)
        this.basicInstance.registerTag('ulist', DepeszaUListTag)
        this.basicInstance.registerTag('br', DepeszaBrTag)
        this.basicInstance.registerTag('list', DepeszaListTag)
        this.basicInstance.registerTag('article-deep-link', DepeszaSeeAlsoTag)
        this.basicInstance.registerTag('article-deep-link-with-photo', DepeszaWorthSeeingTag)
        this.basicInstance.registerTag('span', DepeszaSpanTag)
        this.basicInstance.registerTag('telegram-post', DepeszaTelegramPostTag)
        this.basicInstance.registerTag('reddit', DepeszaRedditTag)
        this.basicInstance.registerTag('ad-block', DepeszaAdBlockTag)
        break
      case Project.ZARADNA:
        this.basicInstance.registerTag('youtube', ZaradnaYoutubeTag)
        this.basicInstance.registerTag('li', ZaradnaLiTag)
        this.basicInstance.registerTag('p', ZaradnaParagraphTag)
        this.basicInstance.registerTag('cdn-img', ZaradnaCdnImgTag)
        this.basicInstance.registerTag('quote', ZaradnaQuoteTag)
        this.basicInstance.registerTag('url', ZaradnaUrlTag)
        this.basicInstance.registerTag('twitter', ZaradnaTwitterTag)
        this.basicInstance.registerTag('facebook', ZaradnaFacebookTag)
        this.basicInstance.registerTag('tiktok', ZaradnaTikTokTag)
        this.basicInstance.registerTag('instagram', ZaradnaInstagramTag)
        this.basicInstance.registerTag('pinterest', ZaradnaPinterestTag)
        this.basicInstance.registerTag('olist', ZaradnaOListTag)
        this.basicInstance.registerTag('ulist', ZaradnaUListTag)
        this.basicInstance.registerTag('br', ZaradnaBrTag)
        this.basicInstance.registerTag('list', ZaradnaListTag)
        this.basicInstance.registerTag('article-deep-link', ZaradnaArticleDeepLinkTag)
        this.basicInstance.registerTag('article-deep-link-with-photo', ZaradnaArticleDeepLinksWithPhotosTag)
        this.basicInstance.registerTag('span', ZaradnaSpanTag)
        this.basicInstance.registerTag('telegram-post', ZaradnaTelegramPostTag)
        this.basicInstance.registerTag('reddit', ZaradnaRedditTag)
        this.basicInstance.registerTag('ad-block', ZaradnaAdBlockTag)
        break
    }
    
  }
  
  toReact(input: string): React.ReactNode {
    return this.basicInstance.toReact(input)
  }

  toHtmlString(input: string): string {
    if (input) {
      return ReactDOMServer.renderToStaticMarkup(this.basicInstance.toReact(input) as any)
    } else {
      return ''
    }
  }
}

export default new BBCodeParser()