import { AuthData, Login } from '../types/auth'
import { BASE_AUTH } from './baseRoutes'
import { BaseAuthApi } from './BaseAuthApi'
import { Success } from '../types/common'

class AuthApi extends BaseAuthApi {
  constructor() {
    super({
      baseURL: `${BASE_AUTH}/api/v1/auth`,
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }

  public login(data: Login) {
    return this.post<AuthData>(`/login`, JSON.stringify(data))
  }

  public resendActivationEmail(email: string) {
    return this.post<Success>(`/email/email-verification/resend`, JSON.stringify({ email }))
  }

  public activateUser(password: string, token: string | null) {
    return this.post<Success>(`/email/verify?token=${token}`, JSON.stringify({ password }))
  }

  public checkUserActivationToken(token: string | null) {
    return this.post<Success>(`/email/verify/check?token=${token}`)
  }

  public checkUserEmailVerificationToken(token: string | null) {
    return this.post<Success>(`/email/verify-change?token=${token}`)
  }

  public sendResetPasswordRequest(email: string) {
    return this.post<Success>("/password/reset/start", JSON.stringify({ email }))
  }

  public checkResetPasswordToken(token: string | null) {
    return this.post<Success>(`/password/reset/check?token=${token}`)
  }

  public finishPasswordReset(password: string, token: string | null) {
    return this.post<Success>(`/password/reset/finish?token=${token}`, JSON.stringify({ password }))
  }
}

export default new AuthApi()