import { ErrorMessage, Field, FormikProps } from 'formik'
import React from "react"
import CustomPasswordField from '../CustomPasswordField/CustomPasswordField'
import styles from './styles.module.scss'

type Props = {
  name: string
  label?: string
  placeholder?: string
  styles?: {
    label?: React.CSSProperties
  }
  classes?: {
    label?: string
  }
  value?: string
  setValue?: React.Dispatch<React.SetStateAction<string>>
  formikProps?: FormikProps<any>
  error?: boolean
}

const PasswordInput = (props: Props) => {
  const [showPassword, setShowPassword] = React.useState<boolean>(false)

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  return (
    <div
      style={{
        minHeight: "5.313rem"
      }}
    >
      {
        props.label ? <label htmlFor={props.name}>
        <div
          className={props.classes?.label || styles.label}
          style={{
            ...(props.styles?.label || {})
          }}
        >
          {props.label}
        </div>
        </label> : null 
      }
      <Field
        id={props.name}
        name={props.name}
        as={CustomPasswordField}
        type={showPassword ? "text" : "password"}
        className={`${styles.textField} ${props.error ? styles.textFieldError : styles.textFieldNormal }`}
        error={props.error}
        placeholder={props.placeholder}
        value={props.value}
        setValue={props.setValue}
        showPasswordOnClick={handleClickShowPassword}
        showPassword={showPassword}
        formikProps={props.formikProps}
        errorLabel={props.error && <ErrorMessage name={props.name} />}
      />
    </div>
  )
}

export default PasswordInput
