import { CSSProperties } from 'react'
import { Project } from '../../../types/common'

type Props = {
  fill: string
  width?: string
  height?: string
  customStyle?: CSSProperties
}

const TriangularPointer = (props: Props) => {
  switch (process.env.REACT_APP_PROJECT) {
    case Project.ZARADNA:
      return (
        <svg 
          width={props.width ? props.width : "11"}
          height={props.height ? props.height : "17"}
          viewBox="0 0 11 17" 
          fill="none" 
          xmlns="http://www.w3.org/2000/svg"
          style={{
            ...(props.width ? { width: props.width } : {}),
            ...(props.height ? { height: props.height } : {}),
            ...props.customStyle
          }}
        >
          <path fillRule="evenodd" clipRule="evenodd" d="m2.325 2.337.17.016c.306.046.72.185 1.087.33 1.67.65 4.937 2.844 6.186 4.175l.364.389.13.15c.265.336.405.749.405 1.171 0 .398-.125.79-.364 1.116-.064.087-.14.171-.308.35l-.44.458c-1.322 1.292-4.185 3.208-5.81 3.892-.452.19-1.1.405-1.41.412a1.9 1.9 0 0 1-1.74-1.024c-.089-.17-.198-.575-.28-.954L.23 12.39c-.15-.93-.233-2.34-.231-3.822l.002-.47c.015-1.396.103-2.668.255-3.495.06-.337.195-.917.273-1.105.169-.405.474-.733.851-.935.292-.158.623-.238.944-.227zm.428 2.462-.202-.074-.043.205-.055.324c-.115.786-.182 2.007-.18 3.315l.001.454c.014 1.2.086 2.302.2 2.998l.078.382.313-.114c1.438-.605 4.293-2.54 5.26-3.583l.13-.14-.345-.356C6.75 7.1 4.033 5.296 2.753 4.799z"
            fill={props.fill ? props.fill : "#F35588"}
          />
        </svg>
      )
      case Project.DEPESZA:
      default:
        return (
          <svg 
            width="17" 
            height="22" 
            viewBox="0 0 17 22" 
            xmlns="http://www.w3.org/2000/svg"
            style={{
              ...(props.width ? { width: props.width } : {}),
              ...(props.height ? { height: props.height } : {}),
              ...props.customStyle
            }}
          >
            <path d="M0 .766v21l16.5-10.5z" fill={props.fill} fillRule="evenodd"/>
          </svg>
        )
  }
  
}

export default TriangularPointer