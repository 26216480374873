import React from 'react'
import styles from './styles.module.scss'
import moment from 'moment'
import { useTranslation } from 'react-i18next';

export type Author = {
  name: string
  lastName: string
  nick?: string
  uuid?: string
}

export type FirstPage = {
  uuid: string
  photo: Photo
}

export type Photo = {
  filename: string,
  hostname: string,
  path: string
}

export type Gallery = {
  uuid: string
  draft: boolean
  active: boolean
  title?: string
  author?: Author
  lastEditor?: Author
  publishedAt?: Date | string
  createdAt?: Date | string
  modifiedAt?: Date | string
  firstPage?: FirstPage
  pageCount?: number
  adultContent: boolean
}

type Props = { 
  gallery: Gallery
}

const GalleryLibraryTileContent = (props: Props) => {

	const texts = useTranslation().t

  const getAddedLabel = () => {
    if (props.gallery.draft) {
      return texts('gallery:draft_created_at_label')
    } else {
      return texts('gallery:add_at_label')
    }
  }

	return (
		<div className={styles.contentContainer}>
      <div className={styles.title}>
        {props.gallery.title}
      </div>
      <div className={styles.info}>
        <span className={styles.infoHeader}>{getAddedLabel()} </span>
        {
          `
          ${props.gallery.author ? `${props.gallery.author.name} ${props.gallery.author.lastName}, ` : ''}
          ${moment(props.gallery.publishedAt ?? props.gallery.createdAt).format('DD.MM.YYYY HH:mm')}
          `
        }
        {
          props.gallery.modifiedAt && props.gallery.lastEditor
          ? <>
              (<span className={styles.infoHeader}>{texts('gallery:update_at_label')} </span>
              {
                `${props.gallery.lastEditor.name} ${props.gallery.lastEditor.lastName}, ${moment(props.gallery.modifiedAt).format('DD.MM.YYYY HH:mm')}`
              })
            </>
          : null
        }
      </div>
      <div className={styles.pageCount}>
        <span className={styles.pageCountLabel}>{texts('gallery:images_count_in_gallery_label')} </span>{props.gallery.pageCount ?? 0}
      </div>
      {
        props.gallery.adultContent
        ? <div className={styles.adultContentLabel}>
            {texts('common:adult_content_label')}
          </div>
        : null
      }
    </div>
	)
}

export default GalleryLibraryTileContent