import { Tag } from 'bbcode-to-react'
import styles from './style.module.scss'
import React from 'react'
import DataApi from '../../../../../../../api/DataApi'
import { useSelector } from 'react-redux'
import { State } from '../../../../../../../redux/reducers/index'
import { onErrorResponseShowMessage } from '../../../../../../../utils/onErrorReponseShowMessage/onErrorResponseShowMessage'
import { FRONT_BASE_URL } from '../../../../../../../utils/common'
import { decode } from 'html-entities'
import TriangularPointer from '../../../../../TriangularPointer/TriangularPointer'
import { useTranslation } from 'react-i18next'

type Props = {
  title: string
  'article-deep-link': string
}

export default class DepeszaSeeAlsoTag<T = { linkify: boolean }> extends Tag<T> {
  toReact() {
    const props = this.params as Props
    return (
      <SeeAlsoComponent {...props}/>
    )
  }
}

const SeeAlsoComponent = (props: Props) => {

  const userUuid = useSelector((e: State) => e.user.uuid)
  const texts = useTranslation().t
  const [article, setArticle] = React.useState<any>(null)

  React.useEffect(() => {
    DataApi.getArticleDetails(props['article-deep-link']).then(res => {
      setArticle(res)
    }).catch(error => onErrorResponseShowMessage(error, true, { userUuid: userUuid }))
  }, [])

  return (
    article
    ?
      <div className={styles.seeAlso_container}>
        <div className={styles.seeAlso_pointer}>
          <TriangularPointer
            fill='#1976D2'
            width={'15px'}
            height={'15px'}
            customStyle={{
              marginRight: '5px'
            }}
          />
        </div>
        <div>
          <div className={styles.seeAlso_description}>
            {`${props.title ? decode(props.title) : texts('common:defaultSeeAlsoTagTitle')}: `}
            <span onClick={() => {
              window.open(`${FRONT_BASE_URL}/${article.slug}`, '_blank', 'noopener,noreferrer')
            }}>
              {article.title}
            </span>
          </div>
        </div>
      </div>
    : null
  )
}