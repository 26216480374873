import * as Yup from "yup"

export const editGalleryValidation = (t: (val: string) => string) => {
  return Yup.object({
    title: Yup.mixed()
      .test("required", t('common:field_required'), function (val: any) {
        const draft = this.resolve(Yup.ref('draft')) as boolean
        return draft || val != null
      }),
    activePageCount: Yup.string()
      .test("required", t('common:active_page_required'), function (val: any) {
        let draft = this.resolve(Yup.ref('draft')) as boolean
        return draft || val >0
    })
  })
}

