import {
  applyMiddleware, createStore
} from "redux"
import { createStateSyncMiddleware, initStateWithPrevTab, withReduxStateSync } from 'redux-state-sync'
import { ADD_CMD_MESSAGE, ADD_ERROR_MESSAGE, ADD_SUCCESS_MESSAGE, CLEAR_MESSAGES } from './redux/messages/types'
import { rootReducer } from "./redux/reducers"

const config = {
  blacklist: [
    ADD_SUCCESS_MESSAGE,
    ADD_ERROR_MESSAGE,
    ADD_CMD_MESSAGE,
    CLEAR_MESSAGES,
  ]
}
const middlewares = [
  createStateSyncMiddleware(config),
]

const store = createStore(
  withReduxStateSync(rootReducer),
  {},
  applyMiddleware(...middlewares)
)

initStateWithPrevTab(store)

export default store