import { Form, Formik, FormikProps } from 'formik'
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { IoMdTrash } from 'react-icons/io';
import { IoAddCircle } from 'react-icons/io5';
import { MdImage } from 'react-icons/md';
import AuthRefreshApi from '../../api/AuthRefreshApi';
import DataApi from '../../api/DataApi';
import { addCmdMessage, addSuccessMessage } from '../../redux/messages/actions'
import store from '../../store';
import { onErrorResponseShowMessage } from '../../utils/onErrorReponseShowMessage/onErrorResponseShowMessage';
import { editorAccountValidation } from '../../utils/validations/editorAccountValidation';
import ChangeEmail from '../auth/ChangeEmail/ChangeEmail';
import ChangePassword from '../auth/ChangePassword/ChangePassword';
import BaseInput from '../common/BaseInput/BaseInput';
import BBCodeEditor from '../common/BBCodeEditor/BBCodeEditor';
import CropperDialog from '../common/CropperDialog/CropperDialog';
import CustomModal from '../common/CustomModal/CustomModal';
import CustomSelectField from '../common/CustomSelectField/CustomSelectField';
import CustomSwitch from '../common/CustomSwitch/CustomSwitch';
import LoadingButton from '../common/LoadingButton/LoadingButton';
import { SinglePhoto } from '../common/MediaLibrary/types';
import MediaLibraryDialog from '../common/MediaLibraryDialog/MediaLibraryDialog';
import { MessageEnum } from '../CustomToastProvider/types';
import styles from './styles.module.scss';
import { AccountDetails } from '../../types/auth'
import { SelectOption, Project } from '../../types/common'
import MoonLoader from 'react-spinners/MoonLoader'
import BBCodeDepeszaArticlePreview from '../common/BBCodeDepeszaArticlePreview/BBCodeDepeszaArticlePreview'
import BBCodeDepeszaAuthorPreview from '../common/BBCodeDepeszaAuthorPreview/BBCodeDepeszaAuthorPreview'
import { useConfirmLeaveHook } from '../../utils/hooks'
import RouteLeavingGuard from '../common/RouteLeavingGuard/RouteLeavingGuard'
import HtmlValidationErrorDialog from '../common/HtmlValidatorDialog/HtmlValidatorDialog'
import { FRONT_BASE_URL } from '../../utils/common';
import BBCodeZaradnaAuthorPreview from '../common/BBCodeZaradnaAuthorPreview/BBCodeZaradnaAuthorPreview'

type InitialFormValues = {
  about: string
  photoUuid: string
  sourceImageUuid: string
  image: SinglePhoto | null | undefined
  email: string
  name: string
  role: any
} 

const initialValues: InitialFormValues = {
  about: '',
  sourceImageUuid: '',
  photoUuid: '', 
  image: null,
  email: '',
  name: '',
  role: undefined
}

const EditorAccount = () => {
  const texts = useTranslation().t
  const formRef = useRef() as any

  const [initialFormValues, setInitialFormValues] = React.useState<InitialFormValues>(initialValues)
  const [accountDetails, setAccountDetails] = React.useState<AccountDetails | null>(null)

  const [isEditActive, setIsEditActive] = React.useState<boolean>(false)
  const [isAccountVisible, setIsAccountVisible] = React.useState<boolean>(false)
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false)
  const [isAvatarRemoved, setIsAvatarRemoved] = React.useState<boolean>(false)
  const [showHtmlValidationErrorDialog, setShowHtmlValidationErrorDialog] = React.useState<boolean>(false)

  const [htmlValidationErrors, setHtmlValidationErrors] = React.useState<string[]>([])
  const [urlToOpen, setUrlToOpen] = React.useState<string>('')

  const [roleList, setRoleList] = React.useState<SelectOption[]>([])

  const [imageToCrop, setImageToCrop] = React.useState<SinglePhoto | null>(null)
  const [avatarImgSourceData, setAvatarImgSourceData] = React.useState<{
    img: SinglePhoto
    croppedImage?: Blob
  } | null> (null)

  const [showChangeEmailModal, setShowChangeEmailModal] = React.useState<boolean>(false)
  const [showMediaLibraryDialog, setShowMediaLibraryDialog] = React.useState<boolean>(false)
  const [showChangePasswordModal, setShowChangePasswordModal] = React.useState<boolean>(false)
  const [showCropperDialog, setShowCropperDialog] = React.useState<boolean>(false)

  const openMediaLibraryDialog = () => {
    store.dispatch(addCmdMessage(MessageEnum.ENABLE_PAGE_COVER))
    setShowMediaLibraryDialog(true)
  }

  const closeMediaLibraryDialog = () => {
    store.dispatch(addCmdMessage(MessageEnum.DISABLE_PAGE_COVER))
    setShowMediaLibraryDialog(false)
  }

  const openHtmlValidationErrorDialog = () => {
    store.dispatch(addCmdMessage(MessageEnum.ENABLE_PAGE_COVER))
    setShowHtmlValidationErrorDialog(true)
  }

  const closeHtmlValidationErrorDialog = () => {
    store.dispatch(addCmdMessage(MessageEnum.DISABLE_PAGE_COVER))
    setShowHtmlValidationErrorDialog(false)
  }

  const changeEditingActivity = () => {
    setIsEditActive(!isEditActive)
  }

  const togglePasswordModal = () => {
    setShowChangePasswordModal(!showChangePasswordModal)
  }

  const toggleEmailModal = () => {
    setShowChangeEmailModal(!showChangeEmailModal)
  }

  const openCropperDialog = () => {
    store.dispatch(addCmdMessage(MessageEnum.ENABLE_PAGE_COVER))
    setShowCropperDialog(true)
  }

  const closeCropperDialog = () => {
    store.dispatch(addCmdMessage(MessageEnum.DISABLE_PAGE_COVER))
    setShowCropperDialog(false)
  }

  const getMainImgUrl = () => {
    if (avatarImgSourceData == null) {
    return ''
    } else if (avatarImgSourceData.croppedImage != null) {
    return URL.createObjectURL(avatarImgSourceData.croppedImage)
    } else {
    return `${avatarImgSourceData.img.hostname}${avatarImgSourceData.img.path}${avatarImgSourceData.img.filename}`
    }
  } 

  const handleSubmit = async (data: any) => {
    try {
      setIsSubmitting(true)
      const body = new FormData()
      const nameParts = data.name.trim().split(' ')
      body.set('name', nameParts[0] ?? '')
      body.set('lastName', nameParts.splice(1).join(' ') ?? '')
      body.set('about', data.about ?? '')
      body.set('hidden', isAccountVisible ? 'false' : 'true')
      body.set('removeAvatar', isAvatarRemoved ? 'true' : 'false')
      body.set('image', data.image)
      body.set('sourceImageUuid', data.sourceImageUuid)
      const res = await AuthRefreshApi.updateAccountDetails(body)
      const newDetails = await AuthRefreshApi.getAccountDetails()
      setAccountDetails(newDetails)
      setInitialFormValues({
        about: newDetails?.about ?? '',
        sourceImageUuid: '',
        photoUuid: '', 
        image: null,
        email: newDetails?.email ?? '',
        name: newDetails?.name || newDetails?.lastName ? `${newDetails?.name ?? ''} ${newDetails?.lastName ?? ''}` : '',
        role: roleList.find((e: SelectOption) => e.value === `${newDetails.role}`)
      })
      setIsAccountVisible(!newDetails.hidden)
      setAvatarImgSourceData(newDetails.avatar ? {
        img: newDetails.avatar,
        croppedImage: undefined
      } : null)
      store.dispatch(addSuccessMessage(MessageEnum.ACCOUNT_UPDATED))
      setIsEditActive(false)
      setIsAvatarRemoved(false)
      if (res.isHtmlValid != null && !res.isHtmlValid) {
        setHtmlValidationErrors(res.htmlErrorMessages ?? [])
        setUrlToOpen(`${FRONT_BASE_URL}/autor/${newDetails.slug}`)
        openHtmlValidationErrorDialog()
      } else {
        window.open(`${FRONT_BASE_URL}/autor/${newDetails.slug}`, '_blank', 'noopener,noreferrer')
      }
    } catch (error) {
      onErrorResponseShowMessage(error)
    } finally {
      setIsSubmitting(false)
    }
  }

  React.useEffect(() => {
    DataApi.getRoleList().then((roles: any)=> {
      const mappedRoles = roles.map((e: any) => {
        return {
          label: e.name,
          value: `${e.value}`
        }
      })
      setRoleList(mappedRoles)
      AuthRefreshApi.getAccountDetails().then((details: any) => {
        setAccountDetails(details)
        setInitialFormValues({
          about: details?.about ?? '',
          sourceImageUuid: '',
          photoUuid: '', 
          image: null,
          email: details?.email ?? '',
          name: details?.name || details?.lastName ? `${details?.name ?? ''} ${details?.lastName ?? ''}` : '',
          role: mappedRoles.find((e: SelectOption) => e.value === `${details.role}`)
        })
        setIsAccountVisible(!details.hidden)
        setAvatarImgSourceData(details.avatar ? {
          img: details.avatar,
          croppedImage: undefined
        } : null)
        setIsAvatarRemoved(false)
        setIsLoading(false)
      }).catch(error => onErrorResponseShowMessage(error))
    }).catch(error => onErrorResponseShowMessage(error))
  },[])

  const getPreviewComponent = () => {
    switch (process.env.REACT_APP_PROJECT) {
      case Project.ZARADNA:
        return BBCodeZaradnaAuthorPreview
      case Project.DEPESZA:
      default:
        return BBCodeDepeszaAuthorPreview
    }
  }

  useConfirmLeaveHook()

  return (
    <>
      <RouteLeavingGuard
        when={isEditActive}
        shouldBlockNavigation={location => {
          if (location.pathname === '/login') {
            return false
          }
          return isEditActive
        }}
      />
      <HtmlValidationErrorDialog 
        hidden={!showHtmlValidationErrorDialog}
        errorMessages={htmlValidationErrors}
        onCloseClick={() => {
          closeHtmlValidationErrorDialog()
          window.open(urlToOpen, '_blank', 'noopener,noreferrer')
        }}
        onEditLabelClick={() => {
          closeHtmlValidationErrorDialog()
        }}
      /> 
      <MediaLibraryDialog
        id={'gallery-media-library-dialog'}
        name={'gallery-media-library-dialog'}
        hidden={!showMediaLibraryDialog}
        onCloseClick={() => {
          closeMediaLibraryDialog()
        }}
        onFinish={(img) => {
          setImageToCrop(img)
          openCropperDialog()
        }}
      /> 
      <CropperDialog
        hidden={!showCropperDialog}
        image={imageToCrop}
        aspect={1}
        onCloseClick={() => {
          closeCropperDialog()
        }}
        onFinish={(img) => {
          formRef?.current?.setFieldValue('image', img)
          formRef?.current?.setFieldValue('sourceImageUuid', imageToCrop!.uuid)
          setAvatarImgSourceData({
            img: imageToCrop!,
            croppedImage: img
          })
        }}
      />
      <CustomModal
        isOpen={showChangeEmailModal}
        onCloseButtonClick ={toggleEmailModal}
        title={texts('auth:change_email')}
      >
        <ChangeEmail
          onFinish={toggleEmailModal}
        />   
      </CustomModal>  
      <CustomModal
        isOpen={showChangePasswordModal}
        onCloseButtonClick ={togglePasswordModal}
        title={texts('auth:change_password')}
      >
        <ChangePassword
          onFinish={togglePasswordModal}
        />
      </CustomModal>
      {
        isLoading
        ? <div className={styles.loadingSpinnerContainer}>
            <MoonLoader loading={true} size={100}/>
          </div>
        : <>
            <div className={styles.pageTitleContainer}>
            <h1 className={styles.pageTitle}>{texts('editorAccount:editor_account')}</h1>
            {
              !isEditActive
              ? <button
                  className={styles.editFormButton}
                  onClick={()=>{
                    changeEditingActivity()
                  }}
                >
                  <div className={styles.editFormButtonContent}>
                    {texts('common:enable_editing')}
                  </div>  
                </button>
              : null
            }
            {
              isEditActive
              ? <button
                  className={styles.cancelEditFormButton}
                  onClick={()=>{
                    changeEditingActivity()
                    setIsAccountVisible(!accountDetails?.hidden ?? false)
                    formRef?.current?.resetForm()
                    setAvatarImgSourceData(accountDetails?.avatar ? {
                      img: accountDetails.avatar,
                      croppedImage: undefined
                    } : null)
                  }}
                >
                  <div className={styles.cancelEditFormButtonContent}>
                    {texts('common:disable_editing')}
                  </div>  
                </button>
              : null
            }       
            </div> 
            <div className={styles.formContainer}>
              <Formik
                initialValues={initialFormValues}
                validationSchema={editorAccountValidation(texts)}
                onSubmit={handleSubmit}
                validateOnBlur={true}
                validateOnChange={true}
                enableReinitialize
                innerRef={formRef}
              >
                {(formikProps) => (
                  <Form className={styles.form}>       
                    <div className={styles.generalSection}>
                      <div className={styles.avatarSection}>
                        <div className={styles.avatarTitle}>
                          {texts('common:avatar')}
                        </div>
                        <div className={styles.flex}>
                          <div className={styles.imageContainer}>
                            {
                              avatarImgSourceData != null
                              ? <img 
                                  className={styles.mainImage}
                                  src={getMainImgUrl()}
                                  alt={texts('common:userAvatarAlt')}
                                  width={150}
                                  height={150}
                                />
                              : <div className={styles.mainImagePlaceholder}>
                                  <MdImage size={40}/>
                                </div>
                            }
                          </div>
                          <div className={styles.imageControlSection}>
                            <button
                              className={styles.mainImageAddButton}
                              type={'button'}
                              onClick={() => {
                                openMediaLibraryDialog()
                              }}
                              disabled={!isEditActive}
                            >
                              <div className={styles.mainImageButtonContent}>
                                <IoAddCircle size={20}/>
                                <div className={styles.mainImageButtonContentLabel}>
                                  {texts('mediaLibrary:title')}
                                </div>
                              </div>
                            </button>                   
                            {
                              avatarImgSourceData != null
                              ? <button
                                  disabled={!isEditActive || avatarImgSourceData == null}
                                  className={styles.mainImgRemoveButton}
                                  type={'button'}
                                  onClick={() => {
                                    formikProps.values.image = null
                                    formikProps.values.sourceImageUuid = ''
                                    setAvatarImgSourceData(null)
                                    setIsAvatarRemoved(true)
                                  }}
                                >
                                  <div className={styles.mainImageButtonContent}>
                                    <IoMdTrash size={20}/>
                                    <div className={styles.mainImageButtonContentLabel}>
                                      {texts('mediaLibrary:remove_photo')}
                                    </div>
                                  </div>
                                </button>
                              : null
                            }        
                          </div>
                        </div>                
                      </div>
                      <div className={styles.formSection}>
                        <BaseInput
                          label={texts('editorAccount:editor_name')}
                          name={'name'}
                          maxLen={32}
                          formikProps={formikProps}
                          disabled={!isEditActive}
                          value={formikProps.values.name}
                          count={true}
                          error={formikProps.touched.name && Boolean(formikProps.errors.name)}
                          classes={{
                            containerWithLabel: styles.inputContainerWithLabel,
                            containerWithoutLabel: styles.inputContainerWithoutLabel,
                            fieldBase: styles.textFieldBase,
                            label: styles.inputLabel
                          }}
                          styles={{
                            input: {
                              opacity: isEditActive ? 1 : 0.3
                            }
                          }}
                        />
                        <div className={styles.fieldLabel}>
                          {texts('editorAccount:account_type')}
                        </div>
                        <CustomSelectField
                          name='role'
                          placeholder={texts('editorAccount:no_role')}
                          instanceId={'role-field'}
                          disabled
                          options={roleList}
                          value={formikProps.values.role}
                        />
                        <BaseInput
                          label={texts('editorAccount:email')}
                          name={'email'}
                          formikProps={formikProps}
                          disabled={true}
                          value={formikProps.values.email}
                          error={formikProps.touched.email && Boolean(formikProps.errors.email)}
                          classes={{
                            containerWithLabel: styles.inputContainerWithLabel,
                            containerWithoutLabel: styles.inputContainerWithoutLabel,
                            fieldBase: styles.textFieldBase,
                            label: styles.inputLabel
                          }}
                          styles={{
                            input: {
                              opacity: 0.3
                            }
                          }}
                        />
                        <div className={styles.switchLabel}>
                          {texts('editorAccount:visibility')}
                        </div>
                        <div className={styles.flex}>
                          <CustomSwitch
                            name={'visibility'}
                            checked={isAccountVisible}
                            setChecked={setIsAccountVisible}
                            disabled={!isEditActive}
                          />
                          <div className={styles.switchDesc}>{texts('editorAccount:visibility_desc')}</div>
                        </div>
                      </div> 
                      <div className={styles.dialogButtonsSection}>
                        <div>
                          <LoadingButton
                            type='button'
                            className={[
                              styles.baseOpenDialogButton,
                              styles.emailFormButton
                            ].join(' ')}
                            onClick={toggleEmailModal}
                          >
                            <div className={styles.openDialogButtonContent}>
                              {texts('auth:change_email')}
                            </div>
                          </LoadingButton>
                        </div>                  
                        <div>
                          <LoadingButton
                            type='button'
                            className={[
                              styles.baseOpenDialogButton,
                              styles.passwordFormButton
                            ].join(' ')}
                            onClick={togglePasswordModal}
                          >
                            <div className={styles.openDialogButtonContent}>
                              {texts('auth:change_password')}
                            </div>
                          </LoadingButton>
                        </div>
                      </div> 
                    </div>
                    <div className={styles.contentEditor}>
                      <BBCodeEditor 
                        name={'about'}
                        title={texts('editorAccount:bio_title')}
                        contentTitle={texts('editorAccount:bio_contents')}
                        previewTitle={texts('editorAccount:bio_preview')}
                        previewWidth={'40rem'}
                        excludedActions={{
                          photo: true,
                          facebook: true,
                          instagram: true,
                          twitter: true,
                          tiktok: true,
                          pinterest: true,
                          youtube: true,
                          telegram: true,
                          reddit: true,
                          link: true,
                          adBlock: true
                        }}
                        formikProps={formikProps}
                        disabled={!isEditActive}
                        previewComponent={getPreviewComponent()({ 
                          contentFieldName: 'about',
                          formikProps: formikProps,
                          imageSrc: getMainImgUrl()
                        })}
                      />
                    </div>
                    <div className={styles.formButtonSection}>
                      <div>
                        <LoadingButton 
                          type={'submit'}
                          loading={isSubmitting}
                          className={styles.submitFormButton}
                          style={{
                            opacity: isEditActive ? 1 : 0.3
                          }}
                        >
                          <div className={styles.formButtonContent}>{texts('editorAccount:save_new_account_data')}</div>
                        </LoadingButton>
                        <div className={styles.submitFormButtonWarning}>
                          {texts('common:update_warning')}
                        </div>
                      </div>
                    </div>
                </Form>)}
              </Formik>
            </div>
          </>
      }
    </>
  )
}

export default EditorAccount