import React from 'react'
import { useTranslation } from 'react-i18next'
import './style.css'
import Flatpickr from 'react-flatpickr'
import CustomFlatpickrInput from '../CustomFlatpickr/CustomFlatpickrInput'
import moment from 'moment'
import { monthList, dayListShorthand, dayList } from './types'

type Props = {
  width?: string
  name: string
  formikProps?: any
  disabled?: boolean
  placeholder?: string
  withTime?: boolean
  setInitial?: boolean
  inputStyle?: React.CSSProperties
  range?: boolean
  value?: Date | Date[]
}

// TODO convert px to rem

const CustomFlatpickr = ({ value, width, name, formikProps, disabled, placeholder, withTime, setInitial, inputStyle, range }: Props) => {
  const [date, setDate] = React.useState<Date | Date[]>(moment().startOf('day').toDate())
    
  return (
    <Flatpickr
      id={name}
      disabled={disabled}
      {...(withTime ? { 'data-enable-time': true } : {})}
      value={value ?? (placeholder != null && !setInitial ? undefined : (formikProps ? formikProps.values[name] : date))}
      options={{
        ...(range ? { mode: 'range' } : {}),
        locale: {
          firstDayOfWeek: 1,
          rangeSeparator: ' - ',
          months: {
            shorthand: monthList,
            longhand: monthList
          },
          weekdays: {
            shorthand: dayListShorthand,
            longhand: dayList
          }
        },
        dateFormat: withTime ? 'Y-m-d H:i' : 'd.m.Y',
        enableTime: withTime,
        time_24hr: true
      }}
      onClose={(date) => {
        formikProps?.setFieldTouched(name)
        if (range) {
          formikProps?.setFieldValue(
            name,
            date
          )
          setDate(date)
        } else {
          formikProps?.setFieldValue(
            name,
            date[0]
          )
          setDate(date[0])
        }
        
      }}
      render={({ defaultValue, value, ...props }, ref) => {
        return (
          <CustomFlatpickrInput
            defaultValue={defaultValue}
            inputRef={ref}
            disabled={disabled}
            width={width ?? '100%'}
            placeholder={placeholder}
            style={inputStyle}
          />
        )
      }}
    />
  )
}

export default CustomFlatpickr
