import React from 'react'

import AssetLoader from '../../../assets/loader'
import styles from './styles.module.scss'

export type Author = {
  name: string
  lastName: string
  nick?: string
  uuid?: string
}

export type FirstPage = {
  uuid: string
  photo: Photo
}

export type Photo = {
  filename: string,
  hostname: string,
  path: string
}

export type Gallery = {
  uuid: string
  draft: boolean
  active: boolean
  title?: string
  author?: Author
  lastEditor?: Author
  publishedAt?: Date | string
  createdAt?: Date | string
  modifiedAt?: Date | string
  firstPage?: FirstPage
  pageCount?: number
  adultContent: boolean
}

type Props = { 
  gallery: Gallery
}

const GalleryLibraryTileImage = (props: Props) => {
	return (
		<div className={styles.imageContainer} >
      <img 
        src={
          props.gallery.firstPage?.photo
          ? `${props.gallery.firstPage.photo.hostname}${props.gallery.firstPage.photo.path}${props.gallery.firstPage.photo.filename}`
          : AssetLoader.getPlaceholderImage()
        }
        width={191}
        height={100}
        className={styles.image}
      />
    </div>
	)
}  

export default GalleryLibraryTileImage