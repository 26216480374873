import React from "react";

function confirmLeaveHandler (e: any) {
  e.preventDefault();
  if (e) {
    e.returnValue = ''; // Legacy method for cross browser support
  }
  return ''; // Legacy method for cross browser support
}

/**
 * @param isOn Should be state
 */

export const useConfirmLeaveHook = (isOn?: boolean) => {
  React.useEffect(() => {
    return () => {
      window.removeEventListener('beforeunload', confirmLeaveHandler);
    }
  }, [])

  React.useEffect(() => {
    if (isOn || isOn == null) {
      window.addEventListener('beforeunload', confirmLeaveHandler);
    } else {
      window.removeEventListener('beforeunload', confirmLeaveHandler);
    }
  }, [isOn])
}