import { BASE_AUTH } from './baseRoutes'
import { BaseApiWithRefresh } from './BaseApiWithRefresh'
import { AuthData, AccountDetails } from '../types/auth'

class AuthRefreshApi extends BaseApiWithRefresh {
  constructor() {
    super({
      baseURL: `${BASE_AUTH}/api/v1/auth`,
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }

  public checkSession() {
    return this.post<Partial<AuthData>>(`/session/check`)
  }

  public getAccountDetails() {
    return this.get<AccountDetails>(`/account/`)
  }

  public updateAccountDetails(data: FormData) {
    return this.api.patch(`/account/edit`, data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }

  public changePassword(oldPwd: string, newPwd: string) {
    return this.patch<AccountDetails>(`/password/change`, JSON.stringify({
      oldPassword: oldPwd,
      newPassword: newPwd
    }))
  }

  public changeEmail(newEmail: string) {
    return this.patch<AccountDetails>(`/email/change`, JSON.stringify({
      email: newEmail
    }))
  }

}

export default new AuthRefreshApi()