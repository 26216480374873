import React from 'react'

export const useGetWindowWidth = () => {
  const [width, setWidth] = React.useState<number>(0)

  const updateWidth = (ev: any) => {
    setWidth(window.innerWidth)
  }

  React.useEffect(() => {
    window.addEventListener('resize', updateWidth)
    // Removes listener on unmount
    return () => {
        window.removeEventListener('resize', updateWidth)
    }
  }, [])

  return width
}
