import { Form, Formik, ErrorMessage } from 'formik'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import DataApi from '../../../api/DataApi'
import { State } from '../../../redux/reducers/index'
import { ArticleState, ArticleStateOptionsList, MAX_ARTICLE_PER_PAGE, MAX_TAG_SEARCH, SearchArticlePropsOptionsList, SelectOption } from '../../../types/common'
import { onErrorResponseShowMessage } from '../../../utils/onErrorReponseShowMessage/onErrorResponseShowMessage'
import { Article, Author } from '../ArticleTile/ArticleTile'
import BaseInput from '../BaseInput/BaseInput'
import CustomAsyncSelectField from '../CustomAsyncSelectField/CustomAsyncSelectField'
import CustomSelectField from '../CustomSelectField/CustomSelectField'
import LoadingButton from '../LoadingButton/LoadingButton'
import styles from './styles.module.scss'
import CustomFlatpickr from '../CustomFlatpickr/CustomFlatpickr'
import moment from 'moment'
import { encode } from 'html-entities'

const defaultInitialFormValues = {
  title: '',
  category: undefined,
  tags: [],
  status: [ArticleStateOptionsList[0], ArticleStateOptionsList[1]],
  author: undefined,
  dateRange: [] as Date[],
  articleProps: []
}

type Props = { 
  selectedPage: number
  setSelectedPage: React.Dispatch<React.SetStateAction<number>>
  isLoading?: boolean
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>
  setArticles: React.Dispatch<React.SetStateAction<Article[]>>
  setArticleCount: React.Dispatch<React.SetStateAction<number>>
  exclude?: string
  saveInState?: boolean
  baseZIndex?: number
  allowedStatuses?: ArticleState[]
  additionalOnSearch?: (data: any) => void
}

const ArticleSearchForm = (props: Props) => {
  const location = useLocation<any>()
  const history = useHistory()
  const texts = useTranslation().t

  const userUuid = useSelector((e: State) => e.user.uuid)

  const searchFormRef = useRef() as any

  const [initialFormValues, setInitialFormValues] = React.useState<any>(location.state?.searchArticleFormInitialValues ?? {
    ...defaultInitialFormValues,
    ...(props.allowedStatuses && props.allowedStatuses.length > 0 ? {
      status: defaultInitialFormValues.status.filter(e => props.allowedStatuses?.includes(e.value))
    } : {})
  })
  const [categoryList, setCategoryList] = React.useState<{label: string, value: string}[]>([])
  const [tagList, setTagList] = React.useState<{label: string, value: string}[]>([])
  const [authorList, setAuthorList] = React.useState<Author[]>([])

  React.useEffect(() => {
    DataApi.getCategoryList().then((res: any) => {
      setCategoryList(res.data.map((e: any) => {
        return {
          label: e.name,
          value: e.uuid
        }
      }))
      DataApi.searchTags('', MAX_TAG_SEARCH, 0).then((res: any) => {
        setTagList(res.data.map((e: any) => {
          return {
            label: e.name,
            value: e.uuid
          }
        }))
        DataApi.getFullUsersList().then((res: any) => {
          setAuthorList(res.data.map((e: any) => {
            return {
              label: `${e.name} ${e.lastName}`,
              value: e.uuid
            }
          }))
          if (props.selectedPage === 1) {
            DataApi.getArticleList(
              initialFormValues.title,
              initialFormValues.category?.value, 
              initialFormValues.tags != null && initialFormValues.tags.length > 0 ? initialFormValues.tags : undefined,
              props.allowedStatuses && props.allowedStatuses.length > 0 && (initialFormValues.status == null || initialFormValues.status.length === 0)
              ? props.allowedStatuses
              : initialFormValues.status?.map((e: any) => e.value),
              initialFormValues.author?.value,
              props.exclude,
              initialFormValues.dateRange && initialFormValues.dateRange.length === 2 ? {
                from: initialFormValues.dateRange[0],
                to: initialFormValues.dateRange[1]
              } : undefined,
              initialFormValues.articleProps?.map((e: SelectOption) => e.value) ?? [],
              MAX_ARTICLE_PER_PAGE,
              (props.selectedPage - 1) * MAX_ARTICLE_PER_PAGE
            ).then((res: any) => {
              props.setArticles(res.data)
              props.setArticleCount(res.count)
              if (props.setIsLoading) {
                props.setIsLoading(false)
              }
            }).catch(error => onErrorResponseShowMessage(error, true, { userUuid: userUuid }))
          }
        }).catch(error => onErrorResponseShowMessage(error, true, { userUuid: userUuid }))
      }).catch(error => onErrorResponseShowMessage(error, true, { userUuid: userUuid }))
    }).catch(error => onErrorResponseShowMessage(error, true, { userUuid: userUuid }))
  }, [])

  React.useEffect(() => {
    if (searchFormRef?.current) {
      if (props.setIsLoading) {
        props.setIsLoading(true)
      }
      DataApi.getArticleList(
        searchFormRef.current.values.title,
        searchFormRef.current.values.category?.value,
        searchFormRef.current.values.tags != null && searchFormRef.current.values.tags.length > 0 ? searchFormRef.current.values.tags : undefined,
        props.allowedStatuses && props.allowedStatuses.length > 0 && (searchFormRef?.current?.values?.status == null || searchFormRef.current.values.status.length === 0)
        ? props.allowedStatuses
        : searchFormRef.current.values.status?.map((e: any) => e.value),
        searchFormRef.current.values.author?.value,
        props.exclude,
        searchFormRef.current.values.dateRange && searchFormRef.current.values.dateRange.length === 2 ? {
          from: searchFormRef.current.values.dateRange[0],
          to: searchFormRef.current.values.dateRange[1]
        } : undefined,
        searchFormRef.current.values.articleProps?.map((e: SelectOption) => e.value),
        MAX_ARTICLE_PER_PAGE,
        (props.selectedPage - 1) * MAX_ARTICLE_PER_PAGE
      ).then((res: any) => {
        props.setArticles(res.data)
        props.setArticleCount(res.count)
        if (res.data.length === 0 && props.selectedPage > 1) {
          props.setSelectedPage(Math.ceil(res.count / MAX_ARTICLE_PER_PAGE))
        } else if (props.setIsLoading) {
          props.setIsLoading(false)
        }
      }).catch(error => onErrorResponseShowMessage(error, true, { userUuid: userUuid }))
    }
   
  }, [props.selectedPage])


  const loadTagOptions = (inputValue: string): Promise<{label: string, value: string}[]>  => 
    new Promise((resolve, reject) => {
      DataApi.searchTags(inputValue, MAX_TAG_SEARCH, 0).then(res => {
        resolve(res.data.map((e: any) => {
          return {
            label: e.name,
            value: e.uuid
          }
        }))
      }).catch(error => {
        onErrorResponseShowMessage(error, true, { userUuid: userUuid })
        reject(error)
      })
    })

  const handleSearchSubmit = async (data: any) => {
    try {
      if (props.setIsLoading) {
        props.setIsLoading(true)
      }
      const res = await DataApi.getArticleList(
        data.title,
        data.category?.value,
        data.tags != null && data.tags.length > 0 ? data.tags : undefined,
        props.allowedStatuses && props.allowedStatuses.length > 0 && data.status.length === 0
        ? props.allowedStatuses
        : data.status?.map((e: any) => e.value),
        // data.status?.value,
        data.author?.value,
        props.exclude,
        data.dateRange && data.dateRange.length === 2 ? {
          from: data.dateRange[0],
          to: data.dateRange[1]
        } : undefined,
        data.articleProps?.map((e: SelectOption) => e.value) ?? [],
        MAX_ARTICLE_PER_PAGE,
        0
      )
      if (props.saveInState) {
        history.replace('', {
          searchArticleFormInitialValues: data
        })
      }
      props.setSelectedPage(1)
      props.setArticles(res.data)
      props.setArticleCount(res.count)
      if (props.additionalOnSearch) {
        props.additionalOnSearch(data)
      }
      if (props.setIsLoading) {
        props.setIsLoading(false)
      }
    } catch (error) {
      onErrorResponseShowMessage(error, true, { userUuid: userUuid })
    }
  }

  return (
    <Formik
      innerRef={searchFormRef}
      initialValues={initialFormValues}
      onSubmit={handleSearchSubmit}
    >
      {(formikProps) => (
        <Form className={styles.searchForm}>
          <div className={styles.flexRow}>
            <div className={styles.textSearchField}>
              <BaseInput
                label={texts('common:title_or_content')}
                name={'title'}
                clearButton
                formikProps={formikProps}
                value={formikProps.values.title}
              />
            </div>
            <div className={styles.searchField}>
              <div className={styles.fieldLabel}>
                {texts('common:category_label')}
              </div>
              <div
                className={styles.clearButton}
                onClick={() => { formikProps.setFieldValue('category', '') }}
              >
                {texts('common:clear_button_label')}
              </div>
              <CustomSelectField
                instanceId={'category-field'}
                name="category"
                placeholder={texts('common:choose_category_placeholder')}
                options={categoryList}
                formikProps={formikProps}
                value={formikProps.values.category}
                error={
                  formikProps.touched.category && Boolean(formikProps.errors.category)
                }
                {...(props.baseZIndex != null ? { portalZIndex: (props.baseZIndex ?? 0) + 1 } : {})}
              />
            </div>
          </div>
          <div className={styles.flexRow}>
            <div className={styles.searchField}>
              <div className={styles.fieldLabel}>
                {texts('common:tag_or_tags_label')}
              </div>
              <div
                className={styles.clearButton}
                onClick={() => { formikProps.setFieldValue('tags', []) }}
              >
                {texts('common:clear_button_label')}
              </div>
              <CustomAsyncSelectField
                instanceId={'tags-field'}
                name="tags"
                isMulti
                placeholder={texts('common:choose_tag_or_tags_label')}
                options={tagList}
                value={formikProps.values.tags}
                formikProps={formikProps}
                loadOptions={loadTagOptions}
                {...(props.baseZIndex != null ? { portalZIndex: (props.baseZIndex ?? 0) + 1 } : {})}
              />
            </div>
            <div className={styles.searchField}>
              <div className={styles.fieldLabel}>
                {texts('common:status_label')}
              </div>
              <div
                className={styles.clearButton}
                onClick={() => { formikProps.setFieldValue('status', []) }}
              >
                {texts('common:clear_button_label')}
              </div>
              <CustomSelectField
                instanceId={'status-field'}
                name="status"
                isMulti
                placeholder={texts('common:all')}
                options={props.allowedStatuses && props.allowedStatuses.length > 0 ? ArticleStateOptionsList.filter(e => props.allowedStatuses?.includes(e.value)) : ArticleStateOptionsList}
                formikProps={formikProps}
                value={formikProps.values.status}
                error={
                  formikProps.touched.status && Boolean(formikProps.errors.status)
                }
                {...(props.baseZIndex != null ? { portalZIndex: (props.baseZIndex ?? 0) + 1 } : {})}
              />
            </div>
            <div className={styles.searchField}>
              <div className={styles.fieldLabel}>
                {texts('article:article_props_search_field_label')}
              </div>
              <div
                className={styles.clearButton}
                onClick={() => { formikProps.setFieldValue('articleProps', []) }}
              >
                {texts('common:clear_button_label')}
              </div>
              <CustomSelectField
                instanceId={'article-props-field'}
                name="articleProps"
                isMulti
                placeholder={texts('article:article_props_search_field_placeholder')}
                options={SearchArticlePropsOptionsList}
                formikProps={formikProps}
                value={formikProps.values.articleProps}
                error={
                  formikProps.touched.articleProps && Boolean(formikProps.errors.articleProps)
                }
                {...(props.baseZIndex != null ? { portalZIndex: (props.baseZIndex ?? 0) + 1 } : {})}
              />
            </div>
            <div className={styles.searchField}>
              <div className={styles.fieldLabel}>
                {texts('common:author_choose_label')}
              </div>
              <div
                className={styles.clearButton}
                onClick={() => { formikProps.setFieldValue('author', '') }}
              >
                {texts('common:clear_button_label')}
              </div>
              <CustomSelectField
                instanceId={'author-field'}
                name="author"
                value={formikProps.values.author}
                placeholder={texts('common:author_choose_placeholder')}
                options={authorList}
                formikProps={formikProps}
                {...(props.baseZIndex != null ? { portalZIndex: (props.baseZIndex ?? 0) + 1 } : {})}
              />
            </div>
            <div className={styles.searchField}>
              <div className={styles.fieldLabel}>
                {texts('common:publish_creation_date_label')}
              </div>
              <div
                className={styles.clearButtonLast}
                onClick={() => { formikProps.setFieldValue('dateRange', []) }}
              >
                {texts('common:clear_button_label')}
              </div>
              <CustomFlatpickr
                name={'dateRange'}
                formikProps={formikProps}
                placeholder={texts('common:all')}
                range
                value={formikProps.values.dateRange}
                inputStyle={{
                  height: '2.375rem'
                }}
              />
              <div className={styles.errorLabel}>
                {
                  formikProps.errors.dateRange ?? <ErrorMessage name={'dateRange'} />
                }
              </div>
            </div>
          </div>
          <div className={styles.flexRow}>
            <div className={styles.flexSpacer}></div>
            <div className={styles.searchButtonContainer}>
              <LoadingButton type={'submit'} className={styles.searchButton}>
                {texts('article:search_article_label')}
              </LoadingButton>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default ArticleSearchForm
