import { ErrorMessage, Form, Formik } from 'formik'
import moment from 'moment'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { IoMdTrash } from 'react-icons/io'
import { IoAddCircle } from 'react-icons/io5'
import { MdImage } from 'react-icons/md'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import MoonLoader from 'react-spinners/MoonLoader'
import DataApi from '../../api/DataApi'
import { addCmdMessage, addSuccessMessage } from '../../redux/messages/actions'
import { State } from '../../redux/reducers'
import store from '../../store'
import { GalleryPageType, MAX_TAG_SEARCH, Project, GalleryStateOptionsList } from '../../types/common'
import { FRONT_BASE_URL } from '../../utils/common'
import { useConfirmLeaveHook } from '../../utils/hooks'
import { onErrorResponseShowMessage } from '../../utils/onErrorReponseShowMessage/onErrorResponseShowMessage'
import { addArticleValidation } from '../../utils/validations/addArticleValidation'
import BaseInput from '../common/BaseInput/BaseInput'
import BBCodeEditor from '../common/BBCodeEditor/BBCodeEditor'
import ConnectedGalleryTile from '../common/ConnectedGalleryTile/ConnectedGalleryTile'
import CropperDialog from '../common/CropperDialog/CropperDialog'
import CustomAsyncCreatableSelectField from '../common/CustomAsyncCreatableSelectField/CustomAsyncCreatableSelectField'
import CustomFlatpickr from '../common/CustomFlatpickr/CustomFlatpickr'
import CustomSelectField from '../common/CustomSelectField/CustomSelectField'
import CustomSwitch from '../common/CustomSwitch/CustomSwitch'
import GalleryLibraryDialog from '../common/GalleryLibraryDialog/GalleryLibraryDialog'
import HtmlValidationErrorDialog from '../common/HtmlValidatorDialog/HtmlValidatorDialog'
import LoadingButton from '../common/LoadingButton/LoadingButton'
import { SinglePhoto } from '../common/MediaLibrary/types'
import MediaLibraryDialog from '../common/MediaLibraryDialog/MediaLibraryDialog'
import RouteLeavingGuard from '../common/RouteLeavingGuard/RouteLeavingGuard'
import { MessageEnum } from '../CustomToastProvider/types'
import styles from './styles.module.scss'
import BBCodeDepeszaArticlePreview from '../common/BBCodeDepeszaArticlePreview/BBCodeDepeszaArticlePreview'
import BBCodeZaradnaArticlePreview from '../common/BBCodeZaradnaArticlePreview/BBCodeZaradnaArticlePreview'
import { GalleryLibraryStateData } from '../common/GalleryLibrary/GalleryLibrary'
import { Resources, SuggestedWikiDto, LoadingState, TagDto, CategoryDto, ArticlePropsDto } from '../../types/data'
import SuggestedWikiArticlesTile from '../common/SuggestedWikiArticlesTile/SuggestedWikiArticlesTile'
import ReassignWikiDialogContent from '../common/ReassignWikiDialogContent/ReassignWikiDialogContent'
import CustomCheckbox from '../common/CustomCheckbox/CustomCheckbox'

type LocationState = {
  galleryData?: {
    uuid?: string
  }
  articleData?: {
    initialValues?: InitialFormValuesDto
    mainImage: {
      img: SinglePhoto
      croppedImage?: Blob
    } | null
    active?: boolean
    showGalleryLibrary?: boolean
    articleListData?: {
      selectedPage: number
      searchFormInitialValues: any
    }
  }
  galleryLibraryData?: GalleryLibraryStateData
}

export type InitialFormValuesDto = {
  title: string
  leadText: string
  seoTitle: string
  seoDescription: string
  ogDescription: string
  twitterCardDescription: string
  webPushTitle: string
  webPushDescription: string
  additionalBodyScript: string
  hideAuthor: boolean
  author: string
  draft: boolean
  category?: CategoryDto | null
  tags: TagDto[]
  articleProps: ArticlePropsDto[]
  adultContent: boolean
  sponsoredContent: boolean
  sponsor: string
  content: string
  image?: any | null
  sourceImageUuid: string
  slug: string
  source: string
  showAdultWarning: boolean
  publishDate?: string | Date | null
  gallery?: any
  galleryUuid?: string
  // redirect: string
  isWikiArticle: boolean
}

const defaultInitValues: InitialFormValuesDto = {
  title: '',
  leadText: '',
  seoTitle: '',
  seoDescription: '',
  hideAuthor: false,
  author: '',
  draft: false,
  tags: [],
  articleProps: [],
  adultContent: false,
  sponsoredContent: false,
  sponsor: '',
  content: '',
  image: null,
  sourceImageUuid: '',
  slug: '',
  showAdultWarning: false,
  source: '',
  ogDescription: '',
  twitterCardDescription: '',
  webPushTitle: '',
  webPushDescription: '',
  additionalBodyScript: '',
  // redirect: '',
  isWikiArticle: false,
  category: null
}

const AddArticlePage = () => {

  const texts = useTranslation().t
  const history = useHistory()
  const location = useLocation<LocationState>()

  const userName = useSelector((e: State) => e.user.name)
  const userLastName = useSelector((e: State) => e.user.lastName)
  const userUuid = useSelector((e: State) => e.user.uuid)

  const [adultContent, setAdultContent] = React.useState<boolean>(false)
  const [showAdultWarning, setShowAdultWarning] = React.useState<boolean>(false)
  const [sponsoredContent, setSponsoredContent] = React.useState<boolean>(false)
  const [showMediaLibraryDialog, setShowMediaLibraryDialog] = React.useState<boolean>(false)
  const [showCropperDialog, setShowCropperDialog] = React.useState<boolean>(false)
  const [showHtmlValidationErrorDialog, setShowHtmlValidationErrorDialog] = React.useState<boolean>(false)
  const [wiki, setWiki] = React.useState<boolean>(false)
  const [showReassignWikiDialog, setShowReassignWikiDialog] = React.useState<boolean>(false)
  const [wikiTagSelected, setWikiTagSelected] = React.useState<TagDto[]>([])  
  const [showSuggestedWikiList, setShowSuggestedWikiList] = React.useState<boolean>(false)
  
  const [htmlValidationErrors, setHtmlValidationErrors] = React.useState<string[]>([])
  const [pageUrlToToOpen, setPageUrlToOpen] = React.useState<string>('')
  const [editPageUrlToToOpen, setEditPageUrlToOpen] = React.useState<string>('')

  const [showGalleryLibrary, setShowGalleryLibrary] = React.useState<boolean>(false)
  const [galleryLibraryInitialData, setGalleryLibraryInitialData] = React.useState<GalleryLibraryStateData | null | undefined>(null)

  const [connectedGallery, setConnectedGallery] = React.useState<any>(null)

  const [formInitialValues, setFormInitialValues] = React.useState<InitialFormValuesDto>(defaultInitValues)

  const [imageToCrop, setImageToCrop] = React.useState<SinglePhoto | null>(null)
  const [newMainImgSourceData, setNewMainImgSourceData] = React.useState<{
    img: SinglePhoto
    croppedImage?: Blob
  } | null> (null)

  const [categoryList, setCategoryList] = React.useState<CategoryDto[]>([])
  const [tagList, setTagList] = React.useState<TagDto[]>([])
  const [articlePropList, setArticlePropList] = React.useState<ArticlePropsDto[]>([])
  const [suggestedWikiList, setSuggestedWikiList] = React.useState<Resources<SuggestedWikiDto[]>>({state: LoadingState.INIT})

  const [isDraft, setIsDraft] = React.useState<boolean>(false)
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false)
  const [isNavigationGuarded, setIsNavigationGuarded] = React.useState<boolean>(true)

  const formRef = useRef() as any

  const openGalleryLibraryDialog = () => {
    store.dispatch(addCmdMessage(MessageEnum.ENABLE_PAGE_COVER))
    window.scrollTo(0, 0)
    setShowGalleryLibrary(true)
  }

  const closeGalleryLibraryDialog = () => {
    store.dispatch(addCmdMessage(MessageEnum.DISABLE_PAGE_COVER))
    setShowGalleryLibrary(false)
    setGalleryLibraryInitialData(null)
  }

  const getGalleryData = async (uuid?: string) => {
    if (uuid) {
      const gallery = await DataApi.getGalleryTileDetails(uuid)
      setConnectedGallery(gallery)
      return
    }
  }

  const getMainImgUrl = () => {
    if (newMainImgSourceData == null) {
      return ''
    } else if (newMainImgSourceData.croppedImage != null) {
      return URL.createObjectURL(newMainImgSourceData.croppedImage)
    } else {
      return `${newMainImgSourceData.img.hostname}${newMainImgSourceData.img.path}${newMainImgSourceData.img.filename}`
    }
  }

  const openMediaLibraryDialog = () => {
    store.dispatch(addCmdMessage(MessageEnum.ENABLE_PAGE_COVER))
    setShowMediaLibraryDialog(true)
  }

  const closeMediaLibraryDialog = () => {
    store.dispatch(addCmdMessage(MessageEnum.DISABLE_PAGE_COVER))
    setShowMediaLibraryDialog(false)
  }

  const openCropperDialog = () => {
    store.dispatch(addCmdMessage(MessageEnum.ENABLE_PAGE_COVER))
    setShowCropperDialog(true)
  }

  const closeCropperDialog = () => {
    store.dispatch(addCmdMessage(MessageEnum.DISABLE_PAGE_COVER))
    setShowCropperDialog(false)
  }

  const openHtmlValidationErrorDialog = () => {
    store.dispatch(addCmdMessage(MessageEnum.ENABLE_PAGE_COVER))
    setShowHtmlValidationErrorDialog(true)
  }

  const closeHtmlValidationErrorDialog = () => {
    store.dispatch(addCmdMessage(MessageEnum.DISABLE_PAGE_COVER))
    setShowHtmlValidationErrorDialog(false)
  }

  const closeReassignWikiDialog = () => {
    store.dispatch(addCmdMessage(MessageEnum.DISABLE_PAGE_COVER))
    setShowReassignWikiDialog(false)
  }

  const openReassignWikiDialog = async () => {
    store.dispatch(addCmdMessage(MessageEnum.ENABLE_PAGE_COVER))
    setShowReassignWikiDialog(true)
  }

  const getSuggestedWikiArticles = async (text: string) => {
    try {
      setSuggestedWikiList({state: LoadingState.LOADING})
      const suggestedWikiList = await DataApi.getSuggestedWikiArticles(text)
      setShowSuggestedWikiList(true)
      setSuggestedWikiList({state: LoadingState.LOADED, data: suggestedWikiList})
    } catch (error) {
      setIsNavigationGuarded(true)
      onErrorResponseShowMessage(error, true, { userUuid: userUuid })
      setSuggestedWikiList({state: LoadingState.ERROR, error: error})
    }
  }

  const loadTagOptions = (inputValue: string): Promise<{label: string, value: string}[]>  => 
    new Promise((resolve, reject) => {
      DataApi.searchTags(inputValue, MAX_TAG_SEARCH, 0).then(res => {
        resolve(res.data.map((e: any) => {
          return {
            label: e.name,
            value: e.uuid,
            wikiArticleUuid: e.wikiArticleUuid
          }
        }))
      }).catch(error => {
        onErrorResponseShowMessage(error, true, { userUuid: userUuid })
        reject(error)
      })
    })

  React.useEffect(() => {
    DataApi.getCategoryList().then((res: any) => {
      setCategoryList(res.data.map((e: any) => {
        return {
          label: e.name,
          value: e.uuid
        }
      }))
      DataApi.searchTags('', MAX_TAG_SEARCH, 0).then((res: any) => {
        setTagList(res.data.map((e: any) => {
          return {
            label: e.name,
            value: e.uuid,
            wikiArticleUuid: e.wikiArticleUuid
          }
        }))
        DataApi.getArticlePropList().then((res: any) => {
          setArticlePropList(res.data.map((e: any) => {
            return {
              label: e.name,
              value: e.uuid
            }
          }))
          if (location.state?.articleData?.initialValues != null) {
            getGalleryData(location.state.articleData.initialValues.galleryUuid).then((res: any) => {
              setFormInitialValues({
                ...location.state!.articleData!.initialValues!,
                draft: false,
              })
              setWiki(location.state!.articleData!.initialValues!.isWikiArticle)
              setNewMainImgSourceData(location!.state!.articleData!.mainImage)
              setAdultContent(location!.state!.articleData!.initialValues!.adultContent ?? false)
              setShowAdultWarning(location!.state!.articleData!.initialValues!.showAdultWarning ?? false)
              setSponsoredContent(location!.state!.articleData!.initialValues!.sponsoredContent ?? false)
              setGalleryLibraryInitialData(location!.state!.articleData?.showGalleryLibrary ? location!.state!.galleryLibraryData : null)
              formRef?.current?.resetForm()
              if (location.state?.articleData?.showGalleryLibrary) {
                openGalleryLibraryDialog()
              }
              setIsLoading(false)
            }).catch(error => onErrorResponseShowMessage(error, true, { userUuid: userUuid }))  
          } else {
            if (location.state?.articleData?.showGalleryLibrary) {
              openGalleryLibraryDialog()
            }
            setIsLoading(false)
          }
        }).catch(error => onErrorResponseShowMessage(error, true, { userUuid: userUuid }))
      }).catch(error => onErrorResponseShowMessage(error, true, { userUuid: userUuid }))
    }).catch(error => onErrorResponseShowMessage(error, true, { userUuid: userUuid }))
  }, [])

  React.useEffect(() => {
    setFormInitialValues({
      ...formInitialValues,
      author: `${userName ?? ''} ${userLastName ?? ''}`
    })
    formRef?.current?.setFieldValue('author', `${userName ?? ''} ${userLastName ?? ''}`)
  }, [userName])

  React.useEffect(() => {
    setFormInitialValues({
      ...formInitialValues,
      author: `${userName ?? ''} ${userLastName ?? ''}`
    })
    formRef?.current?.setFieldValue('author', `${userName ?? ''} ${userLastName ?? ''}`)
  }, [userLastName])
  
  React.useEffect(() => {
    if (!isLoading) {
      formRef?.current?.submitForm()
    }
  }, [isDraft])

  const submitData = async (data: any, draft?: boolean) => {
    try {
      const body = new FormData()
      if (data.galleryUuid) {
        body.set('galleryUuid', data.galleryUuid)
      }
      body.set('draft', draft != null ? draft : data.draft)
      body.set('image', data.image)
      body.set('sourceImageUuid', data.sourceImageUuid)
      body.set('title', data.title !== '' ? data.title : '')
      body.set('leadText', data.leadText !== '' ? data.leadText : '')
      body.set('seoTitle', data.seoTitle !== '' ? data.seoTitle : '')
      body.set('seoDescription', data.seoDescription !== '' ? data.seoDescription : '')
      body.set('author', userUuid)
      body.set('hideAuthor', data.hideAuthor)
      body.set('categoryUuid', data.category?.value ?? '')
      body.set('adultContent', data.adultContent !== '' ? data.adultContent : '')
      body.set('showAdultWarning', data.showAdultWarning)
      body.set('sponsoredContent', data.sponsoredContent !== '' ? data.sponsoredContent : '')
      body.set('sponsor', data.sponsor !== '' ? data.sponsor : '')
      body.set('text', data.content !== '' ? data.content : '')
      body.set('slug', data.slug !== '' ? data.slug : '')
      body.set('source', data.source !== '' ? data.source : '')
      body.set('publishedAt', data.publishDate ? moment(data.publishDate).format() : '')
      // body.set('redirect', data.redirect && data.redirect !== '' ? data.redirect : '')
      body.set('tags', JSON.stringify(data.tags.map((e: any) => {
        return {
          label: e.label,
          uuid: e.__isNew__ || e.label === e.value ? undefined : e.value,
          isNew: e.__isNew__ || e.label === e.value
        }
      })))
      body.set('articleProps', JSON.stringify(data.articleProps.map((e: any) => {
        return {
          label: e.label,
          uuid: e.value
        }
      })))
      body.set('ogDescription', data.ogDescription !== '' ? data.ogDescription : '')
      body.set('twitterCardDescription', data.twitterCardDescription !== '' ? data.twitterCardDescription : '')
      body.set('webPushTitle', data.webPushTitle !== '' ? data.webPushTitle : '')
      body.set('webPushDescription', data.webPushDescription !== '' ? data.webPushDescription : '')
      body.set('additionalBodyScript', data.additionalBodyScript && data.additionalBodyScript !== '' ? data.additionalBodyScript : '')
      body.set('isWikiArticle', String(data.isWikiArticle ?? false))
      return await DataApi.createNewArticle(body)
    } catch (error) {
      throw error
    }
  }

  const handleSubmit = async (data: any) => {
    try {
      setIsSubmitting(true)
      setShowSuggestedWikiList(false)
      const res = await submitData(data)
      store.dispatch(addSuccessMessage(MessageEnum.ARTICLE_ADDED))
      setIsNavigationGuarded(false)
      if (res.isHtmlValid != null && !res.isHtmlValid) {
        setHtmlValidationErrors(res.htmlErrorMessages ?? [])
        setPageUrlToOpen(`${FRONT_BASE_URL}/${res.slug}`)
        setEditPageUrlToOpen(`/article/edit/${res.uuid}`)
        openHtmlValidationErrorDialog()
      } else {
        if (!data.draft && moment(data.publishDate).isSameOrBefore(moment())) {
          window.open(`${FRONT_BASE_URL}/${res.slug}`, '_blank', 'noopener,noreferrer')
        }
        history.push('/')
      }
      
    } catch (error: any) {
      onErrorResponseShowMessage(error, true, { userUuid: userUuid })
    } finally {
      setIsSubmitting(false)
    }
  }

  const getArticlePreviewComponent = () => {
    switch (process.env.REACT_APP_PROJECT) {
      case Project.ZARADNA:
        return BBCodeZaradnaArticlePreview
      case Project.DEPESZA:
      default:
        return BBCodeDepeszaArticlePreview
    }
  }

  const goBack = () => {
    history.push(
      '/',
      {
        selectedArticleListPage: location.state?.articleData?.articleListData?.selectedPage,
        searchArticleFormInitialValues: location.state?.articleData?.articleListData?.searchFormInitialValues
      }
    )
  }

  useConfirmLeaveHook()

  return (
    <>
      <RouteLeavingGuard
        when={isNavigationGuarded}
        shouldBlockNavigation={location => {
          if (location.pathname === '/login') {
            return false
          }
          return isNavigationGuarded
        }}
      /> 
      <HtmlValidationErrorDialog 
        hidden={!showHtmlValidationErrorDialog}
        errorMessages={htmlValidationErrors}
        onCloseClick={() => {
          setIsNavigationGuarded(false)
          closeHtmlValidationErrorDialog()
          if (!isDraft && moment(formRef?.current.values.publishDate).isSameOrBefore(moment())) {
            window.open(pageUrlToToOpen, '_blank', 'noopener,noreferrer')
          }
          history.push('/')
        }}
        onEditLabelClick={() => {
          setIsNavigationGuarded(false)
          closeHtmlValidationErrorDialog()
          history.push(editPageUrlToToOpen)
        }}
      />
      <MediaLibraryDialog
        id={'article-media-library-dialog'}
        name={'article-media-library-dialog'}
        hidden={!showMediaLibraryDialog}
        onCloseClick={() => {
          closeMediaLibraryDialog()
        }}
        onFinish={(img) => {
          setImageToCrop(img)
          openCropperDialog()
        }}
      />
      <CropperDialog
        hidden={!showCropperDialog}
        image={imageToCrop}
        onCloseClick={() => {
          closeCropperDialog()
        }}
        onFinish={(img) => {
          formRef?.current?.setFieldValue('image', img)
          formRef?.current?.setFieldValue('sourceImageUuid', imageToCrop!.uuid)
          setNewMainImgSourceData({
            img: imageToCrop!,
            croppedImage: img
          })
        }}
      />
      <GalleryLibraryDialog
        id={'bbcode-editor-gallery-library'}
        hidden={!showGalleryLibrary}
        initialData={galleryLibraryInitialData}
        onCloseClick={() => {
          closeGalleryLibraryDialog()
        }}
        beforeAdd={async () => {
          setIsNavigationGuarded(false)
          return {
            push: {
              pageType: GalleryPageType.FROM_ADD_ARTICLE,
              articleData: {
                initialValues: formRef?.current?.values,
                mainImage: newMainImgSourceData,
                active: true,
                showGalleryLibrary: true,
                articleListData: location.state.articleData?.articleListData
              }
            },
            replace: {
              url: `/article/add`,
              state: {
                galleryData: {
                  uuid:formRef?.current?.values?.galleryUuid
                },
                articleData: {
                  initialValues: formRef?.current?.values,
                  mainImage: newMainImgSourceData,
                  active: true,
                  showGalleryLibrary: true,
                  articleListData: location.state.articleData?.articleListData
                }
              }
            }
          }
        }}
        beforeEdit={async () => {
          setIsNavigationGuarded(false)
          return {
            push: {
              pageType: GalleryPageType.FROM_ADD_ARTICLE,
              articleData: {
                initialValues: formRef?.current?.values,
                mainImage: newMainImgSourceData,
                active: true,
                showGalleryLibrary: true,
                articleListData: location.state.articleData?.articleListData
              }
            },
            replace: {
              url: `/article/add`,
              state: {
                galleryData: {
                  uuid:formRef?.current?.values?.galleryUuid
                },
                articleData: {
                  initialValues: formRef?.current?.values,
                  mainImage: newMainImgSourceData,
                  active: true,
                  showGalleryLibrary: true,
                  articleListData: location.state.articleData?.articleListData
                }
              }
            }
          }
        }}
        onFinish={async (selectedGallery) => { 
          try {
            formRef?.current?.setFieldValue('galleryUuid', selectedGallery.uuid)
            formRef?.current?.setFieldValue('gallery', selectedGallery)
            await getGalleryData(selectedGallery.uuid)
          } catch (error) {
            onErrorResponseShowMessage(error, true, { userUuid: userUuid })
          }
        }}
      />
      <div className={styles.controlButtonSection}>
        <LoadingButton
          className={styles.backButton}
          onClick={goBack}
          type={"button"}
        > 
          {texts('common:go_back_button_label')}
        </LoadingButton>
        <div className={styles.flexSpacer}></div>
        <LoadingButton 
          loading={isSubmitting}
          className={styles.submitDraftButton}
          onClick={() => {
            formRef.current?.setFieldValue('draft', true)
            setIsDraft(true)
            if (formRef.current?.values.draft) {
              formRef.current?.submitForm()
            }
          }}
        >
          <div className={styles.buttonContent}>{texts('common:save_draft_button')}</div>
        </LoadingButton>
        <LoadingButton 
          className={styles.submitFormButton}
          loading={isSubmitting}
          onClick={() => {
            formRef.current?.setFieldValue('draft', false)
            setIsDraft(false)
            if (!formRef.current?.values.draft) {
              formRef.current?.submitForm()
            }
          }}
        >
          <div className={styles.buttonContent}>{texts('article:publish_button')}</div>
        </LoadingButton>
      </div>
      {
        isLoading
        ? <div className={styles.loadingSpinnerContainer}>
            <MoonLoader loading={true} size={100}/>
          </div>
        : <>
            <h1 className={styles.pageTitle}>
              {texts('article:add_article')}
            </h1>
            <div className={styles.formContainer}>
              <Formik
                innerRef={formRef}
                initialValues={formInitialValues}
                enableReinitialize
                validateOnBlur={true}
                validateOnChange={true}
                onSubmit={handleSubmit}
                validationSchema={addArticleValidation(texts)}
              >
                {(formikProps) => (
                  <>
                    <ReassignWikiDialogContent
                      hidden={!showReassignWikiDialog}
                      title={texts('article:reassign_wiki_dialog')}
                      closeButton
                      onCloseButtonClick={closeReassignWikiDialog}
                      onConfirmButtonClick={closeReassignWikiDialog}
                      wikiTagSelected={wikiTagSelected}
                    />
                    <Form className={styles.form}>
                      <div className={styles.formGeneralSection}>
                        <div className={styles.formColumn}>
                          <div className={`${styles.subsection} ${styles.subsectionNoBottomPadding}`}>
                            <div className={styles.field}>
                              <div className={styles.fieldLabel}>
                                {texts('article:main_image_label')}
                              </div>
                              <div
                                className={styles.mainImageContainer}
                              >
                                <div
                                  className={styles.mainImageImgSection}
                                >
                                  {
                                    newMainImgSourceData != null
                                    ? <img 
                                        className={styles.mainImage}
                                        src={getMainImgUrl()}
                                      />
                                    : <div className={styles.mainImagePlaceholder}>
                                        <MdImage size={40}/>
                                      </div>
                                  }
                                </div>
                                <div className={styles.mainImageControlSection}>
                                  <button
                                    className={styles.mainImgAddButton}
                                    type={'button'}
                                    onClick={() => {
                                      openMediaLibraryDialog()
                                    }}
                                  >
                                    <div className={styles.mainImgButtonContent}>
                                      <IoAddCircle size={20}/>
                                      <div className={styles.mainImgButtonContentLabel}>
                                        {texts('article:choose_or_add_image_button')}
                                      </div>
                                    </div>
                                  </button>
                                  {
                                    newMainImgSourceData != null
                                    ? <button
                                        disabled={newMainImgSourceData == null}
                                        className={styles.mainImgRemoveButton}
                                        type={'button'}
                                        onClick={() => {
                                          formikProps.values.image = null
                                          formikProps.values.sourceImageUuid = ''
                                          setNewMainImgSourceData(null)
                                        }}
                                      >
                                        <div className={styles.mainImgButtonContent}>
                                          <IoMdTrash size={20}/>
                                          <div className={styles.mainImgButtonContentLabel}>
                                            {texts("article:remove_image_button")}
                                          </div>
                                        </div>
                                      </button>
                                    : null
                                  }
                                  
                                </div>
                              </div>
                              <div className={styles.errorLabel}>
                                {
                                  formikProps.errors.image ?? <ErrorMessage name={'image'} />
                                }
                              </div>
                            </div>
                          </div>
                          <div className={`${styles.subsection} ${styles.subsectionBiggerBottomPadding}`}>
                            <div className={styles.field}>
                              <BaseInput
                                label={texts('article:title_label')}
                                name={'title'}
                                formikProps={formikProps}
                                classes={{
                                  containerWithLabel: styles.inputContainerWithLabel,
                                  containerWithoutLabel: styles.inputContainerWithoutLabel,
                                  fieldBase: styles.textFieldBase
                                }}
                                value={formikProps.values.title}
                                maxLen={150}
                                count={true}
                                error={
                                  formikProps.touched.title && Boolean(formikProps.errors.title)
                                }
                              />
                            </div>
                            <div className={styles.field}>
                              <div className={styles.fieldLabel}>
                                {texts('article:choose_category_label')}
                              </div>
                              <CustomSelectField
                                instanceId={'category-field'}
                                name={'category'}
                                placeholder={texts('common:choose_category_placeholder')}
                                options={categoryList}
                                value={formikProps.values.category}
                                formikProps={formikProps}
                                error={
                                  formikProps.touched.category && 
                                  Boolean(formikProps.errors.category)
                                }
                              />
                            </div>
                            <div className={styles.field}>
                              <BaseInput
                                label={texts('article:lead_text_label')}
                                name={'leadText'}
                                rows={9}
                                formikProps={formikProps}
                                classes={{
                                  containerWithLabel: styles.inputContainerWithLabel,
                                  containerWithoutLabel: styles.inputContainerWithoutLabel,
                                  fieldBase: styles.textFieldBase
                                }}
                                value={formikProps.values.leadText}
                                maxLen={500}
                                count={true}
                                error={
                                  formikProps.touched.leadText && Boolean(formikProps.errors.leadText)
                                }
                              />
                            </div>
                            <div className={styles.field} style={{ marginTop: '1.25rem' }}>
                              <BaseInput
                                label={texts('common:slug_label')}
                                name={'slug'}
                                formikProps={formikProps}
                                classes={{
                                  containerWithLabel: styles.inputContainerWithLabel,
                                  containerWithoutLabel: styles.inputContainerWithoutLabel,
                                  fieldBase: styles.textFieldBase
                                }}
                                value={formikProps.values.slug}
                                maxLen={80}
                                count={true}
                                error={
                                  formikProps.touched.slug && Boolean(formikProps.errors.slug)
                                }
                              />
                            </div>
                            <div className={styles.field} style={{ marginTop: '1.3rem' }}>
                              <BaseInput
                                label={texts('article:source_label')}
                                name={'source'}
                                formikProps={formikProps}
                                classes={{
                                  containerWithLabel: styles.inputContainerWithLabel,
                                  containerWithoutLabel: styles.inputContainerWithoutLabel,
                                  fieldBase: styles.textFieldBase
                                }}
                                value={formikProps.values.source}
                                maxLen={80}
                                count={true}
                                error={
                                  formikProps.touched.source && Boolean(formikProps.errors.source)
                                }
                              />
                            </div>
                          </div>
                          <div className={`${styles.subsection} ${styles.subsectionNormalPadding}`}>
                            <div className={styles.subsectionTitle}>
                              {texts('article:tags_wiki_label')}
                            </div>
                            <div className={styles.switchField}>
                              <CustomSwitch 
                                name={'set_wiki'}
                                checked={wiki}
                                setChecked={() => {
                                  setWiki(!wiki)
                                  formikProps.setFieldValue('tags', [])
                                  formikProps.setFieldValue('isWikiArticle', !wiki)
                                }}
                                formikProps={formikProps}
                              />
                              <div className={styles.switchLabel}>
                                {texts('article:set_wiki_label')}
                              </div>
                              <div className={styles.showDialogCheckboxAdditionalLabel} style={{ fontWeight: 'bold' }}>
                                {texts('article:set_wiki_additional_label')}
                              </div>
                            </div>
                            <div className={styles.field}>
                              <div className={styles.fieldLabel}>
                                {texts(wiki ? 'article:choose_tags_label' : 'article:choose_optional_tags_label')}
                              </div>
                              <CustomAsyncCreatableSelectField
                                instanceId={'tags-field'}
                                name={'tags'}
                                placeholder={texts('article:choose_tags_placeholder')}
                                isMulti={!wiki}
                                hideSelectedOption
                                value={formikProps.values.tags}
                                options={tagList}
                                formikProps={formikProps}
                                loadOptions={loadTagOptions}
                                onChange={(name, val) => {
                                  if (wiki && val?.wikiArticleUuid) {
                                    setWikiTagSelected([val])
                                    return openReassignWikiDialog()
                                  }
                                  formikProps?.setFieldValue(name, wiki ? [val] : val)
                                }}
                                error={
                                  formikProps.touched.tags &&
                                  wiki &&
                                  Boolean(formikProps.errors.tags)
                                }
                              />
                              <div className={styles.tagsWarning}>
                                {texts('article:choose_tags_warning')}
                              </div>
                            </div>
                          </div>
                          <div className={`${styles.subsection} ${styles.subsectionNormalPadding}`}>
                            <div className={styles.subsectionTitle}>
                              {texts('article:additional_parameters_label')}
                            </div>
                            <div className={styles.switchField}>
                              <div>
                                <CustomSwitch 
                                  name={'adultContent'}
                                  checked={adultContent}
                                  setChecked={setAdultContent}
                                  formikProps={formikProps}
                                />
                              </div>
                              <div className={styles.switchLabel} style={{ color: 'red' }}>
                                {texts('common:adult_content_label')}
                              </div>
                              <div className={styles.showDialogCheckboxAdditionalLabel}>
                                {texts('common:adult_content_additional_label')}
                              </div>
                            </div>
                            <div className={styles.switchField}>
                              <div>
                                <CustomSwitch 
                                  name={'showAdultWarning'}
                                  checked={showAdultWarning}
                                  setChecked={setShowAdultWarning}
                                  formikProps={formikProps}
                                />
                              </div>
                              <div className={styles.switchLabel} style={{ color: 'red' }}>
                                {texts('common:show_adult_warning_label')}
                              </div>
                              <div className={styles.showDialogCheckboxAdditionalLabel}>
                                {texts('common:show_adult_warning_additional_label')}
                              </div>
                            </div>
                            <div className={styles.adultContentWarning}>
                              {texts('common:adult_content_warning')}
                            </div>
                            <div className={`${styles.switchFieldWithInput} ${styles.lastSwitch}`}>
                              <div className={styles.switchFieldForInput}>
                                <div>
                                  <CustomSwitch 
                                    name={'sponsoredContent'}
                                    checked={sponsoredContent}
                                    setChecked={setSponsoredContent}
                                    formikProps={formikProps}
                                  />
                                </div>
                                <div className={styles.switchLabel}>
                                  {texts('article:sponsored_content_label')}
                                </div>
                              </div>
                              <div
                                className={styles.fieldForSwitch}
                                style={{
                                  opacity: sponsoredContent ? 1 : 0.5
                                }}
                              >
                                <BaseInput
                                  disabled={!sponsoredContent}
                                  label={texts('article:sponsor_label')}
                                  name={'sponsor'}
                                  formikProps={formikProps}
                                  value={formikProps.values.sponsor}
                                  classes={{
                                    containerWithLabel: styles.inputContainerWithLabel,
                                    containerWithoutLabel: styles.inputContainerWithoutLabel,
                                    fieldBase: styles.textFieldBase
                                  }}
                                  maxLen={80}
                                  count={true}
                                  error={
                                    formikProps.touched.sponsor && Boolean(formikProps.errors.sponsor)
                                  }
                                />
                              </div>
                            </div>
                            <div className={styles.field}>
                              <div className={styles.fieldLabel}>
                                {texts('article:article_props_label')}
                              </div>
                              <CustomSelectField
                                instanceId={'additional-params-field'}
                                name="articleProps"
                                placeholder={texts('article:article_props_placeholder')}
                                value={formikProps.values.articleProps}
                                isMulti
                                options={articlePropList}
                                formikProps={formikProps}
                                error={
                                  formikProps.touched.articleProps && Boolean(formikProps.errors.articleProps)
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className={styles.formColumn}>
                          <div className={`${styles.subsection} ${styles.subsectionBiggerBottomPadding}`}>
                            <div className={styles.field}>
                              <BaseInput
                                label={texts('article:seo_title_label')}
                                name={'seoTitle'}
                                formikProps={formikProps}
                                classes={{
                                  containerWithLabel: styles.inputContainerWithLabel,
                                  containerWithoutLabel: styles.inputContainerWithoutLabel,
                                  fieldBase: styles.textFieldBase
                                }}
                                value={formikProps.values.seoTitle}
                                maxLen={80}
                                count={true}
                                error={
                                  formikProps.touched.seoTitle && Boolean(formikProps.errors.seoTitle)
                                }
                              />
                            </div>
                            <div className={styles.field}>
                              <BaseInput
                                label={texts('article:seo_description_label')}
                                name={'seoDescription'}
                                rows={4}
                                formikProps={formikProps}
                                classes={{
                                  containerWithLabel: styles.inputContainerWithLabel,
                                  containerWithoutLabel: styles.inputContainerWithoutLabel,
                                  fieldBase: styles.textFieldBase
                                }}
                                value={formikProps.values.seoDescription}
                                maxLen={160}
                                count={true}
                                error={
                                  formikProps.touched.seoDescription && Boolean(formikProps.errors.seoDescription)
                                }
                              />
                            </div>
                          </div>

                          <div className={`${styles.subsection} ${styles.subsectionBiggerBottomPadding}`}>
                            <div className={styles.field}>
                              <BaseInput
                                label={texts('article:og_description_label')}
                                name={'ogDescription'}
                                rows={4}
                                formikProps={formikProps}
                                classes={{
                                  containerWithLabel: styles.inputContainerWithLabel,
                                  containerWithoutLabel: styles.inputContainerWithoutLabel,
                                  fieldBase: styles.textFieldBase
                                }}
                                value={formikProps.values.ogDescription}
                                maxLen={200}
                                count={true}
                                error={
                                  formikProps.touched.ogDescription && Boolean(formikProps.errors.ogDescription)
                                }
                              />
                            </div>
                          </div>

                          <div className={`${styles.subsection} ${styles.subsectionBiggerBottomPadding}`}>
                            <div className={styles.field}>
                              <BaseInput
                                label={texts('article:twitter_description_label')}
                                name={'twitterCardDescription'}
                                rows={4}
                                formikProps={formikProps}
                                classes={{
                                  containerWithLabel: styles.inputContainerWithLabel,
                                  containerWithoutLabel: styles.inputContainerWithoutLabel,
                                  fieldBase: styles.textFieldBase
                                }}
                                value={formikProps.values.twitterCardDescription}
                                maxLen={200}
                                count={true}
                                error={
                                  formikProps.touched.twitterCardDescription && Boolean(formikProps.errors.twitterCardDescription)
                                }
                              />
                            </div>
                          </div>

                          <div className={`${styles.subsection} ${styles.subsectionBiggerBottomPadding}`}>
                            <div className={styles.field}>
                              <BaseInput
                                label={texts('article:webPush_title_label')}
                                name={'webPushTitle'}
                                rows={4}
                                formikProps={formikProps}
                                classes={{
                                  containerWithLabel: styles.inputContainerWithLabel,
                                  containerWithoutLabel: styles.inputContainerWithoutLabel,
                                  fieldBase: styles.textFieldBase
                                }}
                                value={formikProps.values.webPushTitle}
                                maxLen={200}
                                count={true}
                                error={
                                  formikProps.touched.webPushTitle && Boolean(formikProps.errors.webPushTitle)
                                }
                              />
                            </div>
                            <div className={styles.field} style={{ marginTop: '1.25rem' }}>
                              <BaseInput
                                label={texts('article:webPush_description_label')}
                                name={'webPushDescription'}
                                rows={4}
                                formikProps={formikProps}
                                classes={{
                                  containerWithLabel: styles.inputContainerWithLabel,
                                  containerWithoutLabel: styles.inputContainerWithoutLabel,
                                  fieldBase: styles.textFieldBase
                                }}
                                value={formikProps.values.webPushDescription}
                                maxLen={200}
                                count={true}
                                error={
                                  formikProps.touched.webPushDescription && Boolean(formikProps.errors.webPushDescription)
                                }
                              />
                            </div>
                          </div>
                          <div className={`${styles.subsection} ${styles.subsectionBiggerBottomPadding}`}>
                            <div className={styles.field}>
                              <BaseInput
                                label={texts('article:additional_body_script_label')}
                                name={'additionalBodyScript'}
                                warning={!!formikProps.values.additionalBodyScript}
                                warningLabel={texts('article:additional_body_script_warning')}
                                rows={9}
                                formikProps={formikProps}
                                classes={{
                                  containerWithLabel: styles.inputContainerWithLabel,
                                  containerWithoutLabel: styles.inputContainerWithoutLabel,
                                  fieldBase: styles.textFieldBase
                                }}
                                value={formikProps.values.additionalBodyScript}
                                count={true}
                                error={
                                  formikProps.touched.additionalBodyScript &&
                                  Boolean(formikProps.errors.additionalBodyScript)
                                }
                              />
                            </div>
                          </div>
                          <div className={`${styles.subsection} ${styles.subsectionNormalPadding}`}>
                            <div className={styles.field}>
                              <BaseInput
                                label={texts('article:author_label')}
                                name={'author'}
                                disabled={true}
                                formikProps={formikProps}
                                value={formikProps.values.author}
                                classes={{
                                  containerWithLabel: styles.inputContainerWithLabel,
                                  containerWithoutLabel: styles.inputContainerWithoutLabel,
                                  fieldBase: styles.textFieldBase
                                }}
                              />
                            </div>
                            <div className={styles.hideAuthorField}>
                              <CustomCheckbox
                                name={'hideAuthor'}
                                label={texts('article:hide_author_label')}
                                formikProps={formikProps}
                                onChange={(checked: boolean) => {
                                  if (!checked) {
                                    formikProps.setFieldValue('hideAuthor', false)
                                  }
                                }}
                              />
                            </div>
                            <div className={styles.dualFieldRow}>
                              <div className={`${styles.field} ${styles.dualFieldRowField}`}>
                                <div className={styles.fieldLabelWithResetContainer}>
                                  <div className={styles.fieldLabel}>
                                    {texts('common:publish_date_label')}
                                  </div>
                                  <div
                                    className={styles.fieldResetButton}
                                    onClick={() => {
                                      formikProps.setFieldValue('publishDate', undefined)
                                    }}
                                  >
                                    {texts('common:publish_date_reset_button')}
                                  </div>
                                </div>
                                <CustomFlatpickr
                                  name={'publishDate'}
                                  formikProps={formikProps}
                                  placeholder={texts('common:publish_date_placeholder')}
                                  setInitial
                                  withTime
                                />
                                <div className={styles.errorLabel}>
                                {
                                  formikProps.errors.publishDate ?? <ErrorMessage name={'publishDate'} />
                                }
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <div className={`${styles.subsection} ${styles.subsectionNormalPadding}`}>
                            <div className={styles.subsectionTitle} style={{ marginBottom: "1rem" }}>
                              {texts('article:redirect_title')}
                            </div>
                            <div className={styles.field}>
                              <BaseInput
                                label={texts('article:redirect_label')}
                                name={'redirect'}
                                disabled={true}
                                formikProps={formikProps}
                                value={formikProps.values.redirect}
                                classes={{
                                  containerWithLabel: styles.inputContainerWithLabel,
                                  containerWithoutLabel: styles.inputContainerWithoutLabel,
                                  fieldBase: styles.textFieldBase
                                }}
                              />
                            </div>
                          </div> */}
                        </div>
                      </div>
                      <div className={`${styles.subsection} ${styles.subsectionNormalPadding}`}>
                        <div className={styles.connectedElementsSectionTitle}>
                          {/* Połącz z galerią lub quizem */}
                          {texts('article:connect_with_gallery_button')}
                        </div>
                        {
                          connectedGallery == null 
                          ? <div>
                              <button
                                type={'button'}
                                className={`${styles.controlButton} ${styles.controlButtonMultiContent}`}
                                onClick={openGalleryLibraryDialog}
                              >
                                <MdImage size={20} style={{ marginRight: '0.375rem' }}/> {texts('article:insert_gallery_button')}
                              </button>
                            </div>
                          : null
                        }
                        {
                          connectedGallery != null
                          ? <div className={styles.connectedElementsSectionGallerySubsection}>
                              <div className={styles.connectedElementsSectionGallerySubsectionTitle}>
                                {texts('article:connected_gallery_label')}
                              </div>
                              <ConnectedGalleryTile
                                gallery={connectedGallery}
                                onDisconnect={() => {
                                  setConnectedGallery(null)
                                  formikProps.setFieldValue('gallery', undefined)
                                  formikProps.setFieldValue('galleryUuid', undefined)
                                }}
                                beforeEdit={async () => {
                                  setIsNavigationGuarded(false)
                                  return {
                                    push: {
                                      pageType: GalleryPageType.FROM_ADD_ARTICLE,
                                      articleData: {
                                        initialValues: formRef?.current?.values,
                                        mainImage: newMainImgSourceData,
                                        active: true,
                                        showGalleryLibrary: false,
                                        articleListData: location.state.articleData?.articleListData
                                      }
                                    },
                                    replace: {
                                      url: `/article/add`,
                                      state: {
                                        galleryData: {
                                          uuid:formRef?.current?.values?.galleryUuid
                                        },
                                        articleData: {
                                          initialValues: formRef?.current?.values,
                                          showGalleryLibrary: false,
                                          mainImage: newMainImgSourceData,
                                          active: true
                                        }
                                      }
                                    }
                                  }
                                }}
                              />
                              <div className={styles.connectedElementsSectionGallerySubsectionWarning}>
                                {texts('article:connect_with_gallery_or_quiz_warning')}
                              </div>
                            </div>
                          : null
                        }
                      </div>
                      <div className={styles.formEditorSection}>
                        <BBCodeEditor 
                          name={'content'}
                          gallery={connectedGallery}
                          formikProps={formikProps}
                          mainImageData={newMainImgSourceData}
                          previewComponent={getArticlePreviewComponent()({ 
                            contentFieldName: 'content',
                            formikProps: formikProps,
                            mainImageData: newMainImgSourceData,
                            gallery: connectedGallery,
                            publishedAt: moment().toDate()
                          })}
                        />
                      </div>
                      <div className={`${styles.subsection} ${styles.subsectionNormalPadding} ${styles.suggestedWikiArticlesSection}`}>
                        <SuggestedWikiArticlesTile
                          searchFunction={() => {getSuggestedWikiArticles(formikProps.values.content)}}
                          suggestedWikiList={{...suggestedWikiList, data: suggestedWikiList.data?.filter((tag: SuggestedWikiDto) => tag.uuid !== formikProps.values.tags[0]?.value)}}
                          showList={showSuggestedWikiList}
                          wikiTagSelected={formikProps.values.isWikiArticle ? formikProps.values.tags[0] : undefined}
                        />
                      </div>
                      <p className={styles.suggestedWikiArticlesWarning}>
                        {texts('article:suggested_wiki_articles_warning')}
                      </p>
                      <div className={styles.formButtonSection}>
                        <LoadingButton
                          className={styles.backButton}
                          onClick={goBack}
                          type={"button"}
                        > 
                          {texts('common:go_back_button_label')}
                        </LoadingButton>
                        <div className={styles.flexSpacer}></div>
                        <LoadingButton 
                          loading={isSubmitting}
                          className={styles.submitDraftButton}
                          onClick={() => {
                            formikProps.setFieldValue('draft', true)
                            setIsDraft(true)
                            if (formikProps.values.draft) {
                              formikProps.submitForm()
                            }
                          }}
                        >
                          <div className={styles.buttonContent}>{texts('common:save_draft_button')}</div>
                        </LoadingButton>
                        <LoadingButton 
                          className={styles.submitFormButton}
                          loading={isSubmitting}
                          onClick={() => {
                            formikProps.setFieldValue('draft', false)
                            setIsDraft(false)
                            if (!formikProps.values.draft) {
                              formikProps.submitForm()
                            }
                          }}
                        >
                          <div className={styles.buttonContent}>{texts('article:publish_button')}</div>
                        </LoadingButton>
                      </div>
                      
                    </Form>
                  </>
                )}
              </Formik>
            </div>
          </>
      }
    </>
  )
}

export default AddArticlePage
