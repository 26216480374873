import React from 'react'
import BBCodeParser from './parser/parser'
import parse from 'html-react-parser'
import InnerHTML from 'dangerously-set-html-content'

type Props = {
  bbcode: string
}

const BBCodeArticle = (props: Props) => {
  return (
    <>
      <InnerHTML 
        html={
          `<script>
            function resizeIframeHeight(iframe) {
              iframe.height = (iframe.contentWindow.document.body.scrollHeight + 30) + "px";
            }
          </script>`
        } 
      />
      {BBCodeParser.toReact(props.bbcode ?? '')}
    </>
  )
}

export default BBCodeArticle
