import React from 'react'
import { useTranslation } from 'react-i18next'
import CustomDialog from '../CustomDialog/CustomDialog'
import GalleryLibrary from '../GalleryLibrary/GalleryLibrary'
import styles from './styles.module.scss'

type Props = {
  id?: string
  hidden: boolean
  onCloseClick: () => void
  onFinish: ((selectedGallery: any) => void) | ((selectedGallery: any) => Promise<void>)
  beforeEdit?: (() => { [key: string]: any }) | (() => Promise<{ [key: string]: any }>)
  beforeAdd?: (() => { [key: string]: any }) | (() => Promise<{ [key: string]: any }>)
  initialData?: any
}

const GalleryLibraryDialog = (props: Props) => {
  const texts = useTranslation().t

  return (
    <CustomDialog
      hidden={props.hidden}
      classes={{
        container: styles.container
      }}
      closeButton={true}
      onCloseButtonClick={() => {
        props.onCloseClick()
      }}
      title={texts('galleryLibrary:title')}
    >
      <GalleryLibrary
        baseZIndex={101}
        id={props.id}
        reload={!props.hidden}
        initialData={props.initialData}
        // warnings={{
        //   add: texts('galleryLibrary:add_warning_label'),
        //   edit: texts('galleryLibrary:edit_warning_label')
        // }}
        onFinish={async (selectedGallery) => {
          props.onCloseClick()
          await props.onFinish(selectedGallery)
        }}
        beforeAdd={props.beforeAdd}
        beforeEdit={props.beforeEdit}
      />
    </CustomDialog>
  )
}

export default GalleryLibraryDialog
