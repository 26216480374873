import React from 'react'
import { FormikProps } from 'formik'
import styles from './styles.module.scss'
import { preventEnterKey } from '../../../utils/form'

type Props = {
  name: string
  rows?: number
  id?: string
  placeholder?: string
  className?: string
  errorClassName?: string
  errorLabelClassName?: string
  countClassName?: string
  type?: string
  error?: boolean
  warning?: boolean
  count?: boolean
  maxLen?: number
  byteLen?: boolean
  errorLabel?: JSX.Element
  warningLabel?: string
  disabled?: boolean
  value?: string
  innerRef?: any
  resize?: any
  setValue?: React.Dispatch<React.SetStateAction<string>>
  formikProps?: FormikProps<any>
  inputPattern?: RegExp
  customStyle?: React.CSSProperties
}

const CustomInputField = (props: Props) => {
  const [value, setValue] = React.useState<string>(props.formikProps?.values[props.name] ?? '')
  const [curLen, setCurLen] = React.useState<number>(props.formikProps?.values[props.name]?.length ??0)

  React.useEffect(() => {
    if (props.value != null) {
      setCurLen(props.value.length)
    }
  }, [props.value])

  return (
    <>
      {
        props.rows != null
        ? <textarea
            ref={props.innerRef}
            disabled={props.disabled}
            rows={props.rows}
            {...props}
            style={{
              resize: props.resize ?? 'none',
              ...(props.customStyle ?? {})
            }}
            className={`${(props.className || `${styles.input} ${props.error ? styles.inputError : styles.inputNormal }`)}`}
            value={props.value != null ? props.value : value}
            {...(props.byteLen ? {} : { maxLength: props.maxLen })}
            onChange={(ev) => {
              if (props.inputPattern && !props.inputPattern.test(ev.target.value)) {
                return
              }
              const len = props.byteLen ? Buffer.byteLength(ev.target.value, 'utf8') : ev.target.value?.length
              if (props.byteLen && props.maxLen && len > props.maxLen) {
                return
              }
              if (props.count) {
                setCurLen(len || 0)
              } 
              props.setValue ? props.setValue(ev.target.value) : setValue(ev.target.value)
              props.formikProps?.setFieldValue(
                props.name,
                ev.target.value
              )
            }}
          />
        : <input
            ref={props.innerRef}
            {...props}
            style={{
              ...(props.customStyle ?? {})
            }}
            onKeyDown={preventEnterKey}
            disabled={props.disabled}
            className={`${(props.className || `${styles.input} ${props.error ? styles.inputError : styles.inputNormal }`)}`}
            value={props.value != null ? props.value : value}
            {...(props.byteLen ? {} : { maxLength: props.maxLen })}
            onChange={(ev) => {
              if (props.inputPattern && !props.inputPattern.test(ev.target.value)) {
                return
              }
              const len = props.byteLen ? Buffer.byteLength(ev.target.value, 'utf8') : ev.target.value?.length
              if (props.byteLen && props.maxLen && len > props.maxLen) {
                return
              }
              if (props.count) {
                setCurLen(len || 0)
              } 
              props.setValue ? props.setValue(ev.target.value) : setValue(ev.target.value)
              props.formikProps?.setFieldValue(
                props.name,
                ev.target.value
              )
            }}
          />
      }
      {
        props.error && props.errorLabel ? <div className={props.errorLabelClassName || styles.errorLabel} style={{
          float: 'left'
        }}>
          {props.errorLabel}
        </div> : null
      }
      {
        !props.error && props.warning && props.warningLabel ? <div className={styles.warningLabel} style={{
          float: 'left'
        }}>
          {props.warningLabel}
        </div> : null
      }
      {
        props.count && props.maxLen ? <div className={props.countClassName || styles.countLabel} style={{
          float: 'right'
        }}>
          {`${curLen}/${props.maxLen}`}
        </div> : null
      }
    </>
  )
}

export default CustomInputField
