import { ErrorMessage, Form, Formik} from 'formik'
import moment from 'moment'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { IoMdTrash } from 'react-icons/io'
import { IoAddCircle } from 'react-icons/io5'
import { MdImage } from 'react-icons/md'
import { useSelector } from 'react-redux'
import { useHistory, useLocation, useParams} from 'react-router-dom'
import MoonLoader from 'react-spinners/MoonLoader'
import DataApi from '../../api/DataApi'
import { addCmdMessage, addSuccessMessage } from '../../redux/messages/actions'
import { State } from '../../redux/reducers'
import store from '../../store'
import { GalleryPageType, Project, MAX_TAG_SEARCH } from '../../types/common'
import { FRONT_BASE_URL } from '../../utils/common'
import { useConfirmLeaveHook } from '../../utils/hooks'
import { onErrorResponseShowMessage } from '../../utils/onErrorReponseShowMessage/onErrorResponseShowMessage'
import { editArticleValidation } from '../../utils/validations/editArticleValidation'
import BaseInput from '../common/BaseInput/BaseInput'
import BBCodeDepeszaArticlePreview from '../common/BBCodeDepeszaArticlePreview/BBCodeDepeszaArticlePreview'
import BBCodeEditor from '../common/BBCodeEditor/BBCodeEditor'
import BBCodeZaradnaArticlePreview from '../common/BBCodeZaradnaArticlePreview/BBCodeZaradnaArticlePreview'
import ConnectedGalleryTile from '../common/ConnectedGalleryTile/ConnectedGalleryTile'
import CropperDialog from '../common/CropperDialog/CropperDialog'
import CustomAsyncCreatableSelectField from '../common/CustomAsyncCreatableSelectField/CustomAsyncCreatableSelectField'
import CustomCheckbox from '../common/CustomCheckbox/CustomCheckbox'
import CustomDialog from '../common/CustomDialog/CustomDialog'
import CustomFlatpickr from '../common/CustomFlatpickr/CustomFlatpickr'
import CustomSelectField from '../common/CustomSelectField/CustomSelectField'
import CustomSwitch from '../common/CustomSwitch/CustomSwitch'
import { GalleryLibraryStateData } from '../common/GalleryLibrary/GalleryLibrary'
import GalleryLibraryDialog from '../common/GalleryLibraryDialog/GalleryLibraryDialog'
import HtmlValidationErrorDialog from '../common/HtmlValidatorDialog/HtmlValidatorDialog'
import LoadingButton from '../common/LoadingButton/LoadingButton'
import { SinglePhoto } from '../common/MediaLibrary/types'
import MediaLibraryDialog from '../common/MediaLibraryDialog/MediaLibraryDialog'
import RouteLeavingGuard from '../common/RouteLeavingGuard/RouteLeavingGuard'
import { MessageEnum } from '../CustomToastProvider/types'
import styles from './styles.module.scss'
import SuggestedWikiArticlesTile from '../common/SuggestedWikiArticlesTile/SuggestedWikiArticlesTile'
import { Resources, SuggestedWikiDto, LoadingState, TagDto, CategoryDto, ArticlePropsDto, Editor } from '../../types/data'
import ReassignWikiDialogContent from '../common/ReassignWikiDialogContent/ReassignWikiDialogContent'

type LocationState = {
  galleryData?: {
    uuid?: string
  }  
  articleData?: {
    initialValues?: InitialFormValuesDto
    slug?: string
    mainImage?: {
      img: SinglePhoto
      croppedImage?: Blob
    }
    active?: boolean
    showGalleryLibrary?: boolean
    originalDetails?: any
    articleListData?: {
      selectedPage: number
      searchFormInitialValues: any
    }
  }
  galleryLibraryData?: GalleryLibraryStateData
}

export type InitialFormValuesDto = {
  title: string
  leadText: string
  seoTitle: string
  seoDescription: string
  ogDescription: string
  twitterCardDescription: string
  webPushTitle: string
  webPushDescription: string
  hideAuthor: boolean
  author: string
  authorObj?: Editor | null
  draft: boolean
  category?: CategoryDto | null
  tags: TagDto[]
  articleProps: ArticlePropsDto[]
  adultContent: boolean
  sponsoredContent: boolean
  sponsor: string
  content: string
  image?: any | null
  sourceImageUuid: string
  slug: string
  source: string
  showAdultWarning: boolean
  removeMainImage: boolean
  publishDate?: string | Date | null
  modificationDate?: string | Date | null
  showModificationDate: boolean
  showModificationHour: boolean
  lastEditor: string
  lastEditorObj?: Editor | null
  publishDateReset: boolean
  modificationDateReset: boolean
  gallery?: any
  galleryUuid?: string
  additionalBodyScript: string
  // redirect: string
  isWikiArticle: boolean
}

const defaultInitValues: InitialFormValuesDto = {
  title: '',
  leadText: '',
  seoTitle: '',
  seoDescription: '',
  hideAuthor: false,
  author: '',
  authorObj: null,
  draft: false,
  tags: [],
  articleProps: [],
  adultContent: false,
  sponsoredContent: false,
  sponsor: '',
  content: '',
  image: null,
  sourceImageUuid: '',
  slug: '',
  source: '',
  showAdultWarning: false,
  removeMainImage: false,
  showModificationDate: false,
  showModificationHour: false,
  lastEditor: '',
  lastEditorObj: null,
  publishDateReset: false,
  modificationDateReset: false,
  ogDescription: '',
  twitterCardDescription: '',
  webPushTitle: '',
  webPushDescription: '',
  additionalBodyScript: '',
  // redirect: '',
  isWikiArticle: false,
  category: null
}

const EditArticlePage = () => {

  const texts = useTranslation().t
  const history = useHistory()
  const location = useLocation<LocationState>()

  const { uuid } = useParams<{ uuid: string }>()
  const userName = useSelector((e: State) => e.user.name)
  const userLastName = useSelector((e: State) => e.user.lastName)
  const userUuid = useSelector((e: State) => e.user.uuid)

  const [details, setDetails] = React.useState<any>(null)

  const [adultContent, setAdultContent] = React.useState<boolean>(false)
  const [showAdultWarning, setShowAdultWarning] = React.useState<boolean>(false)
  const [sponsoredContent, setSponsoredContent] = React.useState<boolean>(false)
  const [showMediaLibraryDialog, setShowMediaLibraryDialog] = React.useState<boolean>(false)
  const [showCropperDialog, setShowCropperDialog] = React.useState<boolean>(false)
  const [showDeactivateDialog, setShowDeactivateDialog] = React.useState<boolean>(false)
  const [showActivateDialog, setShowActivateDialog] = React.useState<boolean>(false)
  const [showHtmlValidationErrorDialog, setShowHtmlValidationErrorDialog] = React.useState<boolean>(false)
  const [wiki, setWiki] = React.useState<boolean>(false)
  const [showReassignWikiDialog, setShowReassignWikiDialog] = React.useState<boolean>(false)
  const [wikiTagSelected, setWikiTagSelected] = React.useState<TagDto[]>([])
  
  const [htmlValidationErrors, setHtmlValidationErrors] = React.useState<string[]>([])
  const [urlToOpen, setUrlToOpen] = React.useState<string>('')

  const [imageToCrop, setImageToCrop] = React.useState<SinglePhoto | null>(null)
  const [mainImgSourceData, setMainImgSourceData] = React.useState<{
    img: SinglePhoto
    croppedImage?: Blob
  } | null> (null)

  const [formInitialValues, setFormInitialValues] = React.useState<InitialFormValuesDto>(defaultInitValues)

  const [showGalleryLibrary, setShowGalleryLibrary] = React.useState<boolean>(false)
  const [galleryLibraryInitialData, setGalleryLibraryInitialData] = React.useState<GalleryLibraryStateData | null| undefined>(null)

  const [connectedGallery, setConnectedGallery] = React.useState<any>(null)

  const [categoryList, setCategoryList] = React.useState<CategoryDto[]>([])
  const [tagList, setTagList] = React.useState<TagDto[]>([])
  const [articlePropList, setArticlePropList] = React.useState<ArticlePropsDto[]>([])
  const [suggestedWikiList, setSuggestedWikiList] = React.useState<Resources<SuggestedWikiDto[]>>({state: LoadingState.INIT})
  const [showSuggestedWikiList, setShowSuggestedWikiList] = React.useState<boolean>(false)

  const [isActive, setIsActive] = React.useState<boolean>(true)
  const [isDraft, setIsDraft] = React.useState<boolean>(false)
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [isEditActive, setIsEditActive] = React.useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false)
  const [isNavigationGuarded, setIsNavigationGuarded] = React.useState<boolean>(true)

  const formRef = useRef() as any

  const openGalleryLibraryDialog = () => {
    store.dispatch(addCmdMessage(MessageEnum.ENABLE_PAGE_COVER))
    window.scrollTo(0, 0)
    setShowGalleryLibrary(true)
  }

  const closeGalleryLibraryDialog = () => {
    store.dispatch(addCmdMessage(MessageEnum.DISABLE_PAGE_COVER))
    setShowGalleryLibrary(false)
    setGalleryLibraryInitialData(null)
  }

  const getMainImgUrl = () => {
    if (mainImgSourceData == null) {
      return ''
    } else if (mainImgSourceData.croppedImage != null) {
      return URL.createObjectURL(mainImgSourceData.croppedImage)
    } else {
      return `${mainImgSourceData.img.hostname}${mainImgSourceData.img.path}${mainImgSourceData.img.filename}`
    }
  }

  const openMediaLibraryDialog = () => {
    store.dispatch(addCmdMessage(MessageEnum.ENABLE_PAGE_COVER))
    setShowMediaLibraryDialog(true)
  }

  const closeMediaLibraryDialog = () => {
    store.dispatch(addCmdMessage(MessageEnum.DISABLE_PAGE_COVER))
    setShowMediaLibraryDialog(false)
  }

  const openCropperDialog = () => {
    store.dispatch(addCmdMessage(MessageEnum.ENABLE_PAGE_COVER))
    setShowCropperDialog(true)
  }

  const closeCropperDialog = () => {
    store.dispatch(addCmdMessage(MessageEnum.DISABLE_PAGE_COVER))
    setShowCropperDialog(false)
  }

  const openDeactivateDialog = () => {
    store.dispatch(addCmdMessage(MessageEnum.ENABLE_PAGE_COVER))
    setShowDeactivateDialog(true)
  }

  const closeDeactivateDialog = () => {
    store.dispatch(addCmdMessage(MessageEnum.DISABLE_PAGE_COVER))
    setShowDeactivateDialog(false)
  }

  const openActivateDialog = () => {
    store.dispatch(addCmdMessage(MessageEnum.ENABLE_PAGE_COVER))
    setShowActivateDialog(true)
  }

  const closeActivateDialog = () => {
    store.dispatch(addCmdMessage(MessageEnum.DISABLE_PAGE_COVER))
    setShowActivateDialog(false)
  }

  const openHtmlValidationErrorDialog = () => {
    store.dispatch(addCmdMessage(MessageEnum.ENABLE_PAGE_COVER))
    setShowHtmlValidationErrorDialog(true)
  }

  const closeHtmlValidationErrorDialog = () => {
    store.dispatch(addCmdMessage(MessageEnum.DISABLE_PAGE_COVER))
    setShowHtmlValidationErrorDialog(false)
  }

  const closeReassignWikiDialog = () => {
    store.dispatch(addCmdMessage(MessageEnum.DISABLE_PAGE_COVER))
    setShowReassignWikiDialog(false)
  }

  const openReassignWikiDialog = async () => {
    store.dispatch(addCmdMessage(MessageEnum.ENABLE_PAGE_COVER))
    setShowReassignWikiDialog(true)
  }

  const handleDeactivateArticle = async () => {
    try {
      await DataApi.changeArticleActiveState(uuid, false)
      const res = await DataApi.getArticleDetails(uuid)
      setIsDraft(res.draft)
      setDetails(res)
      closeDeactivateDialog()
      store.dispatch(addSuccessMessage(MessageEnum.ARTICLE_DEACTIVATED))
    } catch (error) {
      setIsNavigationGuarded(true)
      onErrorResponseShowMessage(error, true, { userUuid: userUuid })
    }
  }

  const handleActivateArticle = async () => {
    try {
      await DataApi.changeArticleActiveState(uuid, true)
      const res = await DataApi.getArticleDetails(uuid)
      setIsDraft(res.draft)
      setDetails(res)
      closeActivateDialog()
      store.dispatch(addSuccessMessage(MessageEnum.ARTICLE_ACTIVATED))
    } catch (error) {
      setIsNavigationGuarded(true)
      onErrorResponseShowMessage(error, true, { userUuid: userUuid })
    }
  }

  const deactivateDialogContent = () => {
    return (
      <>
        <div className={styles.deactivateDialogText}>
          {texts('article:deactivate_article_prompt')}
        </div>
        <div className={styles.deactivateDialogButtonsSection}>
          <LoadingButton 
            text={texts('dashboard:confirm_logout_dialog_cancel_button_label')}
            className={styles.deactivateCancelButton}
            onClick={() => {
              closeDeactivateDialog()
            }}
          />
          <LoadingButton 
            text={texts('article:confirmation_deactivate_article_text')}
            className={styles.deactivateConfirmButton}
            onClick={() => {
              setIsNavigationGuarded(false)
              handleDeactivateArticle()
            }}
          /> 
        </div>
      </>
    )
  }

  const activateDialogContent = () => {
    return (
      <>
        <div className={styles.deactivateDialogText}>
          {texts('article:activate_article_prompt')}
        </div>
        <div className={styles.deactivateDialogButtonsSection}>
          <LoadingButton 
            text={texts('dashboard:confirm_logout_dialog_cancel_button_label')}
            className={styles.deactivateCancelButton}
            onClick={() => {
              closeActivateDialog()
            }}
          />
          <LoadingButton 
            text={texts('article:confirmation_activate_article_text')}
            className={styles.deactivateConfirmButton}
            onClick={() => {
              setIsNavigationGuarded(false)
              handleActivateArticle()
            }}
          /> 
        </div>
      </>
    )
  }

  const getSuggestedWikiArticles = async (text: string) => {
    try {
      setSuggestedWikiList({state: LoadingState.LOADING})
      const suggestedWikiList = await DataApi.getSuggestedWikiArticles(text)
      setShowSuggestedWikiList(true)
      setSuggestedWikiList({state: LoadingState.LOADED, data: suggestedWikiList})
    } catch (error) {
      setIsNavigationGuarded(true)
      onErrorResponseShowMessage(error, true, { userUuid: userUuid })
      setSuggestedWikiList({state: LoadingState.ERROR, error: error})
    }
  }

  const getGalleryData = async (uuid?: string) => {
    if (uuid) {
      const gallery = await DataApi.getGalleryTileDetails(uuid)
      setConnectedGallery(gallery)
      return
    }
  }

  const loadTagOptions = (inputValue: string): Promise<{label: string, value: string}[]>  => 
    new Promise((resolve, reject) => {
      DataApi.searchTags(inputValue, MAX_TAG_SEARCH, 0).then(res => {
        resolve(res.data.map((e: any) => {
          return {
            label: e.name,
            value: e.uuid,
            wikiArticleUuid: e.wikiArticleUuid
          }
        }))
      }).catch(error => {
        onErrorResponseShowMessage(error, true, { userUuid: userUuid })
        reject(error)
      })
    })

  React.useEffect(() => {
    DataApi.getCategoryList().then((res: any) => {
      setCategoryList(res.data.map((e: any) => {
        return {
          label: e.name,
          value: e.uuid
        }
      }))
      DataApi.searchTags('', MAX_TAG_SEARCH, 0).then((res: any) => {
        setTagList(res.data.map((e: any) => {
          return {
            label: e.name,
            value: e.uuid,
            wikiArticleUuid: e.wikiArticleUuid
          }
        }))
        DataApi.getArticlePropList().then((res: any) => {
          setArticlePropList(res.data.map((e: any) => {
            return {
              label: e.name,
              value: e.uuid
            }
          }))
          if (location.state?.articleData?.initialValues) {
            getGalleryData(location.state.articleData.initialValues.gallery?.uuid).then(res => {
              setIsDraft(location.state!.articleData!.initialValues!.draft ?? false)
              setIsEditActive(true)
              setDetails(location.state!.articleData!.originalDetails)
              setGalleryLibraryInitialData(location!.state!.articleData?.showGalleryLibrary ? location!.state!.galleryLibraryData : null)
              if (location.state?.articleData?.showGalleryLibrary) {
                openGalleryLibraryDialog()
              }
            }).catch(error => onErrorResponseShowMessage(error, true, { userUuid: userUuid }))
          } else {
            DataApi.getArticleDetails(uuid).then((articleRes: any) => {
              getGalleryData(articleRes.gallery?.uuid).then(res => {
                setIsDraft(articleRes.draft)
                setDetails(articleRes)
                if (location.state?.articleData?.showGalleryLibrary) {
                  openGalleryLibraryDialog()
                }
              }).catch(error => onErrorResponseShowMessage(error, true, { userUuid: userUuid }))
          }).catch(error => onErrorResponseShowMessage(error, true, { userUuid: userUuid }))
          }  
        }).catch(error => onErrorResponseShowMessage(error, true, { userUuid: userUuid }))
      }).catch(error => onErrorResponseShowMessage(error, true, { userUuid: userUuid }))
    }).catch(error => onErrorResponseShowMessage(error, true, { userUuid: userUuid }))
  }, [])
  
  React.useEffect(() => {
    if (!isLoading && !isSubmitting) {
      formRef?.current?.submitForm()
    }
  }, [isDraft])

  React.useEffect(() => {
    if (details != null) {
      store.dispatch(addCmdMessage(MessageEnum.DISABLE_PAGE_COVER))
      setInitialValues()
      setIsLoading(false)
    }
  }, [details])

  const setInitialValues = (resetForm?: boolean) => {
    if (!resetForm && location.state?.articleData?.initialValues) {
      setFormInitialValues(location.state.articleData.initialValues)
      setIsActive(location.state.articleData.active ?? false)
      setAdultContent(location.state.articleData.initialValues.adultContent ?? false)
      setShowAdultWarning(location.state.articleData.initialValues.showAdultWarning ?? false)
      setSponsoredContent(location.state.articleData.initialValues.sponsoredContent ?? false)
      setMainImgSourceData(location.state.articleData.mainImage ?? null)
      setWiki(location.state.articleData.initialValues.isWikiArticle ?? false)
    } else {
      const slugParts = details.slug?.split('-') ?? []
      setFormInitialValues({
        title: details.title ?? '',
        leadText: details.leadText ?? '',
        seoTitle: details.seoTitle ?? '',
        seoDescription: details.seoDescription ?? '',
        hideAuthor: details.hideAuthor,
        author: `${details.author.name} ${details.author.lastName}`,
        authorObj: details.author,
        draft: details.draft,
        category: details.category ? {
          label: details.category.name,
          value: details.category.uuid
        } : undefined,
        tags: details.tags ? details.tags.map((e: any) => {
          return {
            label: e.name,
            value: e.uuid
          }
        }) : [],
        articleProps: details.articleProps ? details.articleProps.map((e: any) => {
          return {
            label: e.name,
            value: e.uuid
          }
        }) : [],
        adultContent: details.adultContent ?? false,
        showAdultWarning: details.showAdultWarning ?? false,
        sponsoredContent: details.sponsoredContent ?? false,
        sponsor: details.sponsorSignature ?? '',
        content: details.text ?? '',
        image: null,
        source: details.source ?? '',
        sourceImageUuid: '',
        slug: slugParts.slice(0, slugParts.length - 1).join('-'),
        publishDate: undefined,
        modificationDate: details.modifiedAt ? moment(details.modifiedAt).toDate() : undefined,
        removeMainImage: false,
        // hideModificationDate: false,
        showModificationDate: !details.settings?.hideModificationDate ?? true,
        showModificationHour: details.settings?.showModificationHour ?? false,
        lastEditor: details.lastEditor ? `${details.lastEditor.name} ${details.lastEditor.lastName}` : '',
        lastEditorObj: details.lastEditor,
        publishDateReset: false,
        modificationDateReset: false,
        galleryUuid: details.gallery?.uuid ?? undefined,
        gallery: details.gallery,
        ogDescription: details.ogDescription ?? '',
        twitterCardDescription: details.twitterCardDescription ?? '',
        webPushTitle: details.webPushTitle ?? '',
        webPushDescription: details.webPushDescription ?? '',
        additionalBodyScript: details.additionalBodyScript ?? '',
        // redirect: details.redirect ?? '',
        isWikiArticle: details.isWikiArticle ?? false,
      })
      setWiki(details.isWikiArticle ?? false)
      setIsActive(details.active ?? false)
      setAdultContent(details.adultContent ?? false)
      setShowAdultWarning(details.showAdultWarning ?? false)
      setSponsoredContent(details.sponsoredContent ?? false)
      setMainImgSourceData(details.mainImage ? {
        img: details.mainImage,
        croppedImage: details.croppedImage
      } : null)
    }
    
    if (formRef?.current) {
      formRef.current.resetForm()
    }
  }

  const handleSubmit = async (data: any) => {
    try {
      setIsSubmitting(true)
      setShowSuggestedWikiList(false)
      const body = new FormData()
      if (data.galleryUuid) {
        body.set('galleryUuid', data.galleryUuid)
      }
      body.set('draft', data.draft)
      body.set('image', data.image)
      body.set('removeMainImage', data.removeMainImage)
      body.set('sourceImageUuid', data.sourceImageUuid ?? '')
      body.set('title', data.title !== '' ? data.title : '')
      body.set('leadText', data.leadText !== '' ? data.leadText : '')
      body.set('seoTitle', data.seoTitle !== '' ? data.seoTitle : '')
      body.set('seoDescription', data.seoDescription !== '' ? data.seoDescription : '')
      body.set('categoryUuid', data.category?.value ?? '')
      body.set('adultContent', data.adultContent !== '' ? data.adultContent : '')
      body.set('showAdultWarning', data.showAdultWarning)
      body.set('sponsoredContent', data.sponsoredContent !== '' ? data.sponsoredContent : '')
      body.set('sponsor', data.sponsor !== '' ? data.sponsor : '')
      body.set('text', data.content !== '' ? data.content : '')
      body.set('source', data.source !== '' ? data.source : '')
      body.set('slug', data.slug !== details.slug && data.slug !== '' ? data.slug : '')
      body.set('hideAuthor', data.hideAuthor)
      body.set('publishedAt', data.publishDate 
        ? moment(data.publishDate).format() 
        : (
            data.publishDateReset 
              ? '' 
              : (
                  details.publishedAt
                    ? moment(details.publishedAt).format()
                    : ''
                )
          )
      )
      body.set('resetPublishAt', data.publishDateReset)
      body.set('resetModifiedAt', data.modificationDateReset)
      body.set('hideModifiedAt', data.showModificationDate ? 'false' : 'true')
      body.set('showModificationHour', data.showModificationHour)
      body.set('ogDescription', data.ogDescription && data.ogDescription !== '' ? data.ogDescription : '')
      body.set('twitterCardDescription', data.twitterCardDescription && data.twitterCardDescription !== '' ? data.twitterCardDescription : '')
      body.set('webPushTitle', data.webPushTitle && data.webPushTitle !== '' ? data.webPushTitle : '')
      body.set('webPushDescription', data.webPushDescription && data.webPushDescription !== '' ? data.webPushDescription : '')
      body.set('additionalBodyScript', data.additionalBodyScript && data.additionalBodyScript !== '' ? data.additionalBodyScript : '')
      // body.set('redirect', data.redirect && data.redirect !== '' ? data.redirect : '')
      body.set('tags', JSON.stringify(data.tags.map((e: any) => {
        return {
          label: e.label,
          uuid: e.__isNew__ || e.label === e.value ? undefined : e.value,
          isNew: e.__isNew__ || e.label === e.value,
        }
      })))
      body.set('articleProps', JSON.stringify(data.articleProps.map((e: any) => {
        return {
          label: e.label,
          uuid: e.value,
        }
      })))
      body.set('isWikiArticle', String(data.isWikiArticle ?? false))
      const res = await DataApi.updateArticle(uuid, body)
      setIsEditActive(false)
      store.dispatch(addSuccessMessage(MessageEnum.ARTICLE_UPDATED))
      const newDetails = await DataApi.getArticleDetails(uuid)
      setDetails(newDetails)
      if (res.isHtmlValid != null && !res.isHtmlValid) {
        setHtmlValidationErrors(res.htmlErrorMessages ?? [])
        setUrlToOpen(`${FRONT_BASE_URL}/${newDetails.slug}`)
        openHtmlValidationErrorDialog()
      } else {
        if (
          !isDraft && (data.publishDateReset || 
          ((data.publishDate && moment(data.publishDate).isSameOrBefore(moment())) || moment(details.publishedAt).isSameOrBefore(moment())))
        ) {
          window.open(`${FRONT_BASE_URL}/${newDetails.slug}`, '_blank', 'noopener,noreferrer')
        }
      }
    } catch (error: any) {
      onErrorResponseShowMessage(error, true, { userUuid: userUuid })
    } finally {
      setIsSubmitting(false)
    }
  }

  const getArticlePreviewComponent = () => {
    switch (process.env.REACT_APP_PROJECT) {
      case Project.ZARADNA:
        return BBCodeZaradnaArticlePreview
      case Project.DEPESZA:
      default:
        return BBCodeDepeszaArticlePreview
    }
  }

  const goBack = () => {
    history.push(
      '/',
      {
        selectedArticleListPage: location.state?.articleData?.articleListData?.selectedPage,
        searchArticleFormInitialValues: location.state?.articleData?.articleListData?.searchFormInitialValues
      }
    )
  }

  useConfirmLeaveHook(isNavigationGuarded)

  return (
    <>
      <RouteLeavingGuard
        when={isEditActive && isNavigationGuarded}
        shouldBlockNavigation={location => {
          if (location.pathname === '/login') {
            return false
          }
          return isEditActive && isNavigationGuarded
        }}
      /> 
      <HtmlValidationErrorDialog 
        hidden={!showHtmlValidationErrorDialog}
        errorMessages={htmlValidationErrors}
        onCloseClick={() => {
          closeHtmlValidationErrorDialog()
          if (
            !isDraft && (formRef?.current?.values.publishDateReset || 
            ((formRef?.current?.values.publishDate && moment(formRef?.current?.values.publishDate).isSameOrBefore(moment()) || moment(details.publishedAt).isSameOrBefore(moment()))))
          ) {
            window.open(urlToOpen, '_blank', 'noopener,noreferrer')
          }
        }}
        onEditLabelClick={() => {
          closeHtmlValidationErrorDialog()
        }}
      />
      <MediaLibraryDialog
        id={'article-media-library-dialog'}
        name={'article-media-library-dialog'}
        hidden={!showMediaLibraryDialog}
        onCloseClick={() => {
          closeMediaLibraryDialog()
        }}
        onFinish={(img) => {
          setImageToCrop(img)
          openCropperDialog()
        }}
      />
      <CropperDialog
        hidden={!showCropperDialog}
        image={imageToCrop}
        onCloseClick={() => {
          closeCropperDialog()
        }}
        onFinish={(img) => {
          formRef?.current?.setFieldValue('image', img)
          formRef?.current?.setFieldValue('sourceImageUuid', imageToCrop!.uuid)
          setMainImgSourceData({
            img: imageToCrop!,
            croppedImage: img
          })
        }}
      />
      <CustomDialog
        hidden={!showDeactivateDialog}
        title={texts('article:deactivate_article_button')}
        closeButton
        onCloseButtonClick={closeDeactivateDialog}
        classes={{
          container: styles.deactivateDialog
        }}
      >
        {deactivateDialogContent()}
      </CustomDialog>
      <CustomDialog
        hidden={!showActivateDialog}
        title={texts('article:activate_article_button')}
        closeButton
        onCloseButtonClick={closeActivateDialog}
        classes={{
          container: styles.deactivateDialog
        }}
      >
        {activateDialogContent()}
      </CustomDialog>
      
      <GalleryLibraryDialog
        id={'bbcode-editor-gallery-library'}
        hidden={!showGalleryLibrary}
        initialData={galleryLibraryInitialData}
        onCloseClick={() => {
          closeGalleryLibraryDialog()
        }}
        beforeAdd={async () => {
          setIsNavigationGuarded(false)
          return {
            push: {
              pageType: GalleryPageType.FROM_EDIT_ARTICLE,
              articleData: {
                uuid: uuid,
                slug: details.slug,
                initialValues: formRef?.current?.values,
                mainImage: mainImgSourceData,
                showGalleryLibrary: true,
                active: details.active,
                originalDetails: details,
                articleListData: location.state.articleData?.articleListData
              },
            },
            replace: {
              url: `/article/edit/${uuid}`,
              state: {
                galleryData: {
                  uuid:formRef?.current?.values?.galleryUuid
                },
                articleData: {
                  uuid: uuid,
                  slug: details.slug,
                  initialValues: formRef?.current?.values,
                  mainImage: mainImgSourceData,
                  active: details.active,
                  showGalleryLibrary: true,
                  originalDetails: details,
                  articleListData: location.state.articleData?.articleListData
                },
              }
            }
          }
        }}
        beforeEdit={async () => {
          setIsNavigationGuarded(false)
          return {
            push: {
              pageType: GalleryPageType.FROM_EDIT_ARTICLE,
              articleData: {
                uuid: uuid,
                slug: details.slug,
                initialValues: formRef?.current?.values,
                mainImage: mainImgSourceData,
                active: details.active,
                showGalleryLibrary: true,
                originalDetails: details,
                articleListData: location.state.articleData?.articleListData
              }
            },
            replace: {
              url: `/article/edit/${uuid}`,
              state: {
                galleryData: {
                  uuid:formRef?.current?.values?.galleryUuid
                },
                articleData: {
                  uuid: uuid,
                  slug: details.slug,
                  initialValues: formRef?.current?.values,
                  mainImage: mainImgSourceData,
                  active: details.active,
                  showGalleryLibrary: true,
                  originalDetails: details,
                  articleListData: location.state.articleData?.articleListData
                }
              }
            }
          }
        }}
        onFinish={async (selectedGallery) => { 
          try {
            formRef?.current?.setFieldValue('galleryUuid', selectedGallery.uuid)
            formRef?.current?.setFieldValue('gallery', selectedGallery)
            await getGalleryData(selectedGallery.uuid)
          } catch (error) {
            onErrorResponseShowMessage(error, true, { userUuid: userUuid })
          }
        }}
      />
      {
        isLoading
        ? <div className={styles.loadingSpinnerContainer}>
            <MoonLoader loading={true} size={100}/>
          </div>
        : <>
            <div className={styles.controlButtonSection}>
              <LoadingButton
                className={styles.backButton}
                onClick={goBack}
                type={"button"}
              > 
                {texts('common:go_back_button_label')}
              </LoadingButton>
              <div className={styles.flexSpacer}></div>
              {
                details?.draft
                ? <div>
                    <LoadingButton 
                      className={styles.submitDraftButton}
                      loading={isSubmitting}
                      disabled={!isEditActive}
                      style={{
                        opacity: isEditActive ? 1 : 0.3
                      }}
                      onClick={() => {
                        formRef.current?.setFieldValue('draft', true)
                        setIsDraft(true)
                        if (formRef.current?.values.draft) {
                          formRef.current?.submitForm()
                        }
                      }}
                    >
                      <div className={styles.buttonContent}>{texts('common:save_draft_button')}</div>
                    </LoadingButton>
                  </div>
                : null
              } 
              <div>
                <LoadingButton 
                  className={
                    [
                      styles.submitFormButton,
                      details?.draft ? styles.submitFormButtonOnDraft : styles.submitFormButtonAlone
                    ].join(' ')
                  }
                  disabled={!isEditActive}
                  loading={isSubmitting}
                  style={{
                    opacity: isEditActive ? 1 : 0.3
                  }}
                  onClick={() => {
                    formRef.current?.setFieldValue('draft', false)
                    setIsDraft(false)
                    if (!formRef.current?.values.draft) {
                      formRef.current?.submitForm()
                    }
                  }}
                >
                  <div className={styles.buttonContent}>{texts('article:publish_button')}</div>
                </LoadingButton>
                {
                  !details.draft && moment(details.publishedAt).isSameOrBefore(moment())
                  ? <div className={styles.submitFormButtonWarning}>
                      {texts('common:update_warning')}
                    </div>
                  : null
                }
              </div>
            </div>
            <div className={styles.pageTitleContainer}>
              <h1 className={styles.pageTitle}>
                {texts('article:edit_article_title')}
              </h1>
              <button
                className={[
                  styles.baseEditButton,
                  isEditActive ? styles.deactivateEditButton : styles.activateEditButton
                ].join(' ')}
                onClick={async () => {
                  if (isEditActive) {
                    setIsEditActive(false)
                    if (details.gallery?.uuid) {
                      try {
                        await getGalleryData(details.gallery?.uuid)
                      } catch (error) {
                        onErrorResponseShowMessage(error, true, { userUuid: userUuid })
                      }
                    } else {
                      setConnectedGallery(null)
                    }
                    setInitialValues(true)
                    setShowSuggestedWikiList(false)
                  } else {
                    setIsEditActive(true)
                    formRef?.current.setFieldValue('lastEditor', `${userName ?? ''} ${userLastName ?? ''}`)
                    formRef?.current.setFieldValue('modificationDate', null)
                  }
                }}
              >
                {isEditActive ? texts('article:cancel_article_edit_button') : texts('article:activate_article_edit_button')}
              </button>
              <button
                disabled={isEditActive}
                style={{
                  ...(isEditActive ? { cursor: 'default' } : {})
                }}
                className={[
                  details.active ? styles.deactivateArticleButton : styles.activateArticleButton,
                  styles.baseEditButton
                ].join(' ')}
                onClick={() => {
                  if (details.active) {
                    openDeactivateDialog()
                  } else {
                    openActivateDialog()
                  }
                }}
              >
                {details.active ? texts('article:deactivate_article_button') : texts('article:activate_article_button')}
              </button>
              <div className={styles.flexSpacer}></div>
              {
                details.active && !details.draft && moment(details.publishedAt).isSameOrBefore(moment())
                ? <button
                    className={styles.articleLinkButton}
                    onClick={() => {
                      window.open(`${FRONT_BASE_URL}/${details.slug}`, '_blank', 'noopener,noreferrer')
                    }}
                  >
                    {texts('article:show_published_article_button')}
                  </button>
                : null
              }
              
            </div>
            
            <div className={styles.formContainer}>
              <Formik
                innerRef={formRef}
                initialValues={formInitialValues}
                validateOnBlur={true}
                validateOnChange={true}
                onSubmit={handleSubmit}
                enableReinitialize
                validationSchema={editArticleValidation(texts, details)}
              >
                {(formikProps) => (
                  <>
                  <ReassignWikiDialogContent
                    hidden={!showReassignWikiDialog}
                    title={texts('article:reassign_wiki_dialog')}
                    closeButton
                    onCloseButtonClick={closeReassignWikiDialog}
                    onConfirmButtonClick={closeReassignWikiDialog}
                    wikiTagSelected={wikiTagSelected}
                  />
                    <Form className={styles.form}>
                      <div className={styles.formGeneralSection}>
                        <div className={styles.formColumn}>
                          <div className={`${styles.subsection} ${styles.subsectionNoBottomPadding}`}>
                            <div className={styles.field}>
                              <div className={styles.fieldLabel}>
                                {texts('article:main_image_label')}
                              </div>
                              <div
                                className={styles.mainImageContainer}
                              >
                                <div
                                  className={styles.mainImageImgSection}
                                >
                                  {
                                    mainImgSourceData != null
                                    ? <img 
                                        className={styles.mainImage}
                                        src={getMainImgUrl()}
                                      />
                                    : <div className={styles.mainImagePlaceholder}>
                                        <MdImage size={40}/>
                                      </div>
                                  }
                                </div>
                                <div className={styles.mainImageControlSection}>
                                  <button
                                    disabled={!isEditActive}
                                    className={styles.mainImgAddButton}
                                    type={'button'}
                                    onClick={() => {
                                      openMediaLibraryDialog()
                                    }}
                                    style={{ opacity: !isEditActive ? 0.5 : 1 }}
                                  >
                                    <div className={styles.mainImgButtonContent}>
                                      <IoAddCircle size={20}/>
                                      <div className={styles.mainImgButtonContentLabel}>
                                        {texts('article:choose_or_add_image_button')}
                                      </div>
                                    </div>
                                  </button>
                                  {
                                    mainImgSourceData != null
                                    ? <button
                                        disabled={!isEditActive || mainImgSourceData == null}
                                        className={styles.mainImgRemoveButton}
                                        type={'button'}
                                        onClick={() => {
                                          formikProps.values.removeMainImage = true
                                          formikProps.values.image = null
                                          formikProps.values.sourceImageUuid = ''
                                          setMainImgSourceData(null)
                                        }}
                                        style={{ opacity: !isEditActive ? 0.5 : 1 }}
                                      >
                                        <div className={styles.mainImgButtonContent}>
                                          <IoMdTrash size={20}/>
                                          <div className={styles.mainImgButtonContentLabel}>
                                            {texts('article:remove_image_button')}
                                          </div>
                                        </div>
                                      </button>
                                    : null
                                  }
                                  
                                </div>
                              </div>
                              <div className={styles.errorLabel}>
                                {
                                  formikProps.errors.image ?? <ErrorMessage name={'image'} />
                                }
                              </div>
                            </div>
                          </div>
                          <div className={`${styles.subsection} ${styles.subsectionBiggerBottomPadding}`}>
                            <div className={styles.field}>
                              <BaseInput
                                label={texts('article:title_label')}
                                disabled={!isEditActive}
                                name={'title'}
                                formikProps={formikProps}
                                classes={{
                                  containerWithLabel: styles.inputContainerWithLabel,
                                  containerWithoutLabel: styles.inputContainerWithoutLabel,
                                  fieldBase: styles.textFieldBase
                                }}
                                styles={{
                                  input: {
                                    opacity: isEditActive ? 1 : 0.3
                                  }
                                }}
                                value={formikProps.values.title}
                                maxLen={150}
                                count={true}
                                error={
                                  formikProps.touched.title &&
                                  Boolean(formikProps.errors.title)
                                }
                              />
                            </div>
                            <div className={styles.field}>
                              <div className={styles.fieldLabel}>
                                {texts('article:choose_category_label')}
                              </div>
                              <CustomSelectField
                                instanceId={'category-field'}
                                disabled={!isEditActive}
                                name="category"
                                placeholder={texts('common:choose_category_placeholder')}
                                options={categoryList}
                                value={formikProps.values.category}
                                formikProps={formikProps}
                                error={
                                  formikProps.touched.category &&
                                  Boolean(formikProps.errors.category)
                                }
                              />
                            </div>
                            <div className={styles.field}>
                              <BaseInput
                                label={texts('article:lead_text_label')}
                                name={'leadText'}
                                disabled={!isEditActive}
                                rows={9}
                                formikProps={formikProps}
                                classes={{
                                  containerWithLabel: styles.inputContainerWithLabel,
                                  containerWithoutLabel: styles.inputContainerWithoutLabel,
                                  fieldBase: styles.textFieldBase
                                }}
                                styles={{
                                  input: {
                                    opacity: isEditActive ? 1 : 0.3
                                  }
                                }}
                                value={formikProps.values.leadText}
                                maxLen={500}
                                count={true}
                                error={
                                  formikProps.touched.leadText &&
                                  Boolean(formikProps.errors.leadText)
                                }
                              />
                            </div>
                            <div className={styles.field} style={{ marginTop: '1.25rem' }}>
                              <BaseInput
                                label={texts('common:slug_label')}
                                name={'slug'}
                                formikProps={formikProps}
                                disabled={!isEditActive || (!details.draft && moment(details.publishedAt).unix() <= moment().unix())}
                                classes={{
                                  containerWithLabel: styles.inputContainerWithLabel,
                                  containerWithoutLabel: styles.inputContainerWithoutLabel,
                                  fieldBase: styles.textFieldBase
                                }}
                                styles={{
                                  input: {
                                    opacity: isEditActive ? 1 : 0.3
                                  }
                                }}
                                value={formikProps.values.slug}
                                maxLen={80}
                                count={true}
                                error={
                                  formikProps.touched.slug &&
                                  Boolean(formikProps.errors.slug)
                                }
                              />
                            </div>
                            <div className={styles.field} style={{ marginTop: '1.3rem' }}>
                              <BaseInput
                                label={texts('article:source_label')}
                                name={'source'}
                                disabled={!isEditActive}
                                formikProps={formikProps}
                                classes={{
                                  containerWithLabel: styles.inputContainerWithLabel,
                                  containerWithoutLabel: styles.inputContainerWithoutLabel,
                                  fieldBase: styles.textFieldBase
                                }}
                                styles={{
                                  input: {
                                    opacity: isEditActive ? 1 : 0.3
                                  }
                                }}
                                value={formikProps.values.source}
                                maxLen={80}
                                count={true}
                                error={
                                  formikProps.touched.source &&
                                  Boolean(formikProps.errors.source)
                                }
                              />
                            </div>
                          </div>
                          <div className={`${styles.subsection} ${styles.subsectionNormalPadding}`}>
                            <div className={styles.subsectionTitle}>
                              {texts('article:tags_wiki_label')}
                            </div>
                            <div className={styles.switchField}>
                              <CustomSwitch 
                                name={'set_wiki'}
                                disabled={!isEditActive}
                                checked={wiki}
                                setChecked={() => {
                                  setWiki(!wiki)
                                  formikProps.setFieldValue('tags', [])
                                  formikProps.setFieldValue('isWikiArticle', !wiki)
                                }}
                                formikProps={formikProps}
                              />
                              <div className={styles.switchLabel}>
                                {texts('article:set_wiki_label')}
                              </div>
                              <div className={styles.showDialogCheckboxAdditionalLabel} style={{ fontWeight: 'bold' }}>
                                {texts('article:set_wiki_additional_label')}
                              </div>
                            </div>
                            <div className={styles.field}>
                              <div className={styles.fieldLabel}>
                                {texts(wiki ? 'article:choose_tags_label' : 'article:choose_optional_tags_label')}
                              </div>
                              <CustomAsyncCreatableSelectField
                                instanceId={'tags-field'}
                                disabled={!isEditActive}
                                name={'tags'}
                                placeholder={texts('article:choose_tags_placeholder')}
                                isMulti={!wiki}
                                hideSelectedOption
                                value={formikProps.values.tags}
                                options={tagList}
                                formikProps={formikProps}
                                loadOptions={loadTagOptions}
                                onChange={(name, val) => {
                                  if (wiki && val?.wikiArticleUuid) {
                                    setWikiTagSelected([val])
                                    return openReassignWikiDialog()
                                  }
                                  formikProps?.setFieldValue(name, wiki ? [val] : val)
                                }}
                                error={
                                  formikProps.touched.tags &&
                                  wiki &&
                                  Boolean(formikProps.errors.tags)
                                }
                              />
                              <div className={styles.tagsWarning}>
                                {texts('article:choose_tags_warning')}
                              </div>
                            </div>
                          </div>
                          <div className={`${styles.subsection} ${styles.subsectionNormalPadding}`}>
                            <div className={styles.subsectionTitle}>
                              {texts('article:additional_parameters_label')}
                            </div>
                            <div className={styles.switchField}>
                              <div>
                                <CustomSwitch 
                                  name={'adultContent'}
                                  disabled={!isEditActive}
                                  checked={adultContent}
                                  setChecked={setAdultContent}
                                  formikProps={formikProps}
                                />
                              </div>
                              <div className={styles.switchLabel} style={{ color: 'red' }}>
                                {texts('common:adult_content_label')}
                              </div>
                              <div className={styles.showDialogCheckboxAdditionalLabel}>
                                {texts('common:adult_content_additional_label')}
                              </div>
                            </div>
                            <div className={styles.switchField}>
                              <div>
                                <CustomSwitch 
                                  name={'showAdultWarning'}
                                  disabled={!isEditActive}
                                  checked={showAdultWarning}
                                  setChecked={setShowAdultWarning}
                                  formikProps={formikProps}
                                />
                              </div>
                              <div className={styles.switchLabel} style={{ color: 'red' }}>
                              {texts('common:show_adult_warning_label')}
                              </div>
                              <div className={styles.showDialogCheckboxAdditionalLabel}>
                              {texts('common:show_adult_warning_additional_label')}
                              </div>
                            </div>
                            <div className={styles.adultContentWarning}>
                              {texts('common:adult_content_warning')}
                            </div>
                            <div className={`${styles.switchFieldWithInput} ${styles.lastSwitch}`}>
                              <div className={styles.switchFieldForInput}>
                                <div>
                                  <CustomSwitch 
                                    name={'sponsoredContent'}
                                    disabled={!isEditActive}
                                    checked={sponsoredContent}
                                    setChecked={setSponsoredContent}
                                    formikProps={formikProps}
                                  />
                                </div>
                                <div className={styles.switchLabel}>
                                  {texts('article:sponsored_content_label')}
                                </div>
                              </div>
                              <div
                                className={styles.fieldForSwitch}
                                style={{
                                  opacity: sponsoredContent ? 1 : 0.3
                                }}
                              >
                                <BaseInput
                                  disabled={!isEditActive && !sponsoredContent}
                                  label={texts('article:sponsor_label')}
                                  name={'sponsor'}
                                  formikProps={formikProps}
                                  value={formikProps.values.sponsor}
                                  classes={{
                                    containerWithLabel: styles.inputContainerWithLabel,
                                    containerWithoutLabel: styles.inputContainerWithoutLabel,
                                    fieldBase: styles.textFieldBase
                                  }}
                                  styles={{
                                    input: {
                                      opacity: isEditActive ? 1 : 0.3
                                    }
                                  }}
                                  maxLen={80}
                                  count={true}
                                  error={
                                    formikProps.touched.sponsor &&
                                    Boolean(formikProps.errors.sponsor)
                                  }
                                />
                              </div>
                            </div>
                            <div className={styles.field}>
                              <div className={styles.fieldLabel}>
                                {texts('article:article_props_label')}
                              </div>
                              <CustomSelectField
                                instanceId={'additional-params-field'}
                                name={'articleProps'}
                                disabled={!isEditActive}
                                value={formikProps.values.articleProps}
                                placeholder={texts('article:article_props_placeholder')}
                                isMulti
                                options={articlePropList}
                                formikProps={formikProps}
                                error={
                                  formikProps.touched.articleProps &&
                                  Boolean(formikProps.errors.articleProps)
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className={styles.formColumn}>
                          <div className={`${styles.subsection} ${styles.subsectionBiggerBottomPadding}`}>
                            <div className={styles.field}>
                              <BaseInput
                                label={texts('article:seo_title_label')}
                                name={'seoTitle'}
                                disabled={!isEditActive}
                                formikProps={formikProps}
                                classes={{
                                  containerWithLabel: styles.inputContainerWithLabel,
                                  containerWithoutLabel: styles.inputContainerWithoutLabel,
                                  fieldBase: styles.textFieldBase
                                }}
                                styles={{
                                  input: {
                                    opacity: isEditActive ? 1 : 0.3
                                  }
                                }}
                                value={formikProps.values.seoTitle}
                                maxLen={80}
                                count={true}
                                error={
                                  formikProps.touched.seoTitle &&
                                  Boolean(formikProps.errors.seoTitle)
                                }
                              />
                            </div>
                            <div className={styles.field}>
                              <BaseInput
                                label={texts('article:seo_description_label')}
                                name={'seoDescription'}
                                disabled={!isEditActive}
                                rows={4}
                                formikProps={formikProps}
                                classes={{
                                  containerWithLabel: styles.inputContainerWithLabel,
                                  containerWithoutLabel: styles.inputContainerWithoutLabel,
                                  fieldBase: styles.textFieldBase
                                }}
                                styles={{
                                  input: {
                                    opacity: isEditActive ? 1 : 0.3
                                  }
                                }}
                                value={formikProps.values.seoDescription}
                                maxLen={160}
                                count={true}
                                error={
                                  formikProps.touched.seoDescription &&
                                  Boolean(formikProps.errors.seoDescription)
                                }
                              />
                            </div>
                          </div>

                          <div className={`${styles.subsection} ${styles.subsectionBiggerBottomPadding}`}>
                            <div className={styles.field}>
                              <BaseInput
                                label={texts('article:og_description_label')}
                                name={'ogDescription'}
                                disabled={!isEditActive}
                                rows={4}
                                formikProps={formikProps}
                                classes={{
                                  containerWithLabel: styles.inputContainerWithLabel,
                                  containerWithoutLabel: styles.inputContainerWithoutLabel,
                                  fieldBase: styles.textFieldBase
                                }}
                                styles={{
                                  input: {
                                    opacity: isEditActive ? 1 : 0.3
                                  }
                                }}
                                value={formikProps.values.ogDescription}
                                maxLen={200}
                                count={true}
                                error={
                                  formikProps.touched.ogDescription &&
                                  Boolean(formikProps.errors.ogDescription)
                                }
                              />
                            </div>
                          </div>

                          <div className={`${styles.subsection} ${styles.subsectionBiggerBottomPadding}`}>
                            <div className={styles.field}>
                              <BaseInput
                                label={texts('article:twitter_description_label')}
                                name={'twitterCardDescription'}
                                disabled={!isEditActive}
                                rows={4}
                                formikProps={formikProps}
                                classes={{
                                  containerWithLabel: styles.inputContainerWithLabel,
                                  containerWithoutLabel: styles.inputContainerWithoutLabel,
                                  fieldBase: styles.textFieldBase
                                }}
                                styles={{
                                  input: {
                                    opacity: isEditActive ? 1 : 0.3
                                  }
                                }}
                                value={formikProps.values.twitterCardDescription}
                                maxLen={200}
                                count={true}
                                error={
                                  formikProps.touched.twitterCardDescription &&
                                  Boolean(formikProps.errors.twitterCardDescription)
                                }
                              />
                            </div>
                          </div>

                          <div className={`${styles.subsection} ${styles.subsectionBiggerBottomPadding}`}>
                            <div className={styles.field}>
                              <BaseInput
                                label={texts('article:webPush_title_label')}
                                name={'webPushTitle'}
                                disabled={!isEditActive}
                                rows={4}
                                formikProps={formikProps}
                                classes={{
                                  containerWithLabel: styles.inputContainerWithLabel,
                                  containerWithoutLabel: styles.inputContainerWithoutLabel,
                                  fieldBase: styles.textFieldBase
                                }}
                                styles={{
                                  input: {
                                    opacity: isEditActive ? 1 : 0.3
                                  }
                                }}
                                value={formikProps.values.webPushTitle}
                                maxLen={200}
                                count={true}
                                error={
                                  formikProps.touched.webPushTitle &&
                                  Boolean(formikProps.errors.webPushTitle)
                                }
                              />
                            </div>
                            <div className={styles.field} style={{ marginTop: '1.25rem' }}>
                              <BaseInput
                                label={texts('article:webPush_description_label')}
                                name={'webPushDescription'}
                                disabled={!isEditActive}
                                rows={4}
                                formikProps={formikProps}
                                classes={{
                                  containerWithLabel: styles.inputContainerWithLabel,
                                  containerWithoutLabel: styles.inputContainerWithoutLabel,
                                  fieldBase: styles.textFieldBase
                                }}
                                styles={{
                                  input: {
                                    opacity: isEditActive ? 1 : 0.3
                                  }
                                }}
                                value={formikProps.values.webPushDescription}
                                maxLen={200}
                                count={true}
                                error={
                                  formikProps.touched.webPushDescription &&
                                  Boolean(formikProps.errors.webPushDescription)
                                }
                              />
                            </div>
                          </div>
                          <div className={`${styles.subsection} ${styles.subsectionBiggerBottomPadding}`}>
                            <div className={styles.field}>
                              <BaseInput
                                label={texts('article:additional_body_script_label')}
                                name={'additionalBodyScript'}
                                disabled={!isEditActive}
                                warning={!!formikProps.values.additionalBodyScript}
                                warningLabel={texts('article:additional_body_script_warning')}
                                rows={9}
                                formikProps={formikProps}
                                classes={{
                                  containerWithLabel: styles.inputContainerWithLabel,
                                  containerWithoutLabel: styles.inputContainerWithoutLabel,
                                  fieldBase: styles.textFieldBase
                                }}
                                styles={{
                                  input: {
                                    opacity: isEditActive ? 1 : 0.3
                                  }
                                }}
                                value={formikProps.values.additionalBodyScript}
                                count={true}
                                error={
                                  formikProps.touched.additionalBodyScript &&
                                  Boolean(formikProps.errors.additionalBodyScript)
                                }
                              />
                            </div>
                          </div>
                          <div className={`${styles.subsection} ${styles.subsectionNormalPadding}`}>
                            <div className={styles.field}>
                              <BaseInput
                                label={texts('article:author_label')}
                                name={'author'}
                                disabled={true}
                                formikProps={formikProps}
                                value={formikProps.values.author}
                                styles={{
                                  input: {
                                    opacity: isEditActive ? 1 : 0.3
                                  }
                                }}
                                classes={{
                                  containerWithLabel: styles.inputContainerWithLabel,
                                  containerWithoutLabel: styles.inputContainerWithoutLabel,
                                  fieldBase: styles.textFieldBase
                                }}
                              />
                            </div>
                            <div className={styles.hideAuthorField}>
                              <CustomCheckbox
                                name={'hideAuthor'}
                                disabled={!isEditActive}
                                label={texts('article:hide_author_label')}
                                formikProps={formikProps}
                                onChange={(checked: boolean) => {
                                  if (!checked) {
                                    formikProps.setFieldValue('hideAuthor', false)
                                  }
                                }}
                              />
                            </div>
                            <div className={styles.field}>
                              <BaseInput
                                label={texts('article:last_edited_by_label')}
                                name={'lastEditor'}
                                disabled={true}
                                formikProps={formikProps}
                                value={formikProps.values.lastEditor}
                                styles={{
                                  input: {
                                    opacity: isEditActive ? 1 : 0.3
                                  }
                                }}
                                classes={{
                                  containerWithLabel: styles.inputContainerWithLabel,
                                  containerWithoutLabel: styles.inputContainerWithoutLabel,
                                  fieldBase: styles.textFieldBase
                                }}
                              />
                            </div>
                            <div className={styles.dualFieldRow}>
                              <div className={`${styles.field} ${styles.dualFieldRowField} ${isEditActive ? '' : styles.disabledField}`}>
                                <div className={styles.fieldLabelWithResetContainer}>
                                  <div className={styles.fieldLabel}>
                                    {texts('common:publish_date_label')}
                                  </div>
                                  <div
                                    className={styles.fieldResetButton}
                                    hidden={!details.draft && moment(details.publishedAt).unix() <= moment().unix()}
                                    onClick={() => {
                                      if (isEditActive && (details.draft || moment(details.publishedAt).unix() > moment().unix())) {
                                        formikProps.setFieldValue('publishDate', undefined)
                                        formikProps.setFieldValue('publishDateReset', true)
                                      }
                                    }}
                                  >
                                    {texts('common:publish_date_reset_button')}
                                  </div>
                                </div>
                                <CustomFlatpickr
                                  name={'publishDate'}
                                  disabled={!isEditActive || (!details.draft && moment(details.publishedAt).unix() <= moment().unix())}
                                  formikProps={formikProps}
                                  placeholder={details.publishedAt && !formikProps.values.publishDateReset ? moment(details.publishedAt).format('YYYY-MM-DD HH:mm') : 'Teraz'}
                                  setInitial
                                  withTime
                                />
                                <div className={styles.errorLabel}>
                                {
                                  formikProps.errors.publishDate ?? <ErrorMessage name={'publishDate'} />
                                }
                                </div>
                              </div>
                              <div className={`${styles.field} ${styles.dualFieldRowField} ${isEditActive ? '' : styles.disabledField}`}>
                                <div className={styles.fieldLabelWithResetContainer}>
                                  <div className={styles.fieldLabel}>
                                    {texts('common:update_date_label')}
                                  </div>
                                  {/* <div
                                    className={styles.fieldResetButton}
                                    onClick={() => {
                                      if (isEditActive) {
                                        if (formikProps.values.modificationDate != null) {
                                          formikProps.setFieldValue('modificationDate', undefined)
                                          formikProps.setFieldValue('modificationDateReset', true)
                                        }
                                      }
                                    }}
                                  >
                                    Ustaw na 'Teraz'
                                  </div> */}
                                </div>
                                <CustomFlatpickr
                                  name={'modificationDate'}
                                  disabled={true}
                                  formikProps={formikProps}
                                  placeholder={texts('common:publish_date_placeholder')}
                                  setInitial={details.modifiedAt != null}
                                  withTime
                                />
                                {/* <div className={styles.errorLabel}>
                                {
                                  formikProps.errors.modificationDate ?? <ErrorMessage name={'modificationDate'} />
                                }
                                </div> */}
                                <div className={styles.checkboxRow}>
                                  <div className={styles.checkbox}>
                                    <CustomCheckbox
                                      disabled={!isEditActive}
                                      name={'showModificationDate'}
                                      label={texts('article:show_update_date_label')}
                                      formikProps={formikProps}
                                      onChange={(checked: boolean) => {
                                        if (!checked) {
                                          formikProps.setFieldValue('showModificationHour', false)
                                        }
                                      }}
                                    />
                                  </div>
                                  <div className={styles.checkbox}>
                                    <CustomCheckbox
                                      disabled={!isEditActive || !formikProps.values.showModificationDate}
                                      name={'showModificationHour'}
                                      label={texts('article:show_update_time_label')}
                                      formikProps={formikProps}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <div className={`${styles.subsection} ${styles.subsectionNormalPadding}`}>
                            <div className={styles.subsectionTitle} style={{ marginBottom: "1rem" }}>
                              {texts('article:redirect_title')}
                            </div>
                            <div className={styles.field}>
                              <BaseInput
                                label={texts('article:redirect_label')}
                                name={'redirect'}
                                disabled={true}
                                formikProps={formikProps}
                                value={formikProps.values.redirect}
                                styles={{
                                  input: {
                                    opacity: isEditActive ? 1 : 0.3
                                  }
                                }}
                                classes={{
                                  containerWithLabel: styles.inputContainerWithLabel,
                                  containerWithoutLabel: styles.inputContainerWithoutLabel,
                                  fieldBase: styles.textFieldBase
                                }}
                              />
                            </div>
                          </div> */}
                        </div>
                      </div>
                      <div className={`${styles.subsection} ${styles.subsectionNormalPadding}`}>
                        <div className={styles.connectedElementsSectionTitle}>
                          {/* Połącz z galerią lub quizem */}
                          {texts('article:connect_with_gallery_button')}
                        </div>
                        {
                          connectedGallery == null 
                          ? <div
                              style={{
                                opacity: isEditActive ? 1 : 0.5
                              }}
                            >
                              <button
                                type={'button'}
                                disabled={!isEditActive}
                                className={`${styles.controlButton} ${styles.controlButtonMultiContent}`}
                                onClick={openGalleryLibraryDialog}
                              >
                                <MdImage size={20} style={{ marginRight: '0.375rem' }}/> WSTAW GALERIĘ ZDJĘĆ
                              </button>
                            </div>
                          : null
                        }
                        {
                          connectedGallery != null
                          ? <div className={styles.connectedElementsSectionGallerySubsection}>
                              <div className={styles.connectedElementsSectionGallerySubsectionTitle}>
                              {texts('article:connected_gallery_label')}
                              </div>
                              <ConnectedGalleryTile
                                gallery={connectedGallery}
                                disabledControl={!isEditActive}
                                onDisconnect={() => {
                                  setConnectedGallery(null)
                                  formikProps.setFieldValue('gallery', undefined)
                                  formikProps.setFieldValue('galleryUuid', undefined)
                                }}
                                beforeEdit={async () => {
                                  setIsNavigationGuarded(false)
                                  return {
                                    push: {
                                      pageType: GalleryPageType.FROM_EDIT_ARTICLE,
                                      articleData: {
                                        uuid: uuid,
                                        slug: details.slug,
                                        initialValues: formRef?.current?.values,
                                        mainImage: mainImgSourceData,
                                        active: details.active,
                                        showGalleryLibrary: false,
                                        originalDetails: details,
                                        articleListData: location.state.articleData?.articleListData
                                      }
                                    },
                                    replace: {
                                      url: `/article/edit/${uuid}`,
                                      state: {
                                        galleryData: {
                                          uuid:formRef?.current?.values?.galleryUuid
                                        },
                                        articleData: {
                                          uuid: uuid,
                                          slug: details.slug,
                                          initialValues: formRef?.current?.values,
                                          showGalleryLibrary: false,
                                          mainImage: mainImgSourceData,
                                          active: details.active,
                                          originalDetails: details
                                        }
                                      }
                                    }
                                  }
                                }}
                              />
                              <div className={styles.connectedElementsSectionGallerySubsectionWarning}>
                              {texts('article:connect_with_gallery_or_quiz_warning')}
                              </div>
                            </div>
                          : null
                        }
                      </div>
                      <div className={styles.formEditorSection}>
                        <BBCodeEditor 
                          name={'content'}
                          disabled={!isEditActive}
                          formikProps={formikProps}
                          mainImageData={mainImgSourceData}
                          gallery={connectedGallery}
                          excludedArticleUuid={uuid}
                          previewComponent={getArticlePreviewComponent()({
                            contentFieldName: 'content',
                            formikProps: formikProps,
                            mainImageData: mainImgSourceData,
                            gallery: connectedGallery,
                            publishedAt: details.publishedAt,
                            modifiedAt: details.modifiedAt
                          })}
                        />
                      </div>
                      <div className={`${styles.subsection} ${styles.subsectionNormalPadding} ${styles.suggestedWikiArticlesSection}`}>
                        <SuggestedWikiArticlesTile
                          searchFunction={() => {getSuggestedWikiArticles(formikProps.values.content)}}
                          disabledControl={!isEditActive}
                          suggestedWikiList={{...suggestedWikiList, data: suggestedWikiList.data?.filter((tag: SuggestedWikiDto) => tag.uuid !== formikProps.values.tags[0]?.value)}}
                          showList={showSuggestedWikiList}
                          wikiTagSelected={formikProps.values.isWikiArticle ? formikProps.values.tags[0] : undefined}
                        />
                      </div>
                      <p className={styles.suggestedWikiArticlesWarning}>
                        {texts('article:suggested_wiki_articles_warning')}
                      </p>
                      <div className={styles.formButtonSection}>
                        <LoadingButton
                          className={styles.backButton}
                          onClick={goBack}
                          type={"button"}
                        > 
                          {texts('common:go_back_button_label')}
                        </LoadingButton>
                        <div className={styles.flexSpacer}></div>
                        {
                          details?.draft
                          ? <div>
                              <LoadingButton 
                                className={styles.submitDraftButton}
                                loading={isSubmitting}
                                disabled={!isEditActive}
                                style={{
                                  opacity: isEditActive ? 1 : 0.3
                                }}
                                onClick={() => {
                                  formikProps.setFieldValue('draft', true)
                                  setIsDraft(true)
                                  if (formikProps.values.draft) {
                                    formikProps.submitForm()
                                  }
                                }}
                              >
                                <div className={styles.buttonContent}>{texts('common:save_draft_button')}</div>
                              </LoadingButton>
                            </div>
                          : null
                        } 
                        <div>
                          <LoadingButton 
                            className={
                              [
                                styles.submitFormButton,
                                details?.draft ? styles.submitFormButtonOnDraft : styles.submitFormButtonAlone
                              ].join(' ')
                            }
                            disabled={!isEditActive}
                            loading={isSubmitting}
                            style={{
                              opacity: isEditActive ? 1 : 0.3
                            }}
                            onClick={() => {
                              formikProps.setFieldValue('draft', false)
                              setIsDraft(false)
                              if (!formikProps.values.draft) {
                                formikProps.submitForm()
                              }
                            }}
                          >
                            <div className={styles.buttonContent}>{texts('article:publish_button')}</div>
                          </LoadingButton>
                          {
                            !details.draft && moment(details.publishedAt).isSameOrBefore(moment())
                            ? <div className={styles.submitFormButtonWarning}>
                                {texts('common:update_warning')}
                              </div>
                            : null
                          }
                        </div>
                      </div>
                    </Form>
                  </>
                )}
              </Formik>
            </div>
          </>
      }
    </>
  )
}

export default EditArticlePage
