import { Tag } from 'bbcode-to-react'
import React from 'react'

type Props = {
  pinterest?: string
}

export default class PintrestTag<T = { linkify: boolean }> extends Tag<T> {
  toReact() {
    return (
      <></>
    )
  }
}