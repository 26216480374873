// TEMPORARY IMAGE
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import { FRONT_BASE_URL } from '../../../utils/common'
import ArticleTileContent from '../ArticleTileContent/ArticleTileContent'
import ArticleTileImage from '../ArticleTileImage/ArticleTileImage'
import styles from './styles.module.scss'
import { SearchArticlePropsOptionsList } from '../../../types/common'


export type Tag = {
  name: string
  uuid?: string
}

export type Category = {
  name: string
  uuid?: string
}

export type Author = {
  name: string
  lastName: string
  nick?: string
  uuid?: string
}

export type MainImage = {
  filename: string,
  hostname: string,
  path: string
}

export type ArticleProp = {
  name: string
  value: number
  uuid: string
}

export type ArticleOnClickData = {
  push?: {
    url?: string
    state?: any
  }
  replace?: {
    url?: string
    state?: any
  }
}

export type Article = {
  uuid: string
  draft: boolean
  active: boolean
  slug: string
  title?: string
  author?: Author
  lastEditor?: Author
  publishDate: Date
  modificationDate?: Date
  isWikiArticle?: boolean
  leadText?: string
  category?: Category
  tags?: Tag[],
  mainImage?: MainImage
  articleProps?: ArticleProp[]
}

type Props = { 
  article: Article
  onClickData?: ArticleOnClickData
}

const ArticleTile = (props: Props) => {
  const texts = useTranslation().t
  const history = useHistory()
  return (
    <div className={styles.container}>
      {
        props.article.active && !props.article.draft && moment(props.article.publishDate).isSameOrBefore(moment())
        ? <button
            className={styles.externalLinkButton}
            onClick={() => {
              window.open(`${FRONT_BASE_URL}/${props.article.slug}`, '_blank', 'noopener,noreferrer')
            }}
          >
            {texts('article:show_article_label')}
          </button>
        : null
      }
      <div 
        onClick={() => {
          if (props.onClickData?.replace?.url) {
            history.replace(
              props.onClickData.replace.url,
              props.onClickData.replace.state
            )
          } 
          if (props.onClickData?.push?.url) {
            history.push(
              props.onClickData.push.url,
              props.onClickData.push.state
            )
          } else {
            history.push(
              `/article/edit/${props.article.uuid}`,
              props.onClickData?.push?.state
            )
          }
        }}
        className={styles.link}
      >
        <div
          className={styles.linkContainer}
          style={{ 
            position: 'relative',
            ...(props.article.draft || !props.article.active ? { opacity: 0.6 } : {})
          }}
        >
          {
            props.article.active && props.article.draft && <div className={styles.draftBanner}>
              {texts('common:draft')}
            </div>
          }
          {
            !props.article.active && <div className={styles.inactiveBanner}>
              {texts('common:inactive')}
            </div>
          }
          <div className={styles.articleBadges}>
            {
              props.article.articleProps?.map(e => {
                return (
                  <div className={styles.articlePropBanner}>
                    {SearchArticlePropsOptionsList.find(o => o.value === e.value)?.label ?? ''}
                  </div>
                )
              })
            }
            {
              props.article.isWikiArticle && <div className={styles.wikiBanner}>
                {texts('common:wiki')}
              </div>
            }
          </div>
          <ArticleTileImage
            article={props.article}
          />
          <ArticleTileContent
            classes={{container:`${styles.contentContainer}`}}
            article={props.article} 
          />
        </div>
      </div>
    </div>
  )
}

export default ArticleTile
