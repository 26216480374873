import React from 'react'
import { useTranslation } from 'react-i18next'
import GalleryLibraryTileContent from '../GalleryLibraryTileContent/GalleryLibraryTileContent'
import GalleryLibraryTileImage from '../GalleryLibraryTileImage/GalleryLibraryTileImage'
import styles from './styles.module.scss'

export type Author = {
  name: string
  lastName: string
  nick?: string
  uuid?: string
}

export type FirstPage = {
  uuid: string
  photo: Photo
}

export type Photo = {
  filename: string,
  hostname: string,
  path: string
}

export type Gallery = {
  uuid: string
  draft: boolean
  active: boolean
  title?: string
  author?: Author
  lastEditor?: Author
  publishedAt?: Date | string
  createdAt?: Date | string
  modifiedAt?: Date | string
  firstPage?: FirstPage
  pageCount?: number
  adultContent: boolean
}

type Props = { 
  gallery: Gallery
  selected?: boolean
  onClick: () => void
  onEdit: () => void
  editWarningLabel?: string
}

// TODO url (to)

const GalleryLibraryTile = (props: Props) => {
  const texts = useTranslation().t

  return (
    <div
      className={`${styles.container} ${props.selected ? styles.selected : styles.notSelected}`}
      onClick={props.onClick}
      style={{ 
        position: 'relative'
      }}
    >
      <div className={styles.wrapper} style={{...(props.gallery.draft || !props.gallery.active ? { opacity: 0.6 } : {})}}>
        {
          props.gallery.active && props.gallery.draft && <div className={styles.draftBanner}>
            {texts('common:draft')}
          </div>
        }
        {
          !props.gallery.active && <div className={styles.inactiveBanner}>
            {texts('common:inactive')}
          </div>
        }
        <GalleryLibraryTileImage
          gallery={props.gallery}
        />
        <GalleryLibraryTileContent
          gallery={props.gallery}  
        />
      </div>
      <div className={`${styles.tickBox} ${props.selected ? styles.tickBoxSelected : styles.tickBoxNotSelected}`}></div>
      <div className={styles.controlContainer}>
        <button
          type={'button'}
          className={styles.editButton}
          onClick={props.onEdit}
        >
          {texts('gallery:edit_gallery_button')}
        </button>
        <div className={styles.editWarning}>
          {props.editWarningLabel}
        </div>
      </div>
    </div>
  )
}

export default GalleryLibraryTile
