import { Tag } from 'bbcode-to-react'
import { decode } from 'html-entities'
import styles from './style.module.scss'

type Props = {
  signature?: string
}

export default class DepeszaQuoteTag<T = { linkify: boolean }> extends Tag<T> {
  toReact() {
    const props = this.params as Props
    return (
      <div className={styles.quote_container}>
        <div className={styles.quote_icon}>
          <svg width="100%" height="100%" viewBox="0 0 25 22" xmlns="http://www.w3.org/2000/svg">
            <g fill="#1976D2" fillRule="nonzero">
              <path d="M14.286 0v10.714h7.143c0 3.939-3.205 7.143-7.143 7.143v3.572C20.194 21.429 25 16.623 25 10.714V0H14.286zM0 10.714h7.143c0 3.939-3.205 7.143-7.143 7.143v3.572c5.908 0 10.714-4.806 10.714-10.715V0H0v10.714z"/>
            </g>
          </svg>
        </div>
        <blockquote className={styles.quote_block}>
          <span className={styles.quote_quote}>{this.getComponents()}</span>{props.signature != null ? ` - ${decode(props.signature)}` : ''}
        </blockquote>
      </div>
    )

  }
}