import React from 'react'
import { useTranslation } from 'react-i18next'
import { MdKeyboardArrowDown, MdKeyboardArrowRight } from 'react-icons/md'
import BBCodeArticle from '../BBCodeArticle/BBCodeArticle'
import Timestamp from '../Timestamp/Timestamp'
import styles from './styles.module.scss'
import AssetLoader from '../../../assets/loader'

type Props = {
  contentFieldName: string
  formikProps: any
  image?: {
    src: string
    signature?: string
  }
  galleryData: any
  pageNumber?: number
  pageCount: number
  leadText: string
}

const BBCodeDepeszaGalleryPagePreview = (props: Props) => {
  const texts = useTranslation().t

  return (
    <>
      <div className={styles.galleryTitle}>
        {
          props.galleryData.title
        }
      </div>
      <div className={styles.galleryPage}>
        <div className={styles.pageImageContainer}>
          <div className={styles.pageCounter}>
            {`${props.pageNumber ?? '-'}/${props.pageCount}`}
          </div>
          <div className={styles.pageImage}>
            {
              props.image?.src
              ? <img
                  src={props.image.src}
                  className={styles.image}
                  alt={''}
              />
              : null
            }
            {
              props.image?.signature
              ? <div className={styles.pageImageSignature}>
                  {props.image.signature}
                </div>
              : null
            }
          </div>
        </div>
      </div>
      <div className={styles.goToBottomButtonContainer}>
        <div
          className={
            [
              styles.goToBottomButton,
              props.image?.signature ? styles.goToBottomButtonTopMargin : ''
            ].join(' ')
          }
        >
          <MdKeyboardArrowDown className={styles.scrollButtonIcon}/>
        </div>
      </div>
      <div className={styles.pageArticle}>
        <div className={styles.content}>
          <BBCodeArticle bbcode={props.formikProps.values[props.contentFieldName]} />
        </div>
        <p className={styles.articleLeadText}>
          {props.leadText}
        </p>
      </div>
      <div id={'next-page'} className={styles.nextPageNameSection}>
        <span className={styles.nextPageNameLabel}>{texts('gallery:see_next_page_label')} </span> {props.galleryData.title} - galeria zdjęć
      </div>
      <div className={styles.nextPageSection}>
        <div className={styles.nextPageTile}>
          <div 
            className={
              [
                styles.imageDarkGradientBase,
                styles.gallerySectionTileGradientNormal,
                styles.gallerySectionTileGradientMoz,
                styles.gallerySectionTileGradientWebkit,
                styles.gallerySectionTileGradientWebkitLinear
              ].join(' ')
            }
          ></div>
          <div 
            className={styles.nextPageTileButton}
          >
            <div className={styles.nextPageTileButtonLabel}>
              <div className={styles.nextPageTileButtonLabelText}>{texts('gallery:next_image_nbsp')}</div>
              <MdKeyboardArrowRight size={30}/>
            </div>
          </div>
          <img
            src={AssetLoader.getPlaceholderImage()}
            width={700}
            height={500}
            alt={''}
            className={styles.nextPageImage}
          />
        </div>
      </div>
      <div className={styles.galleryInfo}>
        <span className={styles.galleryInfoAuthor}>
          {`${texts('gallery:author_preview_label')}${props.galleryData?.author ?? ''}, `}
        </span>
        <span className={styles.galleryInfoDate}>
          <Timestamp 
            date={props.galleryData.publishedAt}
            hideTime
          />
        </span>
      </div>
    </>
  )
}

export default BBCodeDepeszaGalleryPagePreview
