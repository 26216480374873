import { Form, Formik } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import AuthApi from '../../../api/AuthApi'
import AuthRefreshApi from '../../../api/AuthRefreshApi'
import AssetLoader from '../../../assets/loader'
import { addToken } from '../../../redux/auth/actions'
import { State } from '../../../redux/reducers/index'
import { addUserData } from '../../../redux/user/actions'
import { Login } from '../../../types/auth'
import { onErrorResponseShowMessage } from '../../../utils/onErrorReponseShowMessage/onErrorResponseShowMessage'
import { StorageService } from '../../../utils/storage/StorageService'
import { signInValidation } from '../../../utils/validations/signInValidation'
import BaseInput from '../../common/BaseInput/BaseInput'
import LoadingButton from '../../common/LoadingButton/LoadingButton'
import PasswordInput from '../../common/PasswordInput/PasswordInput'
import TopBar from '../../common/TopBar/TopBar'
import styles from './styles.module.scss'

const SignIn = () => {
	const dispatch = useDispatch()
  const history = useHistory()
  const texts = useTranslation().t
  const token = useSelector((e: State) => e.auth.token)

	const [loading, setLoading] = React.useState<boolean>(false)

	const [initialValues, setInitialValues] = React.useState<Login>({ login: '', password: '' })

	const [login, setLogin] = React.useState<string>('')
	const [password, setPassword] = React.useState<string>('')

  // React.useEffect(() => {
  //   if (token) {
  //     const checkToken = async () => {
  //       try {
  //         await AuthRefreshApi.checkSession()
  //         history.replace('/')
  //       } catch (e) {
  //         return
  //       }
  //     }
  //     checkToken()
  //   }
  // }, [token])

  const checkToken = async () => {
    try {
      await AuthRefreshApi.checkSession()
      history.replace('/')
    } catch (e) {
      return
    }
  }

  const storageHandler = () => {
    const token = StorageService.getInstance().getAccessToken()
    if (token) {
      checkToken()
    }
  }

  React.useEffect(() => {
    checkToken()
    window.addEventListener('storage', storageHandler)
    return () => {
      window.removeEventListener('storage', storageHandler)

    }
  }, [])

  const handleSubmitLogin = async (values: Login, form: any) => {
		setLoading(true)
		try {
			const authData = await AuthApi.login(values)
			StorageService.getInstance().setTokens(authData.sessionToken, authData.refreshToken)
			StorageService.getInstance().setUserData(authData)
			dispatch(addToken(authData.sessionToken))
			dispatch(addUserData(authData))
			history.push('/')
		} catch (error) {
			onErrorResponseShowMessage(error, false)
			setInitialValues({ ...values, password: '' })
      setPassword('')
			form.resetForm()
		} finally {
			setLoading(false)
		}
	}

  return (
    <>
      <TopBar 
        logoSrc={AssetLoader.getLogo()}
      />
      <div className={styles.main}>
        <div className={styles.title}>
          {texts(`auth:${process.env.REACT_APP_PROJECT}_login_title`)}
        </div>
        <div>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={handleSubmitLogin}
            validationSchema={signInValidation(texts)}
          >
            {(formikProps) => (
              <Form className={styles.form}>
                <BaseInput
                  name='login'
                  label={texts('auth:email_address')}
                  value={login}
                  setValue={setLogin}
                  error={
                    formikProps.touched.login && Boolean(formikProps.errors.login)
                  }
                  classes={{
                    label: styles.inputLabel
                  }}
                  formikProps={formikProps}
                />
                <PasswordInput
                  name='password'
                  value={password}
                  setValue={setPassword}
                  label={texts('auth:password')}
                  formikProps={formikProps}
                  classes={{
                    label: styles.inputLabel
                  }}
                  error={
                    formikProps.touched.password && Boolean(formikProps.errors.password)
                  }
                />
                <div className={styles.linksContainer}>
                  <div className={styles.linkContainer} style={{ marginTop: '0.313rem' }}>
                    <Link
                      to="/password/reset/request"
                      className={styles.link}
                    >
                      {texts("auth:reset_password_link")}
                    </Link>
                  </div>
                </div>
                <LoadingButton
                  type='submit'
                  className={styles.button}
                  disabled={
                    !formikProps.isValid || !formikProps.dirty
                  }
                  loading={loading}
                  style={{ marginTop: '0.625rem' }}
                >
                  <div className={styles.buttonContent}>
                    {texts('auth:login_button')}
                  </div>
                </LoadingButton>
                <div className={styles.resendSection}>
                  {texts('auth:resend_activation_email_text')}<Link to="/account/activation/resend" className={styles.resendLink}>{texts('auth:resend_activation_email_link')}</Link>
                </div>
                
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  )
}

export default SignIn
