import * as Yup from "yup"
import { slugAllowedCharacters } from './validationRegexps'

export const addArticleValidation = (t: (val: string) => string) => {
  return Yup.object({
    image: Yup.mixed()
      .test("required", t('common:field_required'), function (val: any) {
        const draft = this.resolve(Yup.ref('draft')) as boolean
        return draft || val != null
      }),
    title: Yup.string()
      .test("required", t('common:field_required'), function (val: any) {
        let draft = this.resolve(Yup.ref('draft')) as boolean
        return draft || val != null
    }),
    leadText: Yup.string()
      .test("required", t('common:field_required'), function (val: any) {
        let draft = this.resolve(Yup.ref('draft')) as boolean
        return draft || val != null
    }),
    slug: Yup.string().matches(slugAllowedCharacters, t("common:slug_allowed_characters")),
    category: Yup.object().nullable()
      .test("required", t('common:field_required'), function (val: any) {
        let draft = this.resolve(Yup.ref('draft')) as boolean
        return draft || val != null
    }),
    content: Yup.string()
      .test("required", t('common:field_required'), function (val: any) {
        let draft = this.resolve(Yup.ref('draft')) as boolean
        return draft || val != null
      }),
    tags: Yup.array()
      .test("required", t('common:field_required'), function (val: any) {
        const draft = this.resolve(Yup.ref('draft')) as boolean
        const isWikiArticle = this.resolve(Yup.ref('isWikiArticle')) as boolean
        return draft || (isWikiArticle ? val.length === 1 : val !== null)
      })
  })
}

