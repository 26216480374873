import React from 'react'
import styles from './styles.module.scss'
import { SinglePhoto } from '../MediaLibrary/types'
import BBCodeDescription from '../BBCodeDescription/BBCodeDescription'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

export type GalleryTileData = {
  uuid?: string
  content: string
  active: boolean
  order?: number
  author: {
    name: string
    lastName: string
    uuid?: string
  }
  lastEditor?: {
    name: string
    lastName: string
    uuid?: string
  }
  photoUuid?: string
  adultContent: boolean
  showAdultWarning: boolean
  photo?: SinglePhoto | null
  publishedAt?: Date | string
  modifiedAt?: Date | string
} 

type Props = {
  data: GalleryTileData
}

const GalleryPageTileContent = (props: Props) => {
	const texts = useTranslation().t

	return (
		<div className={styles.contentInfo} style={{ opacity: props.data.active ? 1 : 0.5}}>
      <div className={styles.contentInfoDescription}>
        <BBCodeDescription bbcode={props.data.content} />
      </div>
      <div className={styles.contentInfoDatesContainer}>
        <div className={styles.contentInfoDate}>
          <span className={styles.contentInfoDateLabel}>{texts('gallery:add_at_label')} </span>{`${props.data.author.name} ${props.data.author.lastName}, ${moment(props.data.publishedAt).format('DD.MM.YYYY HH:mm')}`}
        </div>
        {
          props.data.modifiedAt && props.data.lastEditor
          ? <div className={styles.contentInfoDate}>
              (<span className={styles.contentInfoDateLabel}>{texts('gallery:update_at_label')} </span>
              {
                `${props.data.lastEditor.name} ${props.data.lastEditor.lastName}, ${moment(props.data.modifiedAt).format('DD.MM.YYYY HH:mm')}`
              })
            </div>
          : null
        }
      </div>
    </div>	
	)
}   

export default GalleryPageTileContent