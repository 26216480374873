import * as Yup from "yup"
import { slugAllowedCharacters } from './validationRegexps'

export const editArticleValidation = (t: (val: string) => string, originalArticle: any) => {
  return Yup.object({
    image: Yup.mixed()
      .test("required", t('common:field_required'), function (val: any) {
        const draft = this.resolve(Yup.ref('draft')) as boolean
        const removeMainImage = this.resolve(Yup.ref('removeMainImage')) as boolean
        return draft 
          || (val == null && originalArticle?.mainImage != null && !removeMainImage) 
          || val != null
      }),
    title: Yup.string()
      .test("required", t('common:field_required'), function (val: any) {
        const draft = this.resolve(Yup.ref('draft')) as boolean
        return draft || val != null
    }),
    leadText: Yup.string()
      .test("required", t('common:field_required'), function (val: any) {
        const draft = this.resolve(Yup.ref('draft')) as boolean
        return draft || val != null
    }),
    slug: Yup.string().matches(slugAllowedCharacters, t("common:slug_allowed_characters")),
    category: Yup.object().nullable()
      .test("required", t('common:field_required'), function (val: any) {
        const draft = this.resolve(Yup.ref('draft')) as boolean
        return draft || val != null
    }),
    content: Yup.string()
      .test("required", t('common:field_required'), function (val: any) {
        const draft = this.resolve(Yup.ref('draft')) as boolean
        return draft || val != null
    }),
    tags: Yup.array()
      .test("required", t('common:field_required'), function (val: any) {
        const draft = this.resolve(Yup.ref('draft')) as boolean
        const isWikiArticle = this.resolve(Yup.ref('isWikiArticle')) as boolean
        return draft || (isWikiArticle ? val.length === 1 : val !== null)
    })
    // publishDate: Yup.date().nullable()
    //   .test("required", t('common:publish_date_in_the_past_error'), function (val: any) {
    //     let draft = this.resolve(Yup.ref('draft')) as boolean
    //     return draft || val == null || (val != null && val.getTime() >= new Date().setHours(0,0,0,0))
    //   }),
    // modificationDate: Yup.date().nullable()
    //   .test("required", t('common:modification_date_in_the_past_error'), function (val: any) {
    //     let draft = this.resolve(Yup.ref('draft')) as boolean
    //     let hide = this.resolve(Yup.ref('hideModificationDate')) as boolean
    //     console.log('hide', hide)
    //     return draft || hide || val == null || (val != null && val.getTime() >= new Date().setHours(0,0,0,0))
    //   }),
  })
}

