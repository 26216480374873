import { BASE_DATA } from './baseRoutes'
import { BaseApiWithRefresh } from './BaseApiWithRefresh'
import { ArticleState, GalleryState, DateRange, SearchArticleProps } from '../types/common'
import { Role, SuggestedWikiDto } from '../types/data'
import { AxiosResponse } from 'axios'
import moment from 'moment'

class DataApi extends BaseApiWithRefresh {
  constructor() {
    super({
      baseURL: `${BASE_DATA}/api/v1/data/`,
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }

  public getCategoryList() {
    return this.api.get(`/category/list`)
  }

  public getArticleDetails(uuid: string) {
    return this.api.get(`/article/${uuid}`)
  }

  public changeArticleActiveState(uuid: string, active: boolean) {
    return this.api.patch(`/article/${uuid}/active`, JSON.stringify({ active: active }))
  }

  public getArticlePropList() {
    return this.api.get(`/article/props/list`)
  }

  public getArticleList(
    title?: string, category?: string, 
    tags?: any[], statuses?: ArticleState[], 
    author?: string, exclude?: string, 
    dateRange?: DateRange,
    articleProps?: SearchArticleProps[],
    limit?: number, offset?: number
  ) {
    const params: string[] = []
    if (title != null) {
      params.push(`title=${title}`)
    }
    if (category != null) {
      params.push(`category=${category}`)
    }
    if (tags != null) {
      params.push(`tags=${tags.map(e => e.value).join(';')}`)
    }
    if (statuses != null && statuses.length > 0) {
      params.push(`status=${statuses.join(';')}`)
    }
    if (limit != null) {
      params.push(`limit=${limit}`)
    }
    if (offset != null) {
      params.push(`offset=${offset}`)
    }
    if (author != null) {
      params.push(`author=${author}`)
    }
    if (exclude != null) {
      params.push(`exclude=${exclude}`)
    }
    if (articleProps != null && articleProps.length > 0) {
      params.push(`article-props=${articleProps.sort((a,b) => a-b).join(';')}`)
    }
    if (dateRange != null) {
      params.push(`dateFrom=${encodeURIComponent((moment(dateRange.from).format()))}`)
      params.push(`dateTo=${encodeURIComponent((moment(dateRange.to).set({h: 23, m: 59, s: 59}).format()))}`)
    }
    return this.api.get(`/article/list${params.length > 0 ? `?${params.join('&')}` : ''}`)
  }

  public getFullUsersList(limit?: number, offset?: number) {
    const params: string[] = []
    if (limit != null) {
      params.push(`limit=${limit}`)
    }
    if (offset != null) {
      params.push(`offset=${offset}`)
    }
    return this.api.get(`/user/list${params.length > 0 ? `?${params.join('&')}` : ''}`)
  }

  public searchTags(search?: string, limit?: number, offset?: number) {
    const params: string[] = []
    if (search != null) {
      params.push(`search=${search}`)
    }
    if (limit != null) {
      params.push(`limit=${limit}`)
    }
    if (offset != null) {
      params.push(`offset=${offset}`)
    }
    return this.api.get(`/tag/search${params.length > 0 ? `?${params.join('&')}` : ''}`)
  }

  public createNewArticle(data: FormData) {
    return this.api.post(`/article/add`, data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }

  public updateArticle(uuid: string, data: FormData) {
    return this.api.patch(`/article/${uuid}/edit`, data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }

  public createNewGallery(data: any) {
    return this.api.post(`/gallery/add`, data)
  }

  public validateBBCode(text: string) {
    return this.api.post(`/bbcode/validate`, JSON.stringify({ text: text }))
  }

  public getGalleryDetails(uuid: string) {
    return this.api.get(`/gallery/${uuid}/details`)
  }

  public getGalleryTileDetails(uuid: string) {
    return this.api.get(`/gallery/${uuid}/details/tile`)
  }

  public updateGallery(uuid: string, data: any) {
    return this.api.patch(`/gallery/${uuid}/edit`, data)
  }

  public changeGalleryActiveState(uuid: string, active: boolean) {
    return this.api.patch(`/gallery/${uuid}/active`, JSON.stringify({ active: active }))
  }

  public getGalleryList(title?: string, statuses?: GalleryState[], limit?: number, offset?: number) {
    const params: string[] = []
    if (title != null) {
      params.push(`title=${title}`)
    }
    if (statuses != null && statuses.length > 0) {
      params.push(`status=${statuses.join(';')}`)
    }
    if (limit != null) {
      params.push(`limit=${limit}`)
    }
    if (offset != null) {
      params.push(`offset=${offset}`)
    }
    return this.api.get(`/gallery/list${params.length > 0 ? `?${params.join('&')}` : ''}`)
  }

  public getRoleList() {
    return this.api.get<Role[]>(`/account/role/list`)
  }

  public getSuggestedWikiArticles(text: string): Promise<AxiosResponse<SuggestedWikiDto[]>> {
    return this.api.post<SuggestedWikiDto[]>(`article/wiki/suggested`, JSON.stringify({ text: text }))
  }
}

export default new DataApi()