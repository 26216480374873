import React from 'react'
import calendarIcon from '../../../assets/images/calendar.webp'
import { useTranslation } from 'react-i18next'
import "./style.css"
import styles from './styles.module.scss'

type Props = {
  width?: string
  defaultValue: string | undefined
  placeholder?: string
  inputRef: any
  style?: React.CSSProperties
  disabled?: boolean
}

// TODO convert px to rem

const CustomFlatpickrInput = ({ defaultValue, inputRef, width, ...props }: Props) => {
  const t = useTranslation("forms").t
  
  return (
    <div>
      <div style={{
        width: width || '100%',
        position: 'relative'
      }}>
        <input
          {...props}
          type={'text'}
          className={styles.input}
          defaultValue={defaultValue}
          ref={inputRef}
          placeholder={props.placeholder ?? t('date_range_placeholder')}
          style={{
            ...(props.style || {})
          }}
        />
        <div 
          style={{
            position: 'absolute',
            right: '13px',
            top: '50%',
            width: '17px',
            height: '17px',
            transform: 'translateY(-50%)'
          }}
        >
          <img 
            src={calendarIcon}
            width={'17px'}
            height={'17px'}
          />
        </div>
      </div>
    </div>
  )
}

export default CustomFlatpickrInput
