import * as Yup from "yup"

export const addPageToGalleryValidation = (t: (val: string) => string) => {
  return Yup.object({
    content: Yup.mixed()
      .test("required", t('common:field_required'), function (val: any) {
        const draft = this.resolve(Yup.ref('draft')) as boolean
        return draft || val != null
      }),
    photo: Yup.mixed()
      .test("required", t('common:field_required'), function (val: any) {
        const draft = this.resolve(Yup.ref('draft')) as boolean
        return draft || val != null
      })
      
  })
}

