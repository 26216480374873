import * as Yup from "yup"
import { forbiddenSomeSpecialCharactersPassword, passwordRules, englishCharactersAllowedPassword } from "./validationRegexps"

export const resetPasswordFinishValidation = (t: (val: string) => string) => {
  return Yup.object({
    password: Yup.string()
      // .matches(
      //   forbiddenSomeSpecialCharactersPassword,
      //   t("auth:special_characters_password_validation")
      // )
      .matches(englishCharactersAllowedPassword, t("auth:special_characters_password_validation"))
      .matches(passwordRules, t("auth:password_rules_validation"))
      .min(8, t("common:min_8_signs_validation"))
      .max(32, t("common:max_32_signs_validation"))
      .required(t("common:field_required")),
    passwordConfirmation: Yup.string()
      .oneOf([Yup.ref('password'), null], t("auth:different_passwords_validation"))
      .required(t("common:field_required"))
  })
}

