import { Form, Formik } from 'formik'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { AiOutlineSearch } from 'react-icons/ai'
import { IoAddCircle } from 'react-icons/io5'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import DataApi from '../../../api/DataApi'
import { addErrorMessage } from '../../../redux/messages/actions'
import { State } from '../../../redux/reducers/index'
import store from '../../../store'
import { GalleryState, GalleryStateOptionsList } from '../../../types/common'
import { onErrorResponseShowMessage } from '../../../utils/onErrorReponseShowMessage/onErrorResponseShowMessage'
import { MessageEnum } from '../../CustomToastProvider/types'
import BaseInput from '../BaseInput/BaseInput'
import CustomSelectField from '../CustomSelectField/CustomSelectField'
import GalleryLibraryTile from '../GalleryLibraryTile/GalleryLibraryTile'
import LoadingButton from '../LoadingButton/LoadingButton'
import Pagination from '../Pagination/Pagination'
import styles from './styles.module.scss'
import { diff } from 'deep-diff'

export const MAX_GALLERIES_PER_PAGE = 7

type Props = {
  id?: string
  warnings?: {
    add?: string
    edit?: string
  }
  baseZIndex?: number
  onFinish: ((selectedGallery: any) => void) | ((selectedGallery: any) => Promise<void>)
  beforeEdit?: (() => { [key: string]: any }) | (() => Promise<{ [key: string]: any }>)
  beforeAdd?: (() => { [key: string]: any }) | (() => Promise<{ [key: string]: any }>)
  reload?: boolean
  initialData?: GalleryLibraryStateData
}

type FormValues = {
  search: string
  status: { label: string; value: GalleryState }[]
}

const defaultInitialFormValues: FormValues = {
  search: '',
  status: [GalleryStateOptionsList[0], GalleryStateOptionsList[1]],
}

export type GalleryLibraryStateData = {
  page: number
  formValues: FormValues
  uuid?: string
}

const GalleryLibrary = (props: Props) => {
  const texts = useTranslation().t
  const history = useHistory()
  const userUuid = useSelector((e: State) => e.user.uuid)

  const [isSearching, setIsSearching] = React.useState<boolean>(false)
  const [initialFormValues, setInitialFormValues] = React.useState<FormValues>(defaultInitialFormValues)
  const [list, setList] = React.useState<any[]>([])
  const [count, setCount] = React.useState<number>(0)
  const [page, setPage] = React.useState<number>(1)

  const [initialSelectedGallerySet, setInitialSelectedGallerySet] = React.useState<boolean>(false)

  const [lastSearchedData, setLastSearchedData] = React.useState<FormValues>(defaultInitialFormValues)

  const [selectedGallery, setSelectedGallery] = React.useState<any>(null)

  const searchFormRef = useRef() as any

  React.useEffect(() => {}, [])

  React.useEffect(() => {
    setIsSearching(false)
    if (props.initialData && diff(props.initialData?.formValues, initialFormValues)) {
      setInitialFormValues(props.initialData.formValues)
      setSelectedGallery(list.find((e: any)=> e.uuid === props.initialData?.uuid) ?? null)
      setLastSearchedData(props.initialData.formValues)
    } else if (initialFormValues !== defaultInitialFormValues) {
      setInitialFormValues(defaultInitialFormValues)
      setSelectedGallery(null)
      setLastSearchedData(defaultInitialFormValues)
    } else {
      setSelectedGallery(null)
      setPage(props.initialData?.page ?? 1)
      if (page === (props.initialData?.page ?? 1)) {
        DataApi.getGalleryList(
          searchFormRef?.current?.values['search'] ?? '',
          searchFormRef?.current?.values['status']?.map((e: any) => e.value),
          MAX_GALLERIES_PER_PAGE,
          (page - 1) * MAX_GALLERIES_PER_PAGE
        ).then((res: any) => {
          setCount(res.count)
          setList(res.list)
          if (res.list.length === 0 && page > 1) {
            setPage(Math.ceil(res.count/MAX_GALLERIES_PER_PAGE) > 0 ? Math.ceil(res.count/MAX_GALLERIES_PER_PAGE) : 1)
            setSelectedGallery(null)
          } else if (props.initialData?.uuid && !initialSelectedGallerySet) {
            setSelectedGallery(res.list.find((e: any)=> e.uuid === props.initialData?.uuid))
            setInitialSelectedGallerySet(true)
          } else {
            setSelectedGallery(null)
          }
        }).catch(error => onErrorResponseShowMessage(error, true, { userUuid: userUuid }))
      } else if (props.initialData?.page === 1 && props.initialData?.uuid && !initialSelectedGallerySet) {
        setSelectedGallery(list.find((e: any)=> e.uuid === props.initialData?.uuid))
        setInitialSelectedGallerySet(true)
      }
    }
    if (props.initialData && !props.reload) {
      setInitialSelectedGallerySet(false)
    } 
    searchFormRef?.current?.resetForm()
  }, [props.reload])

  React.useEffect(() => {
    DataApi.getGalleryList(
      searchFormRef?.current?.values['search'] ?? '',
      searchFormRef?.current?.values['status']?.map((e: any) => e.value),
      MAX_GALLERIES_PER_PAGE,
      (page - 1) * MAX_GALLERIES_PER_PAGE
    ).then((res: any) => {
      setCount(res.count)
      setList(res.list)
      if (res.list.length === 0 && page > 1) {
        setPage(Math.ceil(res.count/MAX_GALLERIES_PER_PAGE) > 0 ? Math.ceil(res.count/MAX_GALLERIES_PER_PAGE) : 1)
        setSelectedGallery(null)
      } else if (props.initialData?.uuid && !initialSelectedGallerySet) {
        setSelectedGallery(res.list.find((e: any)=> e.uuid === props.initialData?.uuid))
        setInitialSelectedGallerySet(true)
      } else {
        setSelectedGallery(null)
      }
    }).catch(error => onErrorResponseShowMessage(error, true, { userUuid: userUuid }))
  }, [page])

  React.useEffect(() => {
    if (props.initialData && props.initialData.page !== page) {
      setPage(props.initialData.page)
    } else if (page !== 1) {
      setPage(1)
    } else {
      DataApi.getGalleryList(  
        initialFormValues.search,
        initialFormValues.status?.map((e: any) => e.value),
        MAX_GALLERIES_PER_PAGE,
        0
      ).then((res: any) => {
        setCount(res.count)
        setList(res.list)
        if (props.initialData?.uuid && !initialSelectedGallerySet) {
          setSelectedGallery(res.list.find((e: any)=> e.uuid === props.initialData?.uuid))
          setInitialSelectedGallerySet(true)
        }
      }).catch(error => onErrorResponseShowMessage(error, true, { userUuid: userUuid }))
    }
  }, [initialFormValues])

  const onSearchSubmit = async (data: any) => {
    try {
      setIsSearching(true)
      setSelectedGallery(null)
      if (page === 1) {
        console.log('HERE 1')
        const res = await DataApi.getGalleryList(
          data.search ?? '',
          data.status?.map((e: any) => e.value),
          MAX_GALLERIES_PER_PAGE,
          (page - 1) * MAX_GALLERIES_PER_PAGE
        )
        setCount(res.count)
        setList(res.list)
      } else {
        console.log('HERE 2')
        setPage(1)
      }
      setLastSearchedData(data)
    } catch (error) {
      onErrorResponseShowMessage(error, true, { userUuid: userUuid })
    } finally {
      setIsSearching(false)
    }
  }

  const toggleSelectedGallery = (e:any) => {
    if(selectedGallery == null || selectedGallery !== e) {
      setSelectedGallery(e)
    } else {
      setSelectedGallery(null)
    }
  }
  
  return (
    <div>
      <div className={styles.addNewSection} id={props.id}>
        <div>
          <LoadingButton
            type={'button'}
            className={styles.addNewButton}
            onClick={async () => {
              let state
              if (props.beforeAdd != null) {
                state = await props.beforeAdd()
              }
              if (state?.replace) {
                history.replace(
                  state.replace.url,
                  {
                    ...state.replace.state,
                    galleryLibraryData: {
                      page: page,
                      formValues: lastSearchedData
                    }
                  }
                )
              }
              if (state?.push) {
                history.push({
                  pathname: '/gallery/add',
                  state: {
                    ...state?.push,
                    galleryLibraryData: {
                      page: page,
                      formValues: lastSearchedData
                    }
                  }
                })
              }
            }}
          >
            <div className={styles.addNewSectionButtonContent}>
              <IoAddCircle size={18}/>
              {texts('galleryLibrary:add_new_button')}
            </div>
          </LoadingButton>
        </div>
        <div className={styles.addNewSectionButtonWarning}>
          {props.warnings?.add}
        </div>
      </div>
      <div className={styles.searchFormSection}>
        <div className={styles.searchFormSectionTitle}>
          {texts('galleryLibrary:search_title')}
        </div>
        <div className={styles.searchFormSectionForm}>
          <Formik
            innerRef={searchFormRef}
            initialValues={initialFormValues}
            enableReinitialize
            onSubmit={onSearchSubmit}
          >
            {(formikProps) => (
              <Form className={styles.searchForm}>                
                <div className={styles.searchField}>
                  <BaseInput
                    label={texts('galleryLibrary:search_form_title_label')}
                    name={'search'}
                    clearButton
                    formikProps={formikProps}
                    value={formikProps.values.search}
                    classes={{
                      containerWithLabel: styles.inputContainerWithLabel,
                      containerWithoutLabel: styles.inputContainerWithoutLabel,
                      fieldBase: styles.textFieldBase
                    }}
                  />
                </div>
                <div className={styles.searchField}>
                  <div className={styles.fieldLabel}>
                    {texts('galleryLibrary:search_form_status_label')}
                  </div>
                  <div
                    className={styles.clearButton}
                    onClick={() => { formikProps.setFieldValue('status', []) }}
                  >
                    {texts('common:clear_button_label')}
                  </div>
                  <CustomSelectField
                    height={'30px'}
                    portalZIndex={(props.baseZIndex ?? 0) + 1}
                    instanceId={'status-field'}
                    name={'status'}
                    isMulti
                    placeholder={'Wszystkie'}
                    options={GalleryStateOptionsList}
                    formikProps={formikProps}
                    value={formikProps.values.status}
                    error={
                      formikProps.touched.status && Boolean(formikProps.errors.status)
                    }
                  />
                </div>
                <div className={styles.searchButtonContainer}>
                  <LoadingButton
                    type={'button'}
                    className={styles.searchButton}
                    loading={isSearching}
                    circleSize={15}
                    circleColor={'#fff'}
                    onClick={() => {
                      searchFormRef?.current?.submitForm()
                    }}
                  >
                    <div className={styles.searchButtonContent}>
                      <AiOutlineSearch size={20}/>
                      <div>{texts('galleryLibrary:search_form_button_label')}</div>
                    </div>
                  </LoadingButton>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <div className={styles.mainSection}>
        <div className={styles.listSection}>
          {
            list.map(e => (
              <GalleryLibraryTile
                selected={e.uuid === selectedGallery?.uuid}
                onClick={() => {
                  toggleSelectedGallery(e)
                }}
                onEdit={async () => {
                  let state
                  if (props.beforeEdit != null) {
                    state = await props.beforeEdit()
                  }
                  if (state?.replace) {
                    history.replace(
                      state.replace.url,
                      {
                        ...state.replace.state,
                        galleryLibraryData: {
                          page: page,
                          formValues: lastSearchedData
                        }
                      }
                    )
                  }
                  if (state?.push) {
                    history.push({
                      pathname: `/gallery/edit/${e.uuid}`,
                      state: {
                        ...state?.push,
                        galleryLibraryData: {
                          page: page,
                          formValues: lastSearchedData
                        }
                      }
                    })
                  }
                }}
                editWarningLabel={props.warnings?.edit}
                gallery={e}
              />
            ))
          }
          {
            count === 0
            ? <div className={styles.noGalleryFoundMessage}>
                {texts('gallery:no_gallery')}
              </div>
            : null
          }
          {
            count > 0
            ? <div>
                <Pagination 
                  pages={Math.ceil(count/MAX_GALLERIES_PER_PAGE) > 0 ? Math.ceil(count/MAX_GALLERIES_PER_PAGE) : 1}
                  selectedPage={page}
                  onChange={(selectedPage) => {
                    setPage(selectedPage)
                  }}
                />
              </div>
            : null
          }
          
        </div>
      </div>
      <div className={styles.insertButtonContainer}>
        <LoadingButton
          disabled={selectedGallery == null}
          className={styles.insertButton}
          onClick={async () => { 
            if (!selectedGallery.active) {
              store.dispatch(addErrorMessage(MessageEnum.GALLERY_NOT_VALID_TO_INSERT))
            } else {
              await props.onFinish(selectedGallery) 
            }
          }}
        >
          <div className={styles.insertButtonContent}>
            {texts('galleryLibrary:insert_button_label')}
          </div>
        </LoadingButton>
      </div>
    </div>
  )
}

export default GalleryLibrary
