import * as Sentry from '@sentry/react'
import { ErrorMessage, Form, Formik } from 'formik'
import moment from 'moment'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { IoAddCircle } from 'react-icons/io5'
import { useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import MoonLoader from 'react-spinners/MoonLoader'
import DataApi from '../../api/DataApi'
import { addCmdMessage, addSuccessMessage } from '../../redux/messages/actions'
import { State } from '../../redux/reducers/index'
import store from '../../store'
import { GalleryPageType } from '../../types/common'
import { useConfirmLeaveHook } from '../../utils/hooks'
import { onErrorResponseShowMessage } from '../../utils/onErrorReponseShowMessage/onErrorResponseShowMessage'
import { editGalleryValidation } from '../../utils/validations/editGalleryValidation'
import BaseInput from '../common/BaseInput/BaseInput'
import CustomDialog from '../common/CustomDialog/CustomDialog'
import CustomFlatpickr from '../common/CustomFlatpickr/CustomFlatpickr'
import CustomSwitch from '../common/CustomSwitch/CustomSwitch'
import GalleryPageTile, { GalleryTileData } from '../common/GalleryPageTile/GalleryPageTile'
import HtmlValidationErrorDialog from '../common/HtmlValidatorDialog/HtmlValidatorDialog'
import LoadingButton from '../common/LoadingButton/LoadingButton'
import Pagination from '../common/Pagination/Pagination'
import RouteLeavingGuard from '../common/RouteLeavingGuard/RouteLeavingGuard'
import { MessageEnum } from '../CustomToastProvider/types'
import styles from './styles.module.scss'
import { GalleryLibraryStateData } from '../common/GalleryLibrary/GalleryLibrary'

export type InitialFormValues = {
  title: string
  author: string
  draft: boolean | null | undefined
  adultContent: boolean
  publishedAt: Date | null | undefined
  modifiedAt: Date | null | undefined
  showAdultWarning: boolean
  activePageCount: number
  active: boolean | null | undefined
  editor: string
} 

type LocationState = {
  pageType?: GalleryPageType
  pages?: any[]
  uuid?: string
  data?: InitialFormValues,
  articleData?: {
    uuid?: string
    initialValues?: any
    mainImage?: any
    active?: boolean
    originalDetails?: any
  }
  originalGalleryDetails?: any
  galleryLibraryData?: GalleryLibraryStateData
  originalActiveState?: boolean
}

const initialValues: InitialFormValues ={
  title: '',
  author: '',
  draft: false,
  adultContent: false,
  publishedAt: undefined,
  showAdultWarning: false,
  modifiedAt: undefined,
  activePageCount: 0,
  active: true,
  editor: '',
}

const MAX_ELEMENTS_PER_PAGE = 30

const EditGalleryPage = () => {

  const { uuid } = useParams<{ uuid: string }>()
  const texts = useTranslation().t
  const history = useHistory()
  const location = useLocation<LocationState>()
  const userUuid = useSelector((e: State) => e.user.uuid)

  const [initialFormValues, setInitialFormValues] = React.useState<InitialFormValues>(initialValues)

  const [adultContent, setAdultContent] = React.useState<boolean>(false)
  const [showAdultWarning, setShowAdultWarning] = React.useState<boolean>(false)

  const [details, setDetails] = React.useState<any>(null)
  const [originalActiveState, setOriginalActiveState] = React.useState<boolean>(false)

  const [showHtmlValidationErrorDialog, setShowHtmlValidationErrorDialog] = React.useState<boolean>(false)
  
  const [htmlValidationErrors, setHtmlValidationErrors] = React.useState<string[]>([])

  const [isActive, setIsActive] = React.useState<boolean>(true)
  const [isDraft, setIsDraft] = React.useState<boolean>(location.state?.data?.draft ?? true)
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false)
  const [isNavigationGuarded, setIsNavigationGuarded] = React.useState<boolean>(false)
  const [isEditActive, setIsEditActive] = React.useState<boolean>(false)

  const [galleryPages, setGalleryPages] = React.useState<any[]>([])
  const [selectedListPage, setSelectedListPage] = React.useState<number>(1)

  const [showDeactivateDialog, setShowDeactivateDialog] = React.useState<boolean>(false)
  const [showActivateDialog, setShowActivateDialog] = React.useState<boolean>(false)

  const [shouldResetForm, setShouldResetForm] = React.useState<boolean>(false)

  const [stateToPush, setStateToPush] = React.useState<any>(null)

  const formRef = useRef() as any
 
  React.useEffect(() => {
    if (!location.state) { 
      history.goBack()
    } else if (location.state?.data && location.state?.pages) {
      setIsDraft(location.state.data.draft ?? false)
      setIsEditActive(true)
      setDetails(location.state.originalGalleryDetails)
      setOriginalActiveState(location.state.originalActiveState ?? true)
    } else {
      DataApi.getGalleryDetails(uuid).then((res: any) => {
        setIsDraft(res.data.draft)
        setDetails(res)
        setOriginalActiveState(res.data.active)
      }).catch(error => {
        onErrorResponseShowMessage(error, true, { userUuid: userUuid })
      })
    }
    setIsNavigationGuarded(true)
  }, [])
  
  React.useEffect(() => {
    if (!isLoading && !isSubmitting) {
      formRef?.current?.validateForm()
      formRef?.current?.submitForm()
    } 
  }, [isDraft])

  React.useEffect(() => {
    if (stateToPush) {
      if (stateToPush.replace) {
        history.replace(
          stateToPush.replace.url,
          stateToPush.replace.state
        )
      }
      if (stateToPush.push) {
        history.push(
          stateToPush.push.url,
          stateToPush.push.state
        )
      }
    }
  }, [stateToPush])

  React.useEffect(() => {
    setIsNavigationGuarded(isEditActive)
  }, [isEditActive])

  React.useEffect(() => {
    if (details != null) {
      setInitialValues(shouldResetForm)
      setIsLoading(false)
      setShouldResetForm(false)
    }
  }, [details])

  const setInitialValues = (resetForm?: boolean) => {
    if (!resetForm && location.state?.data && location.state?.pages) {
      setGalleryPages(setPageOrder(location.state.pages))
      setAdultContent(location.state.data.adultContent)
      setShowAdultWarning(location.state.data.showAdultWarning)
      setIsActive(location.state.data.active ?? false)
      setInitialFormValues({
        ...location.state.data,
        author: location.state.data.author,
        publishedAt: location.state.data.publishedAt ? moment(location.state.data.publishedAt).toDate() : undefined,
        modifiedAt: location.state.data.modifiedAt ? moment(location.state.data.modifiedAt).toDate() : undefined,
        activePageCount: location.state.pages.filter((e: any) => e.active).length,
        editor: location.state.data.editor ?? '-'
      })
    } else {
      setGalleryPages(setPageOrder(details.pages))
      setAdultContent(details.data.adultContent)
      setShowAdultWarning(details.data.showAdultWarning)
      setIsActive(details.data.active ?? false)
      setInitialFormValues({
        ...details.data,
        author: `${details.data.author.name} ${details.data.author.lastName}`,
        publishedAt: details.data.publishedAt ? moment(details.data.publishedAt).toDate() : undefined,
        modifiedAt: details.data.modifiedAt ? moment(details.data.modifiedAt).toDate() : undefined,
        activePageCount: details.pages.filter((e: any) => e.active).length,
        editor: details.data.lastEditor ? `${details.data.lastEditor.name} ${details.data.lastEditor.lastName}` : '-' 
      })
    }
    

    if (formRef?.current) {
      formRef?.current?.resetForm({})
      formRef?.current?.setErrors({})
    }
  }

  const openHtmlValidationErrorDialog = () => {
    store.dispatch(addCmdMessage(MessageEnum.ENABLE_PAGE_COVER))
    setShowHtmlValidationErrorDialog(true)
  }

  const closeHtmlValidationErrorDialog = () => {
    store.dispatch(addCmdMessage(MessageEnum.DISABLE_PAGE_COVER))
    setShowHtmlValidationErrorDialog(false)
  }

  const handleSubmit = async (data: any) => {
    try {
      setIsSubmitting(true)
      const mappedData = {
        active: data.active,
        draft: isDraft,
        name: data.title,
        adultContent: data.adultContent,
        showAdultWarning: data.showAdultWarning,
        pages: galleryPages.map((e, idx) => {
          return {
            uuid: e.uuid,
            photoUuid: e.photo!.uuid,
            text: e.content,
            active: e.active,
            activeOrder: e.order,
            totalOrder: idx,
            adultContent: e.adultContent,
            showAdultWarning: e.showAdultWarning,
          }
        })
      }
      await DataApi.updateGallery(uuid, mappedData)
      store.dispatch(addSuccessMessage(MessageEnum.GALLERY_UPDATED))
      setIsNavigationGuarded(false)
      if (location.state?.pageType != null && location.state?.pageType !== GalleryPageType.UNDEFINED) {
        let url = ''
        switch (location.state.pageType) {
          case GalleryPageType.FROM_ADD_ARTICLE:
            url = '/article/add'
            break
          case GalleryPageType.FROM_EDIT_ARTICLE:
            url = `/article/edit/${location.state?.articleData?.uuid}`
            break
        }
        history.push({
          pathname: url,
          state: {
            galleryData: {
              uuid: uuid
            },
            articleData: location.state.articleData,
            ...(location.state.galleryLibraryData ? {
              galleryLibraryData: {
                ...location.state.galleryLibraryData,
                uuid: uuid
              }
            } : {}) 
          }
        })
      }
    } catch (error: any) {
      if (error.response?.status === 400 && error.response?.data?.code === 200 && error.response?.data?.subcode === 214 && error.response?.data?.data != null) {
        setHtmlValidationErrors(error.response.data.data?.messages ?? [])
        openHtmlValidationErrorDialog()
        if (process.env.REACT_APP_ENV !== "development") {
          Sentry.captureException(new Error('HTML Validation Error'), {
            extra: {
              userUuid: userUuid,
              data: JSON.stringify(error.response.data.data?.result)
            }
          })
        }
      } else {
        onErrorResponseShowMessage(error, true, { userUuid: userUuid })
      }
    } finally {
      setIsSubmitting(false)
    }
  }

  const openDeactivateDialog = () => {
    store.dispatch(addCmdMessage(MessageEnum.ENABLE_PAGE_COVER))
    setShowDeactivateDialog(true)
  }

  const closeDeactivateDialog = () => {
    store.dispatch(addCmdMessage(MessageEnum.DISABLE_PAGE_COVER))
    setShowDeactivateDialog(false)
  }

  const openActivateDialog = () => {
    store.dispatch(addCmdMessage(MessageEnum.ENABLE_PAGE_COVER))
    setShowActivateDialog(true)
  }

  const closeActivateDialog = () => {
    store.dispatch(addCmdMessage(MessageEnum.DISABLE_PAGE_COVER))
    setShowActivateDialog(false)
  }

  const handleDeactivateGallery = async () => {
    try {
      await DataApi.changeGalleryActiveState(uuid, false)
      const res = await DataApi.getGalleryDetails(uuid)
      setIsDraft(res.data.draft)
      setDetails(res)
      setIsActive(false)
      formRef?.current?.setFieldValue('active', false)
      closeDeactivateDialog()
      store.dispatch(addSuccessMessage(MessageEnum.GALLERY_DEACTIVATED))
    } catch (error) {
      onErrorResponseShowMessage(error, true, { userUuid: userUuid })
    }
  }

  const handleActivateGallery = async () => {
    try {
      await DataApi.changeGalleryActiveState(uuid, true)
      const res = await DataApi.getGalleryDetails(uuid)
      setIsDraft(res.data.draft)
      setDetails(res)
      setIsActive(true)
      formRef?.current?.setFieldValue('active', true)
      closeActivateDialog()
      store.dispatch(addSuccessMessage(MessageEnum.GALLERY_ACTIVATED))
    } catch (error) {
      onErrorResponseShowMessage(error, true, { userUuid: userUuid })
    }
  }

  const deactivateDialogContent = () => {
    return (
      <>
        <div className={styles.deactivateDialogText}>
          {texts('gallery:gallery_delete_dialog')}
        </div>
        <div className={styles.deactivateDialogButtonsSection}>
          <LoadingButton 
            text={texts('dashboard:confirm_logout_dialog_cancel_button_label')}
            className={styles.deactivateCancelButton}
            onClick={() => {
              closeDeactivateDialog()
            }}
          />
          <LoadingButton 
            text={texts('gallery:confirm_gallery_delete_dialog')}
            className={styles.deactivateConfirmButton}
            onClick={() => {
              setIsNavigationGuarded(false)
              handleDeactivateGallery()
            }}
          /> 
        </div>
      </>
    )
  }

  const activateDialogContent = () => {
    return (
      <>
        <div className={styles.deactivateDialogText}>
          {texts('gallery:gallery_restore_dialog')}
        </div>
        <div className={styles.deactivateDialogButtonsSection}>
          <LoadingButton 
            text={texts('dashboard:confirm_logout_dialog_cancel_button_label')}
            className={styles.deactivateCancelButton}
            onClick={() => {
              closeActivateDialog()
            }}
          />
          <LoadingButton 
            text={texts('gallery:confirm_gallery_restore_dialog')}
            className={styles.deactivateConfirmButton}
            onClick={() => {
              setIsNavigationGuarded(false)
              handleActivateGallery()
            }}
          /> 
        </div>
      </>
    )
  }

  const setPageOrder = (arr: GalleryTileData[]): GalleryTileData[] => {
    let count = 1
    for (const page of arr) {
      if (page.active) {
        page.order = count++
      } else {
        page.order = undefined
      }
    }
    return arr
  }

  const goBack = () => {
    if (location.state?.pageType != null && location.state?.pageType !== GalleryPageType.UNDEFINED) {
      let url = ''
      switch (location.state.pageType) {
        case GalleryPageType.FROM_ADD_ARTICLE:
          url = '/article/add'
          break
        case GalleryPageType.FROM_EDIT_ARTICLE:
          url = `/article/edit/${location.state?.articleData?.uuid}`
          break
      }
      history.push({
        pathname: url,
        state: {
          articleData: location.state.articleData,
          ...(location.state.galleryLibraryData ? {
            galleryLibraryData: {
              ...location.state.galleryLibraryData,
              ...(!originalActiveState && isActive ? {
                uuid: uuid
              } : {})
            }
          } : {})
        }
      })
    }
  }

  useConfirmLeaveHook(isNavigationGuarded)

  return (
    <>
      <RouteLeavingGuard
        when={isEditActive && isNavigationGuarded}
        shouldBlockNavigation={location => {
          if ((location.pathname.startsWith('/gallery/edit') && !location.pathname.endsWith('/page')) || location.pathname === '/login') {
            return false
          }
          return isEditActive && isNavigationGuarded
        }}
      /> 
      <HtmlValidationErrorDialog 
        hidden={!showHtmlValidationErrorDialog}
        errorMessages={htmlValidationErrors}
        onCloseClick={() => {
          closeHtmlValidationErrorDialog()
        }}
      />
      <CustomDialog
        hidden={!showDeactivateDialog}
        title={texts('gallery:gallery_delete_title_dialog')}
        closeButton
        onCloseButtonClick={closeDeactivateDialog}
        classes={{
          container: styles.deactivateDialog
        }}
      >
        {deactivateDialogContent()}
      </CustomDialog>
      <CustomDialog
        hidden={!showActivateDialog}
        title={texts('gallery:gallery_restore_dialog')}
        closeButton
        onCloseButtonClick={closeActivateDialog}
        classes={{
          container: styles.deactivateDialog
        }}
      >
        {activateDialogContent()}
      </CustomDialog>
      
      {
        isLoading
        ? <div className={styles.loadingSpinnerContainer}>
            <MoonLoader loading={true} size={100}/>
          </div>
        : <>

            <div className={styles.controlButtonSection}>
              <LoadingButton
                className={styles.backButton}
                onClick={goBack}
                type={"button"}
              > 
                {texts('common:go_back_button_label')}
              </LoadingButton>
              <div className={styles.flexSpacer}></div>
              {
                initialFormValues?.draft
                ? <LoadingButton 
                    className={styles.submitDraftButton}
                    loading={isSubmitting}
                    type={'button'}
                    disabled={!isEditActive}
                    style={{
                      ...(!isEditActive ? { opacity: 0.5, cursor: 'default' } : {})
                    }}
                    onClick={() => {
                      if (formRef.current?.values.draft) {
                        formRef.current?.submitForm()
                      } else {
                        formRef.current?.setFieldValue('draft', true)
                        setIsDraft(true)
                      }
                    }}
                  >
                    <div className={styles.formButtonContent}>{texts('common:save_draft_button')}</div>
                  </LoadingButton>
                : null
              }
              <LoadingButton 
                className={styles.submitFormButton}
                loading={isSubmitting}
                disabled={!isEditActive}
                style={{
                  ...(!isEditActive ? { opacity: 0.5, cursor: 'default' } : {})
                }}
                type={'button'}
                onClick={() => {
                  setIsDraft(false)
                  if (!formRef.current?.values.draft) {
                    formRef.current?.submitForm()
                  } else {
                    formRef.current?.setFieldValue('draft', false)
                  }
                }}
              >
                <div className={styles.formButtonContent}>{texts('gallery:publish_button')}</div>
              </LoadingButton>
            </div>
            <div className={styles.pageTitleContainer}>
              <h1 className={styles.pageTitle}>
                {texts('gallery:edit_gallery_button')}
              </h1>
              <button
                className={[
                  isEditActive ? styles.deactivateEditButton : styles.activateEditButton,
                  styles.baseEditButton
                ].join(' ')}
                onClick={async () => {
                  if (isEditActive) {
                    setIsEditActive(false)
                    try {
                      const res = await DataApi.getGalleryDetails(uuid)
                      setShouldResetForm(true)
                      setIsDraft(res.data.draft)
                      setDetails(res)
                    } catch (error) {
                      onErrorResponseShowMessage(error, true, { userUuid: userUuid })
                    }
                  } else {
                    setIsEditActive(true)
                  }
                }}
              >
                {isEditActive ? texts('gallery:cancel_edit_button') : texts('gallery:activate_edit_button')}
              </button>
              <button
                disabled={isEditActive}
                style={{
                  ...(isEditActive ? { cursor: 'default' } : {})
                }}
                className={[
                  isActive ? styles.deactivateGalleryButton : styles.activateGalleryButton,
                  styles.baseEditButton
                ].join(' ')}
                onClick={() => {
                  if (isActive) {
                    openDeactivateDialog()
                  } else {
                    openActivateDialog()
                  }
                }}
              >
                {isActive ? texts('gallery:gallery_delete_button') : texts('gallery:gallery_restore_button')}
              </button>
            </div>
            <div className={styles.formContainer}>
              <Formik
                innerRef={formRef}
                initialValues={initialFormValues}
                validateOnBlur={true}
                validateOnChange={true}
                validateOnMount={false}
                onSubmit={handleSubmit}
                enableReinitialize
                validationSchema={editGalleryValidation(texts)}
              >
                {(formikProps) => (
                  <Form className={styles.form}>
                    <div className={styles.section}>
                      <div className={styles.formRow}>
                        <div className={styles.field} style={{ width: '100%' }}>
                          <BaseInput
                            label={texts('gallery:title_label')}
                            disabled={!isEditActive}
                            name={'title'}
                            formikProps={formikProps}
                            classes={{
                              containerWithLabel: styles.inputContainerWithLabel,
                              containerWithoutLabel: styles.inputContainerWithoutLabel,
                              fieldBase: styles.textFieldBase
                            }}
                            value={formikProps.values.title}
                            maxLen={80}
                            count={true}
                            error={formikProps.touched.title && Boolean(formikProps.errors.title)}
                          />
                        </div>
                      </div>

                      <div className={styles.formRow}>
                        <div className={styles.field} style={{ width: '26.45%' }}>
                          <BaseInput
                            label={texts('gallery:author_label')}
                            name={'author'}
                            disabled={true}
                            formikProps={formikProps}
                            classes={{
                              containerWithLabel: styles.inputContainerWithLabel,
                              containerWithoutLabel: styles.inputContainerWithoutLabel,
                              fieldBase: styles.textFieldBase
                            }}
                            value={formikProps.values.author}
                            error={formikProps.touched.author && Boolean(formikProps.errors.author)}
                          />
                        </div>                        
                        <div className={styles.field} style={{ width: '26.45%' }}>
                          <BaseInput
                            label={texts('gallery:edited_by_label')}
                            name={'editor'}
                            disabled={true}
                            formikProps={formikProps}
                            classes={{
                              containerWithLabel: styles.inputContainerWithLabel,
                              containerWithoutLabel: styles.inputContainerWithoutLabel,
                              fieldBase: styles.textFieldBase
                            }}
                            value={formikProps.values.editor}
                          />
                        </div>
                        <div className={styles.field} style={{ width: '23.54%' }}>
                          <div className={styles.fieldLabelWithResetContainer}>
                            <div className={styles.fieldLabel}>
                              {texts('common:publish_date_label')}
                            </div>
                          </div>
                          <CustomFlatpickr
                            name={'publishedAt'}
                            disabled={true}
                            formikProps={formikProps}
                            placeholder={texts('common:publish_date_placeholder')}
                            withTime
                            setInitial
                          />
                          <div className={styles.errorLabel}>
                          {
                            formikProps.errors.publishedAt ?? <ErrorMessage name={'publishedAt'} />
                          }
                          </div>
                        </div>
                        <div className={styles.field} style={{ width: '23.54%' }}>
                          <div className={styles.fieldLabelWithResetContainer}>
                            <div className={styles.fieldLabel}>
                              {texts('common:update_date_label')}
                            </div>
                          </div>
                          <CustomFlatpickr
                            name={'modifiedAt'}
                            disabled={true}
                            formikProps={formikProps}
                            // placeholder={texts('common:publish_date_placeholder')}
                            placeholder={'-'}
                            withTime
                            setInitial
                          />
                        </div>                  
                      </div>                      
                      <div className={styles.subsection}>
                        <div className={styles.subsectionTitle}>
                          {texts('gallery:additional_fields_label')}
                        </div>
                        <div className={styles.switchField}>
                          <div>
                            <CustomSwitch 
                              name={'adultContent'}
                              disabled={!isEditActive}
                              checked={adultContent}
                              setChecked={setAdultContent}
                              formikProps={formikProps}
                            />
                          </div>
                          <div className={styles.switchLabel} style={{ color: 'red' }}>
                            {texts('common:adult_content_label')}
                          </div>
                          <div className={styles.showDialogCheckboxAdditionalLabel}>
                            {texts('common:adult_content_additional_label')}
                          </div>
                        </div>
                        <div className={styles.switchField}>
                          <div>
                            <CustomSwitch 
                              name={'showAdultWarning'}
                              disabled={!isEditActive}
                              checked={showAdultWarning}
                              setChecked={setShowAdultWarning}
                              formikProps={formikProps}
                            />
                          </div>
                          <div className={styles.switchLabel} style={{ color: 'red' }}>
                            {texts('common:show_adult_warning_label')}
                          </div>
                          <div className={styles.showDialogCheckboxAdditionalLabel}>
                            {texts('common:show_adult_warning_additional_label')}
                          </div>
                        </div>
                        <div className={styles.adultContentWarning}>
                          {texts('common:adult_content_warning')}
                        </div>
                      </div>
                    </div>
                    <div className={styles.section}>
                      <div className={styles.sectionTitle}>
                        {texts('gallery:gallery_pages_label')}
                      </div>
                      <button
                        className={styles.innerFormButton}
                        disabled={!isEditActive}
                        style={{
                          ...(!isEditActive ? { cursor: 'default' } : {})
                        }}
                        type={'button'}
                        onClick={() => {
                          setIsNavigationGuarded(false)
                          setStateToPush({
                            replace: {
                              url: `/gallery/edit/${uuid}`,
                              state: {
                                pageType: location.state.pageType,
                                data: formikProps.values,
                                pages: galleryPages,
                                articleData: location.state.articleData,
                                originalGalleryDetails: location.state.originalGalleryDetails ?? details,
                                galleryLibraryData: {
                                  ...location.state.galleryLibraryData
                                },
                                originalActiveState: originalActiveState
                              }
                            },
                            push: {
                              url: `/gallery/edit/${uuid}/page`,
                              state: {
                                uuid: uuid,
                                pageType: location.state.pageType,
                                pages: galleryPages,
                                galleryData: formikProps.values,
                                articleData: location.state.articleData,
                                originalGalleryDetails: location.state.originalGalleryDetails ?? details,
                                galleryLibraryData: {
                                  ...location.state.galleryLibraryData
                                }
                              },
                              originalActiveState: originalActiveState
                            }
                          })
                        }}
                      >
                        <div className={styles.innerFormButtonContent}>
                          <IoAddCircle size={20}/>
                          <div className={styles.innerFormButtonContentLabel}>
                            {texts('gallery:add_gallery_page_button')}
                          </div>
                        </div>
                      </button>
                      <div className={styles.pageList}>
                        {
                          galleryPages.slice((selectedListPage - 1) * MAX_ELEMENTS_PER_PAGE, (selectedListPage) * MAX_ELEMENTS_PER_PAGE).map((e, idx) => (
                            <div className={styles.tileContainer}>
                              <GalleryPageTile
                                disabledControls={!isEditActive}
                                index={(selectedListPage - 1) * MAX_ELEMENTS_PER_PAGE + idx + 1}
                                maxIndex={galleryPages.length}
                                activeCount={formikProps.values.activePageCount}
                                data={e}
                                onDown={() => {
                                  const tmpArr: GalleryTileData[] = [...galleryPages] as any[]
                                  [tmpArr[idx], tmpArr[idx + 1]] = [tmpArr[idx + 1], tmpArr[idx]]
                                  setGalleryPages(setPageOrder(tmpArr))
                                }}
                                onUp={() => {
                                  const tmpArr: GalleryTileData[] = [...galleryPages] as any[]
                                  [tmpArr[idx], tmpArr[idx - 1]] = [tmpArr[idx - 1], tmpArr[idx]]
                                  setGalleryPages(setPageOrder(tmpArr))
                                }}
                                onEdit={() => {
                                  setIsNavigationGuarded(false)
                                  setStateToPush({
                                    replace: {
                                      url: `/gallery/edit/${uuid}`,
                                      state: {
                                        pageType: location.state.pageType,
                                        data: formikProps.values,
                                        pages: galleryPages,
                                        articleData: location.state.articleData,
                                        originalGalleryDetails: location.state.originalGalleryDetails ?? details,
                                        galleryLibraryData: {
                                          ...location.state.galleryLibraryData
                                        },
                                        originalActiveState: originalActiveState
                                      }
                                    },
                                    push: {
                                      url: `/gallery/edit/${uuid}/page`,
                                      state: {
                                        uuid: uuid,
                                        pages: galleryPages,
                                        pageType: location.state.pageType,
                                        galleryData: formikProps.values,
                                        editIndex: idx,
                                        articleData: location.state.articleData,
                                        originalGalleryDetails: location.state.originalGalleryDetails ?? details,
                                        galleryLibraryData: {
                                          ...location.state.galleryLibraryData
                                        }
                                      },
                                      originalActiveState: originalActiveState
                                    }
                                  })
                                }}
                                onHide={() => {
                                  const tmpArr = [...galleryPages]
                                  tmpArr[idx].active = !tmpArr[idx].active
                                  setGalleryPages(setPageOrder(tmpArr))
                                  formikProps.values.activePageCount = tmpArr.filter(e => e.active).length
                                }}
                              />
                            </div>
                          ))
                        }
                        {
                          galleryPages.length > MAX_ELEMENTS_PER_PAGE
                          ? <div>
                              <Pagination 
                                pages={Math.ceil(galleryPages.length/MAX_ELEMENTS_PER_PAGE) > 0 ? Math.ceil(galleryPages.length/MAX_ELEMENTS_PER_PAGE) : 1}
                                selectedPage={selectedListPage}
                                onChange={(selectedPage) => {
                                  setSelectedListPage(selectedPage)
                                }}
                              />
                            </div>
                          : null
                        }
                        <div className={styles.errorLabel}>
                          {
                            (formikProps.errors.activePageCount) ?? <ErrorMessage name={'activePageCount'} />
                          }
                        </div>
                      </div>
                    </div>
                    <div className={styles.formButtonSection}>
                      <LoadingButton
                        className={styles.backButton}
                        onClick={goBack}
                        type={"button"}
                      > 
                        {texts('common:go_back_button_label')}
                      </LoadingButton>
                      <div className={styles.flexSpacer}></div>
                      {
                        initialFormValues?.draft
                        ? <LoadingButton 
                            className={styles.submitDraftButton}
                            loading={isSubmitting}
                            type={'button'}
                            disabled={!isEditActive}
                            style={{
                              ...(!isEditActive ? { opacity: 0.5, cursor: 'default' } : {})
                            }}
                            onClick={() => {
                              if (formikProps.values.draft) {
                                formikProps.submitForm()
                              } else {
                                formikProps.setFieldValue('draft', true)
                                setIsDraft(true)
                              }
                            }}
                          >
                            <div className={styles.formButtonContent}>{texts('common:save_draft_button')}</div>
                          </LoadingButton>
                        : null
                      }
                      <LoadingButton 
                        className={styles.submitFormButton}
                        loading={isSubmitting}
                        disabled={!isEditActive}
                        style={{
                          ...(!isEditActive ? { opacity: 0.5, cursor: 'default' } : {})
                        }}
                        type={'button'}
                        onClick={() => {
                          setIsDraft(false)
                          if (!formikProps.values.draft) {
                            formikProps.submitForm()
                          } else {
                            formikProps.setFieldValue('draft', false)
                          }
                        }}
                      >
                        <div className={styles.formButtonContent}>{texts('gallery:publish_button')}</div>
                      </LoadingButton>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </>
      }
    </>
  )
}

export default EditGalleryPage
