import { Tag } from 'bbcode-to-react'
import HorizontalAdBlock from '../../../../../HorizontalAdBlock/HorizontalAdBlock'
import styles from './style.module.scss'

export default class DepeszaAdBlockTag<T = { linkify: boolean }> extends Tag<T> {
  toReact() {
    return (
      <HorizontalAdBlock />
    )
  }
}