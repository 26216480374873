import { Tag } from 'bbcode-to-react'
import styles from './style.module.scss'

export default class DepeszaParagraphTag<T = { linkify: boolean }> extends Tag<T> {
  toReact() {
    return (
      <p className={styles.p_container}>
        {this.getComponents()}
      </p>
    )
  }
}