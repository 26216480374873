import { ErrorMessage, Field, FormikProps } from 'formik'
import CustomInputField from '../CustomInputField/CustomInputField'
import styles from './styles.module.scss'
import { useTranslation } from 'react-i18next'

type Props = {
  containerId?: string
  name: string
  rows?: number
  innerRef?: any
  value?: string
  setValue?: React.Dispatch<React.SetStateAction<string>>
  type?: string
  maxLen?: number
  count?: boolean
  byteLen?: boolean
  label?: string
  placeholder?: string
  error?: boolean
  warning?: boolean
  warningLabel?: string
  disabled?: boolean
  formikProps?: FormikProps<any>
  inputPattern?: RegExp
  resize?: string
  clearButton?: boolean
  styles?: {
    container?: React.CSSProperties
    label?: React.CSSProperties
    input?: React.CSSProperties
  }
  classes?: {
    containerBase?: string
    containerWithLabel?: string
    containerWithoutLabel?: string
    label?: string
    fieldBase?: string
    fieldNormal?: string
    fieldError?: string
    fieldWarning?: string
  }
}

const BaseInput = (props: Props) => {

  const texts = useTranslation().t

  return (
    <div 
      id={props.containerId}
      className={
        props.classes?.containerBase ?? `
        ${styles.container} 
        ${
          props.label 
          ? props.classes?.containerWithLabel ??styles.containerWithLabel 
          : props.classes?.containerWithoutLabel ??styles.containerWithoutLabel
        }
        `
      }
      style={{ 
        ...(props.styles?.container ?? {})
      }}
    >
      {
        props.label || props.clearButton
        ? <div className={styles.labelContainer}>
            {
              props.label 
              ? <label htmlFor={props.name}>
                  <div
                    className={props.classes?.label ?? styles.label}
                    style={{
                      ...(props.styles?.label ?? {})
                    }}
                  >
                    {props.label}
                  </div>
                </label> 
              : null 
            }
            {
              props.clearButton 
              ? <div className={styles.clearButton} onClick={()=> {
                  if (props.setValue) {
                    props.setValue('')
                  }
                  if (props.formikProps) {
                    props.formikProps.setFieldValue(props.name, '')
                  }
                }}>
                  {texts('common:clear_button_label')}
                </div>
              : null 
            }
          </div>
        : null
      }
      
      <Field
        id={props.name}
        name={props.name}
        as={CustomInputField}
        className={
          [
            props.classes?.fieldBase ?? styles.textField,
            props.error ? (props.classes?.fieldError ?? styles.textFieldError) : '',
            props.warning && !props.error ? (props.classes?.fieldWarning ?? styles.textFieldWarning) : '',
            !props.warning && !props.error ? (props.classes?.fieldNormal ?? styles.textFieldNormal) : ''
          ].join(' ')
        }
        error={props.error}
        disabled={props.disabled}
        placeholder={props.placeholder}
        type={props.type ? props.type : 'text'}
        count={props.count}
        maxLen={props.maxLen}
        byteLen={props.byteLen}
        value={props.value}
        resize={props.resize}
        setValue={props.setValue}
        formikProps={props.formikProps}
        inputPattern={props.inputPattern}
        rows={props.rows}
        innerRef={props.innerRef}
        customStyle={props.styles?.input}
        errorLabel={props.error && <ErrorMessage name={props.name} />}
        warning={props.warning}
        warningLabel={props.warningLabel}
      />
    </div>
  )
}

export default BaseInput
