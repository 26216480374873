import { Form, Formik } from 'formik'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import MoonLoader from 'react-spinners/MoonLoader'
import { onErrorResponseShowMessage } from '../../utils/onErrorReponseShowMessage/onErrorResponseShowMessage'
import ArticleSearchForm from '../common/ArticleSearchForm/ArticleSearchForm'
import { Article } from '../common/ArticleTile/ArticleTile'
import AttachedArticle from '../common/AttachedArticle/AttachedArticle'
import BaseInput from '../common/BaseInput/BaseInput'
import LoadingButton from '../common/LoadingButton/LoadingButton'
import Pagination from '../common/Pagination/Pagination'
import styles from './styles.module.scss'
import { MAX_ATTACHED_ARTICLES_WITHOUT_PHOTO, MAX_ATTACHED_ARTICLES_WITH_PHOTO, MAX_ARTICLE_PER_PAGE, ArticleState } from '../../types/common'
import { encode } from 'html-entities'

export enum DeepLinkTagType {
  SINGLE = 'single',
  MULTI = 'multi'
}

type InitialFormValues = {
  option: string
  title: string
}

const initialValues: InitialFormValues = {
  option: 'title',
  title: '',
}

type Props = {
  baseZIndex?: number
  excludedArticleUuid?: string
  onFinish: (type: DeepLinkTagType, uuids: string, title?: string) => void
}

const AddLinkToAnotherArticle = (props: Props) => {
  const texts = useTranslation().t
  
  const [initialFormValues, setInitialFormValues] = React.useState<InitialFormValues>(initialValues)    
  const [selectedType, setSelectedType] = React.useState<DeepLinkTagType>(DeepLinkTagType.SINGLE)
  const [selectedArticles, setSelectedArticles] = React.useState<Article[]>([])
  const [errorText, setErrorText] = React.useState<string>('')
  const [articles, setArticles] = React.useState<Article[]>([])
  const [articleCount, setArticleCount] = React.useState<number>(0)
  const [selectedPage, setSelectedPage] = React.useState<number>(1)
  const [isLoading, setIsLoading] = React.useState<boolean>(true)

  const formRef = useRef() as any

  const handleSubmit = async (data: any) => {
    try {
      if (selectedArticles.length > 0) {
        props.onFinish(selectedType, selectedArticles.map(e => e.uuid).join(';'), data.title ? `"${encode(data.title)}"` : undefined)
      }
    } catch (error) {
      onErrorResponseShowMessage(error)
    }
  }

  const resetSelectedArticles = () => {
    setErrorText('')
    setSelectedArticles([])
  }

  const onChangeValue = (event: any) => {
    setSelectedType(event.target.value);
    resetSelectedArticles()
  }  

  const addArticleToSelected = (article: Article) => {
    if(
      (selectedType === DeepLinkTagType.SINGLE && selectedArticles.length >= MAX_ATTACHED_ARTICLES_WITHOUT_PHOTO) 
      || (selectedType === DeepLinkTagType.MULTI && selectedArticles.length >= MAX_ATTACHED_ARTICLES_WITH_PHOTO)
    ) {
      setErrorText(texts('article:full'))
    } else if (selectedArticles.indexOf(article) >= 0) {
      setErrorText(texts('article:already_chosen'))
    } else {
      setSelectedArticles([...selectedArticles, article])
      setErrorText('')
    }
  }

  const removeArticleFromSelected = (article: Article) => {
    selectedArticles.splice(selectedArticles.indexOf(article),1)
    setSelectedArticles([...selectedArticles])
    setErrorText('')      
  }

  const moveUpSelectedArticle = (article: Article) => {
    let index = selectedArticles.indexOf(article)
    swapSelectedArticlePosition(index, index-1)
  }

  const moveDownSelectedArticle = (article: Article) => {
    let index = selectedArticles.indexOf(article)
    swapSelectedArticlePosition(index, index+1)
  }

  const swapSelectedArticlePosition = (indexA: number, indexB: number) => {
    const tmpArr: Article[] = [...selectedArticles] as any[]
    [tmpArr[indexA], tmpArr[indexB]] = [tmpArr[indexB], tmpArr[indexA]]
    setSelectedArticles(tmpArr)
    setErrorText('')
  }

  return (
    <>
      <Formik
        initialValues={initialFormValues}
        onSubmit={handleSubmit}
        validateOnBlur={true}
        validateOnChange={true}
        enableReinitialize
        innerRef={formRef}
      >
        {(formikProps) => (
          <Form className={styles.form}>
            <div className={styles.section}>
              <div className ={styles.sectionLabel}>
                {texts('article:deep_link_type_section_title')}
              </div>
              <div className={styles.optionsBlock}>
                <div onChange={onChangeValue}>    
                  <div>   
                    <input
                      type={'radio'} 
                      value={DeepLinkTagType.SINGLE} 
                      name={'option'} 
                      defaultChecked 
                      className={styles.radio} 
                    />
                    {texts('article:deep_link_only_title_option')}
                  </div>
                  <div>
                    <input
                      type={'radio'}
                      value={DeepLinkTagType.MULTI}
                      name={'option'}
                      className={styles.radio}
                    />
                    {texts('article:deep_link_only_title_and_photo_option')}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.divider}/>
            <div className={styles.section}>
              <div className ={styles.sectionLabel}>
                {texts('article:deep_link_options_section_title')}
              </div>
              <div className={styles.introduction}>
                <BaseInput
                  name={'title'}
                  label={texts('article:deep_link_title_field')}
                  placeholder={texts('article:deep_link_title_field_placeholder')}
                  formikProps={formikProps}
                  maxLen={32}
                  count={true}
                />
              </div>
              <div className={styles.fieldLabel}>
                {texts('article:selected_articles_label')}
              </div>
              <div className={styles.selectedArticlesList}>
                {               
                  selectedArticles.length > 0
                  ? <div>
                      {
                        selectedArticles.map((ar, idx) => (
                          <AttachedArticle 
                            article={ar}
                            onButtonClick={(ar) => removeArticleFromSelected(ar)}
                            buttonContent={texts('article:remove_article_button')}
                            classes={{button:`${styles.removeButton}`}}
                            index={idx}
                            maxIndex={selectedArticles.length}
                            onUp={(ar) => moveUpSelectedArticle(ar)}
                            onDown={(ar) => moveDownSelectedArticle(ar)}
                          />
                        ))
                      }
                    </div>       
                  : <div className={styles.noArticlesLabel}>
                      {texts('article:no_selected_articles')}
                    </div>        
                }                       
              </div> 
              <div className={styles.wrapper}>
                <div className={styles.errorLabel}>{errorText}</div> 
                <div className={styles.formButtonSection}>
                  <LoadingButton 
                    type={'submit'}
                    disabled={selectedArticles.length === 0}
                    style={{
                      opacity: selectedArticles.length === 0 ? 0.5 : 1
                    }}
                    className={styles.submitFormButton}
                  >                
                    <div className={styles.formButtonContent}>
                      {texts('editor:deep_link_insert_button_label')}
                    </div>
                  </LoadingButton>
                </div>
              </div>  
            </div>
            <div className={styles.divider}/>
          </Form>        
        )}
      </Formik>
      <div className ={styles.sectionLabel}>
        {texts('article:search_article_label')}
      </div>      
      <div className={styles.searchFormContainer}>
        <ArticleSearchForm
          allowedStatuses={[
            ArticleState.PUBLISHED
          ]}
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setArticles={setArticles}
          setArticleCount={setArticleCount}
          baseZIndex={(props.baseZIndex ?? 0) + 1}
          exclude={props.excludedArticleUuid}
        />
      </div>
      <div className={styles.articlesListContainer}>
        {
          isLoading
          ? <div className={styles.articlesLoadingCover}>
              <MoonLoader loading={true} size={100}/>
            </div>
          : null
        }
        {
          articles.length > 0
          ? <>
              <div>
                {                 
                  articles.map((ar, idx) => (                  
                    <AttachedArticle 
                      linkToArticle
                      article={ar}
                      onButtonClick={(ar) => addArticleToSelected(ar)}
                      buttonContent={'Wybierz artykuł'}
                      classes={{button:`${styles.selectionButton}`}}
                    />
                  ))                  
                }
              </div>
              <div className={styles.paginationContainer}>
                <Pagination 
                  pages={Math.ceil(articleCount / MAX_ARTICLE_PER_PAGE) > 0 ? Math.ceil(articleCount / MAX_ARTICLE_PER_PAGE) : 1}
                  selectedPage={selectedPage}
                  onChange={(selectedPage) => {
                    setSelectedPage(selectedPage)
                  }}
                />
              </div>
            </>
          : <div className={styles.noArticlesLabel}>
              {texts('article:no_articles')}
            </div>
        }
      </div>
    </>
  )
}

export default AddLinkToAnotherArticle