import { Parser } from 'bbcode-to-react'
import CdnImgTag from './tags/basic/cdnImg'
import LiTag from './tags/basic/li'
import ParagraphTag from './tags/basic/p'
import YoutubeTag from './tags/basic/youtube'
import QuoteTag from './tags/basic/quote'
import UrlTag from './tags/basic/url'
import TwitterTag from './tags/basic/twitter'
import FacebookTag from './tags/basic/facebook'
import TikTokTag from './tags/basic/tiktok'
import InstagramTag from './tags/basic/instagram'
import PinterestTag from './tags/basic/pinterest'
import OListTag from './tags/basic/oList'
import SpanTag from './tags/basic/span'
import SeeAlsoTag from './tags/basic/seeAlso'
import WorthSeeingTag from './tags/basic/worthSeeing'
import TelegramPostTag from './tags/basic/telegramPost'
import RedditTag from './tags/basic/reddit'
import AdBlockTag from './tags/basic/adBlock'

const basicTags = [
  'b', 'i', 'u', 's', 'h2', 'h3', 'h4', 'h5', 'h6', 'pre', 'table', 'thead', 'tbody',
  'tr', 'th', 'td', 'code', 'img', 'hr', 'size', 'center', 'right', 'color', 'list',
  '\'*\'', 'url', 'link', 'email']

class BBCodeParser {
  private basicInstance: Parser

  constructor() {
    this.basicInstance = new Parser(basicTags)
    this.basicInstance.registerTag('youtube', YoutubeTag)
    this.basicInstance.registerTag('li', LiTag)
    this.basicInstance.registerTag('p', ParagraphTag)
    this.basicInstance.registerTag('cdn-img', CdnImgTag)
    this.basicInstance.registerTag('quote', QuoteTag)
    this.basicInstance.registerTag('url', UrlTag)
    this.basicInstance.registerTag('twitter', TwitterTag)
    this.basicInstance.registerTag('facebook', FacebookTag)
    this.basicInstance.registerTag('tiktok', TikTokTag)
    this.basicInstance.registerTag('instagram', InstagramTag)
    this.basicInstance.registerTag('pinterest', PinterestTag)
    this.basicInstance.registerTag('olist', OListTag)
    this.basicInstance.registerTag('span', SpanTag)
    this.basicInstance.registerTag('article-deep-link', SeeAlsoTag)
    this.basicInstance.registerTag('article-deep-link-with-photo', WorthSeeingTag)
    this.basicInstance.registerTag('telegram-post', TelegramPostTag)
    this.basicInstance.registerTag('reddit', RedditTag)
    this.basicInstance.registerTag('ad-block', AdBlockTag)
  }
  
  toReact(input: string): React.ReactNode {
    return this.basicInstance.toReact(input)
  }
}

export default new BBCodeParser()