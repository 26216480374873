import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import MoonLoader from 'react-spinners/MoonLoader'
import AuthApi from '../../../api/AuthApi'
import AssetLoader from '../../../assets/loader'
import { removeToken } from '../../../redux/auth/actions'
import { addSuccessMessage } from '../../../redux/messages/actions'
import { removeUserData } from '../../../redux/user/actions'
import store from '../../../store'
import { onErrorResponseShowMessage } from '../../../utils/onErrorReponseShowMessage/onErrorResponseShowMessage'
import { StorageService } from '../../../utils/storage/StorageService'
import TopBar from '../../common/TopBar/TopBar'
import { MessageEnum } from '../../CustomToastProvider/types'
import styles from './styles.module.scss'

const AccountVerifyEmail = () => {
  const locations = useLocation()
  const history = useHistory()
  const query = new URLSearchParams(locations.search)
  const token = query.get('token')

  React.useEffect(() => {
    const checkChangePasswordToken = async () => {
      try {
        await AuthApi.checkUserEmailVerificationToken(token)
        store.dispatch(addSuccessMessage(MessageEnum.EMAIL_CHANGE_VERIFIED))
      } catch (error) {
        onErrorResponseShowMessage(error)
      } finally {
        StorageService.getInstance().clearUserCache()
        store.dispatch(removeToken())
        store.dispatch(removeUserData())
        history.push('/login')
      }
    }
    checkChangePasswordToken()
  }, [token, history])

  return (
    <>
      <TopBar 
        logoSrc={AssetLoader.getLogo()}
      />
      <div className={styles.main}>
        <MoonLoader />
      </div>
    </>
  )
}

export default AccountVerifyEmail
