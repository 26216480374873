import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import MoonLoader from 'react-spinners/MoonLoader'
import { MAX_ARTICLE_PER_PAGE } from '../../types/common'
import ArticleSearchForm from '../common/ArticleSearchForm/ArticleSearchForm'
import ArticleTile, { Article } from '../common/ArticleTile/ArticleTile'
import Pagination from '../common/Pagination/Pagination'
import styles from './styles.module.scss'

type LocationState = {
  selectedArticleListPage?: number
  searchArticleFormInitialValues?: any
}

const ArticleListPage = () => {
  const texts = useTranslation().t
  const history = useHistory()
  const location = useLocation<LocationState>()

  const [articles, setArticles] = React.useState<Article[]>([])
  const [articleCount, setArticleCount] = React.useState<number>(0)
  const [selectedPage, setSelectedPage] = React.useState<number>(location.state?.selectedArticleListPage ?? 1)
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [lastSearchedValues, setLastSearchedValues] = React.useState<any>(location.state?.searchArticleFormInitialValues ?? null)

  return (
    <>
      <h1 className={styles.pageTitle}>
        {texts('article:articles_title')}
      </h1>
      <div className={styles.addButtonSection}>
        <div
          className={styles.addArticleButton}
          onClick={() => {
            history.replace(
              '/',
              {
                selectedArticleListPage: selectedPage,
                searchArticleFormInitialValues: lastSearchedValues
              }
            )
            history.push(
              `/article/add`,
              {
                articleData: {
                  articleListData: {
                    selectedPage: selectedPage,
                    searchFormInitialValues: lastSearchedValues
                  }
                }
              }
            )
          }}
        >
          {texts('article:add_article')}
        </div>
      </div>
      <div className={styles.searchTitleSection}>
        <div className={styles.searchTitle}>
          {texts('article:search_article_label')}
        </div>
      </div>
      <div className={styles.searchFormContainer}>
        <ArticleSearchForm
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setArticles={setArticles}
          setArticleCount={setArticleCount}
          saveInState
          additionalOnSearch={(data: any) => {
            setLastSearchedValues(data)
          }}
        />
      </div>
      <div className={styles.articlesListContainer}>
        {
          isLoading
          ? <div className={styles.articlesLoadingCover}>
              <MoonLoader loading={true} size={100}/>
            </div>
          : null
        }
        {
          articles.length > 0
          ? <>
              <div>
                {
                  articles.map((ar, idx) => (
                    <ArticleTile 
                      article={ar}
                      onClickData={{
                        replace: {
                          url: `/`,
                          state: {
                            selectedArticleListPage: selectedPage,
                            searchArticleFormInitialValues: lastSearchedValues
                          }
                        },
                        push: {
                          url: `/article/edit/${ar.uuid}`,
                          state: {
                            articleData: {
                              articleListData: {
                                selectedPage: selectedPage,
                                searchFormInitialValues: lastSearchedValues
                              }
                            }
                          }
                        }
                      }}
                    />
                  ))
                }
              </div>
              <div>
                <Pagination 
                  pages={Math.ceil(articleCount/MAX_ARTICLE_PER_PAGE) > 0 ? Math.ceil(articleCount/MAX_ARTICLE_PER_PAGE) : 1}
                  selectedPage={selectedPage}
                  onChange={(selectedPage) => {
                    setSelectedPage(selectedPage)
                  }}
                />
              </div>
            </>
          : <div className={styles.noArticles}>
              {texts('article:no_articles')}
            </div>
        }
      </div>
    </>
  )
}

export default ArticleListPage
