import { MenuLink } from './Drawer'
import { 
  MdArtTrack,
  MdClass,
  MdPhotoLibrary,
  MdLiveHelp,
  MdStars,
  MdLabel,
  MdPermMedia,
  MdAccountCircle,
 
} from 'react-icons/md'
import { 
  VscAccount
} from 'react-icons/vsc'

export const ACTIVE_ICON_COLOR = '#383838'
export const INACTIVE_ICON_COLOR = 'rgba(56, 56, 56, 0.5)'

export const menuData: MenuLink[] = [
  {
    label: 'ARTYKUŁY',
    icon: MdArtTrack,
    url: '/',
    subLinks: [
      '/article/add',
      '/article/edit'
    ]
  },
  {
    label: 'MOJE KONTO',
    icon: MdAccountCircle,
    url: '/account',
    subLinks:[
      '/'
    ]
  }
  // {
  //   label: 'ARTYKUŁY WIKI',
  //   icon: MdClass,
  //   url: '/2',
  //   subLinks: [

  //   ]
  // },
  // {
  //   label: 'GALERIE ZDJĘĆ',
  //   icon: MdPhotoLibrary,
  //   url: '/3',
  //   subLinks: [

  //   ]
  // },
  // {
  //   label: 'QUIZY',
  //   icon: MdLiveHelp,
  //   url: '/4',
  //   subLinks: [

  //   ]
  // },
  // {
  //   label: 'KATEGORIE',
  //   icon: MdStars,
  //   url: '/5',
  //   subLinks: [

  //   ]
  // },
  // {
  //   label: 'TAGI',
  //   icon: MdLabel,
  //   url: '/6',
  //   subLinks: [

  //   ]
  // },
  // {
  //   label: 'ZDJĘCIA',
  //   icon: MdPermMedia,
  //   url: '/7',
  //   subLinks: [

  //   ]
  // },
  // {
  //   label: 'MOJE KONTO',
  //   icon: VscAccount,
  //   url: '/8',
  //   subLinks: [

  //   ]
  // }
]