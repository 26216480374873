import React from 'react'
import Cropper from 'react-easy-crop'
import { MediaSize } from 'react-easy-crop/types'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { State } from '../../../redux/reducers/index'
import { onErrorResponseShowMessage } from '../../../utils/onErrorReponseShowMessage/onErrorResponseShowMessage'
import CustomDialog from '../CustomDialog/CustomDialog'
import LoadingButton from '../LoadingButton/LoadingButton'
import { SinglePhoto } from '../MediaLibrary/types'
import styles from './styles.module.scss'
import { getCroppedImg } from './utils'

type Props = {
  id?: string
  image: SinglePhoto | null
  hidden: boolean
  onCloseClick: () => void
  onFinish: (image: Blob) => void
  aspect?: number
}

const CropperDialog = (props: Props) => {

  const texts = useTranslation().t
  const userUuid = useSelector((e: State) => e.user.uuid)

  const [crop, setCrop] = React.useState({ x: 0, y: 0 })
  const [zoom, setZoom] = React.useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = React.useState(null)
  const [mediaLoaded, setMediaLoaded] = React.useState(false)
  const [lastCroppedImageUuid, setLastCroppedImageUuid] = React.useState<string | null>(null)

  React.useEffect(() => {
    if (lastCroppedImageUuid && lastCroppedImageUuid !== props.image?.uuid) {
      setMediaLoaded(false)
    }
    setLastCroppedImageUuid(props.image?.uuid ?? null)
  }, [props.image])

  const onCropComplete = React.useCallback((_, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const onClick = async () => {
    try {
      const croppedImage = await getCroppedImg(
        props.image ? `${props.image.hostname}${props.image.path}${props.image.filename}` : '',
        croppedAreaPixels
      )
      const blob: Blob = await fetch(URL.createObjectURL(croppedImage!)).then(r => r.blob());
      props.onFinish(blob)
      props.onCloseClick()
    } catch (error) {
      onErrorResponseShowMessage(error, true, { userUuid: userUuid })
    }
  }
  
  return (
    <CustomDialog
      hidden={props.hidden}
      classes={{
        container: styles.container
      }}
      closeButton={true}
      onCloseButtonClick={() => {
        props.onCloseClick()
      }}
      title={texts('common:cropper_dialog_title')}
    >
      <div className={styles.cropperContainer}>
        <Cropper
          image={props.image ? `${props.image.hostname}${props.image.path}${props.image.filename}` : ''}
          crop={crop}
          zoom={zoom}
          aspect={props.aspect ?? 1.91}
          onMediaLoaded={(data: MediaSize) => {setMediaLoaded(true)}}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          zoomSpeed={0.1}
        />
      </div>
      <div className={styles.buttonContainer}>
        <LoadingButton
          loading={!mediaLoaded}
          className={styles.button}
          onClick={onClick}
        >
          {texts('common:save_button_label')}
        </LoadingButton>
      </div>
    </CustomDialog>
  )
}

export default CropperDialog
