import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import auth from './translations/auth.json'
import common from './translations/common.json'
import messages from './translations/messages.json'
import dashboard from './translations/dashboard.json'
import mediaLibrary from './translations/mediaLibrary.json'
import galleryLibrary from './translations/galleryLibrary.json'
import editorAccount from './translations/editorAccount.json'
import htmlValidation from './translations/htmlValidation.json'
import article from './translations/article.json'
import gallery from './translations/gallery.json'
import editor from './translations/editor.json'

const resources = {
  pl: {
    auth,
    common,
    messages,
    dashboard,
    mediaLibrary,
    galleryLibrary,
    editorAccount,
    htmlValidation,
    gallery,
    article,
    editor
  },
}
i18n.use(initReactI18next).init({
  resources,
  lng: 'pl',
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
})

export default i18n
