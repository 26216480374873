import { FormikProps } from 'formik'
import React, { CSSProperties } from 'react'
import { AiOutlineCheck } from 'react-icons/ai'
import Checkbox from '../../../lib/react-custom-checkbox'
import styles from './styles.module.scss'

type Props = {
  name: string
  disabled?: boolean
  formikProps: FormikProps<any>
  label: string
  styles?: {
    container?: CSSProperties
    checkboxContainer?: CSSProperties
    label?: CSSProperties
    checkbox?: CSSProperties
  }
  onChange?: (checked: boolean) => void
}

const CustomCheckbox = (props: Props) => {
  return (
    <div
      style={
        props.styles?.container ?? {
          height: '15px',
          display: 'flex',
          opacity: props.disabled ? 0.5 : 1
        }
      }
    >
      <Checkbox 
        name={props.name}
        checked={props.formikProps.values[props.name]}
        disabled={props.disabled}
        borderRadius={3}
        size={13}
        borderColor={'#383838'}
        containerStyle={
          props.styles?.checkboxContainer ?? {
            display: 'flex',
            alignItems: 'flex-start',
            marginTop: '0.188rem'
          }
        }
        style={
          props.styles?.checkbox ?? {
            marginTop: '0.125rem'
          }
        }
        icon={
          <div className={styles.checkboxIcon}>
            <AiOutlineCheck size={9}/>
          </div>
        }
        labelStyle={
          props.styles?.label ??{
            fontFamily: 'Roboto',
            color: '#383838',
            marginTop: '0.125rem',
            fontSize: '0.75rem',
            paddingLeft: '0.313rem',
            whiteSpace: 'break-spaces'
          }
        }
        onBlur={() => { props.formikProps.setFieldTouched(props.name)}}
        onChange={(checked: boolean) => {
          if (props.onChange) {
            props.onChange(checked)
          }
          props.formikProps.setFieldTouched(props.name)
          props.formikProps.setFieldValue(props.name, checked)
        }}
        label={<>{ props.label }</>}
      />
    </div>
  )
}

export default CustomCheckbox
