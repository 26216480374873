import React from 'react'
import CustomDialog from '../CustomDialog/CustomDialog'
import MediaLibrary from '../MediaLibrary/MediaLibrary'
import { SinglePhoto } from '../MediaLibrary/types'
import styles from './styles.module.scss'
import { useTranslation } from 'react-i18next'

type Props = {
  id?: string
  name?: string
  hidden: boolean
  onCloseClick: () => void
  onFinish: (image: SinglePhoto) => void
}

const MediaLibraryDialog = (props: Props) => {

  const texts = useTranslation().t

  return (
    <CustomDialog
      hidden={props.hidden}
      classes={{
        container: styles.container
      }}
      closeButton={true}
      onCloseButtonClick={() => {
        props.onCloseClick()
      }}
      title={texts('mediaLibrary:title')}
    >
      <MediaLibrary
        id={props.id}
        name={props.name}
        reload={!props.hidden}
        onFinish={(img) => {
          props.onCloseClick()
          props.onFinish(img)
        }}
      />
    </CustomDialog>
  )
}

export default MediaLibraryDialog
