import { Form, Formik } from 'formik'
import React from 'react'
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useLocation } from 'react-router-dom'
import MoonLoader from 'react-spinners/MoonLoader'
import AuthApi from '../../../api/AuthApi'
import AssetLoader from '../../../assets/loader'
import { removeToken } from '../../../redux/auth/actions'
import { addSuccessMessage } from "../../../redux/messages/actions"
import { State } from "../../../redux/reducers"
import { removeUserData } from '../../../redux/user/actions'
import store from "../../../store"
import { onErrorResponseShowMessage } from "../../../utils/onErrorReponseShowMessage/onErrorResponseShowMessage"
import { StorageService } from '../../../utils/storage/StorageService'
import { activateAccountValidation } from '../../../utils/validations/activateAccountValidation'
import LoadingButton from '../../common/LoadingButton/LoadingButton'
import PasswordInput from '../../common/PasswordInput/PasswordInput'
import TopBar from '../../common/TopBar/TopBar'
import { MessageEnum } from '../../CustomToastProvider/types'
import styles from './styles.module.scss'

const AccountActivation = () => {
  const texts = useTranslation().t
  const message = useSelector((e: State) => e.messages)
  const locations = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const query = new URLSearchParams(locations.search)
  const token = query.get("token")

  const [checkLoading, setCheckLoading] = React.useState<boolean>(true)
  const [loading, setLoading] = React.useState<boolean>(false)

  React.useEffect(() => {
    const checkChangePasswordToken = async () => {
      try {
        await AuthApi.checkUserActivationToken(token)
      } catch (error) {
        onErrorResponseShowMessage(error, false)
        history.push("/login")
      } finally {
        setCheckLoading(false)
      }
    }
    checkChangePasswordToken()
  }, [token, history])

  const handleSubmit = async (values: {
    password: string
    passwordConfirmation: string
  }, form: any) => {
    setLoading(true)
    try {
      await AuthApi.activateUser(values.password, token)
      dispatch(
        addSuccessMessage(
          MessageEnum.ACCOUNT_HAS_BEEN_ACTIVATED
        )
      )
      StorageService.getInstance().clearUserCache()
      store.dispatch(removeToken())
      store.dispatch(removeUserData())
      history.push("/login")
    } catch (error) {
      onErrorResponseShowMessage(error, false)
      history.push("/login")
    } finally {
      setLoading(false)
    }
  }

  React.useEffect(() => {
    if (message.error) {
      history.push('/login')
    }
  }, [message])

  return (
    <>
      <TopBar 
        logoSrc={AssetLoader.getLogo()}
      />
      <div className={styles.main}>
      { checkLoading 
          ? <MoonLoader />
          : <>
              <div className={styles.title}>
                {texts(`auth:${process.env.REACT_APP_PROJECT}_account_activation_title`)}
              </div>
              <div>
                <Formik
                  validationSchema={activateAccountValidation(texts)}
                  initialValues={{
                    password: '',
                    passwordConfirmation: '',
                  }}
                  onSubmit={handleSubmit}
                >
                  {(formikProps) => (
                    <Form className={styles.form}>
                      <PasswordInput
                        label={texts("auth:set_password_label")}
                        name="password"
                        value={formikProps.values.password}
                        formikProps={formikProps}
                        classes={{
                          label: styles.inputLabel
                        }}
                        error={
                          formikProps.touched.password && Boolean(formikProps.errors.password)
                        }
                      />
                      <PasswordInput
                        name="passwordConfirmation"
                        label={texts("auth:repeat_password_label")}
                        value={formikProps.values.passwordConfirmation}
                        classes={{
                          label: styles.inputLabel
                        }}
                        formikProps={formikProps}
                        error={
                          formikProps.touched.passwordConfirmation && Boolean(formikProps.errors.passwordConfirmation)
                        }
                      />
                      <LoadingButton
                        type="submit"
                        className={styles.submitButton}
                        loading={loading}
                        disabled={
                          !formikProps.isValid || !formikProps.dirty
                        }
                        style={{ marginTop: '0.625rem' }}
                      >
                        <div className={styles.submitButtonContent}>
                          {texts("auth:set_button")}
                        </div>
                      </LoadingButton>
                      <Link
                        to="/login"
                        className={styles.link}
                      >
                        <div className={styles.loginLink}>
                          <div className={styles.loginLinkContent}>
                            {texts("auth:go_to_login")}
                          </div>
                        </div>
                      </Link>
                    </Form>
                  )}
                </Formik>
              </div>
            </>
        }
      </div>
    </>
  )
}

export default AccountActivation
