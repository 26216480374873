import { Form, Formik } from 'formik'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import AuthRefreshApi from '../../../api/AuthRefreshApi'
import { addSuccessMessage } from '../../../redux/messages/actions'
import store from '../../../store'
import { onErrorResponseShowMessage } from '../../../utils/onErrorReponseShowMessage/onErrorResponseShowMessage'
import { changeEmailValidation } from '../../../utils/validations/changeEmailValidation'
import BaseInput from '../../common/BaseInput/BaseInput'
import LoadingButton from '../../common/LoadingButton/LoadingButton'
import { MessageEnum } from '../../CustomToastProvider/types'
import styles from './styles.module.scss'

type InitialFormValues = {
  newEmail: string
  reNewEmail: string
}

const initialValues: InitialFormValues = {
  newEmail: '',
  reNewEmail: '',
}

export type Props = {
  onFinish: () => void
}

const ChangeEmail = (props: Props) => {
  const texts = useTranslation().t
  
  const [initialFormValues, setInitialFormValues] = React.useState<InitialFormValues>(initialValues)
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false)
  
  const formRef = useRef() as any

  const handleSubmit = async (data: any) => {
    try {
      setIsSubmitting(true)
      await AuthRefreshApi.changeEmail(data.newEmail)
      store.dispatch(addSuccessMessage(MessageEnum.EMAIL_CHANGE_PENDING))
      props.onFinish()
    } catch (error) {
      onErrorResponseShowMessage(error)
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <>
      <Formik
        initialValues={initialFormValues}
        validationSchema={changeEmailValidation(texts)}
        onSubmit={handleSubmit}
        validateOnBlur={true}
        validateOnChange={true}
        enableReinitialize
        innerRef={formRef}
      >
        {(formikProps) => (
          <Form>
            <div className={styles.emailContainer}>
              <BaseInput
                name={'newEmail'}
                label={texts('auth:new_email')}
                formikProps={formikProps}
                value={formikProps.values.newEmail}
                classes={{
                  label: styles.inputLabel
                }}
                error={
                  formikProps.touched.newEmail && Boolean(formikProps.errors.newEmail)
                }
              />
              <BaseInput
                name={'reNewEmail'}
                label={texts('auth:re_new_email')}
                formikProps={formikProps}
                value={formikProps.values.reNewEmail}
                classes={{
                  label: styles.inputLabel
                }}
                error={
                  formikProps.touched.reNewEmail && Boolean(formikProps.errors.reNewEmail)
                }
              />
            </div>
            <div className={styles.description}>
              {texts('auth:change_email_desc')}
            </div>
            <LoadingButton
              type={'button'}
              className={styles.formButton}
              loading={isSubmitting}
              onClick={() => {
                formikProps.submitForm()
              }}
            >
              <div className={styles.formButtonContent}>{texts('auth:send_verification_email')}</div>
            </LoadingButton>
          </Form>
        )}
      </Formik>
    </>
  )
}
export default ChangeEmail
