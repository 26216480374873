import { Tag } from 'bbcode-to-react'
import React from 'react'
import styles from './style.module.scss'

export default class ZaradnaYoutubeTag<T = { linkify: boolean }> extends Tag<T> {
  toReact() {
    const srcDoc = `
    <style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style>
    <a href=https://www.youtube.com/embed/${this.getContent(true).replace('t=', 'start=')}>
      <img src=https://img.youtube.com/vi/${this.getContent(true).split('?')[0]}/hqdefault.jpg>
      <span>▶</span>
    </a>`
    return (
      <div className={styles.youtube_container}>
        <div className={styles.youtube_tile}>
          <iframe
            srcDoc={srcDoc}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />      
        </div>
      </div>
    )
  }
}