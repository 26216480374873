import * as Yup from "yup"

export const editorAccountValidation = (t: (val: string) => string) => {
    return Yup.object({
        // email: Yup.string()
      // .required(t("common:field_required"))
      // .matches(forbiddenSpecialCharacters, t("common:special_characters_forbidden_validation"))
      // .matches(email, t("auth:invalid_email_validation")),
      // editor: Yup.mixed()
      // .test("required", t('common:field_required'), function (val: any) {
      //   const draft = this.resolve(Yup.ref('draft')) as boolean
      //   return draft || val != null
      // }),  
      name: Yup.string().trim().required(t("common:field_required"))
    })
}