import store from "../../store"
import { addErrorMessage } from "../../redux/messages/actions"
import { MessageEnum } from "../../components/CustomToastProvider/types" 
import * as Sentry from '@sentry/react'

export const onErrorResponseShowMessage = (e: any, loggedIn: boolean = true, sentryExtra: Record<string, any> = {}) => {
  let errorMessage: string = "something_went_wrong_message"
  switch(true) {
    // User confirmation email, change password
    case e.response?.status === 401 && e.response?.data?.code === 100 && e.response?.data?.subcode === 105:
      errorMessage = MessageEnum.TOKEN_INVALID
      break
    // User confirmation email, change password
    case e.response?.status === 409 && e.response?.data?.code === 100 && e.response?.data?.subcode === 108:
      errorMessage = MessageEnum.TOKEN_ALREADY_USED
      break
    // Email taken
    case e.response?.status === 409 && e.response?.data?.code === 200 && e.response?.data?.subcode === 206:
      errorMessage = MessageEnum.EMAIL_TAKEN
      break
    // Same password as old one
    case e.response?.status === 409 && e.response?.data?.code === 100 && e.response?.data?.subcode === 107:
      errorMessage = MessageEnum.SAME_PASSWORD_AS_OLD_ONE
      break
    // User inactive
    case e.response?.status === 401 && e.response?.data?.code === 100 && e.response?.data?.subcode === 103:
      errorMessage = loggedIn ? MessageEnum.LOST_ACCESS : MessageEnum.INACTIVE_USER
      break
    // User removed 
    case e.response?.status === 401 && e.response?.data?.code === 100 && e.response?.data?.subcode === 104:
      errorMessage = loggedIn ? MessageEnum.LOST_ACCESS : MessageEnum.ACCOUNT_REMOVED
      break
    // Incorrect Pwd
    case e.response?.status === 409 && e.response?.data?.code === 100 && e.response?.data?.subcode === 106:
      errorMessage = MessageEnum.INCORRECT_PASSWORD
      break
    // User login 
    case e.response?.status === 401 && e.response?.data?.code === 100 && e.response?.data?.subcode === 100:
      errorMessage = MessageEnum.INVALID_CREDENTIALS
      break
    // Unauthorized
    case e.response?.status === 401 && e.response?.data?.code === 100 && e.response?.data?.subcode === 102:
      errorMessage = MessageEnum.UNAUTHORIZED
      break
    // Unauthorized
    case e.response?.status === 404 && e.response?.data?.code === 200 && e.response?.data?.subcode === 211:
      errorMessage = MessageEnum.ARTICLE_NOT_FOUND
      break
    // Same slug already exists
    case e.response?.status === 409 && e.response?.data?.code === 200 && e.response?.data?.subcode === 216:
      errorMessage = MessageEnum.SAME_SLUG_ALREADY_EXISTS
      break
    // Too many ad blocks
    case e.response?.status === 409 && e.response?.data?.code === 200 && e.response?.data?.subcode === 217:
      errorMessage = MessageEnum.TOO_MANY_AD_BLOCKS
      break
    default: 
      if (process.env.REACT_APP_ENV !== "development") {
        Sentry.captureException(e, {
          extra: {
            ...sentryExtra,
            ...(e.response != null ? { response: e.response } : {})
          }
        })
      }
      break
  }

  store.dispatch(addErrorMessage(errorMessage))
}
