import React from 'react'
import { IconType } from 'react-icons'
import { AiOutlineClose, AiOutlinePoweroff } from 'react-icons/ai'
import { HiMenuAlt2 } from 'react-icons/hi'
import { Link, useHistory } from 'react-router-dom'
import { ACTIVE_ICON_COLOR, INACTIVE_ICON_COLOR, menuData } from './data'
import styles from './styles.module.scss'

export type MenuLink = {
  label: string
  icon: IconType,
  url: string,
  subLinks?: string[]
}

type Props = {
  i18Translation?: any
  logoutButton?: boolean
  links?: MenuLink[]
  onLogout?: () => void
}

const Drawer = (props: Props) => {
  const history = useHistory()

  const [drawerOpen, setDrawerOpen] = React.useState<boolean>(false)

  const checkIfCurrentPath = (link: string, subLinks?: string[]): boolean => {
    if (link === history.location.pathname) {
      return true
    }
    for (let subLink of subLinks ?? []) {
      if (history.location.pathname.startsWith(`${link !== '/' ? link : ''}${subLink}`)) {
        return true
      }
    }
    return false
  }

  return (
    <>
      <div className={`${styles.drawerContainer} ${drawerOpen ? styles.drawerOpen : styles.drawerClose}`}>
        <div className={`${styles.drawer} ${drawerOpen ? styles.drawerOpen : styles.drawerClose}`}>
          <div className={styles.drawerButtonContainer}>
            <div
              className={styles.drawerButton}
              onClick={() => { setDrawerOpen(!drawerOpen) }}
            >
              {
                drawerOpen
                ? <AiOutlineClose color={ACTIVE_ICON_COLOR} size={30}/>
                : <HiMenuAlt2 color={ACTIVE_ICON_COLOR} size={30}/>
              }
            </div>
          </div>
          <ul className={styles.menuButtonsList}>
            {
              (props.links ?? menuData).map((e: MenuLink, idx: number) => (
                <li key={`drawer-menu-link-${idx}`}>
                  <Link to={e.url} className={`${styles.menuButton} ${checkIfCurrentPath(e.url, e.subLinks) ? styles.menuButtonActive : styles.menuButtonInactive}`}>
                    <div className={styles.menuButtonIcon}>
                      {
                        e.icon({
                          color: checkIfCurrentPath(e.url, e.subLinks) ? ACTIVE_ICON_COLOR : INACTIVE_ICON_COLOR,
                          size: 30
                        })
                      }
                    </div>
                    <div className={styles.menuButtonLabel}>
                      {
                        drawerOpen
                        ? (
                            props.i18Translation != null
                            ? props.i18Translation(e.label)
                            : e.label
                          )
                        : null
                      }
                    </div>
                  </Link>
                </li>
              ))
            }
          </ul>
          <div className={styles.logoutSpacer}></div>
          {
            props.logoutButton
            ? <div
                className={`${styles.menuButton} ${styles.menuButtonInactive}`}
                onClick={props.onLogout}
              >
                <div className={styles.menuButtonIcon}>
                  {
                    <AiOutlinePoweroff color={ACTIVE_ICON_COLOR} size={30}/>
                  }
                </div>
                <div className={styles.menuButtonLabel} style={{ color: '#383838' }}>
                  {
                    drawerOpen
                    ? (
                        props.i18Translation != null
                        ? props.i18Translation('logout_button')
                        : 'Wyloguj'
                      )
                    : null
                  }
                </div>
              </div>
            : null
          }
          
        </div>
      </div>
    </>
  )
}

export default Drawer
