import React from 'react'
import BBCodeParser from './parser/parser'
import styles from './style.module.scss'

type Props = {
  bbcode: string
}

const BBCodeDescription = (props: Props) => {
  return (
    <div className={styles.container}>
      {BBCodeParser.toReact(props.bbcode ?? '')}
    </div>
  )
}

export default BBCodeDescription
