import React from 'react'
import moment from 'moment-timezone'
import { useTranslation } from 'react-i18next'

type Props = {
  date?: Date | string | number | null
  timeOnly?: boolean | null
  hideTime?: boolean
  showDateTime?: boolean
}

const Timestamp = (props: Props) => {
  const timeThen = moment(props.date).tz(process.env.NEXT_PUBLIC_TZ ?? 'Europe/Warsaw')
  let timeAgoStr = ''
  const texts = useTranslation().t

  if (props.timeOnly) {
    timeAgoStr = timeThen.format('HH:mm')
  } else if (timeThen.isSame(moment(), 'day')) {
    timeAgoStr = `${texts('common:date_today')}${props.hideTime ? "" : `, ${timeThen.format('HH:mm')}`}`
  } else if (timeThen.isSame(moment().subtract(1, 'day'), 'day')) {
    timeAgoStr = `${texts('common:date_yesterday')}${props.hideTime ? "" : `, ${timeThen.format('HH:mm')}`}`
  } else {
    timeAgoStr = timeThen.format(`DD MMMM YYYY${props.showDateTime ? `, HH:mm` : ""}`)
  }

  return (
    <span>{timeAgoStr}</span>
  )
}

export default Timestamp
