import { Tag } from 'bbcode-to-react'
import React from 'react'
import styles from './style.module.scss'
import { decode } from 'html-entities'

type Props = {
  twitter: string
  embed: string
}

export default class DepeszaTwitterTag<T = { linkify: boolean }> extends Tag<T> {
  toReact() {
    const props = this.params as Props
    return (
      <>
       <div 
          className={styles.twitter_embed_container}
          dangerouslySetInnerHTML={{
            __html: decode(this.getContent().replace(/<br>|<br\/>|<br \/>/g, ''))
          }}
        >
        </div>
      </>
      
    )
  }
}