import React from 'react'
import styles from './styles.module.scss'
import { AiOutlineArrowDown, AiOutlineArrowUp, AiFillEyeInvisible, AiFillEye } from 'react-icons/ai'
import { MdEdit } from 'react-icons/md'
import { SinglePhoto } from '../MediaLibrary/types'
import { useTranslation } from 'react-i18next';
import GalleryPageTileImage from '../GalleryPageTileImage/GalleryPageTileImage';
import GalleryPageTileContent from '../GalleryPageTileContent/GalleryPageTileContent'
import moment from 'moment'
import AssetLoader from '../../../assets/loader'
import BBCodeDescription from '../BBCodeDescription/BBCodeDescription'

export type GalleryTileData = {
  uuid?: string
  content: string
  active: boolean
  order?: number
  author: {
    name: string
    lastName: string
    uuid?: string
  }
  lastEditor?: {
    name: string
    lastName: string
    uuid?: string
  }
  photoUuid?: string
  adultContent: boolean
  showAdultWarning: boolean
  photo?: SinglePhoto | null
  publishedAt?: Date | string
  modifiedAt?: Date | string
} 

type Props = {
  index: number
  maxIndex: number
  activeCount: number
  data: GalleryTileData
  disabledControls?: boolean
  onHide: () => void
  onEdit: () => void
  onUp: () => void
  onDown: () => void
}

const GalleryPageTile = (props: Props) => {
  
  const texts = useTranslation().t

  return (
    <div className={styles.container}>
      <div className={styles.tag} style={{ opacity: props.data.active ? 1 : 0.5}}>
        {
          props.data.active
          ? `${props.data.order}/${props.activeCount}`
          : texts('gallery:inactive_label')
        }
      </div>
      <div className={styles.content}>
        <GalleryPageTileImage
          data={props.data}
        />
        <GalleryPageTileContent
          data={props.data}
        /> 
        <div className={styles.contentButtons}>
          <div className={styles.contentButtonsRow}>
            {
              props.index == null || props.index > 1
              ? <button
                  type={'button'}
                  disabled={props.disabledControls}
                  style={{
                    ...(props.disabledControls ? { cursor: 'default' } : {})
                  }}
                  className={`${styles.contentButtonBase} ${styles.contentButtonStandard}`}
                  onClick={props.onUp}
                >
                  <div className={styles.buttonContent}>
                    <AiOutlineArrowUp size={20}/>
                    <div className={styles.buttonContentStandard}>
                      {texts('gallery:move_up_button')}
                    </div>
                  </div>
                </button>
              : <div className={styles.contentButtonSpacer}></div>
            }
            {
              props.index == null || (props.index !== props.maxIndex && props.maxIndex > 1)
              ? <button
                  type={'button'}
                  disabled={props.disabledControls}
                  style={{
                    ...(props.disabledControls ? { cursor: 'default' } : {})
                  }}
                  className={`${styles.contentButtonBase} ${styles.contentButtonStandard}`}
                  onClick={props.onDown}
                >
                  <div className={styles.buttonContent}>
                    <AiOutlineArrowDown size={20}/>
                    <div className={styles.buttonContentStandard}>
                      {texts('gallery:move_down_button')}
                    </div>
                  </div>
                </button>
              : <div className={styles.contentButtonSpacer}></div>
            }
          </div>
          <div className={styles.contentButtonsRow}>
            <button
              type={'button'}
              disabled={props.disabledControls}
              style={{
                ...(props.disabledControls ? { cursor: 'default' } : {})
              }}
              className={`${styles.contentButtonBase} ${props.data.active ? styles.contentButtonRed : styles.contentButtonGreen}`}
              onClick={props.onHide}
            >
              <div className={styles.buttonContent}>
                {
                  props.data.active 
                  ? <AiFillEyeInvisible size={20} color={'#e02020'}/>
                  : <AiFillEye size={20} color={'#46a04c'}/>
                }
                
                <div className={props.data.active ? styles.buttonContentRed : styles.buttonContentGreen}>
                  { props.data.active ? texts('gallery:hide_page_label') : texts('gallery:show_page_label') }
                </div>
              </div>
            </button>
            <button
              type={'button'}
              disabled={props.disabledControls}
              style={{
                ...(props.disabledControls ? { cursor: 'default' } : {})
              }}
              className={`${styles.contentButtonBase} ${styles.contentButtonBlue}`}
              onClick={props.onEdit}
            >
              <div className={styles.buttonContent}>
                <MdEdit size={20} color={'#4566c0'}/>
                <div className={styles.buttonContentBlue}>
                  {texts('gallery:edit_page_label')}
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GalleryPageTile
