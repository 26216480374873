import { AllowedImageTypes, MAX_FILE_SIZE, MAX_IMG_PER_PAGE, SinglePhoto } from './types'
import styles from './styles.module.scss'
import LoadingButton from '../LoadingButton/LoadingButton'
import React from 'react'
import { useRef } from 'react'
import { IoAddCircle } from 'react-icons/io5'
import { onErrorResponseShowMessage } from '../../../utils/onErrorReponseShowMessage/onErrorResponseShowMessage'
import fileType from 'file-type'
import { addErrorMessage } from '../../../redux/messages/actions'
import store from '../../../store'
import CdnApi from '../../../api/CdnApi'
import { Formik, Form } from 'formik'
import BaseInput from '../BaseInput/BaseInput'
import { AiOutlineSearch } from 'react-icons/ai'
import Pagination from '../Pagination/Pagination'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { editImageDetailsValidation } from '../../../utils/validations/editImageDetailsValidation'
import { useSelector } from 'react-redux'
import { State } from '../../../redux/reducers/index'
import { preventEnterKey } from '../../../utils/form'

type Props = {
  id?: string
  name?: string
  onFinish: (image: SinglePhoto) => void
  reload?: boolean
}

const MediaLibrary = (props: Props) => {
  const texts = useTranslation().t

  const userName = useSelector((e: State) => e.user.name)
  const userLastName = useSelector((e: State) => e.user.lastName)
  const userUuid = useSelector((e: State) => e.user.uuid)

  const [isFileUploading, setIsFileUploading] = React.useState<boolean>(false)
  const [searchingForImages, setIsSearchingForImages] = React.useState<boolean>(false)
  const [imageList, setImageList] = React.useState<any[]>([])
  const [emptyImgSlots, setEmptyImgSlots] = React.useState<any[]>([])
  const [selectedImage, setSelectedImage] = React.useState<any | null>(null)
  const [imageCount, setImageCount] = React.useState<number>(0)
  const [editActive, setEditActive] = React.useState<boolean>(false)
  const [page, setPage] = React.useState<number>(1)

  const fileInputRef = useRef<HTMLInputElement>(null)
  const searchFormRef = useRef() as any
  const editFormRef = useRef() as any

  React.useEffect(() => {
    CdnApi.getImageList('', MAX_IMG_PER_PAGE, 0).then((res: any) => {
      setImageCount(res.count)
      setImageList(res.list)
    }).catch(error => onErrorResponseShowMessage(error, true, { userUuid: userUuid }))
  }, [])

  React.useEffect(() => {
    // if (!props.reload) {
      setSelectedImage(null)
      setPage(1)
      setEditActive(false)
      setIsSearchingForImages(false)
      setIsFileUploading(false)
      searchFormRef?.current?.resetForm()
      editFormRef?.current?.resetForm()
      CdnApi.getImageList('', MAX_IMG_PER_PAGE, 0).then((res: any) => {
        setImageCount(res.count)
        setImageList(res.list)
      }).catch(error => onErrorResponseShowMessage(error, true, { userUuid: userUuid }))
    // }
  }, [props.reload])

  React.useEffect(() => {
    const emptySlots = []
    for (let i = 0; i < MAX_IMG_PER_PAGE - imageList.length; i++) {
      emptySlots.push(true)
    }
    setEmptyImgSlots(emptySlots)
  }, [imageList])

  React.useEffect(() => {
    setEditActive(false)
    if (editFormRef?.current) {
      editFormRef.current.resetForm()
      editFormRef.current.setFieldValue('title', selectedImage?.title ?? '')
      editFormRef.current.setFieldValue('alt', selectedImage?.alt ?? '')
      editFormRef.current.setFieldValue('signature', selectedImage?.signature ?? '')
    }
  }, [selectedImage])

  React.useEffect(() => {
    CdnApi.getImageList(searchFormRef?.current?.values['search'] ?? '', MAX_IMG_PER_PAGE, (page - 1) * MAX_IMG_PER_PAGE).then((res: any) => {
      setImageCount(res.count)
      setImageList(res.list)
    }).catch(error => onErrorResponseShowMessage(error, true, { userUuid: userUuid }))
  }, [page])

  const clearFileInput = () => {
    if (fileInputRef?.current) {
      fileInputRef.current.value = ''
    }
  }

  const onSearchSubmit = async (data: any) => {
    try {
      setIsSearchingForImages(true)
      setPage(1)
      setSelectedImage(null)
      setEditActive(false)
      const res = await CdnApi.getImageList(data.search, MAX_IMG_PER_PAGE, 0)
      setImageCount(res.count)
      setImageList(res.list)
    } catch (error) {
      onErrorResponseShowMessage(error, true, { userUuid: userUuid })
    } finally {
      setIsSearchingForImages(false)
    }
  }

  const onEditSubmit = async (data: any) => {
    try {
      await CdnApi.editImage(selectedImage.uuid, data.title, data.alt, data.signature)
      selectedImage.title = data.title.trim()
      selectedImage.alt = data.alt.trim()
      selectedImage.signature = data.signature.trim()
      selectedImage.updatedAt = moment().format()
      selectedImage.lastEditor = {
        lastName: userLastName,
        name: userName
      }
  
      const imgIdx = imageList.findIndex(e => e.uuid === selectedImage.uuid)
      if (imgIdx > -1) {
        imageList[imgIdx] = selectedImage
      }
      setEditActive(false)
    } catch (error) {
      onErrorResponseShowMessage(error, true, { userUuid: userUuid })
    }
  }

  const onNewFileSelected = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files != null && e.target.files.length > 0) {
      setIsFileUploading(true)
      setPage(1)
      setSelectedImage(null)
      setEditActive(false)
      searchFormRef?.current?.resetForm()
      let buf, type
      // validation loop
      const filesToUpload: any[] = []
      for (let i = 0; i < e.target.files.length; i++) {
        buf = await e.target.files[i].arrayBuffer()
        type = await fileType.fromBuffer(buf)
        if (!type || !AllowedImageTypes.includes(type?.mime)) {
          store.dispatch(addErrorMessage('incorrect_file_type'))
          continue
        }
        if (e.target.files[i].size > MAX_FILE_SIZE) {
          store.dispatch(addErrorMessage('file_too_large'))
          continue
        }
        filesToUpload.push(e.target.files[i])
      }
      // Upload loop
      let imgListRes: any, uploadRes: any
      for (let i = 0; i < filesToUpload.length; i++) {
        try {
          uploadRes = await CdnApi.uploadLibraryMediaImage(filesToUpload[i])
          imgListRes = await CdnApi.getImageList()
          setImageCount(imgListRes.count)
          setImageList(imgListRes.list)
          setSelectedImage(imgListRes.list.find((e: any) => e.uuid === uploadRes.uuid))
        } catch (error) {
          onErrorResponseShowMessage(error, true, { userUuid: userUuid })
          continue
        }
      }
      clearFileInput()
      setIsFileUploading(false)
    }
  }
  
  return (
    <>
      <div className={styles.addNewImageSection} id={props.id}>
        <div>
          <LoadingButton
            circleSize={15}
            loading={isFileUploading}
            className={styles.addNewImageSectionButton}
          >
            <label
              htmlFor={`${props.name ?? 'default'}-add_picture_input`}
            >
              <div className={styles.addNewImageSectionButtonContent}>
                <IoAddCircle size={18}/>
                <div className={styles.buttonContent}>
                  {texts('mediaLibrary:upload_img_button')}
                </div>  
              </div>
            </label>
          </LoadingButton>
        </div>
        <div className={styles.addNewImageSectionButtonWarning}>
          {texts('mediaLibrary:upload_img_warning_label')}
        </div>
        <input
          id={`${props.name ?? 'default'}-add_picture_input`}
          ref={fileInputRef}
          type={'file'}
          accept={'.jpeg,.jpg,.png,.webp'}
          multiple
          className={styles.hiddenInput}
          onChange={onNewFileSelected}
        />
      </div>
      <div className={styles.searchFormSection}>
        <div className={styles.searchFormSectionTitle}>
          {texts('mediaLibrary:search_img_title')}
        </div>
        <div className={styles.searchFormSectionForm}>
          <Formik
            innerRef={searchFormRef}
            initialValues={{
              search: ''
            }}
            onSubmit={onSearchSubmit}
          >
            {(formikProps) => (
              <Form className={styles.searchForm}>
                <div className={styles.searchField}>
                  <BaseInput
                    label={texts('mediaLibrary:search_img_form_title_label')}
                    name={'search'}
                    formikProps={formikProps}
                    value={formikProps.values.search}
                    clearButton
                    classes={{
                      containerWithLabel: styles.inputContainerWithLabel,
                      containerWithoutLabel: styles.inputContainerWithoutLabel,
                      fieldBase: styles.textFieldBase
                    }}
                  />
                </div>
                <div className={styles.searchButtonContainer}>
                  <LoadingButton
                    type={'button'}
                    className={styles.searchButton}
                    loading={searchingForImages}
                    circleSize={15}
                    circleColor={'#fff'}
                    onClick={() => {
                      searchFormRef?.current?.submitForm()
                    }}
                  >
                    <div className={styles.searchButtonContent}>
                      <AiOutlineSearch size={20}/>
                      <div className={styles.buttonContent}>                        
                        {texts('mediaLibrary:search_img_form_button_label')}
                      </div>
                    </div>
                  </LoadingButton>
                </div>
                <div className={styles.insertImageButtonContainer}>
                  <LoadingButton
                    disabled={selectedImage == null || isFileUploading}
                    className={styles.insertImageButton}
                    onClick={() => { 
                      if (selectedImage.title != null && selectedImage.title !== '' && selectedImage.alt != null && selectedImage.alt !== '') {
                        props.onFinish(selectedImage) 
                      } else {
                        store.dispatch(addErrorMessage('insert_image_empty_fields'))
                      }
                    }}
                  >
                    <div className={styles.insertImageButtonContent}>
                      {texts('mediaLibrary:insert_image_label')}
                    </div>
                  </LoadingButton>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <div className={styles.mainSection}>
        <div className={styles.imageListSection}>
          <div className={styles.imageList}>
            {
              imageList.length > 0
              ? <>
                  {
                    imageList.map(img => {
                      return (
                      <div 
                        className={`${styles.imageTileContainer} ${selectedImage?.uuid === img.uuid ? styles.imageTileSelected : styles.imageTileNotSelected}`}
                        onClick={() => {
                          if (!isFileUploading) {
                            setSelectedImage(img)
                          }
                        }}
                      >
                        <img
                          src={`${img.hostname}${img.path}${img.filename}`}
                          width={img.width}
                          height={img.height}
                          className={styles.imageTile}
                        />
                      </div>
                    )})
                  }                
                  {
                    emptyImgSlots.map(e => (
                      <div className={styles.emptyImgTile}></div>
                    ))
                  } 
                </>
              : <div className={styles.noImages}>
                  {texts("mediaLibrary:no_images")}
              </div> 
            }                  
          </div>
          <div>
            <Pagination 
              pages={Math.ceil(imageCount/MAX_IMG_PER_PAGE) > 0 ? Math.ceil(imageCount/MAX_IMG_PER_PAGE) : 1}
              selectedPage={page}
              onChange={(selectedPage) => {
                if (!isFileUploading) {
                  setPage(selectedPage)
                }
              }}
            />
          </div>              
        </div>
        <div className={styles.imageDetailsSection}>
          <div className={styles.imageDetailsTitle}>
            {texts('mediaLibrary:img_details_title')}
          </div>
          <div className={styles.imageDetailsBaseInfo}>
            <div className={styles.imageDetailsBaseInfoItem}>
              <strong className={styles.imageDetailsBaseInfoMainLabel}>{texts('mediaLibrary:img_details_added_when_label')}</strong>{selectedImage ? moment(selectedImage.createdAt).format('DD.MM.YYYY, HH:mm') : '-'}
              <strong className={styles.imageDetailsBaseInfoByLabel}>{texts('mediaLibrary:img_details_added_by_label')}</strong>{selectedImage ? `${selectedImage.uploader.name} ${selectedImage.uploader.lastName}` : '-'}
            </div>
            {
              selectedImage?.lastEditor != null
              ? <div className={styles.imageDetailsBaseInfoItem}>
                  <strong className={styles.imageDetailsBaseInfoMainLabel}>{texts('mediaLibrary:img_details_updated_when_label')}</strong>{selectedImage ? moment(selectedImage.updatedAt).format('DD.MM.YYYY, HH:mm') : '-'}
                  <strong className={styles.imageDetailsBaseInfoByLabel}>{texts('mediaLibrary:img_details_updated_by_label')}</strong>{selectedImage ? `${selectedImage.lastEditor.name} ${selectedImage.lastEditor.lastName}` : '-'}
                </div>
              : null
            }
          </div>
          <Formik
            innerRef={editFormRef}
            initialValues={{
              title: '',
              alt: '',
              signature: ''
            }}
            onSubmit={onEditSubmit}
            validationSchema={editImageDetailsValidation(texts)}
          >
            {(formikProps) => (
              <Form className={styles.editForm}>
                <div className={styles.editFormFields}>
                  <div className={styles.editFormField}>
                    <BaseInput
                      disabled={!editActive}
                      label={texts('mediaLibrary:edit_img_form_title_label')}
                      name={'title'}
                      formikProps={formikProps}
                      value={formikProps.values.title}
                      maxLen={120}
                      count={true}
                      classes={{
                        containerWithLabel: styles.inputContainerWithLabel,
                        containerWithoutLabel: styles.inputContainerWithoutLabel,
                        fieldBase: styles.textFieldBase
                      }}
                      error={
                        formikProps.touched.title && Boolean(formikProps.errors.title)
                      }
                    />
                  </div>
                  <div className={styles.editFormField}>
                    <BaseInput
                      disabled={!editActive}
                      label={texts('mediaLibrary:edit_img_form_alt_label')}
                      name={'alt'}
                      formikProps={formikProps}
                      value={formikProps.values.alt}
                      maxLen={120}
                      count={true}
                      classes={{
                        containerWithLabel: styles.inputContainerWithLabel,
                        containerWithoutLabel: styles.inputContainerWithoutLabel,
                        fieldBase: styles.textFieldBase
                      }}
                      error={
                        formikProps.touched.alt && Boolean(formikProps.errors.alt)
                      }
                    />
                  </div>
                  <div className={styles.editFormField}>
                    <BaseInput
                      disabled={!editActive}
                      label={texts('mediaLibrary:edit_img_form_signature_label')}
                      name={'signature'}
                      formikProps={formikProps}
                      value={formikProps.values.signature}
                      maxLen={120}
                      count={true}
                      classes={{
                        containerWithLabel: styles.inputContainerWithLabel,
                        containerWithoutLabel: styles.inputContainerWithoutLabel,
                        fieldBase: styles.textFieldBase
                      }}
                    />
                  </div>
                </div>
                <div className={styles.editButtonContainer}>
                  <LoadingButton
                    type={'button'}
                    disabled={!selectedImage}
                    onClick={() => setEditActive(true)}
                    className={styles.editButtonActivate}
                    style={{
                      display: editActive ? 'none' : 'block'
                    }}
                  >
                    <div className={styles.editButtonContent}>
                      <div>{texts('mediaLibrary:activate_edit_mode')}</div>
                    </div>
                  </LoadingButton>
                  <LoadingButton
                    type={'button'}
                    disabled={!selectedImage}
                    className={styles.editButtonSubmit}
                    style={{
                      display: editActive ? 'block' : 'none'
                    }}
                    onClick={() => {
                      editFormRef?.current?.submitForm()
                    }}
                  >
                    <div className={styles.editButtonContent}>
                      <div>{texts('mediaLibrary:edit_img_form_submit_button_label')}</div>
                    </div>
                  </LoadingButton>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>      
    </>
  )
}

export default MediaLibrary
