import React from 'react'
import styles from './styles.module.scss'

type Props = {
  logoSrc?: string
  rightComponent?: React.ReactNode
}

const TopBar = (props: Props) => {
  return (
    <div className={styles.topBar}>
      <a className={styles.logoContainer} href={'/'}>
        {
          props.logoSrc
          ? <img
              src={props.logoSrc}
              className={styles.logo}
            />
          : null
        }
      </a>
      {
        process.env.REACT_APP_ENV === "test" 
        ? <div className={styles.badge}>TEST</div>
        : null  
      }
      <div className={styles.topBarSpacer}></div>
      <div>
        {props.rightComponent}
      </div>
    </div>
  )
}

export default TopBar
