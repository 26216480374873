import React from 'react';
import { DefaultToast } from 'react-toast-notifications';
import styles from './styles.module.scss';

const CustomToast = ({ children, ...props }: any) => (
  <DefaultToast {...props}>
    <div className={styles.toast}>{children}</div>
  </DefaultToast>
);

export default CustomToast
