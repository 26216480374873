import { ErrorMessage, Form, Formik } from 'formik'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { IoAddCircle } from 'react-icons/io5'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import MoonLoader from 'react-spinners/MoonLoader'
import DataApi from '../../api/DataApi'
import { addSuccessMessage } from '../../redux/messages/actions'
import { State } from '../../redux/reducers'
import store from '../../store'
import { GalleryPageType } from '../../types/common'
import { useConfirmLeaveHook } from '../../utils/hooks'
import { onErrorResponseShowMessage } from '../../utils/onErrorReponseShowMessage/onErrorResponseShowMessage'
import { addGalleryValidation } from '../../utils/validations/addGalleryValidation'
import BaseInput from '../common/BaseInput/BaseInput'
import CustomFlatpickr from '../common/CustomFlatpickr/CustomFlatpickr'
import CustomSwitch from '../common/CustomSwitch/CustomSwitch'
import GalleryPageTile, { GalleryTileData } from '../common/GalleryPageTile/GalleryPageTile'
import LoadingButton from '../common/LoadingButton/LoadingButton'
import Pagination from '../common/Pagination/Pagination'
import RouteLeavingGuard from '../common/RouteLeavingGuard/RouteLeavingGuard'
import { MessageEnum } from '../CustomToastProvider/types'
import styles from './styles.module.scss'
import { GalleryLibraryStateData } from '../common/GalleryLibrary/GalleryLibrary'

type LocationState = {
  pageType?: GalleryPageType
  pages?: any[]
  data?: InitialFormValues
  articleData?: {
    uuid?: string
    initialValues?: any
    mainImage?: any
    active?: boolean
    originalDetails?: any
  }
  galleryLibraryData?: GalleryLibraryStateData
}

export type InitialFormValues = {
  title: string
  author: string
  draft: boolean
  adultContent: boolean
  publishedAt: Date | null | undefined
  showAdultWarning: boolean
  activePageCount: number
} 

const initialValues: InitialFormValues = {
  title: '',
  author: '',
  draft: true,
  adultContent: false,
  publishedAt: undefined,
  showAdultWarning: false,
  activePageCount: 0
}

const MAX_ELEMENTS_PER_PAGE = 30

const AddGalleryPage = () => {

  const texts = useTranslation().t
  const history = useHistory()
  const location = useLocation<LocationState>()

  const userName = useSelector((e: State) => e.user.name)
  const userLastName = useSelector((e: State) => e.user.lastName)
  const userUuid = useSelector((e: State) => e.user.uuid)

  const [initialFormValues, setInitialFormValues] = React.useState<InitialFormValues>(initialValues)

  const [adultContent, setAdultContent] = React.useState<boolean>(false)
  const [showAdultWarning, setShowAdultWarning] = React.useState<boolean>(false)

  const [isDraft, setIsDraft] = React.useState<boolean>(true)
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false)
  const [isNavigationGuarded, setIsNavigationGuarded] = React.useState<boolean>(false)

  const [galleryPages, setGalleryPages] = React.useState<GalleryTileData[]>([])
  const [selectedListPage, setSelectedListPage] = React.useState<number>(1)

  const [stateToPush, setStateToPush] = React.useState<any>(null)

  const formRef = useRef() as any
 
  React.useEffect(() => {
    if (!location.state) { 
      history.goBack()
    } else if (location.state?.data && location.state?.pages) {
      setGalleryPages(setPageOrder(location.state.pages))
      setAdultContent(location.state.data.adultContent)
      setShowAdultWarning(location.state.data.showAdultWarning)
      setInitialFormValues({
        ...location.state.data,
        draft: true,
        activePageCount: location.state.pages.filter(e => e.active).length
      })
    }
    setIsNavigationGuarded(true)
    setIsLoading(false)
  }, [])

  React.useEffect(() => {
    if (!isLoading) {
      setInitialFormValues({
        ...initialFormValues,
        author: `${userName ?? ''} ${userLastName ?? ''}`
      })
      formRef?.current?.setFieldValue('author', `${userName ?? ''} ${userLastName ?? ''}`)
    }
  }, [userName, isLoading])

  React.useEffect(() => {
    if (!isLoading) {
      setInitialFormValues({
        ...initialFormValues,
        author: `${userName ?? ''} ${userLastName ?? ''}`
      })
      formRef?.current?.setFieldValue('author', `${userName ?? ''} ${userLastName ?? ''}`)
    }
  }, [userLastName, isLoading])
  
  React.useEffect(() => {
    if (!isLoading) {
      formRef?.current?.submitForm()
    }
  }, [isDraft])

  React.useEffect(() => {
    if (stateToPush) {
      if (stateToPush.replace) {
        history.replace(
          stateToPush.replace.url,
          stateToPush.replace.state
        )
      }
      if (stateToPush.push) {
        history.push(
          stateToPush.push.url,
          stateToPush.push.state
        )
      }
    }
  }, [stateToPush])

  const handleSubmit = async (data: any) => {
    try {
      setIsSubmitting(true)
      const mappedData = {
        active: true,
        draft: isDraft,
        name: data.title,
        adultContent: data.adultContent,
        showAdultWarning: data.showAdultWarning,
        pages: galleryPages.map((e, idx) => {
          return {
            photoUuid: e.photo!.uuid,
            text: e.content,
            active: e.active,
            activeOrder: e.order,
            totalOrder: idx,
            adultContent: e.adultContent,
            showAdultWarning: e.showAdultWarning
          }
        })
      }
      const res = await DataApi.createNewGallery(mappedData)
      store.dispatch(addSuccessMessage(MessageEnum.GALLERY_ADDED))
      setIsNavigationGuarded(false)
      if (location.state?.pageType != null && location.state?.pageType !== GalleryPageType.UNDEFINED) {
        let url = ''
        switch (location.state.pageType) {
          case GalleryPageType.FROM_ADD_ARTICLE:
            url = '/article/add'
            break
          case GalleryPageType.FROM_EDIT_ARTICLE:
            url = `/article/edit/${location.state?.articleData?.uuid}`
            break
        }
        history.push({
          pathname: url,
          state: {
            galleryData: {
              uuid: res.uuid
            },
            articleData: location.state.articleData,
            galleryLibraryData: {
              ...location.state.galleryLibraryData,
              uuid: res.uuid
            }
          }
        })
      }
    } catch (error: any) {
      onErrorResponseShowMessage(error, true, { userUuid: userUuid })
    } finally {
      setIsSubmitting(false)
    }
  }

  const setPageOrder = (arr: GalleryTileData[]): GalleryTileData[] => {
    let count = 1
    for (const page of arr) {
      if (page.active) {
        page.order = count++
      } else {
        page.order = undefined
      }
    }
    return arr
  }

  const goBack = () => {
    if (location.state?.pageType != null && location.state?.pageType !== GalleryPageType.UNDEFINED) {
      let url = ''
      switch (location.state.pageType) {
        case GalleryPageType.FROM_ADD_ARTICLE:
          url = '/article/add'
          break
        case GalleryPageType.FROM_EDIT_ARTICLE:
          url = `/article/edit/${location.state?.articleData?.uuid}`
          break
      }
      history.push({
        pathname: url,
        state: {
          articleData: location.state.articleData,
          galleryLibraryData: {
            ...location.state.galleryLibraryData,
          }
        }
      })
    }
  }

  useConfirmLeaveHook()

  return (
    <>
      <RouteLeavingGuard
        when={isNavigationGuarded}
        shouldBlockNavigation={location => {
          if (['/gallery/add', '/login'].includes(location.pathname)) {
            return false
          }
          return isNavigationGuarded
        }}
      /> 
      {
        isLoading
        ? <div className={styles.loadingSpinnerContainer}>
            <MoonLoader loading={true} size={100}/>
          </div>
        : <>
            <div className={styles.controlButtonSection}>
              <LoadingButton
                className={styles.backButton}
                onClick={goBack}
                type={"button"}
              > 
                {texts('common:go_back_button_label')}
              </LoadingButton>
              <div className={styles.flexSpacer}></div>
              <LoadingButton 
                className={styles.submitDraftButton}
                loading={isSubmitting}
                onClick={() => {
                  formRef.current?.setFieldValue('draft', true)
                  setIsDraft(true)
                  if (formRef.current?.values.draft) {
                    formRef.current?.submitForm()
                  }
                }}
              >
                <div className={styles.formButtonContent}>{texts('common:save_draft_button')}</div>
              </LoadingButton>
              <LoadingButton 
                className={styles.submitFormButton}
                loading={isSubmitting}
                onClick={() => {
                  formRef.current?.setFieldValue('draft', false)
                  setIsDraft(false)
                  if (!formRef.current?.values.draft) {
                    formRef.current?.submitForm()
                  }
                }}
              >
                <div className={styles.formButtonContent}>{texts('gallery:publish_button')}</div>
              </LoadingButton>
            </div>
            
            <h1 className={styles.pageTitle}>
              {texts('gallery:add_gallery_title')}
            </h1>
            <div className={styles.formContainer}>
              <Formik
                innerRef={formRef}
                initialValues={initialFormValues}
                validateOnBlur={true}
                validateOnChange={true}
                onSubmit={handleSubmit}
                enableReinitialize
                validationSchema={addGalleryValidation(texts)}
              >
                {(formikProps) => (
                  <Form className={styles.form}>
                    <div className={styles.section}>
                      <div className={styles.formRow}>
                        <div className={styles.field} style={{ width: '100%' }}>
                          <BaseInput
                            label={texts('gallery:title_label')}
                            name={'title'}
                            formikProps={formikProps}
                            classes={{
                              containerWithLabel: styles.inputContainerWithLabel,
                              containerWithoutLabel: styles.inputContainerWithoutLabel,
                              fieldBase: styles.textFieldBase
                            }}
                            value={formikProps.values.title}
                            maxLen={80}
                            count={true}
                            error={
                              formikProps.touched.title &&
                              Boolean(formikProps.errors.title)
                            }
                          />
                        </div>
                      </div>

                      <div className={styles.formRow}>
                        <div className={styles.field} style={{ width: '49%' }}>
                          <BaseInput
                            label={texts('gallery:author_label')}
                            name={'author'}
                            disabled={true}
                            formikProps={formikProps}
                            classes={{
                              containerWithLabel: styles.inputContainerWithLabel,
                              containerWithoutLabel: styles.inputContainerWithoutLabel,
                              fieldBase: styles.textFieldBase
                            }}
                            value={formikProps.values.author}
                            error={
                              formikProps.touched.author &&
                              Boolean(formikProps.errors.author)
                            }
                          />
                        </div>
                        <div className={styles.field} style={{ width: '25%' }}>
                          <div className={styles.fieldLabelWithResetContainer}>
                            <div className={styles.fieldLabel}>
                              {texts('common:publish_date_label')}
                            </div>
                          </div>
                          <CustomFlatpickr
                            name={'publishedAt'}
                            formikProps={formikProps}
                            disabled={true}
                            placeholder={texts('common:publish_date_placeholder')}
                            withTime
                          />
                          <div className={styles.errorLabel}>
                          {
                            formikProps.errors.publishedAt ?? <ErrorMessage name={'publishedAt'} />
                          }
                          </div>
                        </div>
                      </div>
                      <div className={styles.subsection}>
                        <div className={styles.subsectionTitle}>
                          {texts('gallery:additional_fields_label')}
                        </div>
                        <div className={styles.switchField}>
                          <div>
                            <CustomSwitch 
                              name={'adultContent'}
                              checked={adultContent}
                              setChecked={setAdultContent}
                              formikProps={formikProps}
                            />
                          </div>
                          <div className={styles.switchLabel} style={{ color: 'red' }}>
                            {texts('common:adult_content_label')}
                          </div>
                          <div className={styles.showDialogCheckboxAdditionalLabel}>
                            {texts('common:adult_content_additional_label')}
                          </div>
                        </div>
                        <div className={styles.switchField}>
                          <div>
                            <CustomSwitch 
                              name={'showAdultWarning'}
                              checked={showAdultWarning}
                              setChecked={setShowAdultWarning}
                              formikProps={formikProps}
                            />
                          </div>
                          <div className={styles.switchLabel} style={{ color: 'red' }}>
                            {texts('common:show_adult_warning_label')}
                          </div>
                          <div className={styles.showDialogCheckboxAdditionalLabel}>
                            {texts('common:show_adult_warning_additional_label')}
                          </div>
                        </div>
                        <div className={styles.adultContentWarning}>
                          {texts('common:adult_content_warning')}
                        </div>
                      </div>
                    </div>
                    <div className={styles.section}>
                      <div className={styles.sectionTitle}>
                        {texts('gallery:gallery_pages_label')}
                      </div>
                      <button
                        className={styles.innerFormButton}
                        type={'button'}
                        onClick={() => {
                          setIsNavigationGuarded(false)
                          setStateToPush({
                            replace: {
                              url: '/gallery/add',
                              state: {
                                pageType: location.state.pageType,
                                data: formikProps.values,
                                pages: galleryPages,
                                articleData: location.state.articleData,
                                galleryLibraryData: {
                                  ...location.state.galleryLibraryData
                                }
                              }
                            },
                            push: {
                              url: '/gallery/add/page',
                              state: {
                                pages: galleryPages,
                                pageType: location.state.pageType,
                                galleryData: formikProps.values,
                                articleData: location.state.articleData,
                                galleryLibraryData: {
                                  ...location.state.galleryLibraryData
                                }
                              }
                            }
                          })
                        }}
                      >   
                        <div className={styles.innerFormButtonContent}>
                          <IoAddCircle size={20}/>
                          <div className={styles.innerFormButtonContentLabel}>
                            {texts('gallery:add_gallery_page_button')}
                          </div>
                        </div>
                      </button>
                      <div className={styles.pageList}>
                        {
                          galleryPages.slice((selectedListPage - 1) * MAX_ELEMENTS_PER_PAGE, (selectedListPage) * MAX_ELEMENTS_PER_PAGE).map((e, idx) => (
                            <div className={styles.tileContainer}>
                              <GalleryPageTile 
                                index={(selectedListPage - 1) * MAX_ELEMENTS_PER_PAGE + idx + 1}
                                maxIndex={galleryPages.length}
                                activeCount={formikProps.values.activePageCount}
                                data={e}
                                onDown={() => {
                                  const tmpArr: GalleryTileData[] = [...galleryPages] as any[]
                                  [tmpArr[idx], tmpArr[idx + 1]] = [tmpArr[idx + 1], tmpArr[idx]]
                                  setGalleryPages(setPageOrder(tmpArr))
                                }}
                                onUp={() => {
                                  const tmpArr: GalleryTileData[] = [...galleryPages] as any[]
                                  [tmpArr[idx], tmpArr[idx - 1]] = [tmpArr[idx - 1], tmpArr[idx]]
                                  setGalleryPages(setPageOrder(tmpArr))
                                }}
                                onEdit={() => {
                                  setIsNavigationGuarded(false)
                                  setStateToPush({
                                    replace: {
                                      url: '/gallery/add',
                                      state: {
                                        pageType: location.state.pageType,
                                        data: formikProps.values,
                                        pages: galleryPages,
                                        articleData: location.state.articleData,
                                        galleryLibraryData: {
                                          ...location.state.galleryLibraryData
                                        }
                                      }
                                    },
                                    push: {
                                      url: '/gallery/add/page',
                                      state: {
                                        pages: galleryPages,
                                        pageType: location.state.pageType,
                                        galleryData: formikProps.values,
                                        editIndex: idx,
                                        articleData: location.state.articleData,
                                        galleryLibraryData: {
                                          ...location.state.galleryLibraryData
                                        }
                                      }
                                    }
                                  })
                                }}
                                onHide={() => {
                                  const tmpArr = [...galleryPages]
                                  tmpArr[idx].active = !tmpArr[idx].active
                                  setGalleryPages(setPageOrder(tmpArr))
                                  formikProps.values.activePageCount = tmpArr.filter(e => e.active).length
                                }}
                              />
                            </div>
                          ))
                        }
                        {
                          galleryPages.length > MAX_ELEMENTS_PER_PAGE
                          ? <div>
                              <Pagination 
                                pages={Math.ceil(galleryPages.length/MAX_ELEMENTS_PER_PAGE) > 0 ? Math.ceil(galleryPages.length/MAX_ELEMENTS_PER_PAGE) : 1}
                                selectedPage={selectedListPage}
                                onChange={(selectedPage) => {
                                  setSelectedListPage(selectedPage)
                                }}
                              />
                            </div>
                          : null
                        }
                        <div className={styles.errorLabel}>
                          {
                            formikProps.errors.activePageCount ?? <ErrorMessage name={'activePageCount'} />
                          }
                        </div>
                      </div>
                    </div>
                    <div className={styles.formButtonSection}>
                      <LoadingButton
                        className={styles.backButton}
                        onClick={goBack}
                        type={"button"}
                      > 
                        {texts('common:go_back_button_label')}
                      </LoadingButton>
                      <div className={styles.flexSpacer}></div>
                      <LoadingButton 
                        className={styles.submitDraftButton}
                        loading={isSubmitting}
                        onClick={() => {
                          formikProps.setFieldValue('draft', true)
                          setIsDraft(true)
                          if (formikProps.values.draft) {
                            formikProps.submitForm()
                          }
                        }}
                      >
                        <div className={styles.formButtonContent}>{texts('common:save_draft_button')}</div>
                      </LoadingButton>
                      <LoadingButton 
                        className={styles.submitFormButton}
                        loading={isSubmitting}
                        onClick={() => {
                          formikProps.setFieldValue('draft', false)
                          setIsDraft(false)
                          if (!formikProps.values.draft) {
                            formikProps.submitForm()
                          }
                        }}
                      >
                        <div className={styles.formButtonContent}>{texts('gallery:publish_button')}</div>
                      </LoadingButton>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </>
      }
    </>
  )
}

export default AddGalleryPage
