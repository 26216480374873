export const AllowedImageTypes = [
  'image/webp',
  'image/jpeg',
  'image/png'
]

export const MAX_FILE_SIZE = 2 * 1024 * 1024
export const MAX_IMG_PER_PAGE = 20

export type SinglePhoto = {
  uuid: string
  title: string
  filename: string
  hostname: string
  path: string
  alt: string | null | undefined
  signature: string | null | undefined
  width: number | null | undefined
  height: number | null | undefined
  createdAt: string
  updatedAt: string
  uploader: {
    nick?: string
    name?: string
    lastName?: string
  }
  lastEditor?: {
    nick?: string
    name?: string
    lastName?: string
  } | null | undefined
  avatar?: {
    nick?: string
    name?: string
    lastName?: string
  } | null | undefined
}