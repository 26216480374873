import { ErrorMessage, Form, Formik } from 'formik'
import moment from 'moment'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { IoMdTrash } from 'react-icons/io'
import { IoAddCircle } from 'react-icons/io5'
import { MdImage } from 'react-icons/md'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import MoonLoader from 'react-spinners/MoonLoader'
import DataApi from '../../api/DataApi'
import { addCmdMessage } from '../../redux/messages/actions'
import { State } from '../../redux/reducers'
import store from '../../store'
import { GalleryPageType, Project } from '../../types/common'
import { useConfirmLeaveHook } from '../../utils/hooks'
import { onErrorResponseShowMessage } from '../../utils/onErrorReponseShowMessage/onErrorResponseShowMessage'
import { addPageToGalleryValidation } from '../../utils/validations/addPageToGalleryValidation'
import { InitialFormValues as GalleryInitialFormValues } from '../AddGalleryPage/AddGalleryPage'
import BaseInput from '../common/BaseInput/BaseInput'
import BBCodeDepeszaGalleryPagePreview from '../common/BBCodeDepeszaGalleryPagePreview/BBCodeDepeszaGalleryPagePreview'
import BBCodeEditor from '../common/BBCodeEditor/BBCodeEditor'
import BBCodeZaradnaGalleryPagePreview from '../common/BBCodeZaradnaGalleryPagePreview/BBCodeZaradnaGalleryPagePreview'
import CustomFlatpickr from '../common/CustomFlatpickr/CustomFlatpickr'
import CustomSwitch from '../common/CustomSwitch/CustomSwitch'
import HtmlValidationErrorDialog from '../common/HtmlValidatorDialog/HtmlValidatorDialog'
import LoadingButton from '../common/LoadingButton/LoadingButton'
import { SinglePhoto } from '../common/MediaLibrary/types'
import MediaLibraryDialog from '../common/MediaLibraryDialog/MediaLibraryDialog'
import RouteLeavingGuard from '../common/RouteLeavingGuard/RouteLeavingGuard'
import { MessageEnum } from '../CustomToastProvider/types'
import styles from './styles.module.scss'
import { GalleryLibraryStateData } from '../common/GalleryLibrary/GalleryLibrary'

type LocationState = {
  pageType?: GalleryPageType
  pages: any[]
  galleryData: GalleryInitialFormValues
  uuid: string
  editIndex?: number
  articleData?: {
    uuid?: string
    initialValues?: any
    mainImage?: any
    active?: boolean
  }
  originalGalleryDetails?: any
  galleryLibraryData?: GalleryLibraryStateData
}

type InitialFormValues = {
  content: string
  author: string
  photoUuid: string
  adultContent: boolean
  publishedAt: Date | null | undefined
  modifiedAt: Date | null | undefined
  showAdultWarning: boolean
  photo: SinglePhoto | null | undefined
  editor: string
} 

const initialValues: InitialFormValues = {
  author: '',
  content: '',
  adultContent: false,
  publishedAt: undefined,
  modifiedAt: undefined,
  showAdultWarning: false,
  photoUuid: '',
  photo: null,
  editor: '',  
}

const EditPageToGalleryPage = () => {
  const texts = useTranslation().t
  const history = useHistory()
  const location = useLocation<LocationState>()
  
  const userName = useSelector((e: State) => e.user.name)
  const userLastName = useSelector((e: State) => e.user.lastName)
  const userUuid = useSelector((e: State) => e.user.uuid)

  const [initialFormValues, setInitialFormValues] = React.useState<InitialFormValues>(initialValues)

  const [adultContent, setAdultContent] = React.useState<boolean>(false)
  const [showAdultWarning, setShowAdultWarning] = React.useState<boolean>(false)

  const [imageData, setImageData] = React.useState<SinglePhoto | null> (null)

  const [showMediaLibraryDialog, setShowMediaLibraryDialog] = React.useState<boolean>(false)
  const [showHtmlValidationErrorDialog, setShowHtmlValidationErrorDialog] = React.useState<boolean>(false)

  const [htmlValidationErrors, setHtmlValidationErrors] = React.useState<string[]>([])

  const [newPages, setNewPages] = React.useState<any[]>([])
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false)

  const [isNavigationGuarded, setIsNavigationGuarded] = React.useState<boolean>(false)

  const formRef = useRef() as any
 
  React.useEffect(() => {
    if (!location.state) { 
      history.goBack()
    } if (location.state?.editIndex != null) {
      setAdultContent(location.state.pages[location.state.editIndex].adultContent)
      setShowAdultWarning(location.state.pages[location.state.editIndex].showAdultWarning)
      setInitialFormValues({
        author: `${location.state.pages[location.state.editIndex].author.name} ${location.state.pages[location.state.editIndex].author.lastName}`,
        content: location.state.pages[location.state.editIndex].content,
        adultContent: location.state.pages[location.state.editIndex].adultContent,
        publishedAt: undefined,
        showAdultWarning: location.state.pages[location.state.editIndex].showAdultWarning,
        photoUuid: location.state.pages[location.state.editIndex].photoUuid,
        photo: location.state.pages[location.state.editIndex].photo,
        modifiedAt: location.state.pages[location.state.editIndex].modifiedAt ? moment(location.state.pages[location.state.editIndex].modifiedAt).toDate() : undefined,
        editor: location.state.pages[location.state.editIndex].lastEditor ? `${location.state.pages[location.state.editIndex].lastEditor.name} ${location.state.pages[location.state.editIndex].lastEditor.lastName}` : '-' 
      } )
      setImageData(location.state.pages[location.state.editIndex].photo)
    }
    setIsLoading(false) 
    setIsNavigationGuarded(true)
  }, [])

  React.useEffect(() => {
    if (location.state?.editIndex == null) {
      setInitialFormValues({
        ...initialFormValues,
        author: `${userName ?? ''} ${userLastName ?? ''}`
      })
      formRef?.current?.setFieldValue('author', `${userName ?? ''} ${userLastName ?? ''}`)
    }
  }, [userName])

  React.useEffect(() => {
    if (location.state?.editIndex == null) {
      setInitialFormValues({
        ...initialFormValues,
        author: `${userName ?? ''} ${userLastName ?? ''}`
      })
      formRef?.current?.setFieldValue('author', `${userName ?? ''} ${userLastName ?? ''}`)
    }
  }, [userLastName])
  
  const handleSubmit = async (data: any) => {
    try {
      setIsSubmitting(true)
      const pages = [...location.state.pages]
      const res = await DataApi.validateBBCode(data.content)
      if (location.state?.editIndex != null) {
        pages[location.state?.editIndex] = {
          uuid: pages[location.state?.editIndex].uuid,
          content: data.content,
          author: {
            name: userName,
            lastName: userLastName
          },
          active: pages[location.state?.editIndex].active,
          adultContent: data.adultContent,
          showAdultWarning: data.showAdultWarning,
          photoUuid: data.photoUuid,
          photo: imageData,
          settings: {}
        }
      } else {
        pages.push({
          content: data.content,
          author: {
            name: userName,
            lastName: userLastName
          },
          active: true,
          adultContent: data.adultContent,
          showAdultWarning: data.showAdultWarning,
          photoUuid: data.photoUuid,
          photo: imageData,
          settings: {}
        })
      }
      setIsNavigationGuarded(false)
      if (res.valid != null && !res.valid) {
        setNewPages([...pages])
        setHtmlValidationErrors(res.errorMessages ?? [])
        openHtmlValidationErrorDialog()
      } else {
        history.replace(
          `/gallery/edit/${location.state.uuid}/page`,
          {
            uuid: location.state.uuid,
            pages: pages,
            pageType: location.state.pageType,
            galleryData: location.state.galleryData,
            articleData: location.state.articleData,
            editIndex: pages.length - 1,
            originalGalleryDetails: location.state.originalGalleryDetails,
            galleryLibraryData: {
              ...location.state.galleryLibraryData
            }
          }
        )
        history.push(`/gallery/edit/${location.state.uuid}`, {
          pageType: location.state.pageType,
          data: location.state.galleryData,
          uuid: location.state.uuid,
          pages: pages,
          articleData: location.state.articleData,
          originalGalleryDetails: location.state.originalGalleryDetails,
          galleryLibraryData: {
            ...location.state.galleryLibraryData
          }
        })
      }
    } catch (error) {
      onErrorResponseShowMessage(error, true, { userUuid: userUuid })
    } finally {
      setIsSubmitting(false)
    }
  }
  
  const openMediaLibraryDialog = () => {
    store.dispatch(addCmdMessage(MessageEnum.ENABLE_PAGE_COVER))
    setShowMediaLibraryDialog(true)
  }

  const closeMediaLibraryDialog = () => {
    store.dispatch(addCmdMessage(MessageEnum.DISABLE_PAGE_COVER))
    setShowMediaLibraryDialog(false)
  }

  const openHtmlValidationErrorDialog = () => {
    store.dispatch(addCmdMessage(MessageEnum.ENABLE_PAGE_COVER))
    setShowHtmlValidationErrorDialog(true)
  }

  const closeHtmlValidationErrorDialog = () => {
    store.dispatch(addCmdMessage(MessageEnum.DISABLE_PAGE_COVER))
    setShowHtmlValidationErrorDialog(false)
  }

  const getImageUrl = () => {
    return imageData ? `${imageData.hostname}${imageData.path}${imageData.filename}` : ''
  }

  const goBack = () => {    
    history.push(`/gallery/edit/${location.state.uuid}`, {
      pageType: location.state.pageType,
      data: location.state.galleryData,
      uuid: location.state.uuid,
      pages: location.state.pages,
      articleData: location.state.articleData,
      originalGalleryDetails: location.state.originalGalleryDetails,
      galleryLibraryData: {
        ...location.state.galleryLibraryData
      }
    })
  }
  
  const getArticlePreviewComponent = () => {
    switch (process.env.REACT_APP_PROJECT) {
      case Project.ZARADNA:
        return BBCodeZaradnaGalleryPagePreview
      case Project.DEPESZA:
      default:
        return BBCodeDepeszaGalleryPagePreview
    }
  }

  useConfirmLeaveHook()

  return (
    <>
      <RouteLeavingGuard
        when={isNavigationGuarded}
        shouldBlockNavigation={location => {
          if (location.pathname === '/login') {
            return false
          }
          return isNavigationGuarded
        }}
      /> 
      <HtmlValidationErrorDialog 
        hidden={!showHtmlValidationErrorDialog}
        errorMessages={htmlValidationErrors}
        onCloseClick={() => {
          closeHtmlValidationErrorDialog()
          history.push(`/gallery/edit/${location.state.uuid}`, {
            pageType: location.state.pageType,
            data: location.state.galleryData,
            pages: newPages,
            articleData: location.state.articleData,
            originalGalleryDetails: location.state.originalGalleryDetails,
            galleryLibraryData: {
              ...location.state.galleryLibraryData
            }
          })
        }}
        onEditLabelClick={() => {
          closeHtmlValidationErrorDialog()
          history.push(`/gallery/edit/${location.state.uuid}/page`, {
            uuid: location.state.uuid,
            pages: newPages,
            pageType: location.state.pageType,
            galleryData: location.state.galleryData,
            articleData: location.state.articleData,
            editIndex: location.state?.editIndex ?? newPages.length - 1,
            originalGalleryDetails: location.state.originalGalleryDetails,
            galleryLibraryData: {
              ...location.state.galleryLibraryData
            }
          })
        }}
      />
      <MediaLibraryDialog
        id={'gallery-media-library-dialog'}
        name={'gallery-media-library-dialog'}
        hidden={!showMediaLibraryDialog}
        onCloseClick={() => {
          closeMediaLibraryDialog()
        }}
        onFinish={(img) => {
          formRef?.current?.setFieldValue('photo', img)
          setImageData(img)
        }}
      />
      {
        isLoading
        ? <div className={styles.loadingSpinnerContainer}>
            <MoonLoader loading={true} size={100}/>
          </div>
        : <>
            <div className={styles.controlButtonSection}>
              <LoadingButton
                className={styles.backButton}
                onClick={goBack}
                type={"button"}
              > 
                {texts('common:go_back_button_label')}
              </LoadingButton>
              <div className={styles.flexSpacer}></div>
              <LoadingButton 
                type="submit"
                className={styles.submitFormButton}
                onClick={() => {
                  formRef.current?.submitForm()
                }}
              >
                <div className={styles.formButtonContent}>{texts('gallery:save_page_button')}</div>
              </LoadingButton>
            </div>
            <h1 className={styles.pageTitle}>
              {texts('gallery:edit_gallery_button')}
            </h1>
            <div className={styles.formContainer}>
              <Formik
                innerRef={formRef}
                initialValues={initialFormValues}
                validateOnBlur={true}
                validateOnChange={true}
                onSubmit={handleSubmit}
                enableReinitialize
                validationSchema={addPageToGalleryValidation(texts)}
              >
                {(formikProps) => (
                  <Form className={styles.form}>
                    <div className={styles.section}>
                      <div className={styles.sectionTitle}>
                        {texts('gallery:image')}
                      </div>
                      <div
                        className={styles.imageContainer}
                      >
                        <div
                          className={styles.imageImgSection}
                        >
                          {
                            imageData != null
                            ? <img 
                                className={styles.image}
                                src={getImageUrl()}
                              />
                            : <div className={styles.imagePlaceholder}>
                                <MdImage size={40}/>
                              </div>
                          }
                        </div>
                        <div className={styles.imageControlSection}>
                          <button
                            className={styles.imageAddButton}
                            type={'button'}
                            onClick={() => {
                              openMediaLibraryDialog()
                            }}
                          >
                            <div className={styles.imageButtonContent}>
                              <IoAddCircle size={20}/>
                              <div className={styles.imageButtonContentLabel}>
                                {texts('gallery:choose_or_add_image_button')}
                              </div>
                            </div>
                          </button>
                          {
                            imageData != null
                            ? <button
                                disabled={imageData == null}
                                className={styles.imageRemoveButton}
                                type={'button'}
                                onClick={() => {
                                  formikProps.values.photo = null
                                  formikProps.values.photoUuid = ''
                                  setImageData(null)
                                }}
                              >
                                <div className={styles.imageButtonContent}>
                                  <IoMdTrash size={20}/>
                                  <div className={styles.imageButtonContentLabel}>
                                    {texts('gallery:remove_image_button')}
                                  </div>
                                </div>
                              </button>
                            : null
                          }
                          
                        </div>
                      </div>
                      <div className={styles.errorLabel}>
                        {
                          (formikProps.errors.photo && JSON.stringify(formikProps.touched.photo)) ? <ErrorMessage name={'photo'} /> : null
                        }
                      </div>
                    </div>
                    <div className={styles.section}>
                      <div className={styles.formRow}>
                        <div className={styles.field} style={{ width: '26.45%' }}>
                          <BaseInput
                            label={texts('gallery:author_label')}
                            name={'author'}
                            disabled={true}
                            formikProps={formikProps}
                            classes={{
                              containerWithLabel: styles.inputContainerWithLabel,
                              containerWithoutLabel: styles.inputContainerWithoutLabel,
                              fieldBase: styles.textFieldBase
                            }}
                            value={formikProps.values.author}
                            error={
                              formikProps.touched.author &&
                              Boolean(formikProps.errors.author)
                            }
                          />
                        </div>                        
                        <div className={styles.field} style={{ width: '26.45%' }}>
                          <BaseInput
                            label={texts('gallery:edited_by_label')}
                            name={'editor'}
                            disabled={true}
                            formikProps={formikProps}
                            classes={{
                              containerWithLabel: styles.inputContainerWithLabel,
                              containerWithoutLabel: styles.inputContainerWithoutLabel,
                              fieldBase: styles.textFieldBase
                            }}
                            value={formikProps.values.editor}
                          />
                        </div>
                        <div className={styles.field} style={{ width: '23.54%' }}>
                          <div className={styles.fieldLabelWithResetContainer}>
                            <div className={styles.fieldLabel}>
                              {texts('common:add_date_label')}
                            </div>
                          </div>
                          <CustomFlatpickr
                            name={'publishedAt'}
                            disabled={true}
                            formikProps={formikProps}
                            placeholder={texts('common:publish_date_placeholder')}
                            withTime
                          />
                          <div className={styles.errorLabel}>
                          {
                            formikProps.errors.publishedAt ?? <ErrorMessage name={'publishedAt'} />
                          }
                          </div>
                        </div>
                        <div className={styles.field} style={{ width: '23.54%' }}>
                          <div className={styles.fieldLabelWithResetContainer}>
                            <div className={styles.fieldLabel}>
                              {texts('common:update_date_label')}
                            </div>
                          </div>
                          <CustomFlatpickr
                            name={'modifiedAt'}
                            disabled={true}
                            formikProps={formikProps}
                            // placeholder={texts('common:publish_date_placeholder')}
                            placeholder={'-'}
                            withTime
                          />
                        </div>                  
                      </div>                       
                      <div className={styles.subsection}>
                        <div className={styles.subsectionTitle}>
                          {texts('gallery:additional_fields_label')}
                        </div>
                        <div className={styles.switchField}>
                          <div>
                            <CustomSwitch 
                              name={'adultContent'}
                              checked={adultContent}
                              setChecked={setAdultContent}
                              formikProps={formikProps}
                            />
                          </div>
                          <div className={styles.switchLabel} style={{ color: 'red' }}>
                            {texts('common:adult_content_label')}
                          </div>
                          <div className={styles.showDialogCheckboxAdditionalLabel}>
                            {texts('common:adult_content_additional_label')}
                          </div>
                        </div>
                        <div className={styles.switchField}>
                          <div>
                            <CustomSwitch 
                              name={'showAdultWarning'}
                              checked={showAdultWarning}
                              setChecked={setShowAdultWarning}
                              formikProps={formikProps}
                            />
                          </div>
                          <div className={styles.switchLabel} style={{ color: 'red' }}>
                            {texts('common:show_adult_warning_label')}
                          </div>
                          <div className={styles.showDialogCheckboxAdditionalLabel}>
                            {texts('common:show_adult_warning_additional_label')}
                          </div>
                        </div>
                        <div className={styles.adultContentWarning}>
                          {texts('common:adult_content_warning')}
                        </div>
                      </div>
                    </div>
                    <div className={styles.section}>
                      <BBCodeEditor 
                        name={'content'}
                        excludedActions={{
                          photo:true,
                          link:true,
                          facebook: true,
                          instagram: true,
                          twitter: true,
                          tiktok: true,
                          pinterest: true,
                          telegram: true,
                          reddit: true,
                          youtube: true,
                          adBlock: true
                        }}
                        formikProps={formikProps}
                        previewComponent={
                          getArticlePreviewComponent()({
                            contentFieldName: 'content',
                            formikProps: formikProps,
                            pageNumber: location.state?.editIndex != null ? (location.state?.pages[location.state?.editIndex].order) : location.state?.pages.filter(e => e.active).length + 1,
                            pageCount: location.state?.pages != null ? location.state?.pages.filter(e => e.active).length + (location.state?.editIndex != null ? 0 : 1) : 0,
                            image: {
                              src: getImageUrl(),
                              signature: imageData?.signature ?? ''
                            },
                            galleryData: {
                              title: location.state?.galleryData?.title ?? '',
                              author: location.state?.galleryData?.author,
                              publishedAt: location.state?.galleryData?.publishedAt
                            },
                            leadText: location.state?.articleData?.initialValues?.leadText ?? ''
                          })
                        }
                      />
                    </div>
                    <div className={styles.formButtonSection}>
                      <LoadingButton
                        className={styles.backButton}
                        onClick={goBack}
                        type={"button"}
                      > 
                        {texts('common:go_back_button_label')}
                      </LoadingButton>
                      <div className={styles.flexSpacer}></div>
                      <LoadingButton 
                        type="submit"
                        className={styles.submitFormButton}
                      >
                        <div className={styles.formButtonContent}>{texts('gallery:save_page_button')}</div>
                      </LoadingButton>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </>
      }
    </>
  )
}

export default EditPageToGalleryPage
