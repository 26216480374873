import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import BBCodeArticle from '../BBCodeArticle/BBCodeArticle'
import { SinglePhoto } from '../MediaLibrary/types'
import Timestamp from '../Timestamp/Timestamp'
import styles from './styles.module.scss'
import AssetLoader from '../../../assets/loader'
import { evalStringTemplate } from '../../../utils/text'

type Props = {
  contentFieldName: string
  formikProps: any
  mainImageData?: {
    img: SinglePhoto
    croppedImage?: Blob
  } | null
  gallery?: any
  publishedAt?: string | Date
  modifiedAt?: string | Date
}

const BBCodeZaradnaArticlePreview = (props: Props) => {
  const texts = useTranslation().t

  const getMainImgUrl = () => {
    if (props.mainImageData == null) {
      return ''
    } else if (props.mainImageData.croppedImage != null) {
      return URL.createObjectURL(props.mainImageData.croppedImage)
    } else {
      return `${props.mainImageData.img.hostname}${props.mainImageData.img.path}${props.mainImageData.img.filename}`
    }
  }

  return (
    <>
      <div className={styles.container}>
        {
          props.mainImageData
          ? <div className={styles.imageSection}>
              <img
                className={styles.mainImage}
                src={getMainImgUrl()}
                alt={''}
              />
              {
                props.mainImageData.img.signature != null
                ? <div className={styles.mainImageSource}>{props.mainImageData.img.signature}</div>
                : null
              }
            </div>
          : null
        }
        <div className={styles.topSection}>
          <h1 className={styles.titleSection}>{props.formikProps.values.title}</h1>
        </div>
        <div className={styles.mainSectionMainColumn}>
          <div className={styles.mainSectionMainColumnFlexContainer}>
            <div className={styles.mainColumnArticleColumn}>
              <div className={styles.articleInfoSection}>
                <div className={styles.authorInfo}>
                  {
                    props.formikProps.values.hideAuthor
                    ? null
                    : <div className={styles.articleInfoAuthor}>
                        {`${props.formikProps.values.author ?? ''}, `}
                      </div>
                  }
                  <div className={styles.articleInfoDate}>
                    {
                      props.publishedAt
                      ? <Timestamp
                          date={props.publishedAt}
                          showDateTime
                        />
                      : '-'
                    }
                  </div>
                </div>
                {
                  props.modifiedAt == null || !props.formikProps.values.showModificationDate
                  ? ''
                  : <div className={styles.articleInfoModifiedDate}>
                      {`(${texts('article:update_at_label')}: `}
                      <Timestamp
                        date={props.modifiedAt}
                        hideTime={!props.formikProps.values.showModificationHour}
                      />
                      {')'}
                    </div>
                }
              </div>
              <div className={styles.articleLeadText}>
                {props.formikProps.values.leadText}
              </div>
              {
                props.gallery
                ? <div
                    className={styles.goToGalleryButton}
                    onClick={() => {
                      document.getElementById('gallery')?.scrollIntoView({behavior: 'smooth'})
                    }}
                  >
                    <div className={styles.galleryIcon}>
                      <svg width="25" height="25" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19 8H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V10a2 2 0 0 0-2-2z" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M10.438 4.17a1 1 0 0 1 1.192-.76L25.3 6.437a1 1 0 0 1 .76 1.193L23.03 21.299a1 1 0 0 1-1.193.76l-.337-.075v2.047a3 3 0 0 0 3.483-2.3l3.029-13.668a3 3 0 0 0-2.28-3.578L12.063 1.456a3 3 0 0 0-3.578 2.28L7.651 7.5H9.7l.738-3.33z" fill="#fff"/>
                        <path d="M8.5 15a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zM21 20l-5-5L5 26" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>
                    </div>
                    <div className={styles.goToGalleryButtonLabel}>{texts('editor:go_to_gallery_button')}</div>
                  </div>
                : null
              }
              <div className={styles.articleContent}>
                <BBCodeArticle bbcode={props.formikProps.values[props.contentFieldName] ?? ''} />
              </div>
              {
                props.gallery
                ? <>
                    <div className={styles.gallerySectionNameSection}>
                      <span className={styles.gallerySectionNameSectionLabel}>{texts('editor:see_gallery_label')} </span> {props.gallery.title}
                    </div>
                    <div id={'gallery'} className={styles.gallerySection}>
                      <div className={styles.gallerySectionTile}>
                        <div 
                          className={
                            [
                              styles.gallerySectionTileGradientBase,
                              styles.gallerySectionTileGradientNormal,
                              styles.gallerySectionTileGradientMoz,
                              styles.gallerySectionTileGradientWebkit,
                              styles.gallerySectionTileGradientWebkitLinear
                            ].join(' ')
                          }
                        ></div>
                        <div className={styles.gallerySectionTileSeeGalleryContainer}>
                          <div className={styles.gallerySectionSmallArticleImage}>
                            <div className={props.gallery.showAdultWarning || props.gallery.firstPage?.showAdultWarning ? styles.gallerySmallImageContainerBlur : ''}>
                              <img
                                src={
                                  props.gallery.firstPage?.photo
                                  ? `${props.gallery.firstPage.photo.hostname}${props.gallery.firstPage.photo.path}${props.gallery.firstPage.photo.filename}`
                                  : AssetLoader.getPlaceholderImage()
                                }
                                className={styles.smallGalleryImage}
                                width={152}
                                height={80}                       
                              />
                            </div>
                          </div>
                          <div className={styles.gallerySectionTileButton}>
                            <div className={styles.gallerySectionTileButtonLabel}>
                              <svg width="29" height="29" viewBox="0 0 29 29" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" className={styles.gallerySectionIcon}>
                                <defs>
                                  <path id="a" d="M0 0h29v29H0z"/>
                                </defs>
                                <g fill="none" fillRule="evenodd">
                                  <mask id="b" fill="#fff">
                                    <use xlinkHref="#a"/>
                                  </mask>
                                  <path d="M19 8a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V10a2 2 0 0 1 2-2h14z" stroke="#FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="0,0" mask="url(#b)"/>
                                  <path d="m11.847 2.433 13.668 3.029a2 2 0 0 1 1.52 2.385l-3.029 13.668a2 2 0 0 1-2.385 1.52l-.122-.027L21.5 7.5H8.675l.786-3.547a2 2 0 0 1 2.386-1.52z" fill="#FFF" mask="url(#b)"/>
                                  <path d="M8.5 15a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zM21 20l-5-5L5 26" stroke="#FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="0,0" mask="url(#b)"/>
                                </g>
                              </svg>
                              <div className={styles.gallerySectionTileButtonRightLabel}>
                                <div className={styles.gallerySectionTileButtonLabelText}>{texts('editor:see_gallery_label_nbsp')}</div>
                                {
                                  props.gallery.pageCount !== null
                                  ? <div className={styles.gallerySectionTileButtonCount}>
                                      ({evalStringTemplate(texts('gallery:galleryCountLabel'), {count: props.gallery.pageCount, plural: (props.gallery.pageCount === 1 ? 'zdjęcie' : props.gallery.pageCount %10 >= 2 && props.gallery.pageCount %10 <=4 && (props.gallery.pageCount %100 < 10 || props.gallery.pageCount %100 >= 20) ? 'zdjęcia' : 'zdjęć')})})
                                    </div>
                                  : null
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={props.gallery.showAdultWarning || props.gallery.firstPage?.showAdultWarning ? styles.galleryImageContainerBlur : ''}>
                          <img
                            src={
                              props.gallery.firstPage?.photo
                              ? `${props.gallery.firstPage.photo.hostname}${props.gallery.firstPage.photo.path}${props.gallery.firstPage.photo.filename}`
                              : AssetLoader.getPlaceholderImage()
                            }
                            width={660}
                            height={470}
                            className={styles.galleryImage}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                : null
              }
              {
                props.formikProps.values.tags && props.formikProps.values.tags.length > 0 && !props.formikProps.values.isWikiArticle
                ? <div className={styles.previewTagsContainer}>
                    {
                      props.formikProps.values.tags.map((tag: any) => (
                        <div className={styles.previewTag}>{`${tag.label}`}</div>
                      ))
                    }
                  </div>
                : null
              }
              <div>
                {
                  props.formikProps.values.source != null && props.formikProps.values.source !== ''
                  ? <div className={styles.articleSource}>
                      {`${texts('editor:source_label')}: ${props.formikProps.values.source}`}
                    </div> 
                  : null
                }
                {
                  props.formikProps.values.sponsoredContent 
                  ? <div className={styles.sponsoredArticle}>
                      {
                        props.formikProps.values.sponsor != null && props.formikProps.values.sponsor !== ''
                        ? props.formikProps.values.sponsor
                        : texts('article:sponsoredLabel')
                      }
                    </div> 
                  : null
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BBCodeZaradnaArticlePreview
