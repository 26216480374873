import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { addToken, removeToken } from '../redux/auth/actions'
import store from '../store'
import { StorageService } from '../utils/storage/StorageService'
import { Api } from './Api'
import { BASE_AUTH, REFRESH_PATH_END } from './baseRoutes'
import { removeUserData } from '../redux/user/actions'

export class BaseApiWithRefresh extends Api {
  public constructor(config: AxiosRequestConfig) {
    super(config)
    this.setupBasicInterceptors()
    this.addRequestInterceptor((config: AxiosRequestConfig) => {
      if (!config.headers.authorization) {
        config.headers.authorization = `Bearer ${StorageService.getInstance().getAccessToken()}`
      }
      return config
    })
  } 

  public getRefreshTokenPromise(): Promise<any> {
    return this.post(`${BASE_AUTH}/api/v1/auth/refresh`, undefined, {
      headers: {
        authorization: `Bearer ${StorageService.getInstance().getRefreshToken()}`
      }
    })
  }

  private async refreshTokenAndRetry(config: AxiosRequestConfig): Promise<never> {
    const response = await this.getRefreshTokenPromise()
    StorageService.getInstance().setAccessToken(response.sessionToken ?? response.data.sessionToken)
    store.dispatch(addToken(response.sessionToken ?? response.data.sessionToken))
    delete config.headers['authorization']
    return this.request(config)
  }

  protected handleError = async (error: AxiosError): Promise<never> => {
    const originalRequest: any = error.response?.config
    if (error.response?.status === 401 && !originalRequest._retry && !originalRequest.url.endsWith(REFRESH_PATH_END)) {
      originalRequest._retry = true
      return this.refreshTokenAndRetry(error.response.config)
    } else if (error.response?.status === 401) {
      StorageService.getInstance().clearUserCache()
      store.dispatch(removeToken())
      store.dispatch(removeUserData())
    }
    return Promise.reject(error)
  }

  handleResponse = (response : AxiosResponse) => {
    return response.data
  }
}
