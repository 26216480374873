import { Tag } from 'bbcode-to-react';
import React from 'react';
import DataApi from '../../../../../../../api/DataApi';
import { FRONT_BASE_URL } from '../../../../../../../utils/common';
import styles from './style.module.scss';
import { decode } from 'html-entities'
import { useSelector } from 'react-redux'
import { State } from '../../../../../../../redux/reducers/index'
import { onErrorResponseShowMessage } from '../../../../../../../utils/onErrorReponseShowMessage/onErrorResponseShowMessage'
import TriangularPointer from '../../../../../TriangularPointer/TriangularPointer'
import { useTranslation } from 'react-i18next'

type Props = {
  title: string
  'article-deep-link-with-photo': string
}

export default class ZaradnaArticleDeepLinksWithPhotosTag<T = { linkify: boolean }> extends Tag<T> {
  toReact() {
    const props = this.params as Props
    return (
      <ZaradnaArticleDeepLinksWithPhotosTagComponent {...props}/>
    )
  }
}

const ZaradnaArticleDeepLinksWithPhotosTagComponent = (props: Props) => {
  const texts = useTranslation().t
  const userUuid = useSelector((e: State) => e.user.uuid)
  const [articles, setArticles] = React.useState<any[]>([])

  React.useEffect(() => {
    const uuids = props['article-deep-link-with-photo'].split(';')
    const tmpArticles: any[] = []
    Promise.all(uuids.map(uuid => DataApi.getArticleDetails(uuid))).then(res => {
      for (const ar of res) {
        tmpArticles[uuids.indexOf((ar as any).uuid)] = ar
      }
      setArticles(tmpArticles)
    }).catch(error => onErrorResponseShowMessage(error, true, { userUuid: userUuid }))
  }, [])

  return (
    <div className={styles.deepLinkWithPhoto_container}>
      {
        articles != null
        ? <>
            <div>
              {props.title ? decode(props.title) : texts('common:defaultSeeAlsoTagTitle')}
            </div>
            {
              articles.filter(e => e.mainImage != null).slice(0, 3).map((article: any, index: number) => {
                return (
                  <div key={"article-deep-link-with-photo-" + index} className={styles.deepLinkWithPhoto_articleTile}>
                    <div className={styles.deepLinkWithPhoto_articleImage}>
                      <img
                        src={`${article.mainImage.hostname}${article.mainImage.path}${article.mainImage.filename}`}
                        alt={article.imageAlt}
                        width="130px"
                        height="73px"
                      />
                    </div>
                    <div className={styles.deepLinkWithPhoto_description}>
                      <div className={styles.deepLinkWithPhoto_pointer}>
                        <TriangularPointer fill='#F35588'/>
                      </div>
                      <a href={`${FRONT_BASE_URL}/${article.slug}`} target={'_blank'} className={styles.deepLinkWithPhoto_title}>
                        {article.title}
                      </a>
                    </div>
                  </div>
                )
              })
            }
          </>
        : null
      }
    </div>
  )

  // return (
  //   <div className={styles.deepLinkWithPhoto_container}>
  //     <div className={styles.worthSeeing_description}>
  //       <div className={styles.worthSeeing_description_triangle}>
  //         <TriangularPointer 
  //           fill='#1976D2'
  //           width={'15px'}
  //           height={'15px'}
  //           customStyle={{
  //             marginRight: '5px'
  //           }}
  //         />
  //       </div>
  //       {props.title ? decode(props.title) : texts('common:defaultSeeAlsoTagTitle')}
  //     </div>
  //     {
  //       articles != null
  //       ?
  //         articles.filter(e => e.mainImage != null).slice(0, 3).map((article: any, index: number) => {
  //           return (
  //             <div 
  //               key={"article-deep-link-with-photo-" + index} 
  //               className={styles.worthSeeing_articleTile}
  //               onClick={() => {
  //                 window.open(`${FRONT_BASE_URL}/${article.slug}`, '_blank', 'noopener,noreferrer')
  //               }}
  //             >
  //               <div className={styles.worthSeeing_articleImage}>
  //                 <img
  //                   src={`${article.mainImage.hostname}${article.mainImage.path}${article.mainImage.filename}`}
  //                   alt={article.imageAlt}
  //                   width="130px"
  //                   height="68px"
  //                 />
  //               </div>
  //               <div className={styles.worthSeeing_title}>
  //                 {article.title}
  //               </div>
  //             </div>
  //           )
  //         })
  //       : null
  //     }
  //   </div>
  // )
}