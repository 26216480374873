import React from 'react'
import { MdMail } from 'react-icons/md'
import AssetLoader from '../../../assets/loader'
import BBCodeArticle from '../BBCodeArticle/BBCodeArticle'
import styles from './styles.module.scss'

type Props = {
  contentFieldName: string
  formikProps: any
  imageSrc?: string
}

const BBCodeDepeszaAuthorPreview = (props: Props) => {
  return (
    <>
      <div className={styles.authorInfo}>
        <div className={styles.container}>
          <div className={styles.authorImage}>
            <img
              src={props.imageSrc ? props.imageSrc : AssetLoader.getPlaceholderAvatar()}
              alt={''}
              width={125}
              height={125}
            />
          </div>
          <div className={styles.description}>
            <h1 className={styles.name}>{props.formikProps.values.name}</h1>
            <div className={styles.email}>
              <MdMail className={styles.emailIcon} />
              <a 
                href={`mailto:${props.formikProps.values.email}`} 
                className={styles.emailText} 
              >
                {props.formikProps.values.email}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.bbCodeSection}>
        <BBCodeArticle bbcode={props.formikProps.values[props.contentFieldName]} /> 
      </div>
    </>
  )
}

export default BBCodeDepeszaAuthorPreview
