import React from 'react'
import { useTranslation } from 'react-i18next'
import { AiOutlineClose } from 'react-icons/ai'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, Switch, useHistory } from 'react-router-dom'
import AuthRefreshApi from '../../api/AuthRefreshApi'
import AssetLoader from '../../assets/loader'
import { removeToken } from '../../redux/auth/actions'
import { addCmdMessage } from '../../redux/messages/actions'
import { State } from '../../redux/reducers/index'
import { addUserData, removeUserData } from '../../redux/user/actions'
import { onErrorResponseShowMessage } from '../../utils/onErrorReponseShowMessage/onErrorResponseShowMessage'
import { StorageService } from '../../utils/storage/StorageService'
import AddArticlePage from '../AddArticlePage/AddArticlePage'
import AddGalleryPage from '../AddGalleryPage/AddGalleryPage'
import AddPageToGalleryPage from '../AddPageToGalleryPage/AddPageToGalleryPage'
import ArticleListPage from '../ArticleListPage/ArticleListPage'
import CustomDialog from '../common/CustomDialog/CustomDialog'
import Drawer from '../common/Drawer/Drawer'
import LoadingButton from '../common/LoadingButton/LoadingButton'
import TopBar from '../common/TopBar/TopBar'
import { MessageEnum } from '../CustomToastProvider/types'
import EditArticlePage from '../EditArticlePage/EditArticlePage'
import EditGalleryPage from '../EditGalleryPage/EditGalleryPage'
import EditorAccount from '../EditorAccount/EditorAccount'
import EditPageToGalleryPage from '../EditPageToGalleryPage/EditPageToGalleryPage'
import styles from './styles.module.scss'

const Dashboard = () => {
  const token = useSelector((e: State) => e.auth.token)
  const userEmail = useSelector((e: State) => e.user.email)
  const texts = useTranslation().t
  const history = useHistory()
	const dispatch = useDispatch()
  
  const [showLogoutDialog, setShowLogoutDialog] = React.useState<boolean>(false)

  const checkToken = async () => {
    try {
      const res = await AuthRefreshApi.checkSession()
      StorageService.getInstance().setUserData(res)
      dispatch(addUserData(res))
    } catch (error) {
      StorageService.getInstance().clearUserCache()
      dispatch(removeToken())
      dispatch(removeUserData())
      history.replace('/login')
      onErrorResponseShowMessage(error)
    }
  }

  // React.useEffect(() => {
  //   if (!token) {
  //     StorageService.getInstance().clearUserCache()
  //     dispatch(removeToken())
  //     dispatch(removeUserData())
  //     history.replace('/login')
  //   }
  // }, [token])
  
  const storageHandler = () => {
    const token = StorageService.getInstance().getAccessToken()
    if (token) {
      checkToken()
    } else {
      StorageService.getInstance().clearUserCache()
      dispatch(removeToken())
      dispatch(removeUserData())
      history.replace('/login')
    }
  }

  React.useEffect(() => {
    checkToken()
    window.addEventListener('storage', storageHandler)
    return () => {
      window.removeEventListener('storage', storageHandler)
    }
  }, [])

  const handleLogoutButtonClick = () => {
    dispatch(addCmdMessage(MessageEnum.DISABLE_PAGE_COVER))
    StorageService.getInstance().clearUserCache()
    dispatch(removeToken())
    dispatch(removeUserData())
    history.replace('/login')
  }

  const closeConfirmLogoutDialog = () => {
    dispatch(addCmdMessage(MessageEnum.DISABLE_PAGE_COVER))
    setShowLogoutDialog(false)
  }

  const confirmLogoutDialogContent = () => {
    return (
      <div className={styles.logoutDialogContainer}>
        <div className={styles.logoutDialogCloseButton}>
          <span onClick={() => {closeConfirmLogoutDialog()}}>
            <AiOutlineClose size={20}/>
          </span>
        </div>
        <div className={styles.logoutDialogTitle}>
          {texts('dashboard:confirm_logout_dialog_title')}
        </div>
        <div className={styles.logoutDialogButtonsContainer}>
          <LoadingButton 
            text={texts('dashboard:confirm_logout_dialog_cancel_button_label')}
            className={styles.logoutDialogCancelButton}
            onClick={() => {
              closeConfirmLogoutDialog()
            }}
          />
          <LoadingButton 
            text={texts('dashboard:confirm_logout_dialog_logout_button_label')}
            className={styles.logoutDialogConfirmButton}
            onClick={handleLogoutButtonClick}
          />
        </div>
      </div>
    )
  }

  return (
    <>
      <CustomDialog 
        classes={{
          container: styles.logoutDialog
        }}
        hidden={!showLogoutDialog}
      >
        {confirmLogoutDialogContent()}
      </CustomDialog>
      <div className={styles.pageContainer}>
        <Drawer 
          logoutButton
          onLogout={() => {
            dispatch(addCmdMessage(MessageEnum.ENABLE_PAGE_COVER))
            setShowLogoutDialog(true)
          }}
        />
        <div className={styles.mainContentContainer}>
          <TopBar 
            logoSrc={AssetLoader.getLogo()}
            rightComponent={
              <div className={styles.topBarRightComponentContainer}>
                <div className={styles.topBarRightComponentTitle}>{texts('dashboard:logged_in_as')}</div>
                <div className={styles.topBarRightComponentUser}>{userEmail != '' ? userEmail ?? '-' : '-'}</div>
              </div>
            }
          />
          <div className={styles.mainContent}>
            <Switch>
              <Route path={'/'} exact component={ArticleListPage} />
              <Route path={'/article/add'} exact component={AddArticlePage} />
              <Route path={'/article/edit/:uuid'} exact component={EditArticlePage} />
              <Route path={'/gallery/add'} exact component={AddGalleryPage} />
              <Route path={'/gallery/add/page'} exact component={AddPageToGalleryPage} />
              <Route path={'/gallery/edit/:uuid'} exact component={EditGalleryPage} />
              <Route path={'/gallery/edit/:uuid/page'} exact component={EditPageToGalleryPage} />
              <Route path={'/account'} exact component={EditorAccount} />
              <Route path="/" render={() => <Redirect to={'/'}/>} />
              {/* {permissions.permissions.findIndex(e => e === PermissionLevel.SUPERUSER) > -1 ? <Route path='/admin' exact component={SuperuserSettings} /> : null}
              {permissions.permissions.findIndex(e => e === PermissionLevel.SUPERUSER) > -1 ? <Route path='/admin/user/:uuid' exact component={ManageUser} /> : null} */}
            </Switch>
          </div>

        </div>
      </div>
    </>
  )
}

export default Dashboard
