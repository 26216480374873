import { CopyToClipboard } from 'react-copy-to-clipboard'
import styles from './styles.module.scss'
import { useTranslation } from 'react-i18next'
import LoadingButton from '../LoadingButton/LoadingButton'
import { MdContentCopy } from 'react-icons/md'
import { IoMdLink } from 'react-icons/io'
import { FRONT_BASE_URL } from '../../../utils/common'
import { Resources, SuggestedWikiDto, LoadingState, TagDto } from '../../../types/data';
import React from 'react'

type Props = {
  searchFunction: () => void
  disabledControl?: boolean
  suggestedWikiList: Resources<SuggestedWikiDto[]>
  showList: boolean
  wikiTagSelected?: TagDto
}

const SuggestedWikiArticlesTile = (props: Props) => {
  const texts = useTranslation().t

  return (
    <div 
      className={styles.container}
      style={{
        opacity: props.disabledControl ? 0.5 : 1
      }}
    >
      <div className={styles.topSection}>
        <div className={styles.title}>
          {texts('article:suggested_wiki_articles_title')}
        </div>
        <LoadingButton 
          text={texts('article:suggested_wiki_articles_research_button')} 
          style={{width:'18.75rem', height:'1.875rem', whiteSpace:'nowrap', flexGrow:0}}
          loading={props.suggestedWikiList.state === LoadingState.LOADING}
          disabled={props.disabledControl}
          onClick={() => {props.searchFunction()}}
        />
      </div>
      <div className={styles.bottomSection}>
        <div className={styles.listSection}>
          <div className={styles.listTitle}>
            {texts('article:suggested_wiki_articles_phrase_label')}
          </div>
          {
            props.suggestedWikiList.data && props.suggestedWikiList.data.length > 0 && props.showList
            ? props.suggestedWikiList.data.map((data, idx) => (
                <div key={`suggested-wiki-article-phrase-${idx}`} className={`${styles.listPositionContainer} ${props.disabledControl ? styles.noPointerEvents : ''}`}>
                  <div>{data.matchedPhrase}</div>
                  <CopyToClipboard text={data.matchedPhrase}>
                    <div className={styles.copyToClipboardIcon}>
                      <MdContentCopy size={14}/>
                      <span className={styles.buttonTooltip}>
                        {texts('article:copy_suggested_tag_tooltip')}
                      </span>
                    </div>
                  </CopyToClipboard>
                </div> 
              ))
            : <div className={styles.placeholder}>
                {texts('article:suggested_wiki_articles_placeholder')}
              </div>
          }
        </div>
        <div className={styles.listSection}>
          <div className={styles.listTitle}>
           {texts('article:suggested_wiki_articles_tag_label')}
          </div>
          {
            props.suggestedWikiList.data && props.suggestedWikiList.data.length > 0 && props.showList
            ? props.suggestedWikiList.data.map((data, idx) => (
                <div key={`suggested-wiki-article-tag-${idx}`} className={styles.listPositionContainer}>{data.name}</div>
              ))
            : null
          }
        </div>
        <div className={styles.listSection}>
          <div className={styles.listTitle}>
            {texts('article:suggested_wiki_articles_wiki_label')}
          </div>
          {
            props.suggestedWikiList.data && props.suggestedWikiList.data.length > 0 && props.showList
            ? props.suggestedWikiList.data.map((data, idx) => (
                <div key={`suggested-wiki-article-title-${idx}`} className={`${styles.listPositionContainer} ${props.disabledControl ? styles.noPointerEvents : ''}`} >
                  <div>{data.wikiArticle.title}</div>
                  <div className={styles.copyToClipboardIcon} onClick={() => {window.open(`${FRONT_BASE_URL}/${data.wikiArticle.slug}`, '_blank', 'noopener,noreferrer')}}>
                    <IoMdLink size={16}/>
                    <span className={styles.buttonTooltip}>
                      {texts('article:open_article_in_new_tab_tooltip')}
                    </span>
                  </div>
                </div>
              ))
            : null
          }
        </div>
      </div>
    </div>
  )
}

export default SuggestedWikiArticlesTile